import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Packages = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <div className="container mb-5" id="packages">
      <div className="d-flex flex-column justify-content-center align-items-center my-5">
        <div className="mt-5 h6">Featured tours</div>
        <div className="h1">Most Popular Tours</div>
      </div>
      <Carousel
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        arrows={false}
        className="col-md-12 "
      >
        {/* card */}
        {
          cardData.map(card => (
            <div className="card" key={card.id}>
              <img className="card-img-top" src={card.image} alt="Card image cap" />
              <div className="card-body">
                <p className="card-title my-2">{card.rating}</p>
                <h5 className="card-text my-2">{card.place}</h5>
                <p className="card-text my-1"><span className="text-danger">{card.rating}</span> / Per Person</p>
              </div>
            </div>
          ))
        }




      </Carousel>
    </div>
  );
};


const cardData = [
  {
    id: '001',
    image: 'https://images.unsplash.com/photo-1663501001437-c1c20ac6b37d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxNXx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60" alt="Card image cap',
    rating: '8.0 superb',
    place: 'National Park 2 Days Tour',
    charges: '$1870',
  },
  {
    id: '002',
    image: 'https://images.unsplash.com/photo-1663515994080-e0615f6a047c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxNHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60',
    rating: '8.0 superb',
    place: 'National Park 2 Days Tour',
    charges: '$1870',
  },
  {
    id: '003',
    image: 'https://images.unsplash.com/photo-1663530294185-5af3692326c6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxOHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60',
    rating: '8.0 superb',
    place: 'National Park 2 Days Tour',
    charges: '$1870',
  },
  {
    id: '004',
    image: 'https://images.unsplash.com/photo-1663551991664-9a4d701e968f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwzMHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60',
    rating: '8.0 superb',
    place: 'National Park 2 Days Tour',
    charges: '$1870',
  },
  {
    id: '005',
    image: 'https://tevily-nextjs.vercel.app/_next/static/media/popular-tours__img-1.e72f98f2.jpg',
    rating: '8.0 superb',
    place: 'National Park 2 Days Tour',
    charges: '$1870',
  },
  {
    id: '006',
    image: 'https://tevily-nextjs.vercel.app/_next/static/media/popular-tours__img-3.58c2f734.jpg',
    rating: '8.0 superb',
    place: 'National Park 2 Days Tour',
    charges: '$1870',
  },
  {
    id: '007',
    image: 'https://tevily-nextjs.vercel.app/_next/static/media/popular-tours__img-4.506b0db7.jpg',
    rating: '8.0 superb',
    place: 'National Park 2 Days Tour',
    charges: '$1870',
  },
  {
    id: '008',
    image: 'https://tevily-nextjs.vercel.app/_next/static/media/popular-tours__img-4.506b0db7.jpg',
    rating: '8.0 superb',
    place: 'National Park 2 Days Tour',
    charges: '$1870',
  }
]


export default Packages;
