import React, {useState} from "react";
import { TextField } from "@mui/material";
import MUIDataTable from "mui-datatables";
import CustomDropdown from "../../CustomDrp";
import DeleteModal from "../../DeleteModal";

const RoomsType = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const columns = [
    {
        name: "id",
        label: "S.No",
        options: {
          filter: false,
          sort: true,
        },
    },
    {
      name: "hotel",
      label: "hotel",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "room_type",
      label: "room type",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "Action",
      label: "action",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="">
              <button
                className="btn-success rounded edit-delete-icon-button me-1"
                onClick={() => console.log(value, tableMeta)}
              >
                <i className="fa fa-thin fa-pencil"></i>
              </button>
              <button
                className="btn-danger rounded edit-delete-icon-button"
                onClick={() => {
                  setShowDeleteModal(true);
                  setModalData(tableMeta.rowData);
                }}
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
              </button>
            </div>
          );
        },
      },
    },
  ];

  const data = [
    {
      id: 1,
      hotel: "Swissotel Al Maqam Makkah",
      room_type: "Deluxe"
    },
  ];
  const options = {
    filter: false,
    Selection: false,
    print: false,
    download: false,
    viewColumns: false,
    displayRowCheckbox: false,
    selectableRows: "none",
  };
  return (
    <div className="col-12 col-sm-6 mx-auto rounded-3 shadow mt-5 pb-5 flash-news">
      <p className="h4 otawix-heading">Room Type</p>
      <div className="col-12 px-4 mt-5">
        <div className="col-12 d-flex">
          <div className="col-5 me-5">
            <CustomDropdown
              arry={["AED", "EURO", "Room 3", "Room 4"]}
              placehlder="Hotel *"
            />
          </div>
          <TextField
            id="standard-basic"
            label="Room Name *"
            variant="standard"
            className="col-sm-5"
          />
        </div>
        <div className="col-12 py-2 mt-3 d-flex">
          <div className="col-sm-5 me-5"></div>
          <div className="col-sm-5 buttons">
            <button className="btn btn-danger me-2">
              <i className="fa fa-thin fa-close"></i>
            </button>
            <button className="btn btn-primary">
              <i className="fa fa-thin fa-plus"></i>
            </button>
          </div>
          <div className="col-sm-1"></div>
        </div>
        <div className="col-12 mt-3 d-flex flex-wrap">
          <TextField
            id="standard-basic"
            label="Room Name *"
            variant="standard"
            className="col-sm-5 me-5 my-4"
          />
        </div>
        <button className="btn setup-btn">save</button>
      </div>
      <div className="col-12 mt-5 px-4">
      <DeleteModal
          show={showDeleteModal}
          setShow={setShowDeleteModal}
          rowId={modalData && modalData[0]}
        />
        <MUIDataTable
          className="muidatatable"
          title={"Room Type Details"}
          data={data}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  );
};

export default RoomsType;
