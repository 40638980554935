import React, { useState } from "react";
import AgentPNR from "./ImportTab/AgentPNR";
import NewPnr from "./ImportTab/NewPnr";
import "../../Styles/import.css";
import CustomDropDown from '../CustomDrp'
import { TextField } from "@mui/material";

const ImportTab = () => {
  const [value, setValue] = useState("Agent PNR");
  return (
    <div>
      <div className="row align-items-end ">
        <div className="col-sm-3">
          <CustomDropDown arry={['Agent PNR', 'New PNR']} placehlder='' initvalue={value} setfunction={setValue} />
        </div>
        <div className="col-3">
          {value === "Agent PNR" && <TextField variant='standard' label='Select a Company'
            className="w-100"
          />}
        </div>

      </div>



      <div>
        {value === "Agent PNR" && <AgentPNR />}
        {value === "New PNR" && <NewPnr />}
      </div>

    </div>
  );
};

export default ImportTab;
