import React, { useState } from "react";
import TextSlider from "./TextSlider";
import ImageSlider from "./ImageSlider";
import Cards from "./Cards";
import Graph from "./Graph";


import Bookings from '../../assets/images/Bookings.png'
import Invoice from '../../assets/images/Invoice.png'
import Cancel from '../../assets/images/Cancel.png'
import Agents from '../../assets/images/Agents.png'
import Refund from '../../assets/images/Refund.png'
import Tickets from '../../assets/images/Tickets.png'
import Reciept from '../../assets/images/Reciept.png'
import Supplier from '../../assets/images/Suppliers.png'
import Limit from '../../assets/images/approved-limit.png'
import UsedLimit from '../../assets/images/limit-used.png'
import TotalPayment from '../../assets/images/payments.png'
import TotalSale from '../../assets/images/total-sale.png'

const Dashboard = () => {
  const [UserAdmin, setUserAdmin] = useState('agent')
  return (




    <div className="row mb-5" id="contentRow">

      <div className="w-100 px-auto pt-1 pt-md-5">
        <div
          className="bg-white border col-12 col-sm-9 mx-auto rounded-3 shadow dashboard-wrapper login_backgorund_image"
          style={{ minHeight: "auto" }}
        >
          <div className="mx-2 mx-sm-4 pt-2">
            <TextSlider />
          </div>
          <div className="mx-2 mx-sm-4 pb-2">
            <ImageSlider />
          </div>

          {UserAdmin === 'agent' ? <AgentSideDashbord /> :
            <div className="mx-2 mx-sm-4 my-3 py-1 border-0 border-md">

              <div className="row g-md-0">

                <div className="col-6 col-md-3 my-2">
                  <Cards bg_color='booking' title='Booking' img={Bookings} counter='34' preview={true} />
                </div>
                <div className="col-6 col-md-3 my-2">
                  <Cards bg_color='cancel' title='Cancels' img={Cancel} counter='34' preview={true} />
                </div>
                <div className="col-6 col-md-3 my-2">
                  <Cards bg_color='agent' title='Agents' img={Agents} counter='34' preview={true} />
                </div>
                <div className="col-6 col-md-3 my-2">
                  <Cards bg_color='invoice' title='Invoice (PKR)' img={Invoice} counter='34' preview={false} />
                </div>
              </div>

              <div className="row g-md-0">
                <div className="col-6 col-md-3 my-2">
                  <Cards bg_color='tickets' title='Tickets' img={Tickets} counter='34' preview={true} />
                </div>
                <div className="col-6 col-md-3 my-2">
                  <Cards bg_color='refund' title='Refund' img={Refund} counter='34' preview={true} />
                </div>
                <div className="col-6 col-md-3 my-2">
                  <Cards bg_color='supplier' title='Suppliers' img={Supplier} counter='34' preview={true} />
                </div>
                <div className="col-6 col-md-3 my-2">
                  <Cards bg_color='reciept' title='Receipt (PKR)' img={Reciept} counter='34' preview={false} />
                </div>
              </div>
            </div>
          }

          <div className="mx-2 mx-sm-3 py-2 mb-3 row">
            <div className="col-12 col-md-6">
              <Graph
                title="Agents Sale & Refund Chart"
                labels={["ZAIRAA B2B", "ZAIRAA Main Off", "KPT"]}
                values={[0.4, 0.6, 0.8, 1.0]}
              />
            </div>

            <div className="col-12 col-md-6 mt-2 mt-md-0">
              <Graph
                title="Supplier Sale & Refund Chart"
                labels={[
                  "ZAIRAA B2B",
                  "ZAIRAA Main Off",
                  "KPT",
                  "B2B",
                  "Value",
                  "Ticker",
                  "Another Value"
                ]}
                values={[0, 0.4, 0.6, 0.2, 0.8, 0.6, 1.0]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};


const AgentSideDashbord = () => {
  return (
    <div className="row mx-2 mx-sm-4 my-3 py-1 border-0 border-md">

      <div className="col-6 g-md-0">
        <div className="row g-md-0">


          <div className="col-6  my-2">
            <Cards bg_color='booking' title='Booking' img={Bookings} counter='34' preview={true} />
          </div>
          <div className="col-6 my-2">
            <Cards bg_color='cancel' title='Cancels' img={Cancel} counter='34' preview={true} />
          </div>
          <div className="col-6 my-2">
            <Cards bg_color='tickets' title='Tickets' img={Tickets} counter='34' preview={true} />
          </div>
          <div className="col-6 my-2">
            <Cards bg_color='refund' title='Refund' img={Refund} counter='34' preview={true} />
          </div>
        </div>
      </div>

      <div className="col-6 g-md-0 card_container_column">
        <div className="row g-md-0">
          <div className="col-6 my-2">
            <Cards bg_color='#08629e' title='Approved Limit' img={Limit} counter='34' preview={true} />
          </div>
          <div className="col-6 my-2">
            <Cards bg_color='#ae6f3b' title='Used Limit' img={UsedLimit} counter='34' preview={false} />
          </div>
          <div className="col-6 my-2">
            <Cards bg_color='supplier' title='Total Payment' img={TotalPayment} counter='34' preview={true} />
          </div>
          <div className="col-6 my-2">
            <Cards bg_color='reciept' title='Total Sale' img={TotalSale} counter='34' preview={false} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard;
