import React from 'react';
import Header from '../components/Common/Header'
import VisaTypeComp from '../components/Setup/Visa/Type'
import Footer from '../components/Footer'

const VisaType = () => {
  return (
    <div className='Full_height'>
      <Header active="setup" />
      <VisaTypeComp />
      <Footer />
    </div>
  )
}

export default VisaType