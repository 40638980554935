import React, { useEffect, useState } from "react";
import Header from "../components/Common/Header";
import Tabs from "../components/BookingDetails/Tabs";
import General from "../components/BookingDetails/General";
import Contact from "../components/BookingDetails/Contact";
import Pricing from "../components/BookingDetails/Pricing";
import Invoice from "../components/BookingDetails/Invoice";
import Chat from "../components/BookingDetails/Chat";
import Requests from "../components/BookingDetails/Requests";
import { useLocation } from "react-router-dom";

import "../Styles/bookingdetails.css";

function BookingDetails() {
  const location = useLocation();
  const [active_tab, setActive_Tab] = useState(
    location.pathname.split("/")[3] === "active" ||
      location.pathname.split("/")[3] === "resolved"
      ? "requests"
      : "general"
  );

  useEffect(() => {
    console.log(location.pathname.split("/")[3]);
  });

  return (
    <div className='Full_height'>
      <Header active="bookings" />

      <div className="booking_details_container">
        <Tabs tabs={active_tab} setTabs={setActive_Tab} />

        <div>
          {active_tab === "general" && <General />}
          {active_tab === "contact" && <Contact />}
          {active_tab === "pricing" && <Pricing />}
          {active_tab === "invoice" && <Invoice />}
          {active_tab === "chats" && <Chat />}
          {active_tab === "requests" && <Requests />}
        </div>
      </div>
    </div>
  );
}

export default BookingDetails;
