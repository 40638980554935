import { Button, TextField } from '@mui/material'
import React, { useState } from 'react'
import Header from '../../components/Common/Header'
import CustomDropdown from '../CustomDrp'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import AgentGroupPaymentModel from './Common/AgentGroupPaymentModel';
import AgentGroupAccountModel from './Common/AgentGroupAccountModel';

function AgentGroupSubPage() {
    const [ShowPaymentModel, setShowPaymentModel] = useState(false)
    const [ShowAccountModel, setShowAccountModel] = useState(false)
    return (
        <div className='Full_height'>

            <Header />
            <div className="manual_invoices mx-auto mt-5 width_60percent">
                    <p className="otawix-heading h4">New Agent Group</p>
                <div className="px-5 py-3">
                    {/* ************ */}
                    <div className='row my-4 mt-2' >
                        <div className='col-4'>
                            <TextField label='Name' variant='standard' className='w-100' />
                        </div>
                        <div className='col-4'>
                            <CustomDropdown arry={['Zairra Main Office', 'Travel Consultant', 'Net Net Agents', 'Counter Client']} placehlder='Type' />
                        </div>
                        <div className='col-4'>
                            <CustomDropdown arry={['Pak Rupee', 'Saudi Riyal', 'British Pond', 'UAE Dirham', 'US Dollar']} placehlder='Currency' />
                        </div>
                    </div>
                    {/* ********************* */}
                    <div className='my-5'>
                        <div className='rounded-2 border'>
                            <div className="d-flex justify-content-between align-items-center border-bottom py-2 px-3">
                                {
                                    ShowPaymentModel && <AgentGroupPaymentModel setShowPaymentModel={setShowPaymentModel} />
                                }
                                <div className='h5'>Payment</div>
                                <div>
                                    <Button variant='primary' startIcon={<AddRoundedIcon />}
                                        onClick={e => setShowPaymentModel(!ShowPaymentModel)}
                                        style={{background: "#0d6efd", color: "#fff"}}
                                    >Add Payment</Button>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-3 p-4 h5'>
                                    Paymode
                                </div>
                                <div className='col-3 p-4 h5'>
                                    Payment (amount)
                                </div>
                                <div className='col-3 p-4 h5'>
                                    Payment (%)
                                </div>
                                <div className='col-3 p-4 h5'>
                                    Options
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-3 p-4 py-2 h6'>
                                    Book and Pay Now
                                </div>
                                <div className='col-3 p-4 py-2 h6'>
                                    0
                                </div>
                                <div className='col-3 p-4 py-2 h6'>
                                    0
                                </div>
                                <div className='col-3 p-4 py-2 h6'>
                                    <div class="checkbox-rect">
                                        <input type="checkbox" id="option" name="option" />
                                        <label for="option" className="h6">

                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* ********************  account */}

                    <div className='my-5'>
                        <div className='rounded-2 border'>
                            <div className="d-flex justify-content-between align-items-center border-bottom py-2 px-3">
                                {
                                    ShowAccountModel && <AgentGroupAccountModel setShowAccountModel={setShowAccountModel} />
                                }
                                <div className='h5'>Account</div>
                                <div>
                                    <Button variant='primary'
                                        onClick={e => setShowAccountModel(!ShowAccountModel)}
                                        style={{background: "#0d6efd", color: "#fff"}}
                                    >Add Account</Button>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-4 p-4 h5'>
                                    Bank Name
                                </div>
                                <div className='col-4 p-4 h5'>
                                    Bank Account
                                </div>
                                <div className='col-4 p-4 h5'>
                                    Options
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-4 p-4 py-2 h6'>
                                    Jazz Cash
                                </div>

                                <div className='col-4 p-4 py-2 h6'>
                                    03001234567
                                </div>
                                <div className='col-4 p-4 py-2 h6'>
                                    <div class="checkbox-rect">
                                        <input type="checkbox" id="account" name="account" />
                                        <label for="account" className="h6">

                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* ******************* */}
                    <hr />
                    <div className='d-flex justify-content-end align-items-end'>
                        <div className='me-2'>
                            <Button variant='danger' style={{background: "#dc3545", color: "#fff"}}>Close</Button>
                        </div>
                        <div className=''>
                            <Button variant='contained' style={{background: "#0d6efd", color: "#fff"}}>Save</Button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default AgentGroupSubPage