import React from "react";

const TravelersDropDown = ({ title, values, ageSpan, setData }) => {
  return (
    <div className="col-12 d-flex justify-content-between" style={{width: "250px"}}>
      <div className="col-5 me-5">
        <label className="dropdown-label">
          {" "}
          {title.split("_").join(" ")}{" "}
          {ageSpan && <span className="age-brackets">({ageSpan})</span>}
          {title === "adults" && <span className="age-brackets text-danger">({ "> 12 years"})</span>}
          {title === "child" && <span className="age-brackets text-danger">({ "1-12 years"})</span>}
          {title === "infant" && <span className="age-brackets text-danger">({"< 2 years"})</span>}
        </label>
      </div>
      <div name="traveler_plusminus" className="col-6 d-flex">
        <button
          id="plusBtn"
          aria-label="Remove one remain3adults"
          aria-disabled="false"
          className="travelers__minus"
          disabled={
            values[title] === 0 || (title === "adults" && values[title] === 1)
          }
          onClick={(e) => {
            //   if (
            //     values[title] === 0 ||
            //     (title === "adults" && values[title] === 1)
            //   )
            //     return;
            const update = { ...values };
            if (title === "adults" && update.infant === update.adults) {
              update["infant"] = values[title] - 1;
            }
            update[title] = values[title] - 1;
            setData(update);
          }}
        >
          -<span className="sr-only">Remove adults</span>
        </button>
        <label className="mx-auto" id="lbladults">
          {values[title]}
        </label>
        <button
          id="minusBtn"
          aria-label="Added 3 adults"
          aria-disabled="false"
          className="travelers__plus"
          data-toggle="collapse"
          disabled={
            (title === "infant" && values[title] === values.adults) ||
            (title === "adults" && values[title] === 8) ||
            ((title === "child" || title === "adults") &&
              values["child"] + values["adults"] === 8)
          }
          onClick={(e) => {
            // if(ageSpan) {
            //     const maxAge = Number(ageSpan.split("-")[1]);
            //     if(maxAge === values[title]) return;
            // }
            //   if (title === "infant" && values[title] === values.adults)
            //     return;
            //   if (title === "adults" && values[title] === 8) return;
            const update = { ...values };
            update[title] = values[title] + 1;
            setData(update);
          }}
        >
          +<span className="sr-only">Add adults</span>
        </button>
      </div>
    </div>
  );
};

export default TravelersDropDown;
