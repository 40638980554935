import React from "react";
import Header from "../components/Common/Header";
import "../Styles/manualInvoices.css";
import CustomDropdown from "../components/CustomDrp";
import ReactDatePicker from "react-datepicker";
import { Button, TextField } from "@mui/material";


function SaleReport() {
  const [startDate, setStartDate] = React.useState("");
  return (
    <div className="finance-container Full_height">
      <Header />
      <div className="manual_invoices  mx-auto mt-5">
        <div className="manual_invoices_header">
          <div className="w-100 text-center">Sale Report (Date Wise / Sale Type Wise / Agent Wise / Supplier Wise)</div>
        </div>
        <div className="px-2 px-md-5">
          <div className="pt-5">
            <div className="row align-items-end">
              <div className="col-6">
                <ReactDatePicker
                  placeholderText="From Date"
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                  // onCalendarClose={handleCalendarClose}
                  minDate={new Date()}
                  // onCalendarOpen={handleCalendarOpen}
                  className="date_picker"
                />

              </div>
              <div className="col-6">

                <ReactDatePicker
                  placeholderText="To Date"
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                  // onCalendarClose={handleCalendarClose}
                  minDate={new Date()}
                  // onCalendarOpen={handleCalendarOpen}
                  className="date_picker "
                />
              </div>
            </div>
            <div className="row align-items-end pt-4 pt-md-5">
              <div className="col-6">
                <CustomDropdown arry={['Select All', 'Flight', 'Flight Group', 'Insurance', 'Visa', 'Transfer', 'Activity']} placehlder='Select Sales Type' />
              </div>
              <div className="col-6">
                <TextField id="standard-basic" label="Select Office" variant="standard" className="w-100" />
              </div>


              <div className="col-6 pt-4 pt-md-5">
                <TextField id="standard-basic" label="Select Supplier" variant="standard" className='w-100' />
              </div>
            </div>
          </div>



          {/* radio buttons */}
          <div className="d-flex justify-content-center align-items-center pt-5">
            <div className="me-5">
              <label class="form-control">
                <input type="radio" name="radio" />
                <span className="h6">PDF</span>
              </label>
            </div>
            <div className="">
              <label class="form-control">
                <input type="radio" name="radio" />
                <span className="h6">EXCEL</span>
              </label>
            </div>
          </div>


          {/* generat report button */}
          <div className="container py-4">
            <div className="d-flex justify-content-center align-items-center my-3 mt-4 ">

              <Button variant="contained">Generate Report</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}





export default SaleReport;
