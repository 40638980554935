import React, { useEffect } from 'react'
import Header from '../components/Common/Header'
import Dashboard from '../components/Home/Dashboard'
import Footer from '../components/Footer'
import "../Styles/home.css";

const Home = () => {
  useEffect(() => localStorage.setItem("Active_link", ""))
  return (
    <div className=' Full_height'>
      <Header />
      <Dashboard />
      <Footer />
    </div>
  )
}

export default Home