import React, { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const SetupDropDown = () => {
  const activeLink = localStorage.getItem("Active_link");
  const setActiveLink = (value) => localStorage.setItem("Active_link", value);
  const [payment, setpayment] = useState(false);
  const [promotion, setPromotion] = useState(false);
  const [visa, setVisa] = useState(false);
  const [umrah, setumrah] = useState(false);
  const [newRegistration, setnewRegistration] = useState(false);

  const [agency_vocher, setAgency_Vouchers] = useState(false);
  const navigate = useNavigate();
  return (
    <div className="dropdown-menu-setup" id="dropdown-menu-setup">
      <div>
        <div
          className={`finance-dropdown-items ${(activeLink === "setup-payments" || activeLink === "setup-payment-vouchers") && "menu_link_container-active"}`}
          onClick={() => {
            setpayment(!payment);
            setPromotion(false);
            setVisa(false);
            setumrah(false);
            setnewRegistration(false);
            setActiveLink("setup-payments")
          }}
        >
          <div className="d-flex align-items-center">
            <div className="drp-links">Payments</div>
          </div>
          <div>
            {payment ? (
              <IoIosArrowUp size={25} />
            ) : (
              <IoIosArrowDown size={25} />
            )}
          </div>
        </div>
        {payment && (
          <div className="py-2" style={{ paddingLeft: "2rem" }}>
            <div
              className={`finance-dropdown-items ${activeLink === "" && "menu_link_container-active"}`}
              onClick={(e) => navigate("/make-payment")}
            >
              Make Payment
            </div>
            <div
              className={`finance-dropdown-items ${activeLink === "" && "menu_link_container-active"}`}
              onClick={(e) => navigate("/corporate-payment")}
            >
              Corporate Payment
            </div>
            <div
              className={`finance-dropdown-items ${activeLink === "" && "menu_link_container-active"}`}
              onClick={(e) => navigate("/paid-deposits")}
            >
              Reciept
            </div>
            <div
              className={`finance-dropdown-items ${activeLink === "" && "menu_link_container-active"}`}
              onClick={(e) => navigate("/collection")}
            >
              Collection
            </div>
            <div
              className={`finance-dropdown-items ${activeLink === "setup-payment-vouchers" && "menu_link_container-active"}`}
              onClick={() => {
                setAgency_Vouchers(!agency_vocher);
                setActiveLink("setup-payment-vouchers")
              }}
            >
              <div>Payment Vochers</div>
              <div>
                {agency_vocher ? (
                  <IoIosArrowUp size={25} />
                ) : (
                  <IoIosArrowDown size={25} />
                )}
              </div>
            </div>
            {agency_vocher && (
              <div className="py-2" style={{ paddingLeft: "2rem" }}>
                <div
                  className={`finance-dropdown-items ${activeLink === "" && "menu_link_container-active"}`}
                  onClick={(e) => navigate("/admin-vouchers")}
                >
                  Unpaid Vocher
                </div>
                <div
                  className={`finance-dropdown-items ${activeLink === "" && "menu_link_container-active"}`}
                  onClick={(e) => navigate("/admin-paid-vouchers")}
                >
                  Paid Vocher
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <div>
        <div
          className={`finance-dropdown-items ${activeLink === "setup-promotions" && "menu_link_container-active"}`}
          onClick={() => {
            setpayment(false);
            setPromotion(!promotion);
            setVisa(false);
            setumrah(false);
            setnewRegistration(false);
            setActiveLink("setup-promotions")
          }}
        >
          <div className="d-flex align-items-center">
            <div className="drp-links">Promotions</div>
          </div>
          <div>
            {promotion ? (
              <IoIosArrowUp size={25} />
            ) : (
              <IoIosArrowDown size={25} />
            )}
          </div>
        </div>
        {promotion && (
          <div className="py-2" style={{ paddingLeft: "2rem" }}>
            <div
              className={`finance-dropdown-items ${activeLink === "" && "menu_link_container-active"}`}
              onClick={(e) => navigate("/flash-news")}
            >
              Add Flash News
            </div>
            <div
              className={`finance-dropdown-items ${activeLink === "" && "menu_link_container-active"}`}
              onClick={(e) => navigate("/flight-groups")}
            >
              Flight Groups
            </div>
            <div
              className={`finance-dropdown-items ${activeLink === "" && "menu_link_container-active"}`}
              onClick={(e) => navigate("/circular")}
            >
              Circular
            </div>
            <div
              className={`finance-dropdown-items ${activeLink === "" && "menu_link_container-active"}`}
              onClick={(e) => navigate("/promotions")}
            >
              Promotions
            </div>
          </div>
        )}
      </div>

      <div>
        <div
          className={`finance-dropdown-items ${activeLink === "setup-visa" && "menu_link_container-active"}`}
          onClick={() => {
            setpayment(false);
            setPromotion(false);
            setVisa(!visa);
            setumrah(false);
            setnewRegistration(false);
            setActiveLink("setup-visa")
          }}
        >
          <div className="d-flex align-items-center">
            <div className="drp-links">Visa</div>
          </div>
          <div>
            {visa ? <IoIosArrowUp size={25} /> : <IoIosArrowDown size={25} />}
          </div>
        </div>
        {visa && (
          <div className="py-2" style={{ paddingLeft: "2rem" }}>
            <div
              className={`finance-dropdown-items ${activeLink === "" && "menu_link_container-active"}`}
              onClick={(e) => navigate("/visa-countries")}
            >
              Countries
            </div>
            <div
              className={`finance-dropdown-items ${activeLink === "" && "menu_link_container-active"}`}
              onClick={(e) => navigate("/visa-type")}
            >
              Visa Type
            </div>
            <div
              className={`finance-dropdown-items ${activeLink === "" && "menu_link_container-active"}`}
              onClick={(e) => navigate("/visa-requirements")}
            >
              Requirements
            </div>
            <div
              className={`finance-dropdown-items ${activeLink === "" && "menu_link_container-active"}`}
              onClick={(e) => navigate("/visa-terms")}
            >
              Terms & Conditions
            </div>
          </div>
        )}
      </div>

      <div>
        <div
          className={`finance-dropdown-items ${activeLink === "setup-umrah" && "menu_link_container-active"}`}
          onClick={() => {
            setpayment(false);
            setPromotion(false);
            setVisa(false);
            setumrah(!umrah);
            setnewRegistration(false);
            setActiveLink("setup-umrah")
          }}
        >
          <div className="d-flex align-items-center">
            <div className="drp-links">Umrah</div>
          </div>
          <div>
            {umrah ? <IoIosArrowUp size={25} /> : <IoIosArrowDown size={25} />}
          </div>
        </div>
        {umrah && (
          <div className="py-2" style={{ paddingLeft: "2rem" }}>
            <div
              className={`finance-dropdown-items ${activeLink === "" && "menu_link_container-active"}`}
              onClick={(e) => navigate("/umrah-hotels")}
            >
              Hotel
            </div>
            <div
              className={`finance-dropdown-items ${activeLink === "" && "menu_link_container-active"}`}
              onClick={(e) => navigate("/umrah-tranfer")}
            >
              Transfer
            </div>
            <div
              className={`finance-dropdown-items ${activeLink === "" && "menu_link_container-active"}`}
              onClick={(e) => navigate("/umrah-visa-supplier")}
            >
              Visa Supplier
            </div>
            <div
              className={`finance-dropdown-items ${activeLink === "" && "menu_link_container-active"}`}
              onClick={(e) => navigate("/umrah-transfer-supplier")}
            >
              Transfer Supplier
            </div>
            <div
              className={`finance-dropdown-items ${activeLink === "" && "menu_link_container-active"}`}
              onClick={(e) => navigate("/room-type")}
            >
              Hotel Room Type
            </div>
            <div
              className={`finance-dropdown-items ${activeLink === "" && "menu_link_container-active"}`}
              onClick={(e) => navigate("/hotel-rates")}
            >
              Umrah Hotel Rates
            </div>
          </div>
        )}
      </div>

      <div>
        <div
          className={`finance-dropdown-items ${activeLink === "setup-registration" && "menu_link_container-active"}`}
          onClick={() => {
            setpayment(false);
            setPromotion(false);
            setVisa(false);
            setumrah(false);
            setnewRegistration(!newRegistration);
            setActiveLink("setup-registration")
          }}
        >
          <div className="d-flex align-items-center">
            <div className="drp-links">New Registration</div>
          </div>
          <div>
            {newRegistration ? (
              <IoIosArrowUp size={25} />
            ) : (
              <IoIosArrowDown size={25} />
            )}
          </div>
        </div>
        {newRegistration && (
          <div className="py-2" style={{ paddingLeft: "2rem" }}>
            <div
              className={`finance-dropdown-items ${activeLink === "" && "menu_link_container-active"}`}
              onClick={(e) => navigate("/new-agency-registration")}
            >
              New Agency Registration
            </div>
            <div
              className={`finance-dropdown-items ${activeLink === "" && "menu_link_container-active"}`}
              onClick={(e) => navigate("/new-corporate-registration")}
            >
              New Corporate Registration
            </div>
          </div>
        )}
      </div>

      <div
        className={`finance-dropdown-items ${activeLink === "setup-new-supplier" && "menu_link_container-active"}`}
        onClick={(e) => {navigate("/supplier"); setActiveLink("setup-new-supplier")}}
      >
        Add New Supplier
      </div>
      <div
        className={`finance-dropdown-items ${activeLink === "setup-agent-categories" && "menu_link_container-active"}`}
        onClick={(e) => {navigate("/agent-categories"); setActiveLink("setup-agent-categories")}}
      >
        Agent Categories
      </div>
      <div
        className={`finance-dropdown-items ${activeLink === "setup-agent-groups" && "menu_link_container-active"}`}
        onClick={(e) => {navigate("/agent-groups"); setActiveLink("setup-agent-groups")}}
      >
        Agents Group
      </div>
      <div
        className={`finance-dropdown-items ${activeLink === "setup-agents" && "menu_link_container-active"}`}
        onClick={(e) => {navigate("/agents"); setActiveLink("setup-agents")}}
      >
        Agent
      </div>
      <div
        className={`finance-dropdown-items ${activeLink === "setup-bak-details" && "menu_link_container-active"}`}
        onClick={(e) => {navigate("/bank-details"); setActiveLink("setup-bak-details")}}
      >
        Bank Details
      </div>
      <div
        className={`finance-dropdown-items ${activeLink === "setup-currency" && "menu_link_container-active"}`}
        onClick={(e) => {navigate("/currency"); setActiveLink("setup-currency")}}
      >
        Currency
      </div>
      <div
        className={`finance-dropdown-items ${activeLink === "setup-add-role" && "menu_link_container-active"}`}
        onClick={(e) => {navigate("/role"); setActiveLink("setup-add-role")}}
      >
        Add Role
      </div>
      <div
        className={`finance-dropdown-items ${activeLink === "setup-pricing" && "menu_link_container-active"}`}
        onClick={(e) => {navigate("/pricing"); setActiveLink("setup-pricing")}}
      >
        Pricing
      </div>
      <div
        className={`finance-dropdown-items ${activeLink === "setup-add-airline" && "menu_link_container-active"}`}
        onClick={(e) => {navigate("/airlines"); setActiveLink("setup-add-airline")}}
      >
        Add Airlines
      </div>
      <div
        className={`finance-dropdown-items ${activeLink === "setup-password" && "menu_link_container-active"}`}
        onClick={(e) => {navigate("/apis-credentials"); setActiveLink("setup-password")}}
      >
        Password (Api)
      </div>

      <div
        className={`finance-dropdown-items ${activeLink === "setup-supplier-rules" && "menu_link_container-active"}`}
        onClick={(e) => {navigate("/supplier-rules"); setActiveLink("setup-supplier-rules")}}
      >
        Suppliers Roles
      </div>
      <div
        className={`finance-dropdown-items ${activeLink === "setup-resolved-request" && "menu_link_container-active"}`}
        onClick={(e) => {navigate("/resolved-requests"); setActiveLink("setup-resolved-request")}}
      >
        Resolved Requests
      </div>
    </div>
  );
};
export default SetupDropDown;
