import React from 'react'
import Header from '../components/Common/Header'
import UserComp from "../components/Agency/User";
import Footer from '../components/Footer'


const Users = () => {
  return (
    <div className='Full_height'>
      <Header active="setup" />
      <UserComp />
      <Footer />
    </div>
  )
}

export default Users