import React from "react";
import Header from "../components/Common/Header";
import "../Styles/manualInvoices.css";
import { TextField } from "@mui/material";
import "../Styles/radio.css";
import ReactDatePicker from "react-datepicker";

function AgentAccountStatement() {
  const [startDate, setStartDate] = React.useState("");
  return (
    <div className='Full_height'>
      <Header />
      <div className="manual_invoices mx-auto mt-5">
        <div className="manual_invoices_header">
          <div className="w-100 text-center">Agent Account Statement</div>
        </div>
        <div className="px-2 px-md-5">
          <div className=" pt-5">
            <div className="row align-items-center">
              <div className="input_groupS col-6">
                <ReactDatePicker
                  placeholderText="From Date"
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                  // onCalendarClose={handleCalendarClose}
                  minDate={new Date()}
                  // onCalendarOpen={handleCalendarOpen}
                  className="date_picker "
                />
              </div>
              <div className="input_groupS col-6">
                <ReactDatePicker
                  placeholderText="To Date"
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                  // onCalendarClose={handleCalendarClose}
                  minDate={new Date()}
                  // onCalendarOpen={handleCalendarOpen}
                  className="date_picker "
                />
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center mt-3 mt-md-5">
              <TextField
                id="standard-basic"
                label="Select Supplier"
                variant="standard"
                className="w-50"
              />
            </div>
          </div>

          <div className="py-5">
            <div className="row ">
              <div class="checkbox-rect col-6 col-md-3">
                <input type="checkbox" id="checkbox-rect1" name="check" />
                <label for="checkbox-rect1" className="h6">
                  Add Sale Invoice
                </label>
              </div>

              <div class="checkbox-rect col-6 col-md-3">
                <input type="checkbox" id="add_voucher" name="check" />
                <label for="add_voucher" className="h6">
                  Add Vochers
                </label>
              </div>

              <div class="checkbox-rect col-6 mt-4 col-md-3 mt-md-0">
                <input type="checkbox" id="add_credit_note" name="check" />
                <label for="add_credit_note" className="h6">
                  Add Credit Note
                </label>
              </div>

              <div class="checkbox-rect col-6 col-md-3 mt-4 mt-md-0">
                <input type="checkbox" id="add_opening_balence" name="check" />
                <label for="add_opening_balence" className="h6">
                  Add Opening Balence
                </label>
              </div>

              <div class="checkbox-rect col-6 col-md-3 mt-2 mt-md-4">
                <input type="checkbox" id="without_statement" name="check" />
                <label for="without_statement" className="h6">
                  Without Statement
                </label>
              </div>


              <div class="checkbox-rect col-6 col-md-3 mt-2 mt-md-4">
                <input type="checkbox" id="with_sar" name="check" />
                <label for="with_sar" className="h6">
                  With SAR
                </label>
              </div>
              <div class="checkbox-rect col-12 col-md-3 mt-4 ">
                <input type="checkbox" id="only_sar" name="check" />
                <label for="only_sar" className="h6">
                  Only SAR
                </label>
              </div>
            </div>

            {/* radio buttons */}
            <div className="d-flex justify-content-md-center align-items-center mt-5 ">
              <label class="form-control me-3 me-md-5">
                <input type="radio" name="radio" />
                <span className="h6">Account Statement</span>
              </label>
              <label class="form-control">
                <input type="radio" name="radio" />
                <span className="h6">Ledger Report</span>
              </label>
            </div>

            <div className="d-flex justify-content-center align-items-center my-4 mb-0">
              <button className="btn btn-primary">Generate Report</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AgentAccountStatement;
