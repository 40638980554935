import React, { useState } from 'react'
import PreviewInsurance from './PreviewInsurance'

const Beneficiary = ({formData, setFormData}) => {
  const [preview, setPreview] = useState(false);
  return (
    <div>
    
    <div className='d-flex flex-column flex-lg-row align-items-center mt-5 w-50'>
    <div className='w-50 me-4'><input type="text" className='inputs_flight_tab w-100' placeholder='Beneficiary'
    value={formData.beneficiary}
    onChange={e => setFormData({...formData, beneficiary: e.target.value})}
    /></div>
    <div className='w-50'><input type="text" className='inputs_flight_tab w-100'placeholder='Relationship' value={formData.relationship} onChange={e => setFormData({...formData, relationship : e.target.value})}/></div>
    </div>
    <div className="d-flex justify-content-end pt-3">
    <button onClick={()=>setPreview(true)} className='btn btn-primary rounded-pill'>Preview</button>
  </div>
  {preview ? (
    <div className="Preview_model">
      <PreviewInsurance setPreview={setPreview} formData={formData}/>
    </div>
  ) : (
    ""
  )}
    </div>
  )
}

export default Beneficiary