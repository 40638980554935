import React, { useState } from "react";
import { TextField } from "@mui/material";
import CustomDropdown from "../CustomDrp";

const Password = () => {
  const [authUser, setAuthUser] = useState(false);
  const [permissions, setPermissions] = useState({
    sabre: true,
    airblue: true,
    flyDubai: true,
    insurance: true,
    travel_port: true,
    hitit: true,
    airsial: true,
    travel_landa: true,
    paypro: true,
  });
  const [sabreCheckbox, setSabreCheckbox] = useState(false);
  return (
    <>
      {!authUser ? (
        <div className="col-12 col-sm-6 mx-auto rounded-2 shadow my-5 pb-5 flash-news">
          <p className="h4 otawix-heading">Type Your Password For Security</p>
          <div className="col-12 px-4">
            <div className="col-12 mt-4 py-3">
              <TextField
                id="standard-basic"
                label="Password *"
                variant="standard"
                className="col-sm-12"
              />
            </div>
            <button
              className="btn setup-btn"
              onClick={(e) => setAuthUser(true)}
            >
              Submit
            </button>
          </div>
        </div>
      ) : (
        <div className="col-12 rounded-2 shadow mt-5 pb-5 flash-news">
          <p className="h4 otawix-heading">Apis Credentaials</p>
          <div className="col-12 px-3 mt-5 d-flex flex-wrap justify-content-between">
            {permissions.sabre && (
              <div className="rounded-2 shadow pb-5 flash-news api-passwords-cards my-3">
                <p className="h4 otawix-heading">Sabre</p>
                <div className="col-12 px-4">
                  <div className="col-12 mt-4 py-3 d-flex justify-content-between">
                    <TextField
                      id="standard-basic"
                      label="Client ID"
                      variant="standard"
                      className="col-sm-5"
                      required
                    />
                    <TextField
                      id="standard-basic"
                      label="Client Secret"
                      variant="standard"
                      className="col-sm-5"
                      required
                    />
                  </div>
                  <div className="col-12 mt-4 py-3 d-flex justify-content-between">
                    <TextField
                      id="standard-basic"
                      label="ERP *"
                      variant="standard"
                      className="col-sm-5"
                    />
                    <TextField
                      id="standard-basic"
                      label="IPCC *"
                      variant="standard"
                      className="col-sm-5"
                    />
                  </div>
                  <div className="col-12 mt-4 py-3 d-flex justify-content-between">
                    <TextField
                      id="standard-basic"
                      label="LNIATA *"
                      variant="standard"
                      className="col-sm-5"
                    />
                    <TextField
                      id="standard-basic"
                      label="Password *"
                      variant="standard"
                      className="col-sm-5"
                    />
                  </div>
                  <div className="col-12 mt-4 py-3 d-flex justify-content-between">
                    <TextField
                      id="standard-basic"
                      label="Domain *"
                      variant="standard"
                      className="col-sm-5"
                    />
                    <TextField
                      id="standard-basic"
                      label="URL Rest *"
                      variant="standard"
                      className="col-sm-5"
                    />
                  </div>
                  <div className="col-12 mt-4 py-3 d-flex justify-content-between">
                    <TextField
                      id="standard-basic"
                      label="URL Soap *"
                      variant="standard"
                      className="col-sm-5"
                    />
                    <div class="checkbox-rect col-sm-5 checbox-setup">
                      <input
                        type="checkbox"
                        id="with_sar"
                        name="check"
                        onChange={(e) => setSabreCheckbox(e.target.checked)}
                      />
                      <label for="with_sar" className="h6">
                        If active CC will be used
                      </label>
                    </div>
                  </div>
                  {sabreCheckbox && (
                    <div className="col-12 mt-4 py-3 d-flex justify-content-between">
                      <TextField
                        id="standard-basic"
                        label="CC Number *"
                        variant="standard"
                        className="col-sm-5"
                      />
                      <TextField
                        id="standard-basic"
                        label="CC Expiry *"
                        variant="standard"
                        className="col-sm-5"
                      />
                    </div>
                  )}
                  <button className="btn setup-btn mt-5">save</button>
                </div>
              </div>
            )}
            {permissions.airblue && (
              <div className="rounded-2 shadow pb-5 flash-news api-passwords-cards my-3">
                <p className="h4 otawix-heading">airblue</p>
                <div className="col-12 px-4">
                  <div className="col-12 mt-4 py-3 d-flex justify-content-between">
                    <TextField
                      id="standard-basic"
                      label="Application ID *"
                      variant="standard"
                      className="col-sm-5"
                    />
                    <TextField
                      id="standard-basic"
                      label="Application Key *"
                      variant="standard"
                      className="col-sm-5"
                    />
                  </div>
                  <div className="col-12 mt-4 py-3 d-flex justify-content-between">
                    <TextField
                      id="standard-basic"
                      label="Agent ID *"
                      variant="standard"
                      className="col-sm-5"
                    />
                    <TextField
                      id="standard-basic"
                      label="Agent Password *"
                      variant="standard"
                      className="col-sm-5"
                    />
                  </div>
                  <div className="col-12 mt-4 py-3 d-flex justify-content-between">
                    <TextField
                      id="standard-basic"
                      label="Agent Type *"
                      variant="standard"
                      className="col-sm-5"
                    />
                    <TextField
                      id="standard-basic"
                      label="Service Target *"
                      variant="standard"
                      className="col-sm-5"
                    />
                  </div>
                  <div className="col-12 mt-4 py-3 d-flex justify-content-between">
                    <TextField
                      id="standard-basic"
                      label="URL *"
                      variant="standard"
                      className="col-sm-5"
                    />
                  </div>
                  <button className="btn setup-btn mt-5">save</button>
                </div>
              </div>
            )}
            {permissions.flyDubai && (
              <div className="rounded-2 shadow pb-5 flash-news api-passwords-cards my-3">
                <p className="h4 otawix-heading">flyDubai</p>
                <div className="col-12 px-4">
                  <div className="col-12 mt-4 py-3 d-flex justify-content-between">
                    <TextField
                      id="standard-basic"
                      label="Link *"
                      variant="standard"
                      className="col-sm-5"
                    />
                    <TextField
                      id="standard-basic"
                      label="Client ID"
                      variant="standard"
                      className="col-sm-5"
                    />
                  </div>
                  <div className="col-12 mt-4 py-3 d-flex justify-content-between">
                    <TextField
                      id="standard-basic"
                      label="Client Secret"
                      variant="standard"
                      className="col-sm-5"
                    />
                    <TextField
                      id="standard-basic"
                      label="User Name"
                      variant="standard"
                      className="col-sm-5"
                    />
                  </div>
                  <div className="col-12 mt-4 py-3 d-flex justify-content-between">
                    <TextField
                      id="standard-basic"
                      label="Password"
                      variant="standard"
                      className="col-sm-5"
                    />
                    <TextField
                      id="standard-basic"
                      label="IATA"
                      variant="standard"
                      className="col-sm-5"
                    />
                  </div>
                  <button className="btn setup-btn mt-5">save</button>
                </div>
              </div>
            )}
            {permissions.insurance && (
              <div className="rounded-2 shadow pb-5 flash-news api-passwords-cards my-3">
                <p className="h4 otawix-heading">insurance</p>
                <div className="col-12 px-4">
                  <div className="col-12 mt-4 py-3 d-flex justify-content-between">
                    <TextField
                      id="standard-basic"
                      label="API Keys"
                      variant="standard"
                      className="col-sm-5"
                    />
                    <TextField
                      id="standard-basic"
                      label="Credentials"
                      variant="standard"
                      className="col-sm-5"
                    />
                  </div>
                  <div className="col-12 mt-4 py-3 d-flex justify-content-between">
                    <TextField
                      id="standard-basic"
                      label="Link"
                      variant="standard"
                      className="col-sm-5"
                    />
                    <TextField
                      id="standard-basic"
                      label="User Name"
                      variant="standard"
                      className="col-sm-5"
                    />
                  </div>
                  <div className="col-12 mt-4 py-3 d-flex justify-content-between">
                    <TextField
                      id="standard-basic"
                      label="Password"
                      variant="standard"
                      className="col-sm-5"
                    />
                  </div>
                  <button className="btn setup-btn mt-5">save</button>
                </div>
              </div>
            )}
            {permissions.travel_port && (
              <div className="rounded-2 shadow pb-5 flash-news api-passwords-cards my-3">
                <p className="h4 otawix-heading">travel port</p>
                <div className="col-12 px-4">
                  <div className="col-12 mt-4 py-3 d-flex justify-content-between">
                    <TextField
                      id="standard-basic"
                      label="User Name"
                      variant="standard"
                      className="col-sm-5"
                    />
                    <TextField
                      id="standard-basic"
                      label="Password"
                      variant="standard"
                      className="col-sm-5"
                    />
                  </div>
                  <div className="col-12 mt-4 py-3 d-flex justify-content-between">
                    <TextField
                      id="standard-basic"
                      label="Target"
                      variant="standard"
                      className="col-sm-5"
                    />
                    <TextField
                      id="standard-basic"
                      label="Link 1"
                      variant="standard"
                      className="col-sm-5"
                    />
                  </div>
                  <div className="col-12 mt-4 py-3 d-flex justify-content-between">
                    <TextField
                      id="standard-basic"
                      label="Link 2"
                      variant="standard"
                      className="col-sm-5"
                    />
                  </div>
                  <button className="btn setup-btn mt-5">save</button>
                </div>
              </div>
            )}
            {permissions.hitit && (
              <div className="rounded-2 shadow pb-5 flash-news api-passwords-cards my-3">
                <p className="h4 otawix-heading">hitit</p>
                <div className="col-12 px-4">
                  <div className="col-12 mt-4 py-3 d-flex justify-content-between">
                    <TextField
                      id="standard-basic"
                      label="Link"
                      variant="standard"
                      className="col-sm-5"
                    />
                    <TextField
                      id="standard-basic"
                      label="User Name"
                      variant="standard"
                      className="col-sm-5"
                    />
                  </div>
                  <div className="col-12 mt-4 py-3 d-flex justify-content-between">
                    <TextField
                      id="standard-basic"
                      label="Invoice Number"
                      variant="standard"
                      className="col-sm-5"
                    />
                    <TextField
                      id="standard-basic"
                      label="Password"
                      variant="standard"
                      className="col-sm-5"
                    />
                  </div>
                  <div className="col-12 mt-4 py-3 d-flex justify-content-between">
                    <TextField
                      id="standard-basic"
                      label="Company Full Name"
                      variant="standard"
                      className="col-sm-5"
                    />
                    <TextField
                      id="standard-basic"
                      label="Company Short Name"
                      variant="standard"
                      className="col-sm-5"
                    />
                  </div>
                  <div className="col-12 mt-4 py-3 d-flex justify-content-between">
                    <TextField
                      id="standard-basic"
                      label="City Code"
                      variant="standard"
                      className="col-sm-5"
                    />
                  </div>
                  <button className="btn setup-btn mt-5">save</button>
                </div>
              </div>
            )}
            {permissions.airsial && (
              <div className="rounded-2 shadow pb-5 flash-news api-passwords-cards my-3">
                <p className="h4 otawix-heading">airsial</p>
                <div className="col-12 px-4">
                  <div className="col-12 mt-4 py-3 d-flex justify-content-between">
                    <TextField
                      id="standard-basic"
                      label="User Name"
                      variant="standard"
                      className="col-sm-5"
                    />
                    <TextField
                      id="standard-basic"
                      label="Password"
                      variant="standard"
                      className="col-sm-5"
                    />
                  </div>
                  <div className="col-12 mt-4 py-3 d-flex justify-content-between">
                    <TextField
                      id="standard-basic"
                      label="URL"
                      variant="standard"
                      className="col-sm-5"
                    />
                  </div>
                  <button className="btn setup-btn mt-5">save</button>
                </div>
              </div>
            )}
            {permissions.travel_landa && (
              <div className="rounded-2 shadow pb-5 flash-news api-passwords-cards my-3">
                <p className="h4 otawix-heading">Travel Landa</p>
                <div className="col-12 px-4">
                  <div className="col-12 mt-4 py-3 d-flex justify-content-between">
                    <TextField
                      id="standard-basic"
                      label="User Name"
                      variant="standard"
                      className="col-sm-5"
                    />
                    <TextField
                      id="standard-basic"
                      label="Password"
                      variant="standard"
                      className="col-sm-5"
                    />
                  </div>
                  <div className="col-12 mt-4 py-3 d-flex justify-content-between">
                    <TextField
                      id="standard-basic"
                      label="Link 1"
                      variant="standard"
                      className="col-sm-5"
                    />
                    <TextField
                      id="standard-basic"
                      label="Link 2"
                      variant="standard"
                      className="col-sm-5"
                    />
                  </div>
                  <div className="col-12 mt-4 py-3 d-flex justify-content-between">
                    <div className="col-sm-5">
                      <CustomDropdown
                        arry={["Active", "Non Active"]}
                        placehlder="Search *"
                      />
                    </div>
                  </div>
                  <button className="btn setup-btn mt-5">save</button>
                </div>
              </div>
            )}
            {permissions.paypro && (
              <div className="rounded-2 shadow pb-5 flash-news api-passwords-cards my-3">
                <p className="h4 otawix-heading">Paypro</p>
                <div className="col-12 px-4">
                  <div className="col-12 mt-4 py-3 d-flex justify-content-between">
                    <TextField
                      id="standard-basic"
                      label="User Name"
                      variant="standard"
                      className="col-sm-5"
                    />
                    <TextField
                      id="standard-basic"
                      label="Password"
                      variant="standard"
                      className="col-sm-5"
                    />
                  </div>
                  <div className="col-12 mt-4 py-3 d-flex justify-content-between">
                    <TextField
                      id="standard-basic"
                      label="Base URL"
                      variant="standard"
                      className="col-sm-5"
                    />
                    <TextField
                      id="standard-basic"
                      label="Client ID"
                      variant="standard"
                      className="col-sm-5"
                    />
                  </div>
                  <div className="col-12 mt-4 py-3 d-flex justify-content-between">
                    <TextField
                      id="standard-basic"
                      label="Client Secret"
                      variant="standard"
                      className="col-sm-5"
                    />
                  </div>
                  <button className="btn setup-btn mt-5">save</button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Password;
