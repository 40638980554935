import { TextField } from "@mui/material";
import React, { useState } from "react";
import CustomDropdown from "../../CustomDrp";

const PassangerInfo = () => {
  const [multiRows, setMultiRows] = useState([]);

  const addRow = () => {
    const newvalue = multiRows[multiRows.length - 1];
    setMultiRows([...multiRows, newvalue ? newvalue + 1 : 1]);
  };
  const removeRow = () => {
    let rowsArray = multiRows;
    rowsArray.pop();
    setMultiRows([...rowsArray]);
  };

  return (
    <>
      <div className="text-right row">
        <div className="col-sm-8"></div>
        <div className="col-sm-4">
          <button
            className="btn btn-small btn-primary col-sm-6 border rounded-pill px-1"
            onClick={addRow}
          >
            {/*<i className="fa  fa-thin fa-plus"></i>*/} Add Passanger
          </button>
          <button
            className="btn btn-small btn-danger col-sm-6 border rounded-pill px-1"
            onClick={(e) => removeRow()}
          >
            Remove Passanger
            {/*<i class="fa fa-close"></i>*/}
          </button></div>
      </div>
      <div className="row align-items-end mt-5">
        <div className="col-3">
          <CustomDropdown arry={['Adult', 'Child', 'Infant']} placehlder='Select Type' />
        </div>
        <div className="col-3">
          <CustomDropdown arry={['MR', 'MS', 'MRS', 'MISS', 'MSTR']} placehlder='Select Title' />
        </div>
        <div className="col-3">
          <TextField variant="standard" label='First Name' className="w-100" />
        </div>
        <div className="col-3">
          <TextField variant="standard" label='Last Name' className="w-100" />
        </div>
      </div>

      {multiRows.map((val) => (
        <div className="row align-items-end mt-4">
          <div className="col-3">
            <CustomDropdown arry={['Adult', 'Child', 'Infant']} placehlder='Select Type' />
          </div>
          <div className="col-3">
            <CustomDropdown arry={['MR', 'MS', 'MRS', 'MISS', 'MSTR']} placehlder='Select Title' />
          </div>
          <div className="col-3">
            <TextField variant="standard" label='First Name' className="w-100" />
          </div>
          <div className="col-3">
            <TextField variant="standard" label='Last Name' className="w-100" />
          </div>
        </div>
      ))}

    </>
  );
};

export default PassangerInfo;
