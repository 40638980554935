import React from "react";
import { FaPlaneDeparture } from "react-icons/fa";
import { TbBrandBooking } from 'react-icons/tb'

function WhyChooseUs() {
  return (
    <div
      className="d-flex mb-5 why_choose_us"
      id="why-choose-us"
    >
      <div className="why_choose_us_image-section">
        <img

          className="w-100 why-choose-us-image"
          src="https://images.unsplash.com/photo-1536745511564-a5fa6e596e7b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8c3dpbWluZyUyMHBvb2x8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60"
        />
      </div>
      <div className="custom-why-choose-us" >
        <div className="px-2 px-sm-5 py-2 py-sm-5 pe-0 flex-wrap">
          <div className=" px-2 px-sm-5">
            <div className="text-danger h5 mt-5">Our benifit list</div>
            <div className="text-white h1 mt-1">Why Choose Us</div>
            <p className=" wyy-choose-us-paragraph">
              There are many variations of passages of Lorem Ipsum is simply
              free text available in the market for you, but the majority have
              suffered alteration in some form.
              <div className="d-flex align-items-center my-5">
                <div className="me-5">
                  <FaPlaneDeparture size={50} color="#e8604c" />
                </div>
                <div className="w-100">
                  <h5 className="text-white">Professional and Certified</h5>
                  <p >
                    Lorem ipsum is simply free text dolor sit but the majority
                    have suffered amet, consectetur notte
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-center my-5">
                <div className="me-5">
                  <TbBrandBooking size={50} color="#e8604c" />
                </div>
                <div className="w-100">
                  <h5 className="text-white">Professional and Certified</h5>
                  <p>
                    Lorem ipsum is simply free text dolor sit but the majority
                    have suffered amet, consectetur notte
                  </p>
                </div>
              </div>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyChooseUs;
