import React, { useState } from "react";
import { TextField } from "@mui/material";
import MUIDataTable from "mui-datatables";
import DeleteModal from "../DeleteModal";

const Currency = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const columns = [
    {
      name: "id",
      label: "No",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "currency_name",
      label: "Currency Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "currency_code",
      label: "Currency Code",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "roe",
      label: "Remarks",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "Action",
      name: "delete",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="">
              <button
                className="btn-success rounded edit-delete-icon-button me-1"
                onClick={() => console.log(value, tableMeta)}
              >
                <i className="fa fa-thin fa-pencil"></i>
              </button>
              <button
                className="btn-danger rounded edit-delete-icon-button"
                onClick={() => {
                  setShowDeleteModal(true);
                  setModalData(tableMeta.rowData);
                }}
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
              </button>
            </div>
          );
        },
      },
    },
  ];

  const data = [
    {
      id: 1,
      currency_name: "US DOllar",
      currency_code: "USD",
      roe: "1",
    },
  ];
  const options = {
    filter: false,
    Selection: false,
    print: false,
    download: false,
    viewColumns: false,
    displayRowCheckbox: false,
    selectableRows: "none",
    // onRowClick: (rowData) => {
    //   navigate(`/booking-details/${rowData[3]}/active`)
    // },
  };
  return (
    <div className="col-12 col-sm-6 mx-auto rounded-2 shadow my-5 pb-5 flash-news">
      <p className="h4 otawix-heading">Currency</p>
      <div className="col-12 px-4">
        <div className="col-12 mt-4 py-3 d-flex justify-content-between">
          <TextField
            id="standard-basic"
            label="Currency Name"
            variant="standard"
            className="col-sm-4"
            inputProps={{ maxLength: 50 }}
          />
          <TextField
            id="standard-basic"
            label="Currency Code"
            variant="standard"
            className="col-sm-4"
            inputProps={{ maxLength: 50 }}
          />
          <TextField
            id="standard-basic"
            label="Rate Of Exchange"
            type="number"
            variant="standard"
            className="col-sm-3"
            inputProps={{ maxLength: 50 }}
          />
        </div>
        <button className="btn setup-btn">save</button>
      </div>
      <div className="col-12 mt-5 px-4">
      <DeleteModal
          show={showDeleteModal}
          setShow={setShowDeleteModal}
          rowId={modalData && modalData[0]}
        />
        <MUIDataTable
          className="muidatatable"
          title={"Currency Details"}
          data={data}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  );
};

export default Currency;
