import React from "react";
import { TextField } from "@mui/material";
import MUIDataTable from "mui-datatables";
import CountryDropdown, {__esModule} from "country-dropdown-with-flags-for-react";


const Terms = () => {
  const columns = [
    {
      name: "country",
      label: "country",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "requirements",
      label: "Requirements",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <ol>
              {value.map((line) => (
                <li>{line}</li>
              ))}
            </ol>
          );
        },
      },
    },
    {
      name: "Action",
      label: "action",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <button
              className="btn-success rounded edit-delete-icon-button me-1"
              onClick={() => console.log(value, tableMeta)}
            >
              <i className="fa fa-thin fa-pencil"></i>
            </button>
          );
        },
      },
    },
  ];

  const data = [
    {
      id: 1,
      country: "Dubai",
      requirements: ["hdvhsjcvschjewd", "jgdzijdsgijfc", "kfkjdcogoi"],
    },
  ];
  const options = {
    filter: false,
    Selection: false,
    print: false,
    download: false,
    viewColumns: false,
    displayRowCheckbox: false,
    selectableRows: "none",
  };
  return (
    <div className="col-12 col-sm-6 mx-auto rounded-2 shadow mt-5 pb-5 flash-news">
      <p className="h4 otawix-heading">Visa Terms & Condition</p>
      <div className="col-12 px-5 mt-5 d-flex flex-column justify-content-center">
        <div className="col-6">
          <div className="col-12">
            <CountryDropdown
              id="UNIQUE_ID"
              className="inputs w-100 absolute"
              handleChange={(e) => console.log(e.target.value)}
            ></CountryDropdown>
          </div>
        </div>
        <div className="col-12 py-2 mt-3">
          <div className="buttons d-flex">
            <button className="btn btn-danger me-2">
              Rem Visa term
            </button>
            <button className="btn btn-primary">
              Add Visa term
            </button>
          </div>
        </div>
        <div className="col-12 mt-2 mb-5 text-field">
          <TextField
            id="standard-basic"
            label="Visa Terms & Condition"
            variant="standard"
            className="col-sm-12"
          />
        </div>
        <button className="btn setup-btn">save</button>
      </div>
      <div className="col-12 mt-5 px-4">
        <MUIDataTable
          className="muidatatable"
          title={"Terms & Condition Details"}
          data={data}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  );
};

export default Terms;
