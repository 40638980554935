import React from 'react';
import Header from '../components/Common/Header'
import ResolveRequests from '../components/Setup/ResolveRequests'
import Footer from '../components/Footer'

const ResolvedRequests = () => {
  return (
    <div className='Full_height'>
      <Header active="setup" />
      <ResolveRequests />
      <Footer />
    </div>
  )
}

export default ResolvedRequests