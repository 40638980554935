import React from "react";
import ReactDatePicker from "react-datepicker";
import CustomDropdown from "../components/CustomDrp";
import Header from "../components/Common/Header";
import "../Styles/manualInvoices.css";
import { Button } from "@mui/material";


function AirlineReport() {
  const [startDate, setStartDate] = React.useState("");
  return (
    <div className='Full_height'>
      <Header />
      <div className="manual_invoices mx-auto mt-5">
        <div className="manual_invoices_header">
          <div className="w-100 text-center">AirLine Report (Date Wise / AirLine Wise)</div>
        </div>
        <div className="pt-5 px-2 px-md-5">
          <div className="row align-items-center">
            <div className="col-6">
              <ReactDatePicker
                placeholderText="From Date"
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                }}
                // onCalendarClose={handleCalendarClose}
                minDate={new Date()}
                // onCalendarOpen={handleCalendarOpen}
                className="date_picker "
              />
            </div>
            <div className="col-6">
              <ReactDatePicker
                placeholderText="To Date"
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                }}
                // onCalendarClose={handleCalendarClose}
                minDate={new Date()}
                // onCalendarOpen={handleCalendarOpen}
                className="date_picker "
              />
            </div>

            <div className="col-6 mt-4">
              <CustomDropdown arry={['Airline']} placehlder='Select Airline' />
            </div>
          </div>

        </div>
        <div className=" pt-5">


          {/* radio buttons */}
          <div className="d-flex justify-content-center align-items-center ">
            <div className="me-5">
              <label class="form-control">
                <input type="radio" name="radio" />
                <span className="h6">PDF</span>
              </label>

            </div>
            <div className="">
              <label class="form-control">
                <input type="radio" name="radio" />
                <span className="h6">EXCEL</span>
              </label>

            </div>
          </div>
        </div>

        {/* generat report button */}
        <div className="container py-4">
          <div className="d-flex justify-content-center align-items-center my-3 ">

            <Button variant="contained">Generate Report</Button>

          </div>
        </div>
      </div>
    </div>
  );
};





export default AirlineReport;
