import { Button, TextField } from "@mui/material";
import React from "react";
import { IoIosClose } from "react-icons/io";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";

function AgentModel({ setShowModel, rowData }) {
  return (
    <div className="backdrop_blur_cotianer">
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="model_body">
          {/* *************** */}
          <p className="otawix-heading h4">
            {rowData?.name ? "Update Agent Category" : "Add Agent Category"}
          </p>

          {/* *************************** */}
          <div className="p-4">
            <div className="my-2">
              <TextField label="Name" variant="standard" className="w-100" />
            </div>

            <div className="d-flex justify-content-center align-items-end">
              <div className="me-2 mt-5">
                <Button
                  variant="danger modal-button"
                  onClick={(e) => setShowModel(false)}
                  style={{ background: "#dc3545", color: "#fff" }}
                >
                  Close
                </Button>
              </div>
              <div className="">
                <Button
                  variant="primary modal-button"
                  onClick={(e) => setShowModel(false)}
                  style={{ background: "#0d6efd", color: "#fff" }}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AgentModel;
