import React from "react";
import { Modal, Button } from "react-bootstrap";

const DeleteModal = ({ show, setShow, rowId }) => {
  return (
    <Modal show={show} onHide={(e) => setShow(false)} centered>
      <Modal.Header>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-12">
          <div className="col-12 text-center mt-3">
            <span className="text-danger">NOTE:</span> You are about to delete
            the record, this procedure is irreversible.
          </div>
          <div className="col-12 text-center mt-3">Do you want to proceed?</div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="modal-buttons-parent mx-auto">
          <Button variant="primary modal-button" onClick={(e) => setShow(false)}>
            Close
          </Button>
          <Button
            variant="danger modal-button"
            onClick={(e) => setShow(false)}
          >
            Delete
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
