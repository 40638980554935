import React from 'react'
import MUIDataTable from "mui-datatables";
import { useNavigate } from 'react-router-dom';

function BookingTable() {
  const navigate = useNavigate();
    const columns = ["Date","Booking Type", "Booking ID|Supllier", "Passengers", "Agent Group","Office","Amount","Booking Status","Document Status"];

    const data = [
      ["31Aug22", "Visa", "000250 28 Days Umrah No Transfer / Saudia Shirkatul zairaa", "NOOR REHMAN HAJIRA ASADABIDA NOOR REHMAN SUNDUS NOOR NOOR REHMAN HAJIRA ASADABIDA NOOR REHMAN SUNDUS NOOR","ZairaaMain Office","	ZAIRAA Main Office","251,075 PKR","CONFIRMED","	ISSUED"],
    ];

    const options = {
      filter:false,
      Selection:false,
      print:false,
      download:false,
      viewColumns:false,
      displayRowCheckbox:false,
      selectableRows: 'none',
      onRowClick: (rowData) => {
        navigate(`/booking-details/${Number(rowData[2].split(" ")[0])}`)
      },
    };
  return (
    <div className='py-4 px-2'>
    <MUIDataTable
    className='muidatatable'
          title={"Booking Filter Record"}
          data={data}
          columns={columns}
          options={options}
        />
    </div>
  )
}

export default BookingTable