import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import Modal from 'react-bootstrap/Modal';
import { Button, TextField } from "@mui/material";
import CustomDropDown from '../CustomDrp'

const Requests = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const location = useLocation();
  const [radio, setRadio] = useState(
    location.pathname.split("/")[3] === "active" ? "in-progress" : "resolved"
  );

  return (
    <div className="d-flex flex-column justify-content-center mt-4 px-5">
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <h6>Call Center Requests</h6>
      <div className="col-sm-3 d-flex my-4">
        <span class="form-control me-3 me-md-5">
          <input
            type="radio"
            id="resolved"
            value="resolved"
            checked={radio === "resolved"}
            onChange={(e) => setRadio(e.target.value)}
          />
          <label for="resolved" style={{ marginLeft: "5px" }}>
            Resolved
          </label>
        </span>
        <span class="form-control me-3 me-md-5">
          <input
            type="radio"
            id="in-progress"
            value="in-progress"
            checked={radio === "in-progress"}
            onChange={(e) => setRadio(e.target.value)}
          />
          <label htmlFor="in-progress" style={{ marginLeft: "5px" }}>
            In Progress
          </label>
        </span>
      </div>
      <div className="row px-2">
        <table className="table table-bordered text-nowrap table-responsive">
          <thead>
            <tr className="table-head-requests">
              <th>Number</th>
              <th>Date/Time</th>
              <th>Status</th>
              <th>Type</th>
              <th>Description</th>
              <th>Booking ID</th>
              <th>Agent</th>
              <th>Call Center Staff</th>
              <th>Options</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>FLT-103</td>
              <td>17-Sep-2022 11:47 AM</td>
              <td>New</td>
              <td>Issue</td>
              <td>Issue Ticket Description</td>
              <td>ZAIRAA Main Office</td>
              <td>276</td>
              <td>Not yet taken by call center</td>
              <td>
                <i
                  class="fa fa-edit"
                  style={{ fontSize: "24px", cursor: "pointer" }}
                  onClick={() => setModalShow(true)}
                ></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};



function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size='xl'

    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Requested for 2
        </Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <div className="row p-2  align-items-end">
          <div className="col-6 col-md-3">
            <TextField variant="standard" label='Company Name' className="w-100" />
          </div>
          <div className="col-6 col-md-3">
            <TextField variant="standard" label='Approve Limit' className="w-100" />
          </div>
          <div className="col-6 col-md-3">
            <TextField variant="standard" label='Used Limit' className="w-100" />
          </div>
          <div className="col-6 col-md-3">
            <TextField variant="standard" label='Available Limit' className="w-100" />
          </div>
          <div className="col-6 col-md-3 mt-2 mt-md-4">
            <TextField variant="standard" label='Request number' className="w-100 " />
          </div>
          <div className="col-6 col-md-3 mt-2 mt-md-4">
            <TextField variant="standard" label='Request time' className="w-100 " />
          </div>
          <div className="col-6 col-md-3 mt-2 mt-md-4">
            <CustomDropDown arry={['Issue', 'IATA BSP', 'Serene AIR', 'Airblue Limited']} placehlder='Type' />
          </div>
          <div className="col-6 col-md-3 mt-2 mt-md-4">
            <CustomDropDown arry={['HITIT-PK', 'IATA BSP', 'Serene AIR', 'Airblue Limited']} placehlder='Select Supplier' />
          </div>
        </div>
        <hr className="mx-2 my-5" />


        <div className="row align-items-baseline">
          <div className="col-12 py-2 ">
            <div className="custom_border px-4 py-3">

              <p className="h5">Booked Items - ZairraMain Office</p>
              <div className="border rounded-2 py-3">
                <div className="d-flex">
                  <div className="w-25 px-2 h6">Booking ID/Type</div>
                  <div className="w-25 px-2 h6">Booking Status</div>
                  <div className="w-25 px-2 h6">Document Status</div>
                  <div className="w-25 px-2 h6">Paid by Agency</div>
                </div>
                <div className="d-flex mt-3">
                  <div className="w-25 px-2">CVSVHS ()</div>
                  <div className="w-25 px-2">CONFIRMED</div>
                  <div className="w-25 px-2">PENDING</div>
                  <div className="w-25 px-2"></div>
                </div>
              </div>


              <div className=" mt-3 ">
                <p className="h5">Sector</p>
                <div className="row border rounded-2 py-2 align-items-center">
                  <div className="col-4">
                    <img src="https://www.logolynx.com/images/logolynx/d0/d0df26bdd9cb0103f88d681d367f5f9a.jpeg"
                      alt="logo" width={140} height={100} />
                  </div>
                  <div className="col-4">
                    <div className="h6 text-info">23-Oct-2022 18:30 PM</div>
                    <div className="mt-3 h6">LHE - Lahore, Pakistan</div>
                  </div>
                  <div className="col-4">
                    <div className="h6 text-info">23-Oct-2022 18:30 PM</div>
                    <div className="mt-3 h6">KHI - Karachi, Pakistan - Quaid-E-Azam International</div>
                  </div>
                </div>
              </div>
            </div>

          </div>




          <div className="col-12 mt-3">
            <div className="custom_border px-4 py-3">
              <p className="h5">Passengers</p>
              <div className="border rounded-2 px-2  py-3">
                <div className="d-flex ">
                  <div className="h6 req_table_col">CHK</div>
                  <div className="h6 req_table_col">Type</div>
                  <div className="req_table_col_name h6">Name</div>
                  <div className="req_table_col h6">Date Of Birth</div>
                  <div className="req_table_col h6">Nationality</div>
                  <div className="req_table_col h6">Passport #</div>
                  <div className="req_table_col h6">Ticket #</div>
                </div>
                <div className="d-flex mt-3">
                  <div className="req_table_col">
                    <div class="checkbox-rect">
                      <input type="checkbox" id="passenger" name="passenger" />
                      <label for="passenger" className="h6"></label>
                    </div>
                  </div>
                  <div className="req_table_col">Adult</div>
                  <div className="req_table_col-name" style={{ lineHeight: '1.2rem' }}>MR MUHAMMAD ILYAS ANWAR BHATTI</div>
                  <div className="req_table_col ">1979-01-01</div>
                  <div className="req_table_col "></div>
                  <div className="req_table_col "></div>
                  <div className="req_table_col "></div>
                </div>
              </div>
            </div>

          </div>
        </div>


        <div className="row">
          <div className="col-6 mt-5 ">
            <TextField variant="standard" label='Agent Description' className="w-100" />

            <TextField variant="outlined" disabled label='Agent Notes' className="w-100 mt-4" />
            <TextField variant="outlined" label='' className="w-100" multiline
              rows={2} />
          </div>

          <div className="col-6 mt-5">
            <TextField variant="standard" label='Supplier Description' className="w-100" />

            <TextField variant="outlined" disabled label='Supplier Notes' className="w-100 mt-4" />
            <TextField variant="outlined" label='' className="w-100 " multiline
              rows={2} />

          </div>

        </div>

      </Modal.Body>
      <Modal.Footer>

        <Button variant="contained" onClick={props.onHide} color='error'>Cancel</Button>
        <Button variant="contained" className="mx-3">Issue</Button>
        <Button variant="contained" onClick={props.onHide} color='error'>Cancel Request</Button>
      </Modal.Footer>
    </Modal>
  );

}

export default Requests;
