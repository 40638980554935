import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import AddAirlineModel from "./Common/AddAirlineModel";

const Airlines = () => {
  const [ShowModel, setShowModel] = useState(false)
  const [rowData, setRowData] = useState({})
  const columns = [
    {
      name: "airline_name",
      label: "Airline Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "code",
      label: "Airline Code",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "ticketing_code",
      label: "ticketing code",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "commission_code",
      label: "commission code",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "tour_code",
      label: "tour code",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "siti_discount_international",
      label: "siti discount international",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "status",
      label: "status",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "Action",
      name: "actions",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <button
              className="btn-success rounded edit-delete-icon-button"
              onClick={() => {
                setShowModel(true)
                setRowData(data[tableMeta.rowIndex])
              }}
            >
              <i className="fa fa-thin fa-pencil"></i>
            </button>
          );
        },
      },
    },
  ];

  const data = [
    {
      id: 1,
      airline_name: "Air Canada",
      code: "AC",
      ticketing_code: 14,
      commission_code: 0,
      tour_code: 0,
      siti_discount_international: "Not Active",
      status: "Active",
    },
  ];
  const options = {
    filter: false,
    Selection: false,
    print: false,
    download: false,
    viewColumns: false,
    displayRowCheckbox: false,
    selectableRows: "none",
  };
  return (
    <div className="col-12 col-sm-10 mx-auto rounded-2 shadow my-5 pb-5 flash-news">
      {
        ShowModel && <AddAirlineModel setShowModel={setShowModel} rowData={rowData}/>
      }
      <p className="h4 otawix-heading">Airline</p>
      <div className="col-12 px-4 d-flex justify-content-end my-4">
        <button className=" setup-btn"
          onClick={e => setShowModel(!ShowModel)}
        >
          Add Airline <i class="fa fa-thin fa-plus"></i>
        </button>
      </div>
      <div className="col-12 mt-2 px-4">
        <MUIDataTable
          className="muidatatable"
          title={"Airline Details"}
          data={data}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  );
};

export default Airlines;
