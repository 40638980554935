import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import CustomDropdown from "../components/CustomDrp";
import Header from "../components/Common/Header";
import "../Styles/manualInvoices.css";
import { Button } from "@mui/material";

function UmrahMnifestView() {
  const [startDate, setStartDate] = React.useState("");

  return (
    <div className='Full_height'>
      <Header />
      <div className="manual_invoices mx-auto mt-5">
        <div className="manual_invoices_header">
          <div className="w-100 text-center">Umrah Manifest</div>
        </div>
        <div className=" pt-5 px-2 px-md-5">
          <div className="row align-items-center">
            <div className="col-6">
              <ReactDatePicker
                placeholderText="From Date"
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                }}
                // onCalendarClose={handleCalendarClose}
                minDate={new Date()}
                // onCalendarOpen={handleCalendarOpen}
                className="date_picker "
              />
            </div>
            <div className=" col-6">
              <ReactDatePicker
                placeholderText="To Date"
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                }}
                // onCalendarClose={handleCalendarClose}
                minDate={new Date()}
                // onCalendarOpen={handleCalendarOpen}
                className="date_picker "
              />
            </div>
            <div className="col-6 mt-4">
              <CustomDropdown arry={['Umrah', 'visa']} placehlder='Select Umrah' />
            </div>

          </div>

        </div>


        {/* generat report button */}

        <div className="d-flex justify-content-center align-items-center my-3 mt-5">
          <Button variant="contained">Generate Report</Button>
        </div>

      </div>
    </div>
  );
};




export default UmrahMnifestView;
