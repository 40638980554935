import React, { useState } from "react";
import LoginModal from "../LoginReset.js/LoginModal";
import { useNavigate } from "react-router-dom";
import { TiSocialFacebook } from "react-icons/ti";
import { FiInstagram } from "react-icons/fi";
import { FaPinterest, FaTwitter } from "react-icons/fa";
import RegisterModal from "../Register/RegisterModal";
import { BiPhoneCall, BiPhone } from "react-icons/bi";
import { MdAlternateEmail } from "react-icons/md";
import { HiOutlineMenu, HiOutlineMail } from "react-icons/hi";
import { MdClose, MdNavigateNext } from "react-icons/md";
import REgisterForm from './REgisterForm'
import { CgProfile } from 'react-icons/cg'
import User1 from '../../assets/images/user1.png'
import User2 from '../../assets/images/user2.png'
import Registration1 from '../../assets/images/Registration1.png'
import Registration2 from '../../assets/images/Registration2.png'

import '../../Styles/registerModel.css'

const Header = () => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showRegisterModal, setShowReisterModal] = useState(false);
  const [Show, setShow] = useState(false);
  const [ShowUser1Icon, setShowUser1Icon] = useState(true);
  const [ShowRegisterIcon, setShowRegisterIcon] = useState(true);

  const getSelection = (value) => document.querySelector(`#${value}`).scrollIntoView({ behavior: "smooth" });

  const navigate = useNavigate();
  return (
    <>
      {showLoginModal && <LoginModal handleClose={setShowLoginModal} />}
      {showRegisterModal && (
        <REgisterForm handleRegisterClose={setShowReisterModal} setShowReisterModal={setShowReisterModal} />
      )}
      <div className="sticky_top" >
        {/* large screen */}
        <div className="custom-class-largeScreen ">
          <header className="px-5 d-flex justify-content-between align-items-center nav-container">
            <div className="d-flex py-3">
              <div className="me-4 ">
                <BiPhoneCall color="#d05a48" size={20} className="me-2" />
                <a href="#" className="text-decoration-none">
                  + 92 666 999 0000
                </a>
              </div>
              <div>
                <MdAlternateEmail color="#d05a48" size={20} className="me-2" />
                <a href="#" className="text-decoration-none">
                  needhelp@company.com
                </a>
              </div>
            </div>
            <div className="d-flex icons-conteiner">
              <div className="pe-3">
                <TiSocialFacebook color="white" size={17} className="me-3" style={{ cursor: 'pointer' }} />
                <FaTwitter color="white" size={17} className="me-3" style={{ cursor: 'pointer' }} />
                <FiInstagram color="white" size={17} className="me-3 " style={{ cursor: 'pointer' }} />
                <FaPinterest color="white" size={17} className="" style={{ cursor: 'pointer' }} />
              </div>

            </div>
          </header>

          <nav className="navbar navbar-expand-lg navigation-container px-5 d-flex justify-content-between align-items-center ">
            <div>
              <img src="/zairaa.png" alt="logo" className="logo" />
              {/* logo here  */}
            </div>
            <div className="navigation-links-container d-flex align-items-center justify-content-center">
              {/* navigation link */}
              <button
                href="#"
                className="text-decoration-none mx-4 h5 border-0  outline-0 bg-white m-0 navigation-link"
                onClick={e => getSelection("about-us")}
              >
                About us
              </button>
              <button
                href="#"
                className="text-decoration-none mx-4 h5 border-0  outline-0 bg-white m-0 navigation-link"
                onClick={e => getSelection("packages")}
              >
                Packages
              </button>
              <button
                href="#"
                className="text-decoration-none mx-4 h5 border-0  outline-0 bg-white m-0 navigation-link"
                onClick={e => getSelection("why-choose-us")}
              >
                Why Choose Us
              </button>
              <button
                href="#"
                className="text-decoration-none mx-4 h5 border-0  outline-0 bg-white m-0 navigation-link"
                onClick={e => getSelection("contact-us")}
              >
                Contact Us
              </button>
            </div>

            {/* register icon and login icon  */}
            <div className="d-flex justify-content-center align-items-center pt-4">
              <div className="me-4 profile_info text-center" style={{ cursor: 'pointer' }} onClick={(e) => setShowLoginModal(true)}>
                <img onMouseOver={() => setShowUser1Icon(false)} src={User1} className="user-signup-signin-icons" />
                <p className="">Sign in</p>
              </div>
              <div className="profile_info text-center" style={{ cursor: 'pointer' }} onClick={(e) => setShowReisterModal(true)}>
                <img onMouseOver={() => setShowRegisterIcon(false)} src={Registration1} className="user-signup-signin-icons" />
                <p>Register</p>
              </div>
            </div>
            {/* ****************************/}


          </nav>
        </div>













        {/* small screen */}
        <div className="custom-class-smallScreen">
          <header className="navigation-container d-flex justify-content-between align-items-center px-3">
            <div >
              <img src="/zairaa.png" alt="logo" width={130} height={80} />
            </div>
            <div className="">
              {/* <HiOutlineMenu
                size={25}
                color="#d05a48"
                onClick={() => setShow(!Show)}
              /> */}

              <div className="d-flex justify-content-center align-items-center">
                <div className="me-3 d-flex justify-content-center align-items-center flex-column" onClick={(e) => setShowLoginModal(true)}>
                  {
                    ShowUser1Icon ? <img onMouseOver={() => setShowUser1Icon(false)} src={User1} className="user-signup-signin-icons" /> :
                      <img onMouseOut={() => setShowUser1Icon(true)} src={User2} className="user-signup-signin-icons" />
                  }
                  <div>Sign in</div>
                </div>
                <div className=" d-flex justify-content-center align-items-center flex-column" onClick={(e) => setShowReisterModal(true)}>
                  {
                    ShowRegisterIcon ? <img onMouseOver={() => setShowRegisterIcon(false)} src={Registration1} className="user-signup-signin-icons" /> :
                      <img onMouseOut={() => setShowRegisterIcon(true)} src={Registration2} className="user-signup-signin-icons" />
                  }
                  <div>Register</div>
                </div>
              </div>

            </div>
          </header>
          {Show ? (
            <div className="position-absolute top-0 w-100 h-100 menubar_for_mobile ">
              <div className=" h-100 custom_class_menulinks px-3 pt-4">
                {/* logo and close icon */}
                <div className="d-flex justify-content-between align-items-center">
                  <img src="/zairaa.png" alt="logo" width={130} height={80} />
                  <MdClose
                    color="#919db1"
                    size={25}
                    onClick={() => setShow(!Show)}
                  />
                </div>
                {/* menu links */}
                <div className="pt-4">
                  <hr className="bg-white" />
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="text-white ">Home</div>
                    <div className="bg-danger px-1 pb-1">
                      <MdNavigateNext size={20} color="white" />
                    </div>
                  </div>
                  <hr className="bg-white" />
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="text-white ">Destinations</div>
                    <div className="bg-danger px-1 pb-1">
                      <MdNavigateNext size={20} color="white" />
                    </div>
                  </div>
                  <hr className="bg-white" />
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="text-white ">Tours</div>
                    <div className="bg-danger px-1 pb-1">
                      <MdNavigateNext size={20} color="white" />
                    </div>
                  </div>
                  <hr className="bg-white" />
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="text-white ">Pages</div>
                    <div className="bg-danger px-1 pb-1">
                      <MdNavigateNext size={20} color="white" />
                    </div>
                  </div>
                  <hr className="bg-white" />
                </div>

                {/* contact links */}

                <div>
                  <div className=" ">
                    <div className="pt-1">
                      <span className="bg-danger me-2 py-1 pb-2 rounded-circle px-2">
                        <HiOutlineMail color="white" size={18} />
                      </span>
                      <a href="#" className="text-decoration-none text-white">
                        needhelp@company.com
                      </a>
                    </div>
                    <div className="me-4 pt-2 mt-3">
                      <span className="bg-danger me-2 py-1 pb-2 rounded-circle px-2">
                        <BiPhone color="white" size={18} />
                      </span>
                      <a href="#" className="text-decoration-none text-white">
                        + 92 666 999 0000
                      </a>
                    </div>
                  </div>
                  <div className="pe-3 pt-3">
                    <TiSocialFacebook
                      color="#d05a48"
                      size={17}
                      className="me-3"
                    />
                    <FaTwitter color="#d05a48" size={17} className="me-3" />
                    <FiInstagram color="#d05a48" size={17} className="me-3 " />
                    <FaPinterest color="#d05a48" size={17} className="" />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default Header;
