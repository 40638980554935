import React, { useState } from 'react'
import Beneficiary from './InsuranceTab/Beneficiary';
import Plan from './InsuranceTab/Plan';
import Tabs from './InsuranceTab/Tabs';
import Traveler from './InsuranceTab/Traveler';

const InsuranceTab = () => {
  const [InputGroup, setInputGroup] = useState("plan");
  const [formData, setFormData] = useState({
    covid: "covered",
    family_status: "single",
    trip: "WORLDWIDE",
    from_date: "",
    no_of_days: "",
    country: "",
    insured_name: "",
    date_of_birth: "",
    email: "",
    cnic: "",
    passport_no: "",
    address: "",
    contact: "",
    isActiveTaxPayer: false,
    beneficiary: "",
    relationship: "",
  });
  const [family_status, setFamilyStatus] = useState("single")
  return (
    <div>
    <Tabs InputGroup={InputGroup} setInputGroup={setInputGroup} />
    {InputGroup === "plan" && <Plan setFamilyStatus={setFamilyStatus} family_status={family_status} formData={formData} setFormData={setFormData}/>}
    {InputGroup === "traveler" && <Traveler family_status={family_status} formData={formData} setFormData={setFormData}/>}
    {InputGroup === "beneficiary" && <Beneficiary formData={formData} setFormData={setFormData}/>}
    </div>
  )
}

export default InsuranceTab