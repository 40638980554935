import React from "react";
import Header from "../components/Common/Header";
import "../Styles/manualInvoices.css";
import { Button, TextField } from "@mui/material";
import ReactDatePicker from "react-datepicker";


function SupplierAccountStatement() {
  const [startDate, setStartDate] = React.useState("");
  return (
    <div className='Full_height'>
      <Header />
      <div className="manual_invoices mx-auto mt-5">
        <div className="manual_invoices_header">
          <div className="w-100 text-center">Supplier Account Statement</div>
        </div>
        <div className="px-2 px-md-5 mt-5">

          <div className="row align-items-center">
            <div className="input_groupS col-6">
              <ReactDatePicker
                placeholderText="From Date"
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                }}
                // onCalendarClose={handleCalendarClose}
                minDate={new Date()}
                // onCalendarOpen={handleCalendarOpen}
                className="date_picker "
              />
            </div>
            <div className="input_groupS col-6">
              <ReactDatePicker
                placeholderText="To Date"
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                }}
                // onCalendarClose={handleCalendarClose}
                minDate={new Date()}
                // onCalendarOpen={handleCalendarOpen}
                className="date_picker "
              />
            </div>

          </div>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <TextField id="standard-basic" label="Select Supplier" variant="standard" className="w-50 " />
          </div>

        </div>

        {/* checkboxes */}
        <div className="py-4 px-2 px-md-5">

          <div className="row mt-4">

            <div class="checkbox-rect col-6 col-md-4 ">
              <input type="checkbox" id="add-sale-invoice" name="add-sale-invoice" />
              <label for="add-sale-invoice" className="h6">
                Add Sale Invoice
              </label>
            </div>

            <div class="checkbox-rect col-6 col-md-4 ">
              <input type="checkbox" id="add-payment-voucher" name="add-payment-voucher" />
              <label for="add-payment-voucher" className="h6">
                Add Payment Voucher
              </label>
            </div>
            <div class="checkbox-rect col-6 col-md-4 mt-4 mt-md-0">
              <input type="checkbox" id="add-credit-note" name="add-credit-note" />
              <label for="add-credit-note" className="h6">
                Add Credit Note
              </label>
            </div>



            <div class="checkbox-rect col-6 col-md-4 mt-4 ">
              <input type="checkbox" id="add-opening-balance" name="add-opening-balance" />
              <label for="add-opening-balance" className="h6">
                Add Opening Balence
              </label>
            </div>

            <div class="checkbox-rect col-6 col-md-4 mt-4 ">
              <input type="checkbox" id="supplier-all-service" name="supplier-all-service" />
              <label for="supplier-all-service" className="h6">
                Supplier All Services
              </label>
            </div>

            <div class="checkbox-rect col-6 col-md-4 mt-4 ">
              <input type="checkbox" id="with-sar" name="with-sar" />
              <label for="with-sar" className="h6">
                With SAR
              </label>
            </div>
          </div>




          <div className="d-flex justify-content-center align-items-center my-3 mt-5">
            <Button variant="contained">Generate Report</Button>
            {/* <button className="btn btn-primary">Generate Report</button> */}

          </div>
        </div>

      </div>
    </div>
  );
}



export default SupplierAccountStatement;
