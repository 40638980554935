import React from 'react'
import Header from '../components/Common/Header'
import UnpaidVoucher from '../components/Setup/Payments/UnpaidVoucher'
import Footer from '../components/Footer'

const AdminUnpaidVoucher = () => {
  return (
    <div className='Full_height'>
      <Header active="collection" />
      <UnpaidVoucher />
      <Footer />
    </div>
  )
}

export default AdminUnpaidVoucher