import React from 'react'
import CustomDropdown from "../../../CustomDrp";
import { TextField } from "@mui/material";
import ReactDatePicker from 'react-datepicker';

const ServiceType = ({ show_Comp, setshow_Comp }) => {
    const [startDate, setStartDate] = React.useState("");
    return (
        <>
            <div className="col-12 col-md-3 my-4 py-3">
                <CustomDropdown arry={['Flight', 'Insurance', 'Visa', 'Hotel', 'Transfer', 'Activity', 'Umrah Visa']} placehlder='Select Service Type' setfunction={setshow_Comp} />
            </div>
            <hr className=" horizontal-line" />
            {/*  CUSTOMER , SUPPLIER , DOCUMENT NO , INVOICE DATE ,  */}
            <div className="row align-items-end my-4 py-3">
                <div className="col-6 col-md-2">
                    <TextField id="standard-basic" label="Customer" variant="standard" className="w-100" />
                </div>
                <div className="col-6 col-md-2" >
                    {
                        (show_Comp === 'Visa' || show_Comp === 'Umrah Visa') ? <CustomDropdown arry={['Dubai', 'Saudia', 'Turkey', 'Thai visa', 'ITALI']} placehlder='Select Country' /> :
                            <CustomDropdown arry={['Flight', 'Insurance', 'Visa', 'Hotel', 'Transfer', 'Activity', 'Umrah Visa']} placehlder='Select Agent' />
                    }

                </div>
                <div className="col-6 col-md-2 mt-3 mt-md-0">
                    <TextField id="standard-basic" label="Document No| PNR" variant="standard" className="w-100" />
                </div>
                <div className="col-6 col-md-2 mt-3 mt-md-0">
                    <div>
                        <ReactDatePicker
                            placeholderText="Invoice Date"
                            selected={startDate}
                            onChange={(date) => {
                                setStartDate(date);
                            }}
                            // onCalendarClose={handleCalendarClose}
                            minDate={new Date()}
                            // onCalendarOpen={handleCalendarOpen}
                            className="date_picker"
                        />
                    </div>
                </div>
            </div>

        </>
    );
};

export default ServiceType