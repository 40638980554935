import React, {useState} from "react";
import DeleteModal from "../../DeleteModal";
import { TextField } from "@mui/material";
import MUIDataTable from "mui-datatables";
import CustomDropdown from "../../CustomDrp";

const TransferSupplier = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const columns = [
    {
      name: "id",
      label: "S.No",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "name",
      label: "name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "address",
      label: "address",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "contact_no",
      label: "contact no",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "Action",
      name: "image",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <img src={value} className="table-row-image" />;
        },
      },
    },
    {
      label: "Action",
      name: "edit",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="">
              <button
                className="btn-success rounded edit-delete-icon-button me-1"
                onClick={() => console.log(value, tableMeta)}
              >
                <i className="fa fa-thin fa-pencil"></i>
              </button>
              <button
                className="btn-danger rounded edit-delete-icon-button"
                onClick={() => {
                  setShowDeleteModal(true);
                  setModalData(tableMeta.rowData);
                }}
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
              </button>
            </div>
          );
        },
      },
    },
  ];

  const data = [
    {
      id: 1,
      name: "	Swissotel Al Maqam Makkah",
      contact_no: 832169821649832,
      image: "/zairaa.jpeg",
      address: "hSVXHJSVhavxkivs",
    },
  ];
  const options = {
    filter: false,
    Selection: false,
    print: false,
    download: false,
    viewColumns: false,
    displayRowCheckbox: false,
    selectableRows: "none",
    // onRowClick: (rowData) => {
    //   navigate(`/booking-details/${rowData[3]}/active`)
    // },
  };
  return (
    <div className="col-12 col-sm-7 mx-auto rounded-2 shadow my-5 pb-5 flash-news">
      <p className="h4 otawix-heading">Umrah Transfer Supplier</p>
      <div className="col-12 px-4 d-flex flex-column justify-content-center">
        <div className="col-12 mt-4 d-flex">
          <TextField
            id="standard-basic"
            label="Name"
            variant="standard"
            className="col-sm-5 me-5"
          />
          <TextField
            id="standard-basic"
            label="Contact"
            variant="standard"
            className="col-sm-5"
          />
        </div>
        <div className="col-12 mt-4 d-flex">
          <div className="col-sm-5 d-flex flex-column me-5">
            <label htmlFor="" className="mb-2">
              Logo
            </label>
            <TextField type={"file"} variant="standard" className="col-sm-12" />
          </div>
          <div className="mb-2 col-sm-5">
            <label>Address *</label>
            <textarea className="col-sm-12 mt-3" rows={3}></textarea>
          </div>
        </div>
        <button className="btn setup-btn mt-2">save</button>
      </div>
      <div className="col-12 mt-5 px-4">
      <DeleteModal
          show={showDeleteModal}
          setShow={setShowDeleteModal}
          rowId={modalData && modalData[0]}
        />
        <MUIDataTable
          className="muidatatable"
          title={"Umrah Transfer Supplier Details"}
          data={data}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  );
};

export default TransferSupplier;
