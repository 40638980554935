import { Button, TextField } from '@mui/material'
import React from 'react'
import ReactDatePicker from 'react-datepicker'
import CustomDropdown from '../../../CustomDrp'
import Header from '../../../Common/Header'

function VoucherEntry() {
    const [startDate, setStartDate] = React.useState("");
    const [VoucherType, setVoucherType] = React.useState("Journal Voucher");
    return (
        <div className='finance-container'>
            <Header />
            <div className="manual_invoices width_95percent mx-auto">
                <div className="manual_invoices_header ">
                    <div className='text-center w-100'>Manual Voucher Entry</div>
                </div>
                <div className="row px-2 px-md-4 py-4 align-items-end">
                    <div className='col-6 col-md-3'>
                        <CustomDropdown arry={['Journal Voucher', 'Payment Voucher', 'Reciept Voucher', 'Cash Deposit', 'Debit Note']} placehlder='Voucher Type' setfunction={setVoucherType} />
                    </div>
                    <div className='col-6 col-md-3 '>
                        {
                            VoucherType === 'Payment Voucher' ? <CustomDropdown arry={['1-1-1-1-1 (Cash in Hand)', '1-1-1-2-4 (Paypro 1212121212)', '1-1-1-2-4 (Alfalah 128182128812)']} placehlder='Select Creditor Account' /> :
                                VoucherType === 'Cash Deposit' ? <TextField label="Bank Receipt No. " variant="standard" className="w-100" /> : <TextField label="Customers" variant="standard" className="w-100" />
                        }
                    </div>
                    <div className='col-6 col-md-3 mt-4 mt-md-0'>
                        <div>
                            <ReactDatePicker
                                placeholderText="Voucher Date"
                                selected={startDate}
                                onChange={(date) => {
                                    setStartDate(date);
                                }}
                                // onCalendarClose={handleCalendarClose}
                                minDate={new Date()}
                                // onCalendarOpen={handleCalendarOpen}
                                className="date_picker"
                            />
                        </div>
                    </div>
                </div>
                <div className='row px-2 px-md-4 align-items-end my-2'>
                    <div className='col-12 col-md-3'>
                        <TextField label="Vouchers Description" variant="outlined" className="w-100" multiline
                            rows={2} />
                    </div>
                    {
                        VoucherType === 'Cash Deposit' ? <div className='col-12 col-md-3'>
                            <TextField label="Description" variant="outlined" className="w-100" multiline
                                rows={2} />
                        </div> : VoucherType === 'Payment Voucher' ? <div className='col-12 col-md-3 mt-4 mt-md-0'>
                            <div className='d-flex flex-column'>

                                <label htmlFor="" className='mb-2'>Attach IBFT Image</label>
                                <TextField type={'file'} variant="standard" title="Choose a video please" />


                            </div>
                        </div> : ''
                    }

                </div>
                {
                    VoucherType === 'Payment Voucher' ? <div className='row px-2 px-md-4 mt-5'>
                        <div className='col-6 col-md-1'>
                            <label class="form-control">
                                <input type="radio" name="radio" />
                                <span className="">Cheque</span>
                            </label>
                        </div>
                        <div className='col-6 col-md-1'>
                            <label class="form-control">
                                <input type="radio" name="radio" />
                                <span className="">IBFT</span>
                            </label>
                        </div>
                    </div> : ''
                }


                {/* currency detail ****************** */}
                <div className='row px-2 px-md-4 pt-4 pb-2 align-items-end'>
                    <div className='col-10 col-md-3'>
                        <TextField label="Account No / Account Description *" variant="standard" className="w-100" />
                    </div>
                    <div className='col-4 col-md-3 mt-3 mt-md-0'>
                        <TextField label="Description" variant="standard" className="w-100" />
                    </div>
                    <div className='col-4 col-md-1 mt-3 mt-md-0'>
                        <TextField label="ROE" variant="standard" className="w-100" type={'number'} />
                    </div>
                    <div className='col-4 col-md-1 mt-3 mt-md-0'>
                        <TextField label="SAR Debit" variant="standard" className="w-100" type={'number'} />
                    </div>
                    <div className='col-4 col-md-1 mt-3 mt-md-0'>
                        <TextField label="SAR Credit" variant="standard" className="w-100" type={'number'} />
                    </div>
                    <div className='col-4 col-md-1 mt-3 mt-md-0'>
                        <TextField label="Debitor" variant="standard" className="w-100" type={'number'} />
                    </div>
                    <div className='col-4 col-md-1 mt-3 mt-md-0'>
                        <TextField label="Creditor" variant="standard" className="w-100" type={'number'} />
                    </div>
                    <div className='col-4 col-md-1 mt-3 mt-md-0'>
                        <Button size="small" variant='contained'>Add</Button>
                    </div>
                </div>

                {/* ************* */}
                {/* currency detail ****************** */}
                <div className='row px-2 px-md-4 pt-4 pb-2 align-items-end'>
                    <div className='col-10 col-md-3 mt-3 mt-md-0'>
                        <TextField label="Account No / Account Description *" variant="standard" className="w-100" />
                    </div>
                    <div className='col-4 col-md-3 mt-3 mt-md-0'>
                        <TextField label="Description" variant="standard" className="w-100" />
                    </div>
                    <div className='col-4 col-md-1 mt-3 mt-md-0'>
                        <TextField label="ROE" variant="standard" className="w-100" />
                    </div>
                    <div className='col-4 col-md-1 mt-3 mt-md-0'>
                        <TextField label="SAR Debit" variant="standard" className="w-100" />
                    </div>
                    <div className='col-4 col-md-1 mt-3 mt-md-0'>
                        <TextField label="SAR Credit" variant="standard" className="w-100" />
                    </div>
                    <div className='col-4 col-md-1 mt-3 mt-md-0'>
                        <TextField label="Debitor" variant="standard" className="w-100" />
                    </div>
                    <div className='col-4 col-md-1 mt-3 mt-md-0'>
                        <TextField label="Creditor" variant="standard" className="w-100" />
                    </div>
                    <div className='col-4 col-md-1 mt-3 mt-md-0'>
                        <Button size="small" variant='contained' color='error'>Remove</Button>
                    </div>
                </div>
                {/* ****************** */}

                <hr />

                {/* total  */}
                <div className=" py-2">
                    <div className="h5 text-center my-2">Total</div>
                    <div className="d-flex justify-content-center align-items-center flex-column my-4">
                        <div className="h6 mb-2">
                            SAR Debit = 330 PKR
                        </div>
                        <div className="h6 mb-2">
                            SAR Credit = 330 PKR
                        </div>
                        <div className="h6 mb-2">
                            Debitor = 330 PKR
                        </div>
                        <div className="h6 mb-3">
                            Creditor = 330 PKR
                        </div>
                        <Button size="medium" variant='contained'>Submit</Button>
                    </div>
                </div>

                {/* ********** */}
            </div>
        </div>
    )
}

export default VoucherEntry