import React from "react";
import Header from "../components/Common/Header";
import "../Styles/manualInvoices.css";
import CustomDropdown from "../components/CustomDrp";
import ReactDatePicker from "react-datepicker";
import { Button, TextField } from "@mui/material";


function VisaReport() {
  const [startDate, setStartDate] = React.useState("");
  return (
    <div className='Full_height'>
      <Header />
      <div className="manual_invoices mx-auto mt-5">
        <div className="manual_invoices_header">
          <div className="w-100 text-center">Visa Report (Date Wise / Visa Country / Agent | Office | Customer / Supplier / Status / Report Format )</div>
        </div>
        <div className="pt-5 px-2 px-md-5">
          <div className="row align-items-center">
            <div className=" col-6">
              <ReactDatePicker
                placeholderText="From Date"
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                }}
                // onCalendarClose={handleCalendarClose}
                minDate={new Date()}
                // onCalendarOpen={handleCalendarOpen}
                className="date_picker "
              />
            </div>
            <div className=" col-6">
              <ReactDatePicker
                placeholderText="To Date"
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                }}
                // onCalendarClose={handleCalendarClose}
                minDate={new Date()}
                // onCalendarOpen={handleCalendarOpen}
                className="date_picker "
              />
            </div>
          </div>
          <div className="row align-items-center mt-5">
            <div className="col-6">
              <CustomDropdown arry={['Select All', 'Flight', 'Insurance', 'Visa', 'Transfer', 'Activity']} placehlder='Select Sales Type' />
            </div>
            <div className="col-6" >
              <CustomDropdown arry={['Select All', 'Issued', 'Requested']} placehlder='Select Status' />
            </div>
          </div>

          <div className="row align-items-center mt-5">

            <div className="col-6" >
              <TextField id="standard-basic" label="Select Supplier" variant="standard" className="w-100" />
            </div>
            <div className="col-6" >
              <TextField id="standard-basic" label="Select Office" variant="standard" className="w-100" />
            </div>
          </div>

        </div>
        <div className="mx-2 mx-md-5 pt-5">
          {/* radio buttons */}
          <div className="row align-items-start ">
            <div className="col-6 col-md-3">
              <label class="form-control">
                <input type="radio" name="radio" />
                <span className="h6">Booking Status</span>
              </label>

            </div>
            <div className="col-6 col-md-3">
              <label class="form-control">
                <input type="radio" name="radio" />
                <span className="h6">Document Status</span>
              </label>

            </div>


            <div className="col-6 col-md-2">
              <label class="form-control">
                <input type="radio" name="radio" />
                <span className="h6">PDF</span>
              </label>

            </div>
            <div className="col-6 col-md-3">
              <label class="form-control">
                <input type="radio" name="radio" />
                <span className="h6">EXCEL</span>
              </label>
            </div>
          </div>
        </div>


        {/* generat report button */}
        <div className="container py-4">
          <div className="d-flex justify-content-center align-items-center my-3 ">

            <Button variant="contained">Generate Report</Button>

          </div>
        </div>
      </div>
    </div>
  );
}





export default VisaReport;
