import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

function CustomDropdown({ arry, placehlder, initvalue, setfunction }) {
    // const [showList, setshowList] = useState(false)
    // const [SelectedValue, setSelectedValue] = useState(placehlder)
    // const [age, setAge] = React.useState(value);

    // const handleChange = (event) => {
    //     setAge(event.target.value);
    //     setfunction(event.target.value)
    // };
    return (

        <FormControl variant="standard" className='w-100 ' sx={{}}>
            <InputLabel id="demo-simple-select-standard-label">{placehlder}</InputLabel>
            <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={initvalue}
                onChange={e => setfunction(e.target.value)}
                label={placehlder}
            >

                {arry.map((list) => (
                    <MenuItem value={list} sx={{ m: 1, bgcolor: '#fff' }} >{list}</MenuItem>
                ))}

            </Select>
        </FormControl>




        // <div className="custom_drop_down">
        //     <div className="custom_drp_value_dev " onClick={(e) => {
        //         setshowList(!showList)
        //     }}
        //     >
        //         <span id="selected_value" className='me-5'>{SelectedValue}</span>
        //         <IoIosArrowDown />
        //     </div>
        //     {
        //         showList &&
        //         <div className="custom_drp_list" id="custom_drp_list">
        //             <div>{arry.map(list => {
        //                 return <div key={list} className="custom_drp_list_items" onClick={() => {
        //                     setSelectedValue(list)
        //                     setshowList(false)
        //                     setfunction(list)
        //                 }}>{list}</div>
        //             })}</div>
        //         </div>
        //     }
        // </div>
    )
}

export default CustomDropdown