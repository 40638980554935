import React, { useState } from "react";
import Header from "../components/Common/Header";
import CustomDropdown from "../components/CustomDrp";
import ReactDatePicker from "react-datepicker";

import "../Styles/manualInvoices.css";
import { Button } from "@mui/material";

function DailySaleReport() {
  const [startDate, setStartDate] = React.useState("");
  const [first, setfirst] = useState('')
  return (
    <div className="finance-container Full_height">
      <Header />
      <div className="manual_invoices mx-auto  mt-5">
        <div className="manual_invoices_header ">
          <div className="w-100 text-center">Daily Sale Report</div>
        </div>

        <div className="row align-items-end py-4">



          <div className="col-12 col-md-6 px-5">
            <CustomDropdown arry={['select service2', 'select service3']} placehlder='select service' setfunction={setfirst} />
          </div>




          <div className="col-12 col-md-6 px-5 pe-4 pe-md-0 px-md-0  mt-5 mt-md-0">
            <ReactDatePicker
              placeholderText="Date"
              selected={startDate}
              onChange={(date) => {
                setStartDate(date);
              }}
              // onCalendarClose={handleCalendarClose}
              minDate={new Date()}
              // onCalendarOpen={handleCalendarOpen}
              className="date_picker "
            />


          </div>


        </div>
        <div className="d-flex justify-content-center align-items-center my-4">
          <Button variant="contained">Generate DSR</Button>
        </div>
      </div>
    </div>
  );
}





export default DailySaleReport;
