import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import { Modal, Button } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import CustomDropdown from "../CustomDrp";

const Index = () => {
  const [showBankStatus, setShowBankStatus] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const columns = [
    {
      name: "date",
      label: "Date",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "office_name",
      label: "Office",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "view_statement",
      label: "View Statement",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "Deposit Slip",
      name: "view_deposit_slip",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <button
              className="table-btn btn-success"
              onClick={() => console.log(value, tableMeta)}
            >
              {value}
            </button>
          );
        },
      },
    },
    {
      label: "Status By Bank",
      name: "bank_status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <button
              className="table-btn btn-success"
              onClick={() => {
                setShowBankStatus(true);
                setModalData(tableMeta.rowData);
              }}
            >
              {value}
            </button>
          );
        },
      },
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "bank",
      label: "Bank",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "bank_reciept",
      label: "Bank Reciept",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "Action",
      label: "dekete",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <button
              className="btn-danger rounded edit-delete-icon-button"
              onClick={() => {
                setShowDeleteModal(true);
                setModalData(tableMeta.rowData);
              }}
            >
              <i className="fa fa-trash" aria-hidden="true"></i>
            </button>
          );
        },
      },
    },
  ];

  const data = [
    {
      payment_id: "59",
      date: "17-Sep-2022 11:47 AM",
      office_name: "ZAIRAA Main Office",
      view_statement: "276",
      view_deposit_slip: "59", // payment_id and this one should be same
      bank_status: "Confirmed",
      amount: "Issue",
      bank: "Issue Ticket Description",
      bank_reciept: "Generate Booking",
      reciepts: "RV-50",
    },
  ];
  const options = {
    filter: false,
    Selection: false,
    print: false,
    download: false,
    viewColumns: false,
    displayRowCheckbox: false,
    selectableRows: "none",
    // onRowClick: (rowData) => {
    //   navigate(`/booking-details/${rowData[3]}/active`)
    // },
  };

  return (
    <div className="pb-5 px-2 mt-5">
      <StatusModal
        show={showBankStatus}
        setShow={setShowBankStatus}
        modalData={modalData}
      />
      <DeleteRecord
        show={showDeleteModal}
        setShow={setShowDeleteModal}
        rowId={modalData && modalData[3]}
      />
      <MUIDataTable
        className="muidatatable"
        title={"Collections"}
        data={data}
        columns={columns}
        options={options}
      />
    </div>
  );
};

export default Index;

const StatusModal = ({ show, setShow, modalData }) => {
  return (
    <Modal
      show={show}
      onHide={(e) => setShow(false)}
      centered
      dialogClassName="modal-lg"
    >
      <Modal.Header>
        <Modal.Title>Deposit Slip # {modalData && modalData[3]}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-12">
          <div className="col-12 text-center mt-3">
            Do you really want to change this status ?
          </div>
          <div className="col-12 d-flex my-3">
            <div className="col-6 px-3">
              <CustomDropdown
                arry={["Process By Bank", "Cash In Hand"]}
                placehlder="Payment Method"
              />
            </div>
            <div className="col-6">
              <ReactDatePicker
                placeholderText="Date"
                // selected={startDate}
                // onChange={(date) => {
                //     setStartDate(date);
                // }}
                monthsShown={2}
                selected={new Date()}
                // onCalendarClose={handleCalendarClose}
                minDate={new Date()}
                // onCalendarOpen={handleCalendarOpen}
                className="date_picker mt-4"
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="modal-buttons-parent mx-auto">
          <Button variant="danger modal-button" onClick={(e) => setShow(false)}>
            Close
          </Button>
          <Button
            variant="primary modal-button"
            onClick={(e) => setShow(false)}
          >
            Confirm
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

const DeleteRecord = ({ show, setShow, rowId }) => {
  return (
    <Modal
      show={show}
      onHide={(e) => setShow(false)}
      centered
    >
      <Modal.Header>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-12">
          <div className="col-12 text-center mt-3">
            Reason for deleting this record *
          </div>
          <div className="col-12 text-center mt-3">
            <textarea rows={6} className="col-11 mx-auto"></textarea>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="modal-buttons-parent mx-auto">
          <Button variant="danger modal-button" onClick={(e) => setShow(false)}>
            Close
          </Button>
          <Button
            variant="primary modal-button"
            onClick={(e) => setShow(false)}
          >
            Delete
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
