import * as React from 'react';

import CustomDropdown from '../../../CustomDrp';
import { Button, TextField } from '@mui/material';
import ReactDatePicker from 'react-datepicker';

function Transfer() {
    return (
        <div className='py-3'>

            {/* passenger for room section */}

            <div>
                <PAX />
            </div>

            {/* hotel info */}
            <div>
                <TransferInfo />
            </div>

            <hr />
            {/* total  */}
            <div className=" py-2">
                <div className="h5 text-center my-2">Total</div>
                <div className="d-flex justify-content-center align-items-center flex-column my-4">
                    <div className="h6 mb-4">
                        Customer = 330 PKR
                    </div>
                    <div className="h6">
                        Supplier = 330 PKR
                    </div>
                    <Button size="small" variant='contained' className='mt-2'>Generate</Button>

                </div>
            </div>


        </div>
    )
}


const PAX = () => {
    return (
        <div>
            {/*  add pax remove pax */}
            <div className='w-75'>
                <div className='d-flex justify-content-between align-items-end '>
                    <div className='h6'>PAX</div>
                    <div>
                        <Button size="small" variant='contained' color='error' className='me-2'>Remove PAX</Button>
                        <Button size="small" variant='contained'>Add PAX</Button>

                    </div>
                </div>
                {/* pax info */}
                <div className='row justify-content-start align-items-end my-4'>
                    <div className='col-3'>
                        <CustomDropdown arry={['Adult', 'Child']} placehlder='Select Type' />
                    </div>
                    <div className='col-3'>
                        <CustomDropdown arry={['MS', 'MR', 'MRS']} placehlder='Select Title' />
                    </div>
                    <div className='col-3'>
                        <TextField label="F-Name" variant="standard" className="w-100" />
                    </div>
                    <div className='col-3'>
                        <TextField label="L-Name" variant="standard" className="w-100" />
                    </div>
                </div>


            </div>
        </div>
    )
}


const TransferInfo = () => {
    const [startDate, setStartDate] = React.useState("");
    return (
        <div className='py-4 w-75'>

            <div className='d-flex justify-content-between align-items-end '>
                <div className='h6'>Transfer</div>
                <div>
                    <Button size="small" variant='contained' color='error' className='me-2'>Remove Transfer</Button>
                    <Button size="small" variant='contained'>Add Transfer</Button>

                </div>
            </div>

            <div className='row justify-content-start align-items-end my-3'>
                <div className='col-3'>
                    <TextField label="Location (Pick Up)" variant="standard" className="w-100" />
                </div>
                <div className='col-3'>
                    <TextField label="Location (Drop Up)" variant="standard" className="w-100" />
                </div>
                <div className='col-3'>

                    <div>
                        <ReactDatePicker
                            placeholderText="Pick Up Date"
                            selected={startDate}
                            onChange={(date) => {
                                setStartDate(date);
                            }}
                            // onCalendarClose={handleCalendarClose}
                            minDate={new Date()}
                            // onCalendarOpen={handleCalendarOpen}
                            className="date_picker "
                        />
                    </div>
                </div>
                <div className='col-3'>
                    <div>
                        <ReactDatePicker
                            placeholderText="Drop Out Date"
                            selected={startDate}
                            onChange={(date) => {
                                setStartDate(date);
                            }}
                            // onCalendarClose={handleCalendarClose}
                            minDate={new Date()}
                            // onCalendarOpen={handleCalendarOpen}
                            className="date_picker"
                        />

                    </div>
                </div>

            </div>

            {/* currency detail  */}
            <div className='row justify-content-start align-items-end my-4'>
                <div className='col-2'>
                    <CustomDropdown arry={['AED', 'EURO', 'Room 3', 'Room 4']} placehlder='Currency' />
                </div>
                <div className='col-2'>
                    <TextField label="ROE" variant="standard" className="w-100" />
                </div>
                <div className='col-2'>
                    <TextField label="AED Pay" variant="standard" className="w-100" />
                </div>
                <div className='col-2'>
                    <TextField label="AED REC" variant="standard" className="w-100" />
                </div>
                <div className='col-2'>
                    <TextField label="PKR Pay" variant="standard" className="w-100" />
                </div>
                <div className='col-2'>
                    <TextField label="PKR REC" variant="standard" className="w-100" />
                </div>
            </div>


        </div>
    )
}

export default Transfer