import React from 'react'
import MUIDataTable from "mui-datatables";
import { useNavigate } from 'react-router-dom';

const UserLevel = () => {
    const navigate = useNavigate();
    const columns = [
        {
            label: "All Pages",
            name: "pages",
        },
        {
            label: "Admin",
            name: "admin",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <div class="checkbox-rect">
                    <input type="checkbox" id="with_sar" name="check" checked={value} />
                    <label for="with_sar" className="h6"></label>
                  </div>
                );
              },
            },
          },
          {
            label: "G manager",
            name: "general_manager",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <div class="checkbox-rect">
                    <input type="checkbox" id="with_sar" name="check" checked={value}/>
                    <label for="with_sar" className="h6"></label>
                  </div>
                );
              },
            },
          },
          {
            label: "Call center",
            name: "call_center",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <div class="checkbox-rect">
                    <input type="checkbox" id="with_sar" name="check" checked={value} />
                    <label for="with_sar" className="h6"></label>
                  </div>
                );
              },
            },
          },
          {
            label: "Accountant",
            name: "accountant",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <div class="checkbox-rect">
                    <input type="checkbox" id="with_sar" name="check" checked={value} />
                    <label for="with_sar" className="h6"></label>
                  </div>
                );
              },
            },
          },
          {
            label: "Finance Manager",
            name: "finance_manger",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <div class="checkbox-rect">
                    <input type="checkbox" id="with_sar" name="check" checked={value} />
                    <label for="with_sar" className="h6"></label>
                  </div>
                );
              },
            },
          },
          {
            label: "GM Sub Office",
            name: "gm_sub_office",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <div class="checkbox-rect">
                    <input type="checkbox" id="with_sar" name="check" checked={value} />
                    <label for="with_sar" className="h6"></label>
                  </div>
                );
              },
            },
          },
          {
            label: "Call Center Sub Office",
            name: "call_center_sub_office",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <div class="checkbox-rect">
                    <input type="checkbox" id="with_sar" name="check" checked={value} />
                    <label for="with_sar" className="h6"></label>
                  </div>
                );
              },
            },
          },
          {
            label: "Travel Consultant",
            name: "travel_consultant",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <div class="checkbox-rect">
                    <input type="checkbox" id="with_sar" name="check" checked={value} />
                    <label for="with_sar" className="h6"></label>
                  </div>
                );
              },
            },
          },
          {
            label: "Sales Manager",
            name: "sales_manager",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <div class="checkbox-rect">
                    <input type="checkbox" id="with_sar" name="check" checked={value} />
                    <label for="with_sar" className="h6"></label>
                  </div>
                );
              },
            },
          },
    ];

    const data = [
        {
            id: 1,
            pages: "Search",
            admin: false,
            general_manager: true,
            call_center: true,
            accountant: false,
            finance_manger: false,
            gm_sub_office: false,
            call_center_sub_office: true,
            travel_consultant: false,
            sales_manager: true,
        }
    ];

    const options = {
      filter:false,
      Selection:false,
      print:false,
      download:false,
      viewColumns:false,
      displayRowCheckbox:false,
      selectableRows: 'none',
    };
  return (
    <div className='py-4 px-2'>
    <MUIDataTable
    className='muidatatable'
          title={"User Level Details"}
          data={data}
          columns={columns}
          options={options}
        />
    </div>
  )
}

export default UserLevel