import { Button } from '@mui/material'
import React from 'react'
import Header from '../../../Common/Header'

import ReactDatePicker from "react-datepicker";

function TrialBalence() {
    return (
        <div className='Full_height'>
            <Header />
            <div className="manual_invoices  mx-auto">
                <div className="manual_invoices_header">
                    <div>Trial Balance</div>
                </div>
                <div className='row py-5 px-2 px-md-4 align-items-end'>

                    <div className='col-12 col-md-8'>
                        <ReactDatePicker
                            placeholderText="Upto Date"
                            // selected={startDate}
                            // onChange={(date) => {
                            //     setStartDate(date);
                            // }}
                            monthsShown={1}
                            // selected={new Date()}
                            // onCalendarClose={handleCalendarClose}
                            minDate={new Date()}
                            // onCalendarOpen={handleCalendarOpen}
                            className="date_picker"
                        />
                    </div>

                    <div className=' col-12 col-md-4 mt-3 mt-md-0'>
                        <Button variant='contained'>Generate Report</Button>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default TrialBalence