import React, {useState} from "react";
import { TextField } from "@mui/material";
import MUIDataTable from "mui-datatables";
import DeleteModal from "../../DeleteModal";

const Circular = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [updateRecord, setUpdateRecord] = useState({});
  const [modalData, setModalData] = useState([]);
  console.log(updateRecord.remarks)
  const columns = [
    {
      label: "Airline Logo",
      name: "airline_logo",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <img src={`${value}`} className="table-row-image" />;
        },
      },
    },
    {
      label: "PDF File",
      name: "pdf_file",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <a href={`${value.link}`} className="table-anchor">
              {value.name}
            </a>
          );
        },
      },
    },
    {
      name: "remarks",
      label: "Remarks",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "Action",
      name: "delete",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="">
              <button
                className="btn-success rounded edit-delete-icon-button me-1"
                onClick={() => setUpdateRecord(data[tableMeta.rowIndex])}
              >
                <i className="fa fa-thin fa-pencil"></i>
              </button>
              <button
                className="btn-danger rounded edit-delete-icon-button"
                onClick={() => {
                  setShowDeleteModal(true);
                  setModalData(tableMeta.rowData);
                }}
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
              </button>
            </div>
          );
        },
      },
    },
  ];

  const data = [
    {
      id: 1,
      airline_logo: "/zairaa.jpeg",
      pdf_file: {
        name: "abc.pdf",
        link: "abc.com",
      },
      remarks: "Test Circular 01",
      action: 1,
    },
  ];
  const options = {
    filter: false,
    Selection: false,
    print: false,
    download: false,
    viewColumns: false,
    displayRowCheckbox: false,
    selectableRows: "none",
    // onRowClick: (rowData) => {
    //   navigate(`/booking-details/${rowData[3]}/active`)
    // },
  };
  return (
    <div className="col-12 col-sm-6 mx-auto rounded-2 shadow mt-5 pb-5 flash-news">
      <p className="h4 otawix-heading">Circular</p>
      <div className="col-12 px-4 d-flex flex-column justify-content-center">
      {updateRecord.airline_logo && <div className="col-12 row px-5">
          <div className="col-12 col-sm-6 d-flex flex-column mt-5"><label>Previouse AirLine Logo</label><img src={updateRecord.airline_logo} className="update-airline-logo mt-3"/></div>
          <div className="col-12 col-sm-6 d-flex flex-column mt-5">
          <label>Previouse PDF File</label>
          <a href={updateRecord.pdf_file.link} target="_blank" className="update-airline-logo mt-3">{updateRecord.pdf_file.name}</a>
          </div>
      </div>}
        <div className="col-12 row px-5">
          <div className="col-12 col-sm-6 d-flex flex-column mt-5">
            <label htmlFor="" className="mb-2">
              Attach Logo <span className="text-danger">(size 70*70)</span>
            </label>
            <TextField type={"file"} variant="standard" className="col-12"/>
          </div>
          <div className="col-12 col-sm-6 d-flex flex-column mt-5">
            <label htmlFor="" className="mb-2">
              Attach File <span className="text-danger">(PDF only)</span>
            </label>
            <TextField type={"file"} variant="standard" className="col-12" />
          </div>
        </div>
        <div className="col-12 px-5">
          <div className="col-12 d-flex flex-column mt-5 px-2">
            {updateRecord.remarks ? 
              <TextField
              id="standard-basic"
              label="Remarks (75 characters only)"
              variant="standard"
              className="col-12 my-5"
              defaultValue={updateRecord.remarks}
              value={updateRecord.remarks}
              autoFocus
              inputProps={{ maxLength: 75 }}
            /> : 
            <TextField
              id="standard-basic"
              label="Remarks (75 characters only)"
              variant="standard"
              className="col-12 my-5"
              inputProps={{ maxLength: 75 }}
            />
          }
          </div>
        </div>
        {updateRecord.remarks ?<button className="btn setup-btn">Update</button>:<button className="btn setup-btn">save</button>}
      </div>
      <div className="col-12 mt-5 px-4">
      <DeleteModal
          show={showDeleteModal}
          setShow={setShowDeleteModal}
          rowId={modalData && modalData[0]}
        />
        <MUIDataTable
          className="muidatatable"
          title={"Circular Details"}
          data={data}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  );
};

export default Circular;
