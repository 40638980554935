import { Button, TextField } from "@mui/material";
import React from "react";
import CustomDropdown from "../../CustomDrp";

const AgentPNR = () => {
  return (
    <div className="mt-4">
      <div className="row align-items-end">
        <div className="col-sm-3">
          <TextField variant="standard" label='PNR' className="w-100" />
        </div>
        <div className="col-sm-3">
          <TextField variant="standard" label='Last Name' className="w-100" />
        </div>
        <div className="col-sm-3">
          <CustomDropdown arry={['Travelport', 'Sabre', 'Hitit', 'Airblue', 'serene', 'Airsial']} placehlder='' initvalue='Travelport' />

        </div>
        <div className="col-sm-3">
          <TextField variant="standard" label='Please select a company' className="w-100" />
          {/* <button
            className="btn inputs_import_tab dropdown-toggle"
            type="button"
            name="dropdown-btn"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            onClick={(e) => {
              const element = document.getElementById(
                "import_agentpnd-dropdown"
              );
              if (element.style.display === "none") {
                element.style.display = "block";
              } else {
                element.style.display = "none";
              }
            }}
          >
            Please select a company
          </button>
          <div className="dropdown-menu" id="import_agentpnd-dropdown">
            <div className="row px-4"><input type="text rounded-2" placeholder="please enter 1 or more char" /></div>
          </div> */}
        </div>
      </div>
      <div className="col-sm-12 mt-4">
        <Button variant='contained'>Retrieve</Button>

      </div>
    </div>
  );
};

export default AgentPNR;
