import React, { useState } from "react";

import PassengerInformation from "./UmrahTab/PassengerInformation";
import Tabs from "./UmrahTab/Tabs";
import FlightInfo from "./UmrahTab/FlightInfo";
import HotelInfo from "./UmrahTab/HotelInfo";
import TransferInformation from "./UmrahTab/TransferInformation";
import { TextField } from "@mui/material";

const UmrahTab = () => {
  const [InputGroup, setInputGroup] = useState("passanger");
  return (
    <div>
      <Tabs InputGroup={InputGroup} setInputGroup={setInputGroup} />
      <div className="col-6 col-md-3">
        <TextField variant="standard" label='Select a Company' className="w-100" />
      </div>
      {InputGroup === "passanger" && (
        <>
          <PassengerInformation />
        </>
      )}

      {InputGroup === "flight" && (
        <>
          <FlightInfo />
        </>
      )}
      {InputGroup === "hotel" && (
        <>
          <HotelInfo />
        </>
      )}
      {InputGroup === "Transfer" && (
        <>
          <TransferInformation />
        </>
      )}
    </div>
  );
};

export default UmrahTab;
