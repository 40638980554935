import React from 'react'
import { useNavigate } from 'react-router-dom';

const AgencyDropDown = () => {
    const navigate = useNavigate()
    const activeLink = localStorage.getItem("Active_link");
    const setActiveLink = (value) => localStorage.setItem("Active_link", value);
    return (
        <div className="dropdown-menu-agency" id="dropdown-menu-agency">
            <div
                className={`finance-dropdown-items ${activeLink === "agency-data" && "menu_link_container-active"}`}
                onClick={e => {
                    setActiveLink("agency-data")
                    navigate('/agency-data')
                }}>
                Agency Data
            </div>
            <div
                className={`finance-dropdown-items ${activeLink === "agency-documents" && "menu_link_container-active"}`}
                onClick={e => {
                    setActiveLink("agency-documents")
                    navigate('/agency-documents')
                }}>
                Documents
            </div>
            <div
                className={`finance-dropdown-items ${activeLink === "agency-users" && "menu_link_container-active"}`}
                onClick={e => {
                    setActiveLink("agency-users")
                    navigate('/agency-users')
                }}>Users</div>
            <div
                className={`finance-dropdown-items ${activeLink === "agency-user-level" && "menu_link_container-active"}`}
                onClick={e => {
                    setActiveLink("agency-user-level")
                    navigate('/agency-user-level')
                }}>
                User Level
            </div>
        </div>
    );
};
export default AgencyDropDown  