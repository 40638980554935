import React from "react";
import { useNavigate } from "react-router-dom";
import FinanceDropDown from "./FinanceDropDown";
import AgencyDropDown from "./AgencyDropDown";
import SetupDropDown from "./SetupDropDown";

import Reservation from "../../assets/images/reservation.png";
import Booking from "../../assets/images/online-booking.png";
import Collection from "../../assets/images/shopping-basket.png";
import Requests from "../../assets/images/customer-service.png";
import FlightReq from "../../assets/images/flight-attendant.png";
import UmrahReq from "../../assets/images/ritual.png";
import Groups from "../../assets/images/travel.png";
import Setup from "../../assets/images/setup.png";
import Finance from "../../assets/images/chart.png";
import Agency from "../../assets/images/agency.png";
import Chat from "../../assets/images/chat.png";

import ActiveReservation from "../../assets/images/white/reservation.png";
import ActiveBookings from "../../assets/images/white/booking.png";
import ActiveCollections from "../../assets/images/white/collection.png";
import ActiveRequests from "../../assets/images/white/request.png";
import ActiveFlightRequests from "../../assets/images/white/flight-req.png";
import ActiveUmrahRequests from "../../assets/images/white/umrah.png";
import ActiveGroups from "../../assets/images/white/group.png";
import ActiveSetup from "../../assets/images/white/setup.png";
import ActiveFinance from "../../assets/images/white/financial.png";
import ActiveAgency from "../../assets/images/white/agency.png";
import ActiveChat from "../../assets/images/white/chat.png";





function NavigationBar() {
  const navigate = useNavigate();
  const activeLink = localStorage.getItem("Active_link");
  const setActiveLink = (value) => localStorage.setItem("Active_link", value);

  return (
    <>
      <div className="navigation_bar">
        <div
          className={`menu_link_container ${activeLink === "reservation" && "menu_link_container-active"
            }`}
          onClick={(e) => {
            navigate("/reservation");
            setActiveLink("reservation");
          }}
        >
          <img src={Reservation} alt="reservation" className="menu_link_icon" />
          <img src={ActiveReservation} alt="reservation" className="menu_link_icon-active" />
          <span>Reservation</span>
        </div>
        <div
          className={`menu_link_container ${activeLink === "booking" && "menu_link_container-active"
            }`}
          onClick={(e) => {
            navigate("/booking");
            setActiveLink("booking");
          }}
        >
          <img src={Booking} alt="booking" className="menu_link_icon" />
          <img src={ActiveBookings} alt="reservation" className="menu_link_icon-active" />
          <span>Booking</span>
        </div>
        <div
          className={`menu_link_container ${activeLink === "collection" && "menu_link_container-active"
            }`}
          onClick={(e) => {
            navigate("/collection");
            setActiveLink("collection");
          }}
        >
          <img src={Collection} alt="collection" className="menu_link_icon" />
          <img src={ActiveCollections} alt="reservation" className="menu_link_icon-active" />
          <span>Collection</span>
        </div>
        <div
          className={`menu_link_container ${activeLink === "requests" && "menu_link_container-active"
            }`}
          onClick={(e) => {
            navigate("/requests");
            setActiveLink("requests");
          }}
        >
          <img src={Requests} alt="requests" className="menu_link_icon" />
          <img src={ActiveRequests} alt="reservation" className="menu_link_icon-active" />
          <span>Requests</span>
        </div>
        <div
          className={`menu_link_container ${activeLink === "flight-requests" && "menu_link_container-active"
            }`}
          onClick={(e) => {
            navigate("/flight-requests");
            setActiveLink("flight-requests");
          }}
        >
          <img src={FlightReq} alt="flight-requests" className="menu_link_icon" />
          <img src={ActiveFlightRequests} alt="reservation" className="menu_link_icon-active" />
          <span>Flight-Req</span>
        </div>
        <div
          className={`menu_link_container ${activeLink === "umrah-requests" && "menu_link_container-active"
            }`}
          onClick={(e) => {
            navigate("/umrah-requests");
            setActiveLink("umrah-requests");
          }}
        >
          <img src={UmrahReq} alt="umrah-requests" className="menu_link_icon" />
          <img src={ActiveUmrahRequests} alt="reservation" className="menu_link_icon-active" />
          <span>Umrah-Req</span>
        </div>
        <div
          className={`menu_link_container ${activeLink === "groups" && "menu_link_container-active"
            }`}
          onClick={(e) => {
            navigate("/flight-group-list");
            setActiveLink("groups");
          }}
        >
          <img src={Groups} alt="groups" className="menu_link_icon" />
          <img src={ActiveGroups} alt="reservation" className="menu_link_icon-active" />
          <span>Groups</span>
        </div>
        <div
          className={`menu_link_container ${activeLink?.split("-")[0] === "setup" && "menu_link_container-active"
            }`}
          onMouseEnter={(e) => {
            const element = document.getElementById("dropdown-menu-setup");
            element.classList.add("dropdown-menu-setup-show");
          }}
          onMouseLeave={(e) => {
            const element = document.getElementById("dropdown-menu-setup");
            element.classList.remove("dropdown-menu-setup-show");
          }}
        >
          <img src={Setup} alt="reservation" className="menu_link_icon" />
          <img src={ActiveSetup} alt="reservation" className="menu_link_icon-active" />
          <span>Setup</span>
          <SetupDropDown />
        </div>
        <div
          className={`menu_link_container ${activeLink?.split("-")[0] === "finance" &&
            "menu_link_container-active"
            }`}
          onMouseEnter={(e) => {
            const element = document.getElementById("dropdown-menu-finance");
            element.classList.add("dropdown-menu-finance-show");
          }}
          onMouseLeave={(e) => {
            const element = document.getElementById("dropdown-menu-finance");
            element.classList.remove("dropdown-menu-finance-show");
          }}
        >
          <img src={Finance} alt="reservation" className="menu_link_icon" />
          <img src={ActiveFinance} alt="reservation" className="menu_link_icon-active" />
          <span>Finance</span>
          <FinanceDropDown />
        </div>
        <div
          className={`menu_link_container ${activeLink?.split("-")[0] === "agency" && "menu_link_container-active"
            }`}
          onMouseEnter={(e) => {
            const element = document.getElementById("dropdown-menu-agency");
            element.classList.add("dropdown-menu-agency-show");
          }}
          onMouseLeave={(e) => {
            const element = document.getElementById("dropdown-menu-agency");
            element.classList.remove("dropdown-menu-agency-show");
          }}
        >
          <img src={Agency} alt="reservation" className="menu_link_icon" />
          <img src={ActiveAgency} alt="reservation" className="menu_link_icon-active" />
          <span>Agency</span>
          <AgencyDropDown />
        </div>
        <div
          className={`menu_link_container ${activeLink === "chat" && "menu_link_container-active"
            }`}
        >
          <img
            src={Chat}
            alt="reservation"
            className="menu_link_icon"
            onClick={(e) => {
              setActiveLink("chat");
            }}
          />
          <img src={ActiveChat} alt="reservation" className="menu_link_icon-active" />
          <span>Chats</span>
        </div>
      </div>











    </>
  );
}


export default NavigationBar;
