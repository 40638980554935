import React, { useState } from 'react'
import Office from '../../assets/images/work-space.png'
import User from '../../assets/images/user1.png'
import '../../Styles/menubar.css'

import { Button, TextField } from '@mui/material'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { Logout } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

function ProfileHeader({ setshowMobileMenu }) {
    const [show_profileModel, setshow_profileModel] = useState(false);
    const navigate = useNavigate();
    return (
        <>
            <div className='d-flex justify-content-between align-items-center px-4 px-md-5 bg-white shadow'>
                <div className='logo_container' onClick={e => { localStorage.setItem("Active_link", ""); navigate("/home") }}>
                    <img src="/zairaa.png" alt="logo" style={{ width: '100%', height: '100%' }} />
                </div>
                <div className='d-block d-lg-none'>
                    <MenuRoundedIcon onClick={e => setshowMobileMenu(true)} />
                </div>
                <div className='d-none d-lg-flex justify-content-between align-items-center profile_icon_container pt-4'>
                    <div className='d-flex flex-column align-items-center px-3 profile_info'>
                        <img src={Office} alt="company" width={30} height={30} />
                        <p className='text-center' >Company</p>
                    </div>
                    <div className='d-flex flex-column align-items-center px-2 profile_info' onClick={() => {
                        setshow_profileModel(!show_profileModel)
                    }}>
                        <img src={User} alt="user" width={30} height={30} />
                        <p className='text-center' >Staff Info</p>
                    </div>
                    {
                        show_profileModel && <div className='backdrop_blur_cotianer' >
                            <div className='out_side_click' onClick={e => {
                                setshow_profileModel(false)
                            }}></div>
                            <ProfilModel setshow_profileModel={setshow_profileModel} />
                        </div>
                    }

                </div>
            </div>
            <div className='d-flex d-md-none justify-content-center align-items-center py-3 bg-white border-top mt-1'>
                <div className='d-flex flex-column align-items-center me-5'>
                    <img src={Office} alt="company" width={30} height={30} />
                    <span className='text-center mt-1'>Company</span>
                </div>
                <div className='d-flex flex-column align-items-center ' onClick={() => {
                    setshow_profileModel(!show_profileModel)
                }}>
                    <img src={User} alt="user" width={30} height={30} />
                    <span className='text-center mt-1' >Staff Info</span>
                </div>
                {
                    show_profileModel && <div className='backdrop_blur_cotianer' >
                        <div className='out_side_click' onClick={e => {
                            setshow_profileModel(false)
                        }}></div>
                        <ProfilModel setshow_profileModel={setshow_profileModel} />
                    </div>
                }

            </div>
        </>
    )
}




const ProfilModel = ({ setshow_profileModel }) => {
    return (
        <div className='profile_model'>
            <div className='d-flex justify-content-center align-items-center flex-column financial-info'>

                <div className='pro-title-text '>Financial Info</div>
                <div className='pro-info-text text-success'>
                    <span className=''>Approval Limit : </span>
                    <span>PKR 232</span>
                </div>
                <div className=' mt-3 pro-info-text text-primary'>
                    <span className=''>Available Limit : </span>
                    <span>PKR 333</span>
                </div>
                <div className=' mt-3 pro-info-text text-danger'>
                    <span className=''>Use Limit : </span>
                    <span>PKR 232</span>
                </div>

                <div className='horizontal_line'
                />

            </div>
            <div className='profil_model_input_section px-2'>
                <div className='row '>
                    <div className='pro-title-text'>Staff Info</div>
                    <div className='col-6'>
                        <TextField label="F-Name" variant="standard" className="w-100" />
                    </div>
                    <div className='col-6'>
                        <TextField label="L-Name" variant="standard" className="w-100" />
                    </div>
                    <div className='col-6 my-2'>
                        <TextField label="E-Mail" variant="standard" className="w-100" />
                    </div>
                    <div className='col-6 my-2'>
                        <TextField label="Mobile" variant="standard" className="w-100" />
                    </div>
                    <div className='col-6'>
                        <TextField label="Phone" variant="standard" className="w-100" />
                    </div>
                    <div className='col-6'>
                        <TextField label="Skype ID" variant="standard" className="w-100" />
                    </div>
                    <div className='col-6 my-2'>
                        <TextField label="Password" variant="standard" className="w-100" />
                    </div>
                    <div className='col-6 my-2'>
                        <TextField label="Repeat Password" variant="standard" className="w-100" />
                    </div>
                    <div className='d-flex mt-4 my-2 justify-content-center align-items-center'>
                        <Button size="small" variant='contained' className='me-2'
                            onClick={e => {
                                setshow_profileModel(false)
                            }}>Update</Button>
                        <Button size="small" variant='contained' color='error'
                            onClick={e => {
                                setshow_profileModel(false)
                            }}>Cancel</Button>
                    </div>
                </div>
                <div className='horizontal_line'
                />
                <div className=' w-100 text-center logout_text'>
                    <Button variant="contained" endIcon={<Logout />} onClick={e => {
                        setshow_profileModel(false)
                    }}>
                        Log Out
                    </Button>
                </div>

            </div>



        </div>
    )
}



export default ProfileHeader