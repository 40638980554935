import React, { useState } from 'react'
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { Link } from "react-router-dom";


const FinanceDropDown = () => {
    const activeLink = localStorage.getItem("Active_link");
    const setActiveLink = (value) => localStorage.setItem("Active_link", value);
    const [sub_menuagents, setSub_MenuAgents] = useState(false)
    const [sub_menuSUpllier, setSub_MenuSupplier] = useState(false)
    const [sub_menuSale, setSub_MenuSale] = useState(false)

    const [sub_menuVocher, setSub_MenuVocher] = useState(false)
    const [sub_menuAccount, setSub_MenuAccount] = useState(false)
    const [bankbooking, setBankBooking] = useState(false)
    return (
        <div className="dropdown-menu-finance" id="dropdown-menu-finance">
            <div>
                <div className={`finance-dropdown-items ${activeLink === "finance-agents" && "menu_link_container-active"}`} onClick={() => {
                    setSub_MenuAgents(!sub_menuagents)
                    setSub_MenuSupplier(false)
                    setSub_MenuSale(false)
                    setSub_MenuVocher(false)
                    setSub_MenuAccount(false)
                    setActiveLink("finance-agents")
                }}>
                    <div className="d-flex align-items-center" >
                        <div className="drp-links" >Agents</div>
                    </div>
                    <div>
                        {sub_menuagents ? <IoIosArrowUp size={25} /> : <IoIosArrowDown size={25} />}
                    </div>
                </div>
                {sub_menuagents && <div className='py-2' style={{ paddingLeft: '2rem' }}>
                    <Link to="/agent-list" className="finance-dropdown-items">
                        <div >Agent List</div>
                    </Link>
                    <Link to='/agent-account-statement' className="finance-dropdown-items">
                        <div>Agent Statement</div>
                    </Link>
                </div>}
            </div>



            <div>
                <div className={`finance-dropdown-items ${activeLink === "finance-supplier" && "menu_link_container-active"}`} onClick={() => {
                    setSub_MenuSupplier(!sub_menuSUpllier)
                    setSub_MenuAgents(false)

                    setSub_MenuSale(false)
                    setSub_MenuVocher(false)
                    setSub_MenuAccount(false)
                    setActiveLink("finance-supplier")

                }}>
                    <div className="d-flex align-items-center">
                        <div className="drp-links">Suppliers</div>
                    </div>
                    <div>
                        {sub_menuSUpllier ? <IoIosArrowUp size={25} /> : <IoIosArrowDown size={25} />}
                    </div>
                </div>
                {sub_menuSUpllier && <div className='py-2' style={{ paddingLeft: '2rem' }}>
                    <Link to='/supplier-account-statement' className="finance-dropdown-items">
                        <div >Account Statement</div>
                    </Link>

                </div>}
            </div>





            <div>
                <div className={`finance-dropdown-items ${activeLink === "finance-sale" && "menu_link_container-active"}`} onClick={() => {

                    setSub_MenuSale(!sub_menuSale)

                    setSub_MenuAgents(false)
                    setSub_MenuSupplier(false)

                    setSub_MenuVocher(false)
                    setSub_MenuAccount(false)
                    setActiveLink("finance-sale")
                }}>
                    <div className="d-flex align-items-center">
                        <div className="drp-links">Sale</div>
                    </div>
                    <div>
                        {sub_menuSale ? <IoIosArrowUp size={25} /> : <IoIosArrowDown size={25} />}
                    </div>
                </div>
                {sub_menuSale && <div className='py-2' style={{ paddingLeft: '2rem' }}>
                    <Link className="finance-dropdown-items" to='/daily-sales-report'>
                        <div>Daily Sale Report</div>
                    </Link>
                    <Link className="finance-dropdown-items" to='/sales-report'>
                        <div>Sale Report</div>
                    </Link>

                    <Link to={'/refund-report'} className="finance-dropdown-items">
                        <div>Refund Report</div>
                    </Link>
                    <Link to={'/transaction-report'} className="finance-dropdown-items">
                        <div>Transactional Report</div>
                    </Link>

                    <Link to={'/visa-report'} className="finance-dropdown-items">
                        <div>Visa Report</div>
                    </Link>
                    <Link to={'/group-flight-manifest'} className="finance-dropdown-items">
                        <div>Group Flight Menifest</div>
                    </Link>
                    <Link to={'/airline-report'} className="finance-dropdown-items">
                        <div>Airline PLB</div>
                    </Link>

                    <div className="finance-dropdown-items">Agent Error Finding</div>

                    <div className="finance-dropdown-items">Supplier Error Finding</div>

                    <Link to={'/umrah-manifest-view'} className="finance-dropdown-items">
                        <div>Umrah Manifest View</div>
                    </Link>
                </div>}
            </div>


            <div>
                <div className={`finance-dropdown-items ${activeLink === "finance-vouchers" && "menu_link_container-active"}`} onClick={() => {
                    setSub_MenuVocher(!sub_menuVocher)
                    setSub_MenuAgents(false)
                    setSub_MenuSupplier(false)
                    setSub_MenuSale(false)

                    setSub_MenuAccount(false)
                    setActiveLink("finance-vouchers")
                }}>

                    <div className="d-flex align-items-center">
                        <div className="drp-links">Vochers</div>
                    </div>
                    <div>
                        {sub_menuVocher ? <IoIosArrowUp size={25} /> : <IoIosArrowDown size={25} />}
                    </div>
                </div>
                {sub_menuVocher && <div className='py-2' style={{ paddingLeft: '2rem' }}>

                    <Link to='/all-vouchers' className="finance-dropdown-items">
                        <div>All Vouchers</div>
                    </Link>
                    <Link to='/vouchers-entry' className="finance-dropdown-items">
                        <div>Vochers Entry</div>
                    </Link>
                    <Link to='/manual-invoice' className="finance-dropdown-items">
                        <div>Manual Invoice</div>
                    </Link>
                    <Link to='/manual-credit' className="finance-dropdown-items">
                        <div>Manual Credit</div>
                    </Link>
                    <Link to='/invoice-logs' className="finance-dropdown-items">
                        <div>Invoice Logs</div>
                    </Link>

                </div>}
            </div>




            <div>
                <div className={`finance-dropdown-items ${(activeLink === "finance-accounts" || activeLink === "finance-accounts-bank-book") && "menu_link_container-active"}`} onClick={() => {

                    setSub_MenuAccount(!sub_menuAccount)
                    setSub_MenuAgents(false)
                    setSub_MenuSupplier(false)
                    setSub_MenuSale(false)
                    setSub_MenuVocher(false)
                    setBankBooking(false)
                    setActiveLink("finance-accounts")
                }}>
                    <div className="d-flex align-items-center">
                        <div className="drp-links">Accounts</div>
                    </div>
                    <div>
                        {sub_menuAccount ? <IoIosArrowUp size={25} /> : <IoIosArrowDown size={25} />}
                    </div>
                </div>
            </div>
            {sub_menuAccount && <div className='py-2' style={{ paddingLeft: '2rem' }}>
                <Link to={'/chart-of-account'} className="finance-dropdown-items">
                    <div>Chart of Account</div>
                </Link>
                <Link to={'/gournal-ledger'} className="finance-dropdown-items">
                    <div>Gournal ladger</div>
                </Link>
                <Link to={'/trial-balance'} className="finance-dropdown-items">
                    <div>Trial Balence</div>
                </Link>
                <Link to={'/balance-sheet'} className="finance-dropdown-items">
                    <div>Balence Sheet</div>
                </Link>
                <Link to={'/profit-and-lose'} className="finance-dropdown-items">
                    <div>Profit and Lose</div>
                </Link>
                <Link to={'/asset-statement'} className="finance-dropdown-items">
                    <div>Assets Statement</div>
                </Link>
                <Link to={'/expance-statement'} className="finance-dropdown-items">
                    <div>Expance Statement</div>
                </Link>

                <div className={`finance-dropdown-items ${activeLink === "finance-accounts-bank-book" && "menu_link_container-active"}`} onClick={() => {
                    setBankBooking(!bankbooking)
                    setActiveLink("finance-accounts-bank-book")
                }}>
                    <div>Bank Book</div>
                    <div>
                        {bankbooking ? <IoIosArrowUp size={25} /> : <IoIosArrowDown size={25} />}
                    </div>
                </div>
                {

                    bankbooking && <div className='py-2' style={{ paddingLeft: '2rem' }}>
                        <Link to={'/bank-cash'} className="finance-dropdown-items">
                            <div>Bank/Cash</div>
                        </Link>
                        <Link to={'/add-cheque-book'} className="finance-dropdown-items">
                            <div>Add Cheque Book</div>
                        </Link>

                    </div>

                }
            </div>}
        </div>
    );
};


export default FinanceDropDown