import React from "react";

const Aboutus = () => {
  return (
    <div className="container mb-5" id="about-us">
      <div className="row">
        <div className="col-md-6">
          <img
            src="https://thumbs.dreamstime.com/b/aeroplane-head-140424.jpg"
            alt="about us"
            className="about-us-image"
          />
        </div>
        <div className="col-md-6">
          <div className="about-one__right">
            <div className="section-title text-left">
              <h2 className="section-title__title">
                Plan Your Trip with Trevily
              </h2>
            </div>
            <p className="about-one__right-text justify">
              There are many variations of passages of available but the
              majority have suffered alteration in some form, by injected hum
              randomised words which don't look even slightly.
              There are many variations of passages of available but the
              majority have suffered alteration in some form, by injected hum
              randomised words which don't look even slightly.
              There are many variations of passages of available but the
              majority have suffered alteration in some form, by injected hum
              randomised words which don't look even slightly.
              There are many variations of passages of available but the
              majority have suffered alteration in some form, by injected hum
              randomised words which don't look even slightly.
              There are many variations of passages of available but the
              majority have suffered alteration in some form, by injected hum
              randomised words which don't look even slightly.
              There are many variations of passages of available but the
              majority have suffered alteration in some form, by injected hum
              randomised words which don't look even slightly.

            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aboutus;
