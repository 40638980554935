import React from 'react'

const Tabs = ({ InputGroup, setInputGroup }) => {
  return (
    <div className="d-flex col-sm-6 mx-auto justify-content-between text-center progress_bar-register-cont mt-5">
      <div
        className="d-flex flex-column align-items-center pb-3"
        style={{ height: "8rem", width: "", cursor: "pointer" }}
      >
        <div
          className="progress_bar-register rounded-circle bg-primary text-white px-3 py-2 h4"
          onClick={() => setInputGroup("plan")}
        >
          1
        </div>

        <h6 className="text-primary">Plan</h6>
      </div>

      <div
        className="d-flex flex-column align-items-center pb-3"
        style={{ height: "8rem", width: "", cursor: "pointer" }}
      >
        <div
          className={`progress_bar-register rounded-circle ${
            (InputGroup === "traveler" || InputGroup === "beneficiary")
              ? "bg-primary"
              : "bg-secondary"
          } text-white px-3 py-2 h4`}
          onClick={() => setInputGroup("traveler")}
        >
          2
        </div>

        <h6
          className={`${
            (InputGroup === "traveler" || InputGroup === "beneficiary")
              ? "text-primary"
              : "text-secondary"
          }`}
        >
          Traveler
        </h6>
      </div>
      <div
        className="d-flex flex-column align-items-center pb-3"
        style={{ height: "8rem", width: "", cursor: "pointer" }}
      >
        <div
          className={`progress_bar-register rounded-circle ${
            InputGroup === "beneficiary"
              ? "bg-primary"
              : "bg-secondary" 
          } text-white px-3 py-2 h4`}
          onClick={() => setInputGroup("beneficiary")}
        >
          3
        </div>

        <h6
          className={`${
            InputGroup === "beneficiary"
              ? "text-primary"
              : "text-secondary"
          } `}
        >
        Beneficiary
        </h6>
    </div>
    </div>
  )
}

export default Tabs