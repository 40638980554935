import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";


import { GrClose } from "react-icons/gr";

function REgisterForm({ setShowReisterModal }) {
  const [InputGroup1, setInputGroup1] = useState(true);
  return (
    <div className="back_drop_shadow_container">
      <Modal show={true} size="lg" onHide={e => setShowReisterModal(false)}>
        <form className="px-2 px-md-4 py-2">
          {/**   header */}
          <div className="d-flex justify-content-between align-items-start registeration_form_header">
            <div className="me-4">
              <h4>B2B Registration</h4>
              <p>
                You will get the confirmation of your account activation soon as
                well as news about our products.
              </p>
            </div>
            <div>
              <GrClose
                size={18}
                onClick={() => setShowReisterModal(false)}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>



          <div className="d-none d-md-flex justify-content-center py-3 pt-5 align-items-center progress_bar-register">
            <div className="d-flex justify-content-between align-items-center w-50 progress_bar-register-cont">
              <div
                className="d-flex flex-column align-items-center pb-3"
                style={{ height: "8rem" }}
              >
                <div
                  className={`progress_bar-register rounded-circle ${InputGroup1 ? "bg-primary" : "bg-success"
                    } text-white px-3 py-2 h4`}
                >
                  1
                </div>

                <h4 className={` ${InputGroup1 ? "text-primary" : "text-success"
                  } h4`} >Company Info</h4>

              </div>
              <div
                className="d-flex flex-column align-items-center pb-3"
                style={{ height: "8rem" }}
              >
                <div
                  className={`progress_bar-register rounded-circle ${InputGroup1 ? "bg-secondary" : "bg-primary"
                    } text-white px-3 py-2 h4`}
                >
                  2
                </div>

                <h4 className={` ${InputGroup1 ? "text-secondary" : "text-primary"
                  } h4`}>
                  Personal Info
                </h4>

              </div>
            </div>
          </div>





          <div className="inputs_group">
            {InputGroup1 ? (
              <div className="first_input-group ">
                <div
                  className="d-flex flex-column align-items-center pb-3 d-md-none"
                  style={{ height: "8rem" }}
                >
                  <div
                    className={`progress_bar-register rounded-circle ${InputGroup1 ? "bg-primary" : "bg-success"
                      } text-white px-3 py-2 h4`}
                  >
                    1
                  </div>

                  <h4 className={` ${InputGroup1 ? "text-primary" : "text-success"
                    } h4`} >Company Info</h4>

                </div>
                <div className="row">
                  <div className="col-6 py-2 py-md-3 px-2">
                    {/* <label className="custom_label d-block">Office Name</label>
                    <input
                      className="custom_input"
                      placeholder="Character Limit 25"
                    /> */}
                    <TextField label='Office Name' variant="standard" className="w-100" />
                  </div>
                  <div className="col-6 py-2 py-md-3 px-2">
                    {/* <label className="custom_label d-block">
                      Official Name (Trading)*
                    </label>
                    <input
                      className="custom_input"
                      placeholder="Character Limit 25"
                    /> */}
                    <TextField label='Official Name (Trading)*' variant="standard" className="w-100" />
                  </div>

                  <div className="col-6 py-2 py-md-3 px-2">
                    {/* <label className="custom_label d-block">
                      Registration number
                    </label>
                    <input className="custom_input" placeholder="" /> */}
                    <TextField label='Registration number' variant="standard" className="w-100" />
                  </div>
                  <div className="col-6 py-2 py-md-3 px-2">
                    {/* <label className="custom_label d-block">
                      Tax / TRN number
                    </label>
                    <input className="custom_input" placeholder="" /> */}
                    <TextField label='Tax / TRN number' variant="standard" className="w-100" />
                  </div>
                  <div className="col-6 py-2 py-md-3 px-2">
                    {/* <label className="custom_label d-block">Country *</label>
                    <input className="custom_input" placeholder="" /> */}
                    <TextField label='Country' variant="standard" className="w-100" />
                  </div>
                  <div className="col-6 py-2 py-md-3 px-2">
                    {/* <label className="custom_label d-block">City *</label>
                    <input className="custom_input" placeholder="" /> */}
                    <TextField label='City' variant="standard" className="w-100" />
                  </div>
                  <div className="col-6 py-2 py-md-3 px-2">
                    {/* <label className="custom_label d-block">Zip code</label>
                    <input className="custom_input" placeholder="" /> */}
                    <TextField label='Zip code' variant="standard" className="w-100" />
                  </div>
                  <div className="col-6 py-2 py-md-3 px-2">
                    {/* <label className="custom_label d-block">Address *</label>
                    <input className="custom_input" placeholder="" /> */}
                    <TextField label='Address' variant="standard" className="w-100" />
                  </div>
                  <div className="col-6 py-2 py-md-3 px-2">
                    {/* <label className="custom_label d-block">Company url</label>
                    <input className="custom_input" placeholder="" /> */}
                    <TextField label='Company url' variant="standard" className="w-100" />
                  </div>
                  <div className="col-6 py-2 py-md-3 px-2">
                    {/* <label className="custom_label d-block">
                      Official email *
                    </label>
                    <input className="custom_input" placeholder="" /> */}
                    <TextField label='Official email ' variant="standard" className="w-100" />
                  </div>
                  <div className="col-6 py-2 py-md-3 px-2">
                    {/* <label className="custom_label d-block">
                      Office Number *
                    </label>
                    <input
                      className="custom_input"
                      placeholder="10 Digits No special character"
                    /> */}
                    <TextField label='Office Number ' variant="standard" className="w-100" />
                  </div>
                  <div className="col-6 py-2 py-md-3 px-2">
                    {/* <label className="custom_label d-block">
                      Emergency phone number *
                    </label>
                    <input
                      className="custom_input"
                      placeholder="10 Digits No special character"
                    /> */}
                    <TextField label='Emergency phone number' variant="standard" className="w-100" />
                  </div>
                </div>
              </div>
            ) : (
              <div className="first_input-group">
                <div
                  className="d-flex flex-column align-items-center pb-3 d-md-none"
                  style={{ height: "8rem" }}
                >
                  <div
                    className={`progress_bar-register rounded-circle ${InputGroup1 ? "bg-secondary" : "bg-primary"
                      } text-white px-3 py-2 h4`}
                  >
                    2
                  </div>

                  <h4 className={` ${InputGroup1 ? "text-secondary" : "text-primary"
                    } h4`}>
                    Personal Info
                  </h4>

                </div>
                <div className="row">
                  <div className="col-6 py-2 py-md-3 px-2">
                    {/* <label className="custom_label d-block">Title *</label>
                    <input
                      className="custom_input"
                      placeholder="Character Limit 25"
                    /> */}
                    <TextField label='Title' variant="standard" className="w-100" />
                  </div>
                  <div className="col-6 py-2 py-md-3 px-2">
                    {/* <label className="custom_label d-block">First name *</label>
                    <input
                      className="custom_input"
                      placeholder="Character Limit 25"
                    /> */}
                    <TextField label='First name' variant="standard" className="w-100" />
                  </div>
                  <div className="col-6 py-2 py-md-3 px-2">
                    {/* <label className="custom_label d-block">Last name *</label>
                    <input
                      className="custom_input"
                      placeholder="Character Limit 25"
                    /> */}
                    <TextField label='Last name ' variant="standard" className="w-100" />
                  </div>
                  <div className="col-6 py-2 py-md-3 px-2">
                    {/* <label className="custom_label d-block">
                      E-mail (Your Login ID) *
                    </label>
                    <input
                      className="custom_input"
                      placeholder="Character Limit 25"
                    /> */}
                    <TextField label='E-mail (Your Login ID)' variant="standard" className="w-100" />
                  </div>
                  <div className="col-6 py-2 py-md-3 px-2">
                    {/* <label className="custom_label d-block">Phone</label>
                    <input
                      className="custom_input" 
                      placeholder="Character Limit 25"
                    />*/}
                    <TextField label='Phone' variant="standard" className="w-100" />
                  </div>
                  <div className="col-6 py-2 py-md-3 px-2">
                    {/* <label className="custom_label d-block">Mobile *</label>
                    <input
                      className="custom_input"
                      placeholder="Character Limit 25"
                    /> */}
                    <TextField label='Mobile' variant="standard" className="w-100" />
                  </div>
                  <div className="col-6 py-2 py-md-3 px-2">
                    {/* <label className="custom_label d-block">Position</label>
                    <input
                      className="custom_input"
                      placeholder="Character Limit 25"
                    /> */}
                    <TextField label='Position' variant="standard" className="w-100" />
                  </div>
                  <div className="col-6 py-2 py-md-3 px-2">
                    {/* <label className="custom_label d-block">Skype id</label>
                    <input
                      className="custom_input"
                      placeholder="Character Limit 25"
                    /> */}
                    <TextField label='Skype id' variant="standard" className="w-100" />
                  </div>
                  <div className="col-6 py-2 py-md-3 px-2">
                    {/* <label className="custom_label d-block">Password *</label>
                    <input
                      className="custom_input"
                      placeholder="Character Limit 25"
                    /> */}
                    <TextField label='Password' variant="standard" className="w-100" />
                  </div>
                  <div className="col-6 py-2 py-md-3 px-2">
                    {/* <label className="custom_label d-block">
                      Repeat Password *
                    </label>
                    <input
                      className="custom_input"
                      placeholder="Character Limit 25"
                    /> */}
                    <TextField label='Repeat Password' variant="standard" className="w-100" />
                  </div>
                </div>
              </div>
            )}
          </div>

          {/** */}
          <div className="d-flex justify-content-center align-items-center py-5 px-1 px-md-3 pe-2 pe-md-4 pb-0">
            <div>
              {InputGroup1 ? (
                <Button variant="contained"
                  onClick={() => setInputGroup1(false)}
                // className={`${InputGroup1 ? "btn-primary" : "btn-secondary"
                //   } px-2 py-2`}
                // style={{ cursor: "pointer" }}
                >
                  Continue
                </Button>
              ) : (
                <>
                  <Button variant="contained"
                    onClick={() => setInputGroup1(true)}
                    // className={`${InputGroup1 ? "bg-secondary" : "btn-primary"
                    //   } px-2 py-2 me-4 `}
                    // style={{ cursor: "pointer" }}
                    className='me-2 me-md-4'
                  >
                    Previous
                  </Button>

                  <Button variant="contained"
                  // className={`${InputGroup1 ? "btn-secondary" : "btn-primary"
                  //   } px-2 py-2 border-0 outline-0`}
                  // style={{ height: "35px", cursor: "pointer" }}
                  >
                    Create Account
                  </Button>
                </>
              )}
            </div>
          </div>
        </form>

      </Modal>
    </div>
  );
}

export default REgisterForm;
