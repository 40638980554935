import React from 'react'
import Header from '../components/Common/Header'
import CircularComp from '../components/Setup/Promotions/Circular'
import Footer from '../components/Footer'

const Circular = () => {
  return (
    <div className='Full_height'>
      <Header active="setup" />
      <CircularComp />
      <Footer />
    </div>
  )
}

export default Circular