import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import AgentModel from "./Common/AgentModel";
import DeleteModal from "../DeleteModal";


const AgentCategories = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const [modalData, setModalData] = useState([]);
  const [ShowModel, setShowModel] = useState(false);
  const columns = [
    {
      name: "name",
      label: "Agent Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "Action",
      name: "actions",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="">
              <button
                className="btn-success rounded edit-delete-icon-button me-1"
                onClick={() => {
                  setShowModel(true);
                  setRowData(data[tableMeta.rowIndex]);
                }}
              >
                <i className="fa fa-thin fa-pencil"></i>
              </button>
              <button
                className="btn-danger rounded edit-delete-icon-button"
                onClick={() => {
                  setShowDeleteModal(true);
                  setModalData(tableMeta.rowData);
                }}
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
              </button>
            </div>
          );
        },
      },
    },
  ];

  const data = [
    {
      id: 1,
      name: "Counter Clients",
    },
  ];
  const options = {
    filter: false,
    Selection: false,
    print: false,
    download: false,
    viewColumns: false,
    displayRowCheckbox: false,
    selectableRows: "none",
  };
  return (
    <div className="col-12 col-sm-5 mx-auto rounded-2 shadow mt-5 pb-5 flash-news">
      {ShowModel && (
        <AgentModel setShowModel={setShowModel} rowData={rowData} />
      )}

      <p className="h4 otawix-heading">Agent Categories</p>
      <div className="col-12 px-4 d-flex justify-content-end my-4">
        <button
          className=" setup-btn"
          onClick={(e) => {
            setShowModel(!ShowModel);
          }}
        >
          Add Agent Category <i class="fa fa-thin fa-plus"></i>
        </button>
      </div>
      <div className="col-12 mt-2 px-4">
      <DeleteModal
          show={showDeleteModal}
          setShow={setShowDeleteModal}
          rowId={modalData && modalData[0]}
        />
        <MUIDataTable
          className="muidatatable"
          title={"Agents Details"}
          data={data}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  );
};

export default AgentCategories;
