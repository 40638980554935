import React from "react";
import { Modal, Button } from "react-bootstrap";
import { TextField } from "@mui/material";

const DepositSlip = ({ show, setShow, modalData }) => {
  return (
    <Modal
      show={show}
      onHide={(e) => setShow(false)}
      dialogClassName="modal-full"
      centered
    >
      <Modal.Body>
        <div className="row">
          <div className="col-sm-4">
            <div className="row">
              <div className="col-2">
                <img
                  src="/zairaa.jpeg"
                  style={{ width: "50px", height: "30px" }}
                />
              </div>
              <div className="col-8">
                <h3
                  class="text-center font-weight-semibold"
                  style={{ fontSize: "15px" }}
                >
                  ZAIRAA B2B
                </h3>
              </div>
            </div>
            <p class="text-center" style={{ fontSize: "12px" }}>
              Jinnah Road, Bani Gala,, Islamabad
            </p>
            <h3
              class="text-center font-weight-semibold"
              style={{ fontSize: "12px" }}
            >
              ZAIRAA COPY
            </h3>
            <div className="col-12 d-flex justify-content-between mb-1">
              <span>Issue Date:</span>
              <span>11Oct22</span>
            </div>
            <div className="col-12 d-flex justify-content-between mb-1">
              <span>Deposit Slip No:</span>
              <span>00000060</span>
            </div>
            <div className="col-12 d-flex justify-content-between mb-1">
              <span>Agent ID:</span>
              <span>TA-14</span>
            </div>
            <div className="col-12 d-flex justify-content-between mb-1">
              <span>Agent Name:</span>
              <span>ZAIRAA Main Office</span>
            </div>
            <div className="col-12 d-flex justify-content-between mb-1">
              <span>Valid Until:</span>
              <span>03oct22</span>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="row">
              <div className="col-2">
                <img
                  src="/zairaa.jpeg"
                  style={{ width: "50px", height: "30px" }}
                />
              </div>
              <div className="col-8">
                <h3
                  class="text-center font-weight-semibold"
                  style={{ fontSize: "15px" }}
                >
                  ZAIRAA B2B
                </h3>
              </div>
            </div>
            <p class="text-center" style={{ fontSize: "12px" }}>
              Jinnah Road, Bani Gala,, Islamabad
            </p>
            <h3
              class="text-center font-weight-semibold"
              style={{ fontSize: "12px" }}
            >
              ZAIRAA COPY
            </h3>
            <div className="col-12 d-flex justify-content-between mb-1">
              <span>Issue Date:</span>
              <span>11Oct22</span>
            </div>
            <div className="col-12 d-flex justify-content-between mb-1">
              <span>Deposit Slip No:</span>
              <span>00000060</span>
            </div>
            <div className="col-12 d-flex justify-content-between mb-1">
              <span>Agent ID:</span>
              <span>TA-14</span>
            </div>
            <div className="col-12 d-flex justify-content-between mb-1">
              <span>Agent Name:</span>
              <span>ZAIRAA Main Office</span>
            </div>
            <div className="col-12 d-flex justify-content-between mb-1">
              <span>Valid Until:</span>
              <span>03oct22</span>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="row">
              <div className="col-2">
                <img
                  src="/zairaa.jpeg"
                  style={{ width: "50px", height: "30px" }}
                />
              </div>
              <div className="col-8">
                <h3
                  class="text-center font-weight-semibold"
                  style={{ fontSize: "15px" }}
                >
                  ZAIRAA B2B
                </h3>
              </div>
            </div>
            <p class="text-center" style={{ fontSize: "12px" }}>
              Jinnah Road, Bani Gala,, Islamabad
            </p>
            <h3
              class="text-center font-weight-semibold"
              style={{ fontSize: "12px" }}
            >
              ZAIRAA COPY
            </h3>
            <div className="col-12 d-flex justify-content-between mb-1">
              <span>Issue Date:</span>
              <span>11Oct22</span>
            </div>
            <div className="col-12 d-flex justify-content-between mb-1">
              <span>Deposit Slip No:</span>
              <span>00000060</span>
            </div>
            <div className="col-12 d-flex justify-content-between mb-1">
              <span>Agent ID:</span>
              <span>TA-14</span>
            </div>
            <div className="col-12 d-flex justify-content-between mb-1">
              <span>Agent Name:</span>
              <span>ZAIRAA Main Office</span>
            </div>
            <div className="col-12 d-flex justify-content-between mb-1">
              <span>Valid Until:</span>
              <span>03oct22</span>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="modal-buttons-parent mx-auto">
          <Button
            variant="secondary modal-button"
            onClick={(e) => setShow(false)}
          >
            Cancel
          </Button>
          <Button variant="primary modal-button">Save</Button>
        </div>
      </Modal.Footer>
    </Modal>
  );    
};

export default DepositSlip;
