import React from 'react'
import Header from '../components/Common/Header'
import Deposit from '../components/Setup/Payments/Reciept'
import Footer from '../components/Footer'

const Reciept = () => {
  return (
    <div className='Full_height'>
      <Header active="setup" />
      <Deposit />
      <Footer />
    </div>
  )
}

export default Reciept