import React from "react";
import { Button, TextField } from "@mui/material";
import CustomDropdown from "../../CustomDrp";

function SupplierModel({ setShowModel, rowData }) {
  return (
    <div className="backdrop_blur_cotianer">
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="model_body">
          {/* *************** */}
          <p className="otawix-heading h4">
            {rowData?.category ? "Update Supplier" : "Add Supplier"}
          </p>

          {/* *************************** */}
          <div className="p-4 py-5">
            <div>
              <CustomDropdown
                arry={["Visa", "Flight", "Insurance", "Hotel"]}
                placehlder="Supplier Category"
              />
            </div>
            <div className="my-5">
              <TextField
                label="Supplier Name"
                variant="standard"
                className="w-100"
              />
            </div>

            <div className="row">
              <div className="col-6">
                <TextField
                  label="Contact Number"
                  variant="standard"
                  className="w-100"
                />
              </div>
              <div className="col-6">
                <CustomDropdown
                  arry={["Weekly", "ForeNight", "Montly", "Credit Term"]}
                  placehlder="Biling Cycle"
                />
              </div>
            </div>
            <div className="row my-5">
              <div className="col-6">
                <CustomDropdown
                  arry={[
                    "Pak Rupee",
                    "Saudi Riyal",
                    "British Pond",
                    "UAE Dirhim",
                    "US Dollar",
                  ]}
                  placehlder="Currency"
                />
              </div>
              <div className="col-6">
                <TextField
                  label="Cntract"
                  variant="standard"
                  className="w-100"
                  type={"file"}
                />
              </div>
            </div>
            <div className="row my-5">
              <div className="col-6">
                <TextField
                  label="Email"
                  variant="outlined"
                  className="w-100"
                  multiline
                  rows={2}
                />
              </div>
              <div className="col-6">
                <TextField
                  label="Address"
                  variant="outlined"
                  className="w-100"
                  multiline
                  rows={2}
                />
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-end">
              <div className="me-2">
                <Button
                  variant="danger modal-button"
                  onClick={(e) => setShowModel(false)}
                  style={{background: "#dc3545", color: "#fff"}}
                >
                  Close
                </Button>
              </div>
              <div className="">
                <Button
                  variant="primary modal-button"
                  onClick={(e) => setShowModel(false)}
                  style={{background: "#0d6efd", color: "#fff"}}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SupplierModel;
