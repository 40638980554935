import React from 'react'
import Header from '../components/Common/Header'
import RequirementsComp from '../components/Setup/Visa/Requirements'
import Footer from '../components/Footer'

const VisaRequirement = () => {
  return (
    <div className='Full_height'>
      <Header active="setup" />
      <RequirementsComp />
      <Footer />
    </div>
  )
}

export default VisaRequirement