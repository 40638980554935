import React, { useState } from 'react'
import CorporateModal from './CorporateModal'

const CorporatePayment = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div
      className="col-sm-5 mx-auto pb-3 rounded-3"
      style={{ marginTop: "5rem", background: "#fff" }}
    >
    <p className="otawix-heading h4">Corporate Payment</p>
      <div className="row mt-4 mx-auto col-sm-12 px-3 my-5">
        <div className="col-sm-6">
          <span>Amount *</span>
          <input
            type="text"
            className="inputs_visa_tab mt-3"
            placeholder="Enter Amount"
            required
          />
        </div>
        <div className="col-sm-6">
         <span>Company *</span>
          <input
            type="text"
            className=" inputs_visa_tab mt-3"
            role="dropdown"
            placeholder="Select Company"
          />
        </div>
      </div>
        <div className="col-sm-3 mx-auto">
          <button className="btn colored-button" onClick={e => setShowModal(true)}>Confirm Payment</button>
          <CorporateModal show={showModal} setShow={setShowModal}/>
        </div>
    </div>
  )
}

export default CorporatePayment