import React from 'react'
import Header from '../components/Common/Header'
import GroupsComp from '../components/Groups/Groups'
import Footer from '../components/Footer'

const Groups = () => {
  return (
    <div className='Full_height'>
    <Header />
    <GroupsComp />
    <Footer />
    </div>
  )
}

export default Groups