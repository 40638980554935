import React from 'react';
import Header from '../components/Common/Header';
import PricingComp from '../components/Setup/Pricing';
import Footer from '../components/Footer';

const Pricing = () => {
  return (
    <div className='Full_height'>
      <Header active="setup" />
      <PricingComp />
      <Footer />
    </div>
  )
}

export default Pricing