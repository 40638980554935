import { TextField } from "@mui/material";
import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import TimePicker from 'react-time-picker';

const FlightInfo = () => {
  const [multiRows, setMultiRows] = useState([]);

  const addRow = () => {
    console.log("add")
    const newvalue = multiRows[multiRows.length - 1];
    setMultiRows([...multiRows, newvalue ? newvalue + 1 : 1]);
  };
  const removeRow = () => {
    let rowsArray = multiRows;
    rowsArray.pop();
    setMultiRows([...rowsArray]);
  };
  return (
    <>
      <div className="text-right row">
        <div className="col-sm-9"></div>
        <div className="col-sm-3">
          <button
            className="btn btn-small btn-primary col-sm-6 border rounded-pill px-1"
            onClick={addRow}
          >
            {/*<i className="fa  fa-thin fa-plus"></i>*/} Add Flight
          </button>
          <button
            className="btn btn-small btn-danger col-sm-6 border rounded-pill px-1"
            onClick={(e) => removeRow()}
          >
            Remove Flight
            {/*<i class="fa fa-close"></i>*/}
          </button></div>
      </div>
      <div className="mt-5">
        <h6>1. Flight</h6>
        <div className="row align-items-end mt-2">
          <div className="col-md-2">
            <TextField variant="standard" label="Where From" className="w-100" />
          </div>
          <div className="col-md-2">
            <ReactDatePicker minDate={new Date()} className='date_picker' placeholderText="Departure Date" monthsShown={2} />
          </div>
          <div className="col-md-2">
            <input type='time' placeholder="Departure Time" className='date_picker' />
          </div>
          <div className="col-md-2 me-2">
            <TextField variant="standard" label="AirLine Code PK,EK" className="w-100" />
          </div>
          <div className="col-md-2">
            <TextField variant="standard" label="AirLine No e.g 220" className="w-100" />
          </div>
        </div>
        <div className="row align-items-end">
          <div className="col-md-2">
            <TextField variant="standard" label="Where To" className="w-100" />
          </div>
          <div className="col-md-2">
            <ReactDatePicker minDate={new Date()} className='date_picker' placeholderText="Arrival Date" monthsShown={2} />
          </div>
          <div className="col-md-2 ">
            <input type='time' placeholder="Departure Time" className='date_picker w-100' />
          </div>
        </div>
      </div>
      {multiRows.map((val) => {
        return <div className="mt-5">
          <hr />
          <h6>{val + 1}. Flight</h6>

          <div className="row align-items-end mt-2">
            <div className="col-md-2">
              <TextField variant="standard" label="Where From" className="w-100" />
            </div>
            <div className="col-md-2">
              <ReactDatePicker minDate={new Date()} className='date_picker' placeholderText="Departure Date" monthsShown={2} />
            </div>
            <div className="col-md-2">
              <input type='time' placeholder="Departure Time" className='date_picker' />
            </div>
            <div className="col-md-2 me-2">
              <TextField variant="standard" label="AirLine Code PK,EK" className="w-100" />
            </div>
            <div className="col-md-2">
              <TextField variant="standard" label="AirLine No e.g 220" className="w-100" />
            </div>
          </div>
          <div className="row align-items-end">
            <div className="col-md-2">
              <TextField variant="standard" label="Where To" className="w-100" />
            </div>
            <div className="col-md-2">
              <ReactDatePicker minDate={new Date()} className='date_picker' placeholderText="Arrival Date" monthsShown={2} />
            </div>
            <div className="col-md-2 ">
              <input type='time' placeholder="Departure Time" className='date_picker w-100' />
            </div>
          </div>
        </div>
      })}
    </>
  );
};

export default FlightInfo;
