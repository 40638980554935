import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import { Modal, Button } from "react-bootstrap";
import { TextField } from "@mui/material";
import DepositSlip from "./Modals/DepositSlip";

const Reciept = () => {
  const [recieptShow, setRecieptShow] = useState(false);
  const [showSubRecieptModal, setSubReciptModalShow] = useState(false);
  const [showDepositSlip, setShowDepositSlip] = useState(false);
  const [modalData, setModalData] = useState({
    id: "",
    customer_name: "",
    company_name: "Zairaa Main Office",
  });
  const columns = [
    {
      name: "payment_id",
      label: "Payment ID",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "date",
      label: "Date",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "office_name",
      label: "Office",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "view_statement",
      label: "View Statement",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "Deposit Slip",
      name: "view_deposit_slip",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <button
              className="table-btn btn-success"
              onClick={() => setShowDepositSlip(true)}
            >
              {value}
            </button>
          );
        },
      },
    },
    {
      label: "Status By Bank",
      name: "bank_status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <button
              className="table-btn btn-success"
              onClick={() => console.log(value, tableMeta)}
              disabled
            >
              {value}
            </button>
          );
        },
      },
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "bank",
      label: "Bank",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "bank_reciept",
      label: "Bank Reciept",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <p>{value}</p>
              <div>
                <img src="/zairaa.jpeg" height={80} width={80} />
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "reciepts",
      label: "Reciept",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <button
              className="table-btn btn-primary"
              onClick={() => {
                setRecieptShow(true);
                setModalData({ customer_name: "", id: value });
              }}
            >
              {value}
            </button>
          );
        },
      },
    },
  ];

  const data = [
    {
      payment_id: "59",
      date: "17-Sep-2022 11:47 AM",
      office_name: "ZAIRAA Main Office",
      view_statement: "276",
      view_deposit_slip: "59", // payment_id and this one should be same
      bank_status: "Confirmed",
      amount: "Issue",
      bank: "Issue Ticket Description",
      bank_reciept: "Generate Booking",
      reciepts: "RV-50",
    },
  ];
  const options = {
    filter: false,
    Selection: false,
    print: false,
    download: false,
    viewColumns: false,
    displayRowCheckbox: false,
    selectableRows: "none",
    // onRowClick: (rowData) => {
    //   navigate(`/booking-details/${rowData[3]}/active`)
    // },
  };

  return (
    <div className="pb-5 px-2 mt-5">
      <RecieptModal
        show={recieptShow}
        setShow={setRecieptShow}
        setSubModalShow={setSubReciptModalShow}
        setModalData={setModalData}
        modalData={modalData}
      />
      <SubRecieptModal
        show={showSubRecieptModal}
        setShow={setSubReciptModalShow}
        modalData={modalData}
      />
      <DepositSlip
        show={showDepositSlip}
        setShow={setShowDepositSlip}
        modalData={modalData}
      />
      <MUIDataTable
        className="muidatatable"
        title={"Booking Filter Record"}
        data={data}
        columns={columns}
        options={options}
      />
    </div>
  );
};

export default Reciept;

const RecieptModal = ({
  show,
  setShow,
  setSubModalShow,
  setModalData,
  modalData,
}) => {
  const [radio, setRadio] = useState("company");
  const printReciept = () => {
    setShow(false);
    setSubModalShow(true);
  };
  return (
    <Modal show={show} onHide={(e) => setShow(false)} centered>
      <Modal.Header>
        <Modal.Title>Print Receipt</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-center">
          <div className="col-5 my-2">
            <label class="form-control">
              <input
                type="radio"
                name="radio"
                value="company"
                checked={radio === "company"}
                onChange={(e) => setRadio(e.target.value)}
              />
              <span className="">Company Name</span>
            </label>
          </div>
          <div className="col-5 my-2 align-items-end">
            <label class="form-control">
              <input
                type="radio"
                name="radio"
                value="customer"
                checked={radio === "customer"}
                onChange={(e) => setRadio(e.target.value)}
              />
              <span className="">Customer Name</span>
            </label>
          </div>
        </div>
        {radio === "customer" && (
          <div className="col-10 mx-auto my-3">
            <TextField
              label="Customer Name"
              variant="standard"
              className="w-100"
              onChange={(e) =>
                setModalData({ ...modalData, customer_name: e.target.value })
              }
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="modal-buttons-parent mx-auto">
          <Button
            variant="secondary modal-button"
            onClick={(e) => setShow(false)}
          >
            Cancel
          </Button>
          <Button variant="primary modal-button" onClick={printReciept}>
            Save
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

const SubRecieptModal = ({ show, setShow, modalData }) => {
  const [radio, setRadio] = useState("company");
  return (
    <Modal
      show={show}
      onHide={(e) => setShow(false)}
      centered
      dialogClassName="modal-xl"
    >
      <Modal.Body>
        <div className="d-flex flex-column justify-content-center col-12">
          <div className="row mt-2 w-100">
            <div className="col-3">
              <img src="/zairaa.jpeg" className="modal-img" />
            </div>
            <div className="col-6">
              <div className="reciept-sub-modal-header">
                <div className="h4">ZAIRAA B2B</div>
                <span className="mt-2">Jinnah Road, Bani Gala,, Islamabad</span>
                <span className="my-2">Tel: +923345469946 / +923345469946</span>
                <div className="h6 reciept-lic-no">Govt Lic No. ID-0099</div>
                <div className="h2">Receipt</div>
              </div>
            </div>
            <div className="col-3">
              <img
                src="/IATA.png"
                className="modal-img"
                style={{ float: "right" }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 d-flex justify-content-between">
              <p>Reciept#{modalData.id}</p>
              <p>Date: 11-oct-2022</p>
            </div>
          </div>
          <div className="mt-0">
            <div className="col-12">
              <TextField
                label="Received with thanks from Messers"
                variant="standard"
                className="w-100"
                defaultValue={modalData.customer_name || modalData.company_name}
                disabled
              />
            </div>
            <div className="col-12 mt-2">
              <TextField
                label="Deposit Slip#"
                variant="standard"
                className="w-100"
              />
            </div>
            <div className="col-12 mt-2">
              <TextField
                label="The sum of Rupees"
                variant="standard"
                className="w-100"
              />
            </div>
            <div className="col-12 mt-2">
              <TextField
                label="By (Cash/ Cheque) No"
                variant="standard"
                className="w-100"
              />
            </div>
            <div className="col-3 mt-2">
              <TextField
                label="RS"
                variant="standard"
                className="w-100"
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="modal-buttons-parent mx-auto">
          <Button
            variant="secondary modal-button"
            onClick={(e) => setShow(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary modal-button"
            onClick={(e) => setShow(false)}
          >
            Save
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
