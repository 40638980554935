import React, { useState } from "react";
import Header from "../../../Common/Header";
import "../../../../Styles/manualInvoices.css";
import Flight from "./Flight";
import ServiceType from "./ServiceType";
import Insurance from "./Insurance";
import Visa from "./Visa";
import Hotel from "./Hotel";
import Transfer from "./Transfer";
import Activity from "./Activity";
import UmrahVisa from "./UmrahVisa";

function ManualInvoice() {
    const [show_Comp, setshow_Comp] = useState('Flight')
    return (
        <div className='finance-container'>
            <Header />
            <div className="manual_invoices width_95percent">
                <div className="manual_invoices_header">
                    <div>Manual Invoice</div>
                </div>
                <div className=" px-2 px-md-4">
                    <ServiceType show_Comp={show_Comp} setshow_Comp={setshow_Comp} />
                    <hr className=" horizontal-line" />
                    {
                        show_Comp === 'Flight' && <Flight />
                    }
                    {
                        show_Comp === 'Insurance' && <Insurance />
                    }
                    {
                        show_Comp === 'Visa' && <Visa />
                    }
                    {
                        show_Comp === 'Hotel' && <Hotel />
                    }
                    {
                        show_Comp === 'Transfer' && <Transfer />
                    }
                    {
                        show_Comp === 'Activity' && <Activity />
                    }
                    {
                        show_Comp === 'Umrah Visa' && <UmrahVisa />
                    }
                </div>
            </div>
        </div>
    );
}






export default ManualInvoice;
