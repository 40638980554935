import { Button, TextField } from "@mui/material";
import React, { useState } from "react";

import { GrClose } from "react-icons/gr";
import CustomDropdown from "../../CustomDrp";

function TransferInformation() {
  const [preview, setPreview] = useState(false);
  const [multiRows, setMultiRows] = useState([]);

  const addRow = () => {
    const newvalue = multiRows[multiRows.length - 1];
    setMultiRows([...multiRows, newvalue ? newvalue + 1 : 1]);
  };

  const removeRow = () => {
    let rowsArray = multiRows;
    rowsArray.pop();
    setMultiRows([...rowsArray]);
  };

  return (
    <div className="mt-4">
      <div className="d-flex justify-content-between align-items-center row">
        <h4 className="col-sm-8"> Transfer Information: </h4>
        <div className="col-sm-4 text-end">
          <Button variant='contained' className="me-2"
            onClick={addRow}
          > Add Transfer
          </Button>
          <Button variant='contained' color='error'
            onClick={removeRow}
          > Remove Transfer
          </Button>
        </div>
      </div>

      <div>
        <TransferInfoFields />
      </div>
      {multiRows.map((val) => {
        return (
          <div className="mt-3">
            <TransferInfoFields />
          </div>
        );
      })}
      <div className="d-flex justify-content-end pt-3">
        <Button variant="contained" onClick={() => setPreview(true)}>Preview</Button>
      </div>
      {preview ? (
        <div className="Preview_model">
          <Preview setPreview={setPreview} />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

const TransferInfoFields = () => (
  <div>
    <div className="custom_passenger_info py-4" style={{ height: "auto" }}>
      <div className="row align-items-end px-2">
        <div className="col-3">
          <CustomDropdown arry={['Air', '', '', '']} placehlder='Select Route' />
        </div>
        <div className="col-3">
          <CustomDropdown arry={['Plan', 'Train', 'Bus', '']} placehlder='Select Vehicle' />
        </div>
        <div className="col-3">
          <CustomDropdown arry={['', '', '', '']} placehlder='Select Supplier' />
        </div>
        <div className="col-3">
          <TextField variant="standard" label='Rate Of Exchange' className="w-100" />
        </div>
      </div>

      <div className="row align-items-end my-4 px-2">
        <div className="col-3">
          <TextField variant="standard" label='Total Rec SAR' className="w-100" type={'number'} />
        </div>
        <div className="col-3">
          <TextField variant="standard" label='Total Pay SAR' className="w-100" type={'number'} />
        </div>
        <div className="col-3">
          <TextField variant="standard" label='Total Rec PKR' className="w-100" type={'number'} />
        </div>
        <div className="col-3">
          <TextField variant="standard" label='Total Pay PKR' className="w-100" type={'number'} />
        </div>
      </div>
    </div>

  </div>
);

const Preview = ({ setPreview }) => {
  return <div className="Preview_Container container ">

    <header className="d-flex justify-content-between align-items-baseline border-bottom py-2 header_preview_model">
      <h4>Umra Information</h4>
      <GrClose
        size={25}
        onClick={() => setPreview(false)}
        style={{ cursor: "pointer" }}
      />
    </header>
    {/********************************  passenger information ************************** */}
    <div className="px-3">
      <div className="my-4 border-bottom pb-5">
        <h4>Passenger Information:</h4>
        <div className="d-flex flex-column my-4">
          <label className="h6">Company</label>
          <input disabled className="inputs_flight_tab  h6 w-25 bg-white" placeholder="Company Name here.." />
        </div>
        <h5>Passenger Manual Information:</h5>

        <div className="mt-5 h4">
          1.
        </div>

        <div className="d-flex  align-items-center mt-3">
          <div className="d-flex flex-column ">
            <label className="h6">Title</label>
            <input disabled className="inputs_flight_tab w-100 h6 bg-white" placeholder="title here" />
          </div>
          <div className="d-flex flex-column mx-3">
            <label className="h6">First Name</label>
            <input disabled className="inputs_flight_tab w-100 h6 bg-white" placeholder="First name here" />
          </div>
          <div className="d-flex flex-column ">
            <label className="h6">Last Name</label>
            <input disabled className="inputs_flight_tab w-100 h6 bg-white" placeholder="Last Name here" />
          </div>
          <div className="d-flex flex-column mx-3">
            <label className="h6">BOB</label>
            <input disabled className="inputs_flight_tab w-100 h6 bg-white" placeholder="12/aug/1990" />
          </div>
          <div className="d-flex flex-column ">
            <label className="h6">PSPT No</label>
            <input disabled className="inputs_flight_tab w-100 h6 bg-white" placeholder="90988" />
          </div>

          <div className="d-flex flex-column mx-3">
            <label className="h6">Visa No</label>
            <input disabled className="inputs_flight_tab w-100 h6 w-25 bg-white" placeholder="22333" />
          </div>
          <div className="d-flex flex-column ">
            <label className="h6">Visa Supplier</label>
            <input disabled className="inputs_flight_tab w-100 h6 w-25 bg-white" placeholder="Supplier Name ..." />
          </div>

        </div>

      </div>




      {/********************************  flight information ************************** */}
      <div className="my-4 border-bottom pb-2">
        <div className="d-flex justify-content-between align-items-center">
          <h4>Flight Information:</h4>
          <div className="d-flex flex-column my-4">
            <label className="h6">ENR</label>
            <input disabled className="inputs_flight_tab h6 w-100 bg-white" placeholder="ENR here.." />
          </div>
        </div>



        <div className="h6">Arrival ( Saudi Arabia )</div>
        <div className="d-flex  align-items-center mt-3">

          <div className="d-flex flex-column">
            <label className="h6">Flight</label>
            <input disabled className="inputs_flight_tab w-100 h6 bg-white" placeholder="Flight here ..." />
          </div>
          <div className="d-flex flex-column mx-3">
            <label className="h6">Date</label>
            <input disabled className="inputs_flight_tab w-100 h6 bg-white" placeholder="12/aug/1990" />
          </div>
          <div className="d-flex flex-column ">
            <label className="h6">Origin</label>
            <input disabled className="inputs_flight_tab w-100 h6 bg-white" placeholder="dubai ...." />
          </div>
          <div className="d-flex flex-column mx-3">
            <label className="h6">Destination</label>
            <input disabled className="inputs_flight_tab w-100 h6 bg-white" placeholder="Mecca" />
          </div>

          <div className="d-flex flex-column me-3">
            <label className="h6">Departure Time</label>
            <input disabled className="inputs_flight_tab w-100 h6 w-25 bg-white" placeholder="12/aug/2022 10am" />
          </div>
          <div className="d-flex flex-column ">
            <label className="h6">Arrival</label>
            <input disabled className="inputs_flight_tab w-100 h6 w-25 bg-white" placeholder="12/aug/2022 03pm" />
          </div>

        </div>



        <div className="h6 mt-5">Departure ( Saudi Arabia )</div>
        <div className="d-flex  align-items-center mt-3 mb-4">

          <div className="d-flex flex-column">
            <label className="h6">Flight</label>
            <input disabled className="inputs_flight_tab w-100 h6 bg-white" placeholder="Flight here ..." />
          </div>
          <div className="d-flex flex-column mx-3">
            <label className="h6">Date</label>
            <input disabled className="inputs_flight_tab w-100 h6 bg-white" placeholder="12/aug/1990" />
          </div>
          <div className="d-flex flex-column ">
            <label className="h6">Origin</label>
            <input disabled className="inputs_flight_tab w-100 h6 bg-white" placeholder="dubai ...." />
          </div>
          <div className="d-flex flex-column mx-3">
            <label className="h6">Destination</label>
            <input disabled className="inputs_flight_tab w-100 h6 bg-white" placeholder="Mecca" />
          </div>

          <div className="d-flex flex-column me-3">
            <label className="h6">Departure Time</label>
            <input disabled className="inputs_flight_tab w-100 h6 w-25 bg-white" placeholder="12/aug/2022 10am" />
          </div>
          <div className="d-flex flex-column ">
            <label className="h6">Arrival</label>
            <input disabled className="inputs_flight_tab w-100 h6 w-25 bg-white" placeholder="12/aug/2022 03pm" />
          </div>

        </div>

      </div>


      {/********************************  Hotel information ************************** */}
      <div className="my-4 border-bottom ">

        <h4 className="my-5">Hotel Information:</h4>

        {/**************************hotel info */}
        <div className="d-flex  align-items-center mt-3 border-bottom pb-4">

          <div className="d-flex flex-column">
            <label className="h6">From Date</label>
            <input disabled className="inputs_flight_tab w-100 h6 bg-white" placeholder="Flight here ..." />
          </div>
          <div className="d-flex flex-column mx-3">
            <label className="h6">To Date</label>
            <input disabled className="inputs_flight_tab w-100 h6 bg-white" placeholder="12/aug/1990" />
          </div>
          <div className="d-flex flex-column ">
            <label className="h6">Nights</label>
            <input disabled className="inputs_flight_tab w-100 h6 bg-white" placeholder="dubai ...." />
          </div>
          <div className="d-flex flex-column mx-3">
            <label className="h6">Room Nature</label>
            <input disabled className="inputs_flight_tab w-100 h6 bg-white" placeholder="Mecca" />
          </div>

          <div className="d-flex flex-column me-3">
            <label className="h6">City</label>
            <input disabled className="inputs_flight_tab w-100 h6 w-25 bg-white" placeholder="12/aug/2022 10am" />
          </div>
          <div className="d-flex flex-column ">
            <label className="h6">Hotel</label>
            <input disabled className="inputs_flight_tab w-100 h6 w-25 bg-white" placeholder="12/aug/2022 03pm" />
          </div>

        </div>


        {/************************ room info */}
        <div className="d-flex  align-items-center my-5 ">

          <div className="d-flex flex-column">
            <label className="h6">Room</label>
            <input disabled className="inputs_flight_tab w-100 h6 bg-white" placeholder="Flight here ..." />
          </div>
          <div className="d-flex flex-column mx-3">
            <label className="h6">Room Price
            </label>
            <input disabled className="inputs_flight_tab w-100 h6 bg-white" placeholder="12/aug/1990" />
          </div>
          <div className="d-flex flex-column ">
            <label className="h6">SAR ROE</label>
            <input disabled className="inputs_flight_tab w-100 h6 bg-white" placeholder="dubai ...." />
          </div>
          <div className="d-flex flex-column mx-3">
            <label className="h6">Rec SAR</label>
            <input disabled className="inputs_flight_tab w-100 h6 bg-white" placeholder="Mecca" />
          </div>

          <div className="d-flex flex-column ">
            <label className="h6">Pay SAR</label>
            <input disabled className="inputs_flight_tab w-100 h6 w-25 bg-white" placeholder="12/aug/2022 10am" />
          </div>
          <div className="d-flex flex-column mx-3">
            <label className="h6">Rec PKR</label>
            <input disabled className="inputs_flight_tab w-100 h6 w-25 bg-white" placeholder="12/aug/2022 03pm" />
          </div>
          <div className="d-flex flex-column ">
            <label className="h6">Pay PKR</label>
            <input disabled className="inputs_flight_tab w-100 h6 w-25 bg-white" placeholder="12/aug/2022 03pm" />
          </div>

        </div>

        {/************************ totel payebel info *******************/}
        <div className="d-flex  align-items-center  border-top py-5">

          <div className="d-flex flex-column">
            <label className="h6">Hotel Totel Recievable SAR</label>
            <input disabled className="inputs_flight_tab w-100 h6 bg-white" placeholder="Flight here ..." />
          </div>
          <div className="d-flex flex-column mx-3">
            <label className="h6">Hotel Total Payable SAR
            </label>
            <input disabled className="inputs_flight_tab w-100 h6 bg-white" placeholder="12/aug/1990" />
          </div>
          <div className="d-flex flex-column ">
            <label className="h6">Hotel Total Receivable PKR</label>
            <input disabled className="inputs_flight_tab w-100 h6 bg-white" placeholder="dubai ...." />
          </div>
          <div className="d-flex flex-column mx-3">
            <label className="h6">Hotel Total Payable PKR</label>
            <input disabled className="inputs_flight_tab w-100 h6 bg-white" placeholder="Mecca" />
          </div>


        </div>


      </div>



      {/******************************** Transfer information ************************** */}
      <div className="my-4 border-bottom pb-5">

        <h4 className="my-5">Transfer Information:</h4>

        <div className="d-flex  align-items-center pb-4">

          <div className="d-flex flex-column">
            <label className="h6">Route</label>
            <input disabled className="inputs_flight_tab w-100 h6 bg-white" placeholder="Flight here ..." />
          </div>
          <div className="d-flex flex-column mx-3">
            <label className="h6">Vehicle</label>
            <input disabled className="inputs_flight_tab w-100 h6 bg-white" placeholder="12/aug/1990" />
          </div>
          <div className="d-flex flex-column ">
            <label className="h6">Supplier</label>
            <input disabled className="inputs_flight_tab w-100 h6 bg-white" placeholder="dubai ...." />
          </div>
          <div className="d-flex flex-column mx-3">
            <label className="h6">ROE</label>
            <input disabled className="inputs_flight_tab w-100 h6 bg-white" placeholder="rate of exchange" />
          </div>

        </div>
        <div className="d-flex  align-items-center ">

          <div className="d-flex flex-column">
            <label className="h6">SomeV</label>
            <input disabled className="inputs_flight_tab w-100 h6 bg-white" placeholder="Flight here ..." />
          </div>
          <div className="d-flex flex-column mx-3">
            <label className="h6">REcievable Amount</label>
            <input disabled className="inputs_flight_tab w-100 h6 bg-white" placeholder="12/aug/1990" />
          </div>
          <div className="d-flex flex-column ">
            <label className="h6">Payable Value</label>
            <input disabled className="inputs_flight_tab w-100 h6 bg-white" placeholder="dubai ...." />
          </div>
          <div className="d-flex flex-column mx-3">
            <label className="h6">Payeble Amount</label>
            <input disabled className="inputs_flight_tab w-100 h6 bg-white" placeholder="rate of exchange" />
          </div>

        </div>


      </div>

      {/******************************** Totel Vochers information ************************** */}
      <div className="my-4 ">

        <h4 className="my-5 ">Totel Vochers Payebel</h4>

        <div className="d-flex  align-items-center pb-4">

          <div className="d-flex flex-column">
            <label className="h6">Amount</label>
            <input disabled className="inputs_flight_tab w-100 h6 bg-white" placeholder="224" />
          </div>
          <div className="d-flex flex-column mx-3">
            <label className="h6">Recievable Amount</label>
            <input disabled className="inputs_flight_tab w-100 h6 bg-white" placeholder="222" />
          </div>
          <div className="d-flex flex-column ">
            <label className="h6">Payebel Value</label>
            <input disabled className="inputs_flight_tab w-100 h6 bg-white" placeholder="889" />
          </div>
          <div className="d-flex flex-column mx-3">
            <label className="h6">Payebel Amount</label>
            <input disabled className="inputs_flight_tab w-100 h6 bg-white" placeholder="987" />
          </div>

        </div>


      </div>
    </div>
    {/***********************  foooter ************************************ */}

    <div className="d-flex justify-content-end align-items-center border-top py-2 footer_preview_model">
      <button onClick={() => setPreview(true)} className='btn btn-primary rounded-2 me-2'>Edit</button>
      <button onClick={() => setPreview(true)} className='btn btn-primary rounded-2 '>Submit</button>
    </div>


  </div>;
};

export default TransferInformation;
