import { Button, TextField } from '@mui/material'
import React from 'react'
import CustomDropdown from '../../../CustomDrp'
import CountryDropdown from 'country-dropdown-with-flags-for-react';

function Insurance() {
    return (
        <div className='pb-3 '>

            <div className='h4'>Insurance Particulars</div>
            {/* insurance particulars   */}
            <div className='container py-4'>
                <div className='row px-5 justify-content-end align-items-end'>
                    <div className='col-3'>
                        <CustomDropdown arry={['Schengen', 'World wide', 'Rest of world']} placehlder='Destination' />
                    </div>
                    <div className='col-3 '>
                        <CountryDropdown id="UNIQUE_ID" className='inputs w-100 absolute' preferredCountries={['pk']} value="pakistan" handleChange={e => console.log(e.target.value)}></CountryDropdown>

                    </div>
                    <div className='col-3 '>
                        <CustomDropdown arry={['7 Days', '14 Days', '1 Month', '3 Month', '6 Month', '1 Year', '2 Years']} placehlder='Duration' />
                    </div>
                    <div className='col-3 '>
                        <CustomDropdown arry={['Single', 'Family', 'Student']} placehlder='Plan Type' />
                    </div>
                </div>
                <div className='row px-5 mt-2'>
                    <div className='col-3'>
                        <TextField id="standard-basic" label="Travel Date" variant="standard" className="w-100" type={'number'} />
                    </div>
                    <div className='col-3 '>
                        <TextField id="standard-basic" label="Payable" variant="standard" className="w-100" type={'number'} />

                    </div>
                    <div className='col-3 '>
                        <TextField id="standard-basic" label="Receivable" variant="standard" className="w-100" type={'number'} />
                    </div>
                    <div className='col-3 '>
                        <TextField id="standard-basic" label="Advance Tax" variant="standard" className="w-100" type={'number'} />
                    </div>
                </div>
            </div>
            <hr />




            <div className='h4'>Insurer</div>
            {/* insurance particulars   */}
            <div className='container py-4 '>
                <div className='row px-5 justify-content-end align-items-end'>
                    <div className='col-3'>
                        <CustomDropdown arry={['Adult', 'Child', 'Infant']} placehlder='Type' />
                    </div>
                    <div className='col-3 '>
                        <CustomDropdown arry={['MR', 'MS', 'MRS']} placehlder='Title' />
                    </div>
                    <div className='col-3 '>
                        <TextField id="standard-basic" label="F-Name" variant="standard" className="w-100" />
                    </div>
                    <div className='col-3 '>
                        <TextField id="standard-basic" label="L-Name" variant="standard" className="w-100" />
                    </div>
                </div>
            </div>
            <hr />

            <div className=" py-2">
                <div className="h5 text-center my-2">Total</div>
                <div className="d-flex justify-content-center align-items-center flex-column my-4">
                    <div className="h6 mb-4">
                        Customer = 330 PKR
                    </div>
                    <div className="h6">
                        Supplier = 330 PKR
                    </div>
                    <Button size="small" variant='contained' className='mt-3'>Generate</Button>

                </div>
            </div>
        </div>
    )
}

export default Insurance