import * as React from 'react';

import CustomDropdown from '../../../CustomDrp';
import { Button, TextField } from '@mui/material';

import CountryDropdown from 'country-dropdown-with-flags-for-react';
import ReactDatePicker from 'react-datepicker';

function Hotel() {
    return (
        <div className='py-3'>
            {/* total room section */}
            <div>
                <div className='h6'>
                    Total Rooms
                </div>
                <div className='width_15 my-4'>
                    <CustomDropdown arry={['Room 1', 'Room 2', 'Room 3', 'Room 4']} placehlder='Select No Room' />
                </div>
            </div>
            <hr />
            {/* passenger for room section */}

            <div>
                <PAXForRoom />
            </div>

            {/* hotel info */}
            <div>
                <HotelInfo />
            </div>

            <hr />
            {/* total  */}

            <div className=" py-2">

                <div className="h5  my-2">Total</div>
                <div className="d-flex justify-content-center align-items-center flex-column my-4">
                    <div className="h6 mb-2">
                        Type Of Currency = 330 PKR
                    </div>
                    <div className="h6 mb-2">
                        Currency Value = 330 PKR
                    </div>
                    <div className="h6 mb-2">
                        Customer = 330 PKR
                    </div>
                    <div className="h6">
                        Supplier = 330 PKR
                    </div>
                    <Button size="small" variant='contained' className='mt-2'>Generate</Button>


                </div>

            </div>
        </div>
    )
}



const PAXForRoom = () => {
    return (
        <div>
            {/*  add pax remove pax */}
            <div className='w-75'>
                <div className='d-flex justify-content-between align-items-end '>
                    <div className='h6'>PAX For Room 1</div>
                    <div>
                        <Button size="small" variant='contained' color='error' className='me-2'>Remove PAX</Button>
                        <Button size="small" variant='contained'>Add PAX</Button>

                    </div>
                </div>
                {/* pax info */}
                <div className='row justify-content-start align-items-end my-4'>
                    <div className='col-3'>
                        <CustomDropdown arry={['Adult', 'Child']} placehlder='Select Type' />
                    </div>
                    <div className='col-3'>
                        <CustomDropdown arry={['MS', 'MR', 'MRS']} placehlder='Select Title' />
                    </div>
                    <div className='col-3'>
                        <TextField label="F-Name" variant="standard" className="w-100" />
                    </div>
                    <div className='col-3'>
                        <TextField label="L-Name" variant="standard" className="w-100" />
                    </div>
                </div>


            </div>
        </div>
    )
}


const HotelInfo = () => {
    const [startDate, setStartDate] = React.useState("");
    return (
        <div className='py-4'>


            <div className='d-flex justify-content-between align-items-end '>
                <div className='h6'>Hotel</div>
                {/* <div>
                    <button className='btn btn-primary me-2'>Remove Hotel</button>
                    <button className='btn btn-danger'>Add Hotel</button>
                </div> */}
            </div>

            <div className='row justify-content-end align-items-end'>
                <div className='col-2'>
                    <TextField label="Hotel Name" variant="standard" className="w-100" />
                </div>
                <div className='col-2'>
                    <TextField label="Room Type" variant="standard" className="w-100" />
                </div>
                <div className='col-2'>
                    <TextField label="Board Type" variant="standard" className="w-100" />
                </div>
                <div className='col-2'>
                    <CountryDropdown id="UNIQUE_ID" className='inputs w-100 absolute' preferredCountries={['pk']} value="pakistan" handleChange={e => console.log(e.target.value)}></CountryDropdown>
                </div>
                <div className='col-2'>

                    <ReactDatePicker
                        placeholderText="Check In"
                        selected={startDate}
                        onChange={(date) => {
                            setStartDate(date);
                        }}
                        // onCalendarClose={handleCalendarClose}
                        minDate={new Date()}
                        // onCalendarOpen={handleCalendarOpen}
                        className="date_picker "
                    />
                </div>
                <div className='col-2'>

                    <ReactDatePicker
                        placeholderText="Check Out"
                        selected={startDate}
                        onChange={(date) => {
                            setStartDate(date);
                        }}
                        // onCalendarClose={handleCalendarClose}
                        minDate={new Date()}
                        // onCalendarOpen={handleCalendarOpen}
                        className="date_picker"
                    />

                </div>

            </div>

            {/* currency detail  */}
            <div className='row justify-content-start align-items-end my-4'>
                <div className='col-2'>
                    <CustomDropdown arry={['AED', 'EURO', 'Room 3', 'Room 4']} placehlder='Currency' />
                </div>
                <div className='col-1'>
                    <TextField label="ROE" variant="standard" className="w-100" />
                </div>
                <div className='col-1'>
                    <TextField label="AED Pay" variant="standard" className="w-100" />
                </div>
                <div className='col-1'>
                    <TextField label="AED REC" variant="standard" className="w-100" />
                </div>
                <div className='col-1'>
                    <TextField label="PKR Pay" variant="standard" className="w-100" />
                </div>
                <div className='col-1'>
                    <TextField label="PKR REC" variant="standard" className="w-100" />
                </div>
            </div>

        </div>
    )
}

export default Hotel