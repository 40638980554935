import { TextField } from "@mui/material";
import React, { useState } from "react";
import MakePaymentModal from "./MakePaymentModal";

const MakePayment = () => {
  const [radio, setRadio] = useState("supplier");
  const [showModal, setShowModal] = useState(false);
  return (
    <div
      className="col-sm-7 mx-auto pb-3 rounded-3"
      style={{ marginTop: "5rem", background: "#fff" }}
    >
      <p className="otawix-heading h4">Make Payment</p>
      <div className="col-sm-4 d-flex mx-auto px-3 mt-5">
        <label class="form-control me-4 ">
          <input type="radio"
            id="supplier"
            value="supplier"
            checked={radio === "supplier"}
            onChange={(e) => setRadio(e.target.value)} />
          <span className="h6">Supplier</span>
        </label>

        <label class="form-control">
          <input type="radio"
            id="customer"
            value="customer"
            checked={radio === "customer"}
            onChange={(e) => setRadio(e.target.value)} />
          <span className="h6">Customer</span>
        </label>

      </div>
      <div className="row mt-4 mx-auto col-sm-11 mt-5 px-3">
        <div className='col-sm-1'></div>
        <div className="col-sm-5">
          <TextField id="standard-basic" type="number" label="Enter Amount" variant="standard" style={{ width: '23rem', }} />
        </div>
        <div className="col-sm-5">
          <TextField id="standard-basic" label={radio === "supplier" ? "Suppliers" : "Company"} variant="standard" style={{ width: '23rem', }} />
        </div>
        <div className='col-sm-1'></div>
      </div>
      <div className="col-sm-2 mx-auto my-5">
        <button className="btn colored-button mt-4" onClick={e => setShowModal(true)}>Confirm Payment</button>
        <MakePaymentModal show={showModal} setShow={setShowModal} />
      </div>
    </div>
  );
};

export default MakePayment;
