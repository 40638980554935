import MUIDataTable from 'mui-datatables';
import React, { useState } from 'react'
import Header from '../../../Common/Header'
import AccountModal from './Modal'

function ChartOfAccount() {
    const [ShowModel, setShowModel] = useState(false)
    const [rowData, setRowData] = useState({})
    const columns = [
        {
            label: "Account Code",
            name: "AccountCode",

        },
        {
            label: "Account Title",
            name: "AccountTitle",

        },
        {
            label: "Account Nature",
            name: "AccountNature",

        },
        {
            label: "Account Active",
            name: "AccountActive",

        },

        {
            label: "Option",
            name: "Option",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <button
                            className="btn-success rounded edit-delete-icon-button"
                            onClick={() => { setShowModel(true); setRowData(data[tableMeta.rowIndex]) }}
                        >
                            <i className="fa fa-thin fa-pencil"></i>
                        </button>
                    );
                },
            },
        },

    ];

    const data = [
        {
            AccountCode: 1,
            AccountTitle: "Assets",
            AccountNature: "Control",
            AccountActive: "Active",


        },
    ];
    const options = {
        filter: false,
        Selection: false,
        print: false,
        download: false,
        viewColumns: false,
        displayRowCheckbox: false,
        selectableRows: "none",
    };
    return (
        <div className='Full_height'>
            <Header />

            <div className="col-11 mx-auto rounded-2 shadow mt-5 pb-5 flash-news">
                {
                    ShowModel && <AccountModal />
                }
                <p className="h4 otawix-heading">Account List</p>
                <div className="col-12 px-4 d-flex justify-content-end my-4">
                    <button className="setup-btn" onClick={e => {
                        setShowModel(true)
                    }}>
                        Add Account <i class="fa fa-thin fa-plus"></i>
                    </button>
                </div>
                <div className="col-12 mt-2 px-4">
                    <MUIDataTable
                        className="muidatatable"
                        title={"Supplier Details"}
                        data={data}
                        columns={columns}
                        options={options}
                    />
                </div>
            </div>
        </div>
    )
}

export default ChartOfAccount