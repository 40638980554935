import { TextField } from '@mui/material'
import React, { useState } from 'react'
import CustomDropdown from '../../../CustomDrp'
import Button from '@mui/material/Button';
import ReactDatePicker from 'react-datepicker';
import CountryDropdown from 'country-dropdown-with-flags-for-react';

function UmrahVisa() {
    return (
        <div className='pb-4'>
            {/* visa type */}
            <div className='h5'>Visa Type</div>
            <div className='d-flex align-items-center my-4'>
                <label class="form-control me-5">
                    <input type="radio" name="radio" />
                    <span className="">30 DAYS SINGLE ENTRY / UAE-Visa</span>
                </label>
                <label class="form-control me-5">
                    <input type="radio" name="radio" />
                    <span className="">Short Stay / Uatv</span>
                </label>
                <label class="form-control">
                    <input type="radio" name="radio" />
                    <span className="">Short Stay / Silkways</span>
                </label>
            </div>

            <ROE />

            <PAXDetail />

            <UploadFile />

            <ExcelData />
            <div className='text-center mt-5'>
                <Button size="medium" variant='contained'>Generate</Button>
            </div>
        </div>
    )
}

const ROE = () => {
    return (
        <div>
            <TextField label="ROE" variant="standard" className="w-25" />
        </div>
    )
}

const PAXDetail = () => {
    return (
        <div className=''>
            {/* adult info */}
            <div className='row justify-content-start align-items-end my-4'>
                <div className='col-2'>
                    <CustomDropdown arry={['Adult']} placehlder='Select Type' />
                </div>
                <div className='col-2'>
                    <TextField label="Payable SAR" variant="standard" className="w-100" type={'number'} />
                </div>
                <div className='col-2'>
                    <TextField label="Recievable SAR" variant="standard" className="w-100" type={'number'} />
                </div>
                <div className='col-2'>
                    <TextField label="Payable PKR" disabled variant="standard" className="w-100" type={'number'} />
                </div>
                <div className='col-2'>
                    <TextField label="Recievable PKR" disabled variant="standard" className="w-100" type={'number'} />
                </div>
            </div>

            {/* child info */}
            <div className='row justify-content-start align-items-end my-4'>
                <div className='col-2'>
                    <CustomDropdown arry={['Child']} placehlder='Select Type' />
                </div>
                <div className='col-2'>
                    <TextField label="Payable SAR" variant="standard" className="w-100" type={'number'} />
                </div>
                <div className='col-2'>
                    <TextField label="Recievable SAR" variant="standard" className="w-100" type={'number'} />
                </div>
                <div className='col-2'>
                    <TextField label="Payable PKR" disabled variant="standard" className="w-100" type={'number'} />
                </div>
                <div className='col-2'>
                    <TextField label="Recievable PKR" disabled variant="standard" className="w-100" type={'number'} />
                </div>
            </div>

            {/* infant info */}
            <div className='row justify-content-start align-items-end my-4'>
                <div className='col-2'>
                    <CustomDropdown arry={['Infant']} placehlder='Select Type' />
                </div>
                <div className='col-2'>
                    <TextField label="Payable SAR" variant="standard" className="w-100" type={'number'} />
                </div>
                <div className='col-2'>
                    <TextField label="Recievable SAR" variant="standard" className="w-100" type={'number'} />
                </div>
                <div className='col-2'>
                    <TextField label="Payable PKR" disabled variant="standard" className="w-100" type={'number'} />
                </div>
                <div className='col-2'>
                    <TextField label="Recievable PKR" disabled variant="standard" className="w-100" type={'number'} />
                </div>
            </div>

        </div>
    )
}


const UploadFile = () => {
    const [excel, setexcel] = useState()
    const handleChange = (e) => {
        e.preventDefault()
        setexcel(e.target.value)
        console.log(e.target.value)
    }
    return (
        <div className='row py-3'>
            <div className='col-2'>
                <label htmlFor="" className='mb-2'>Upload Excel Sheet</label>
                <TextField type={'file'} variant="standard" value={excel} onChange={handleChange} />
            </div>
            <div className='col-2'>
                <label htmlFor="" className='mb-2'>Fetch Data From ExcelSheet</label>
                <Button size="small" variant='contained' onClick={handleChange}>Fetch Data</Button>
            </div>
        </div>
    )
}




const ExcelData = () => {
    const [startDate, setStartDate] = React.useState("");
    return (
        <div className='row  align-items-end  my-4'>
            <div className='col-1'>
                <TextField label="S-NO" variant="standard" className="w-100" />
            </div>
            <div className='col-2'>
                <CustomDropdown arry={['Adult', 'Child', 'Infant']} placehlder='Passenger Type' />
            </div>
            <div className='col-2'>
                <TextField label="Name" variant="standard" className="w-100" />
            </div>
            <div className='col-2'>
                <div>
                    <ReactDatePicker
                        placeholderText="Date Of Birth"
                        selected={startDate}
                        onChange={(date) => {
                            setStartDate(date);
                        }}
                        // onCalendarClose={handleCalendarClose}
                        minDate={new Date()}
                        // onCalendarOpen={handleCalendarOpen}
                        className="date_picker"
                    />
                </div>
            </div>
            <div className='col-2'>
                <TextField label="Passport No" variant="standard" className="w-100" type={'number'} />
            </div>
            <div className='col-2'>
                <CountryDropdown className='inputs w-100 ' value="pakistan" handleChange={e => console.log(e.target.value)}></CountryDropdown>
            </div>
            <div className='col-1'>
                <TextField label="Group Code" variant="standard" className="w-100" type={'number'} />
            </div>
        </div>
    )
}

export default UmrahVisa