import React, { useState } from "react";
import "../Styles/AuthHeader.css";
import "../Styles/fontawesome/all.css";
import "../Styles/fontawesome/fontawesome.css";
import { Link, useNavigate } from "react-router-dom";

import { IoIosArrowDown } from 'react-icons/io'
import { IoIosArrowUp } from 'react-icons/io'

const Header = ({ active }) => {
  const navigate = useNavigate();
  const [com_id, set_com_id] = useState(1);
  const [company, setCompany] = useState("zairaa");
  const [auth_user, set_auth_user] = useState({
    id: 1,
    company: "Travel_Consultant",
    name: "uzair",
  });
  const [messages, setMessages] = useState([
    {
      booking_id: 1,
      message: "hello",
    },
  ]);
  const [page_name, set_page_name] = "B2B Setting";
  const [page_link, set_page_link] = useState("page_link");
  return (
    <div className="col-sm-12">
      <header style={{ background: "#fff" }}>
        <div className="mobile-menu">
          <div className="circle" id="navbar">
            <i className="fa fa-bars" aria-hidden="true"></i>
          </div>
          <div className="nveMenu text-left">
            <div className="mobile-cross close-btn-nav" id="navbar">
              <i className="fa fa-times" aria-hidden="true"></i>
            </div>
            <div>
              <a href="{{ route('home_view') }}">
                <img
                  src="/zairaa.jpeg"
                  alt="travel-agency"
                  style={{ width: "200px", height: "50px" }}
                />
              </a>
            </div>
            <ul className="navlinks p-0 mt-4">
              <li>
                <a href="{{ route('search') }}">Reservation</a>
              </li>
              <li>
                <a href="{{ route('booking') }}">Bookings </a>
              </li>
              {(auth_user?.company === "Travel_Consultant" ||
                auth_user?.company === "Travel_Agent") && (
                  <li>
                    <a href="{{ route('travelerprofile') }}">Traveler Profile </a>
                  </li>
                )}
              {com_id === 1 && (
                <li>
                  <a href="{{ route('collection') }}">Collection </a>
                </li>
              )}
              {com_id === 1 && (
                <li>
                  <a href="{{ route('requests_count') }}">Request </a>
                </li>
              )}
              <li>
                <a href="{{ route('flight_group_list') }}">Flight Groups </a>
              </li>
              {auth_user?.id === 1 && (
                <li className="confirmation_dialog_open_for_db">
                  <a href="javascript:;">Database Backup </a>
                </li>
              )}
              <li>
                <a href="{{ route('flight_request_booking') }}">
                  Flight Request{" "}
                </a>
              </li>
              <li>
                <a href="{{ route('umrah_requests') }}">Umrah Request </a>
              </li>
              {page_name == "B2B Setting" && (
                <li className="dropdown">
                  <a
                    data-toggle="collapse"
                    data-target="#mobdrop1"
                    href="#"
                    className="collapsed"
                    aria-expanded="false"
                  >
                    {com_id === 1 ? "B2B Settings" : "Deposit Section"}
                    <i className="fa fa-angle-down"></i>
                  </a>
                  <ul id="mobdrop1" className="collapse">
                    {(page_link === "/make_payment" ||
                      page_link === "/collection" ||
                      page_link === "/paid_deposits" ||
                      page_link === "/deposit_slips" ||
                      page_link === "/corporate_payment" ||
                      page_link === "/collection_agent") && (
                        <li className="dropdown">
                          <a
                            data-toggle="collapse"
                            data-target="#mobdrop2"
                            href="#"
                            className="collapsed"
                            aria-expanded="false"
                          >
                            {com_id === 1 ? "Payment" : "Deposit Section"}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul id="mobdrop2" className="collapse">
                            {page_link === "/make_payment" && (
                              <li>
                                <a href="{{ route('make_payment') }}">
                                  {com_id === 1 ? "Make Payment" : "Make Deposit"}
                                </a>
                              </li>
                            )}
                            {com_id === 1 &&
                              page_link === "/collection_agent" && (
                                <li>
                                  <a href="{{ route('collection_agent') }}">
                                    Collection{" "}
                                  </a>
                                </li>
                              )}{" "}
                            {page_link === "/corporate_payment" && (
                              <li>
                                <a href="{{ route('corporate_payment') }}">
                                  Corporate Payment{" "}
                                </a>
                              </li>
                            )}{" "}
                            {page_link === "/paid_deposits" && (
                              <li>
                                <a href="{{ route('paid_deposits') }}">
                                  {" "}
                                  {com_id === 1
                                    ? "Receipt"
                                    : "Receipt Voucher"}{" "}
                                </a>
                              </li>
                            )}{" "}
                            {com_id === 1 && page_link === "/collection" && (
                              <li>
                                <a href="{{ route('collection') }}">
                                  Collection{" "}
                                </a>
                              </li>
                            )}{" "}
                            {(page_link === "/admin_vouchers" ||
                              page_link === "/admin_paid_vouchers") && (
                                <li className="dropdown">
                                  <a
                                    data-toggle="collapse"
                                    data-target="#mobdrop6"
                                    href="#"
                                    className="collapsed"
                                    aria-expanded="false"
                                  >
                                    Payment Vouchers{" "}
                                    <i className="fa fa-angle-down"></i>
                                  </a>
                                  <ul id="mobdrop6" className="collapse">
                                    {page_link === "/admin_vouchers" && (
                                      <li>
                                        <a href="{{ route('admin_vouchers') }}">
                                          Unpaid Voucher{" "}
                                        </a>
                                      </li>
                                    )}{" "}
                                    {page_link === "/admin_paid_vouchers" && (
                                      <li>
                                        <a href="{{ route('admin_paid_vouchers') }}">
                                          Paid Voucher{" "}
                                        </a>
                                      </li>
                                    )}
                                  </ul>
                                </li>
                              )}
                          </ul>
                        </li>
                      )}
                    {(page_link === "/flashnews" ||
                      page_link === "/flight_groups" ||
                      page_link === "/circular") && (
                        <li className="dropdown">
                          <a
                            data-toggle="collapse"
                            data-target="#mobdrop3"
                            href="#"
                            className="collapsed"
                            aria-expanded="false"
                          >
                            Promotions <i className="fa fa-angle-down"></i>
                          </a>
                          <ul id="mobdrop3" className="collapse">
                            {page_link === "/flashnews" && (
                              <li>
                                <a href="{{ route('flashnews') }}">
                                  Add Flash News
                                </a>
                              </li>
                            )}{" "}
                            {page_link === "/flight_groups" && (
                              <li>
                                <a href="{{ route('flight_groups') }}">
                                  {" "}
                                  Flight Group{" "}
                                </a>
                              </li>
                            )}{" "}
                            {page_link === "/circular" && (
                              <li>
                                <a href="{{ route('circular') }}">Circular </a>
                              </li>
                            )}
                            <li>
                              <a href="{{ route('home_promotion') }}">
                                Promotions{" "}
                              </a>
                            </li>
                          </ul>
                        </li>
                      )}{" "}
                    {(page_link === "/visa_countries" ||
                      page_link === "/visa_type" ||
                      page_link === "/visa_requirements" ||
                      page_link === "/visa_terms") && (
                        <li className="dropdown">
                          <a
                            data-toggle="collapse"
                            data-target="#mobdrop4"
                            href="#"
                            className="collapsed"
                            aria-expanded="false"
                          >
                            Visa <i className="fa fa-angle-down"></i>
                          </a>
                          <ul id="mobdrop4" className="collapse">
                            {page_link === "/visa_countries" && (
                              <li>
                                <a href="{{ route('visa_countries') }}">
                                  Countries{" "}
                                </a>
                              </li>
                            )}{" "}
                            {page_link === "/visa_type" && (
                              <li>
                                <a href="{{ route('visa_type') }}">Visa Type</a>
                              </li>
                            )}{" "}
                            {page_link === "/visa_requirements" && (
                              <li>
                                <a href="{{ route('visa_requirements') }}">
                                  Requirements{" "}
                                </a>
                              </li>
                            )}{" "}
                            {page_link === "/visa_terms" && (
                              <li>
                                <a href="{{ route('visa_terms') }}">
                                  Terms & Condition
                                </a>
                              </li>
                            )}
                          </ul>
                        </li>
                      )}{" "}
                    {(page_link === "/umrah_hotel" ||
                      page_link === "/umrah_transfer" ||
                      page_link === "/umrah_visa_supplier") && (
                        <li className="dropdown">
                          <a
                            data-toggle="collapse"
                            data-target="#mobdrop13"
                            href="#"
                            className="collapsed"
                            aria-expanded="false"
                          >
                            Umrah <i className="fa fa-angle-down"></i>
                          </a>
                          <ul id="mobdrop13" className="collapse">
                            {page_link === "/umrah_hotel" && (
                              <li>
                                <a href="{ route('umrah_hotel') }}">Hotel </a>
                              </li>
                            )}{" "}
                            {page_link === "/umrah_transfer" && (
                              <li>
                                <a href="{{ route('umrah_transfer') }}">
                                  Transfer{" "}
                                </a>
                              </li>
                            )}{" "}
                            {page_link === "/umrah_visa_supplier" && (
                              <li>
                                <a href="{{ route('umrah_visa_supplier') }}">
                                  Visa Supplier{" "}
                                </a>
                              </li>
                            )}
                            <li>
                              <a href="{{ route('transfer_supplier') }}">
                                Transfer Supplier{" "}
                              </a>
                            </li>
                            <li>
                              <a href="{{ route('room_type') }}">
                                Hotel Room Type{" "}
                              </a>
                            </li>
                            <li>
                              <a href="{{ route('hotel_rates') }}">
                                Umrah Hotel Rates{" "}
                              </a>
                            </li>
                          </ul>
                        </li>
                      )}{" "}
                    {(page_link === "/new_agency" ||
                      page_link === "/new_corporate") && (
                        <li className="dropdown">
                          <a
                            data-toggle="collapse"
                            data-target="#mobdrop4"
                            href="#"
                            className="collapsed"
                            aria-expanded="false"
                          >
                            New Registration <i className="fa fa-angle-down"></i>
                          </a>
                          <ul id="mobdrop4" className="collapse">
                            {page_link === "/new_agency" && (
                              <li>
                                <a href="{ route('new_agency') }}">
                                  New Agency Registration{" "}
                                </a>
                              </li>
                            )}{" "}
                            {page_link === "/new_corporate" && (
                              <li>
                                <a href="{{ route('new_corporate') }}">
                                  New Corporate Registration{" "}
                                </a>
                              </li>
                            )}
                          </ul>
                        </li>
                      )}{" "}
                    {page_link === "/supplier" && (
                      <li>
                        <a href="{{ route('supplier') }}">Add New Supplier</a>
                      </li>
                    )}{" "}
                    {page_link === "/buyer_grp_cat" && (
                      <li>
                        <a href="{{ route('bgc') }}">Agent Category</a>
                      </li>
                    )}{" "}
                    {page_link === "/buyergroup" && (
                      <li>
                        <a href="{{ route('buyergroup') }}">Agent Group</a>
                      </li>
                    )}{" "}
                    {page_link === "/office" && (
                      <li>
                        <a href="{{ route('office') }}">Agents </a>
                      </li>
                    )}{" "}
                    {page_link === "/bankdetail" && (
                      <li>
                        <a href="{{ route('bankdetail') }}">Bank Details</a>
                      </li>
                    )}{" "}
                    {page_link === "/currency" && (
                      <li>
                        <a href="{{ route('currency') }}">Currency</a>
                      </li>
                    )}
                    {com_id === 1 && page_link === "/role" && (
                      <li>
                        <a href="{{ route('role') }}">Add Role</a>
                      </li>
                    )}{" "}
                    {com_id === 1 && page_link === "/page" && (
                      <li>
                        <a href="{{ route('page') }}">Add Page</a>
                      </li>
                    )}{" "}
                    {com_id === 1 && page_link === "/pricing" && (
                      <li>
                        <a href="{{ route('pricing') }}">Pricing </a>
                      </li>
                    )}{" "}
                    {com_id === 1 && page_link === "/airline" && (
                      <li>
                        <a href="{{ route('airline') }}">Add Airline</a>
                      </li>
                    )}{" "}
                    {com_id === 1 && page_link === "/apis_credentials" && (
                      <li>
                        <a href="{{ route('apis_credentials') }}">
                          Password (API)
                        </a>
                      </li>
                    )}{" "}
                    {com_id === 1 && page_link === "/supplier_rules" && (
                      <li>
                        <a href="{{ route('supplier_rules') }}">
                          Supplier Rules
                        </a>
                      </li>
                    )}{" "}
                    {com_id === 1 && page_link === "/resolved_requests" && (
                      <li>
                        <a href="{{ route('resolved_requests') }}">
                          Resolved Request{" "}
                        </a>
                      </li>
                    )}
                  </ul>
                </li>
              )}{" "}
              {page_name === "Finance / Reporting" && (
                <li className="dropdown">
                  <a
                    data-toggle="collapse"
                    data-target="#mobdrop5"
                    href="#"
                    className="collapsed"
                    aria-expanded="false"
                  >
                    {" "}
                    {com_id === 1 ? "Finance" : "Finance Section"}
                    <i className="fa fa-angle-down"></i>
                  </a>
                  <ul id="mobdrop5" className="collapse">
                    {(page_link === "/reports/offices/1" ||
                      page_link === "/reports/offices/2") && (
                        <li className="dropdown">
                          <a
                            data-toggle="collapse"
                            data-target="#mobdrop6"
                            href="#"
                            className="collapsed"
                            aria-expanded="false"
                          >
                            Agents <i className="fa fa-angle-down"></i>
                          </a>
                          <ul id="mobdrop6" className="collapse">
                            {page_link === "/reports/offices/1" && (
                              <li>
                                <a href="{{ route('cust',1) }}">Agent List </a>
                              </li>
                            )}{" "}
                            {page_link === "/reports/offices/2" && (
                              <li>
                                <a href="{{ route('cust',2) }}">
                                  Agent Account Statement{" "}
                                </a>
                              </li>
                            )}
                          </ul>
                        </li>
                      )}{" "}
                    {page_link === "/reports/suppliers/4" && (
                      <li className="dropdown">
                        <a
                          data-toggle="collapse"
                          data-target="#mobdrop7"
                          href="#"
                          className="collapsed"
                          aria-expanded="false"
                        >
                          Suppliers <i className="fa fa-angle-down"></i>
                        </a>
                        <ul id="mobdrop7" className="collapse">
                          {page_link === "/reports/suppliers/4" && (
                            <li>
                              <a href="{{ route('sup',4) }}">
                                Supplier Statement
                              </a>
                            </li>
                          )}
                        </ul>
                      </li>
                    )}{" "}
                    {(page_link === "/daily_sale_report" ||
                      page_link === "/sale_report_view" ||
                      page_link === "/refund_report_view" ||
                      page_link === "/transactional_report_view" ||
                      page_link === "/visa_report_view" ||
                      page_link === "/group_flight_manifest_view" ||
                      page_link === "/airline_report_view" ||
                      page_link === "/agent_error_finding_report" ||
                      page_link === "/supplier_error_finding_report" ||
                      page_link === "/umrah_manifest_view") && (
                        <li className="dropdown">
                          <a
                            data-toggle="collapse"
                            data-target="#mobdrop8"
                            href="#"
                            className="collapsed"
                            aria-expanded="false"
                          >
                            Sales <i className="fa fa-angle-down"></i>
                          </a>
                          <ul id="mobdrop8" className="collapse">
                            {page_link === "/daily_sale_report" && (
                              <li>
                                <a href="{{ route('daily_sale_report.index') }}">
                                  Daily Sale Report{" "}
                                </a>
                              </li>
                            )}
                            {page_link === "/sale_report_view" && (
                              <li>
                                <a href="{{ route('sale_report_view') }}">
                                  Sale Report
                                </a>
                              </li>
                            )}
                            {page_link === "/refund_report_view" && (
                              <li>
                                <a href="{{ route('refund_report_view') }}">
                                  Refund Report{" "}
                                </a>
                              </li>
                            )}
                            {page_link === "/transactional_report_view" && (
                              <li>
                                <a href="{{ route('transactional_report_view') }}">
                                  Transactional Report
                                </a>
                              </li>
                            )}
                            {page_link === "/visa_report_view" && (
                              <li>
                                <a href="{{ route('visa_report_view') }}">
                                  Visa Report
                                </a>
                              </li>
                            )}
                            {page_link === "/group_flight_manifest_view" && (
                              <li>
                                <a href="{{ route('group_flight_manifest_view') }}">
                                  Group Flight Manifest{" "}
                                </a>
                              </li>
                            )}
                            {page_link === "/airline_report_view" && (
                              <li>
                                <a href="{{ route('airline_report_view') }}">
                                  AirLine PLB{" "}
                                </a>
                              </li>
                            )}
                            {page_link === "/agent_error_finding_report" && (
                              <li>
                                <a
                                  href="#"
                                // onclick='window.open("{{ route("agent_error_finding_report") }}");return false;'
                                >
                                  Agent Error Finding{" "}
                                </a>
                              </li>
                            )}
                            {page_link === "/supplier_error_finding_report" && (
                              <li>
                                <a
                                  href="#"
                                // onclick='window.open("{{ route("supplier_error_finding_report") }}");return false;'
                                >
                                  Supplier Error Finding
                                </a>
                              </li>
                            )}
                            {page_link === "/umrah_manifest_view" && (
                              <li>
                                <a href="{{ route('umrah_manifest_view') }}">
                                  Umrah Manifest View{" "}
                                </a>
                              </li>
                            )}
                          </ul>
                        </li>
                      )}{" "}
                    {(page_link === "/accounts/account_voucher_list" ||
                      page_link === "/accounts/account_voucher" ||
                      page_link === "/accounts/manual/invoice" ||
                      page_link === "/accounts/manual/invoice/refund" ||
                      page_link === "/invoice_logs") && (
                        <li className="dropdown">
                          <a
                            data-toggle="collapse"
                            data-target="#mobdrop9"
                            href="#"
                            className="collapsed"
                            aria-expanded="false"
                          >
                            Vouchers <i className="fa fa-angle-down"></i>
                          </a>
                          <ul id="mobdrop9" className="collapse">
                            {page_link === "/accounts/account_voucher_list" && (
                              <li>
                                <a href="{{ route('acc_voucher.list') }}">
                                  All Vouchers
                                </a>
                              </li>
                            )}
                            {page_link === "/accounts/account_voucher" && (
                              <li>
                                <a href="{{ route('acc_voucher.index') }}">
                                  Voucher Entry
                                </a>
                              </li>
                            )}
                            {page_link === "/accounts/manual/invoice" && (
                              <li>
                                <a href="{{ route('manual.invoice.index') }}">
                                  Manual Invoice
                                </a>
                              </li>
                            )}
                            {page_link === "/accounts/manual/invoice/refund" && (
                              <li>
                                <a href="{{ route('manual.invoice.refund') }}">
                                  Manual Credit
                                </a>
                              </li>
                            )}{" "}
                            {page_link === "/invoice_logs" && (
                              <li>
                                <a href="{{ route('invoice_logs') }}">
                                  Invoice Logs{" "}
                                </a>
                              </li>
                            )}
                          </ul>
                        </li>
                      )}{" "}
                    {(page_link === "/balance_sheet" ||
                      page_link === "/bankbook_bank" ||
                      page_link === "/add_chequebook" ||
                      page_link === "/accounts/accounting" ||
                      page_link === "/general_ledger" ||
                      page_link === "/profit_loss" ||
                      page_link === "/trial_balance") && (
                        <li className="dropdown">
                          <a
                            data-toggle="collapse"
                            data-target="#mobdrop10"
                            href="#"
                            className="collapsed"
                            aria-expanded="false"
                          >
                            Accounts <i className="fa fa-angle-down"></i>
                          </a>
                          <ul id="mobdrop10" className="collapse">
                            {page_link && "/accounts/accounting" && (
                              <li>
                                <a href="{{ route('accounting') }}">
                                  Chart of Account
                                </a>
                              </li>
                            )}{" "}
                            {page_link === "/general_ledger" && (
                              <li>
                                <a href="{{ route('general_ledger') }}">
                                  Journal Ledger
                                </a>
                              </li>
                            )}
                            {page_link === "/trial_balance" && (
                              <li>
                                <a href="{{ route('trial_balance') }}">
                                  Trail Balance{" "}
                                </a>
                              </li>
                            )}
                            {page_link === "/balance_sheet" && (
                              <li>
                                <a href="{{ route('balance_sheet') }}">
                                  Balance Sheet
                                </a>
                              </li>
                            )}
                            {page_link === "/profit_loss" && (
                              <li>
                                <a href="{{ route('profit_loss') }}">
                                  Profit & Loss
                                </a>
                              </li>
                            )}
                            {page_link === "/accrued-adjustment" && (
                              <li>
                                <a href="{{ route('accrued.adjustment') }}">
                                  Opening Balance
                                </a>
                              </li>
                            )}
                            {page_link === "/print_asset_statement" && (
                              <li>
                                <a
                                  href="#"
                                // onclick='window.open("{{ route("print_asset_statement") }}");return false;'
                                >
                                  Assets Statement
                                </a>
                              </li>
                            )}
                            {page_link === "/expanse_statement" && (
                              <li>
                                <a href='{{ route("expanse_statement") }}'>
                                  Expanse Statement
                                </a>
                              </li>
                            )}
                            {(page_link === "/bankbook_bank" ||
                              page_link === "/add_chequebook") && (
                                <li className="dropdown">
                                  <a
                                    data-toggle="collapse"
                                    data-target="#mobdrop11"
                                    href="#"
                                    className="collapsed"
                                    aria-expanded="false"
                                  >
                                    Bank Book <i className="fa fa-angle-down"></i>
                                  </a>
                                  <ul id="mobdrop11" className="collapse">
                                    {page_link === "/bankbook_bank" && (
                                      <li>
                                        <a href="{{ route('bankbook_bank') }}">
                                          Bank / Cash
                                        </a>
                                      </li>
                                    )}
                                    {page_link === "/bankbook_bank" && (
                                      <li>
                                        <a href="{{ route('add_chequebook') }}">
                                          Add Chq Book
                                        </a>
                                      </li>
                                    )}
                                  </ul>
                                </li>
                              )}
                          </ul>
                        </li>
                      )}
                  </ul>
                </li>
              )}{" "}
              {page_name === "Office Profile" && (
                <li className="dropdown">
                  <a
                    data-toggle="collapse"
                    data-target="#mobdrop12"
                    href="#"
                    className="collapsed"
                    aria-expanded="false"
                  >
                    {" "}
                    {com_id === 1 ? "Office Profile" : "Agency rofile"}
                    <i className="fa fa-angle-down"></i>
                  </a>
                  <ul id="mobdrop12" className="collapse">
                    {page_link === "/officedata" && (
                      <li>
                        <a href="{{ route('officedata') }}">
                          {" "}
                          {com_id === 1 ? "Agency Data" : "Agency Details"}
                        </a>
                      </li>
                    )}
                    {page_link === "/userdocs" && (
                      <li>
                        <a href="{{ route('userdocs') }}">
                          {com_id === 1 ? "Documents" : "Agency Document"}
                        </a>
                      </li>
                    )}
                    {page_link === "/userlevel" && (
                      <li>
                        <a href="{{ route('userlevel') }}">User Level</a>
                      </li>
                    )}
                    {page_link === "/user" && (
                      <li>
                        <a href="{{ route('user') }}">
                          {" "}
                          {com_id === 1 ? "Users" : "Users List"}
                        </a>
                      </li>
                    )}
                  </ul>
                </li>
              )}
              <li>
                <a href="{{ route('user_profile_edit') }}">User </a>
              </li>
              <li>
                {" "}
                <a
                  href="javascript:;"
                // onclick="event.preventDefault(); document.getElementById('logout-form').submit();"
                >
                  Log Out{" "}
                </a>
                <form
                  id="logout-form"
                  action="{{ route('logout') }}"
                  method="POST"
                  style={{ display: "none" }}
                ></form>
              </li>
            </ul>
          </div>
          <div className="overlay"></div>
        </div>
        <div className="col-sm-11 px-5 d-flex justify-content-between py-1">
          <div>
            <a href="{{ route('home_view') }}">
              <img className="zairaalogo" src="/zairaa.jpeg" alt="logo" />
            </a>
          </div>
          <div className="zaira_menu_section">
            <div className="iconbar my-auto pt-2">
              <div className="col-sm-10">
                <div className="user-infobar float-right">
                  <div className="row pr-2">
                    {company && (
                      <>
                        <div className="col-sm-2">
                          <i
                            className="fa fa-thin fa-building HeaderIcon"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Traveler Profile"
                          // onclick="document.getElementById('traveler_consultant').click()"
                          ></i>
                        </div>
                        <div className="col-sm-10 header-icon-text">
                          <span className="user-info">
                            {company.office_name || "Zairaa B2B"}
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="row pr-2">
                    <div className="col-sm-2">
                      <i
                        className="fa fa-light fa-user HeaderIcon"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Traveler Profile"
                      // onclick="document.getElementById('traveler_consultant').click()"
                      ></i>
                    </div>
                    <div className="col-sm-10">
                      <span className="user-info">{auth_user.name}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-2 ml-3">
                <i
                  className="fa fa-thin fa-circle-user UserProfile"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="User Profile"
                // onclick="document.getElementById('user_profile').click()"
                ></i>
              </div>
              <a href="{{ route('user_profile_edit') }}" id="user_profile"></a>
              <a
                href="{{ route('flight_request_booking') }}"
                id="flight_request_booking"
              ></a>
            </div>
          </div>
        </div>
      </header>
      <div id="menu_area" className="menu-area">
        <div className="row menu-area-row">
          <div className="col-sm-6">
            <div
              id="mainmenu"
              className="row"
              style={{ display: "none", zIndex: 1000 }}
            >
              <div id="menuclosebutton">
                <div
                  id="hideMenu"
                  className="text-center"
                  style={{
                    cursor: "pointer",
                    paddingTop: "5px",
                    paddingLeft: "50px",
                    fontSize: "18px",
                    color: "#ffffff",
                    width: "10%",
                  }}
                >
                  <i className="fa fa-times-circle"></i>
                </div>
              </div>
              {page_name === "B2B Setting" && (
                <div
                  id="menubb2b"
                  style={{ width: "180px", paddingLeft: "50px" }}
                >
                  <div
                    id="jquery-accordion-menu-b2bSetting"
                    className="jquery-accordion-menu"
                  >
                    <div className="jquery-accordion-menu-header">
                      {com_id === 1 ? "B2B Settings" : "Deposit Section"}
                    </div>
                    <ul>
                      {(page_link === "/make_payment" ||
                        page_link === "/collection" ||
                        page_link === "/paid_deposits" ||
                        page_link === "/deposit_slips" ||
                        page_link === "/corporate_payment" ||
                        page_link === "/collection_agent") && (
                          <li className="{{ set_active(['make_payment*','paid_deposits*','collection*','deposit_slips*','corporate_payment*','collection_agent.*']) }}">
                            <a href="#">
                              <i className="fa fa-file"></i>
                              {com_id === 1 ? "" : "Deposit"}
                            </a>
                            <ul className="submenu">
                              {page_link === "/make_payment" && (
                                <li className="{{ set_active('make_payment') }}">
                                  <a href="{{ route('make_payment') }}">
                                    <i className="fa fa-arrow-circle-right"></i>
                                    {com_id === 1
                                      ? "Make Payment"
                                      : "Make Deposit"}
                                  </a>
                                </li>
                              )}
                              {com_id !== 1 && page_link === "/collection_agent" && (
                                <li className="{{ set_active('collection_agent') }}">
                                  <a href="{{ route('collection_agent') }}">
                                    <i className="fa fa-arrow-circle-right"></i>
                                    Collection
                                  </a>
                                </li>
                              )}
                              {page_link === "/corporate_payment" && (
                                <li className="{{ set_active('corporate_payment') }}">
                                  <a href="{{ route('corporate_payment') }}">
                                    <i className="fa fa-arrow-circle-right"></i>
                                    Corporate Payment
                                  </a>
                                </li>
                              )}
                              {page_link === "/paid_deposits" && (
                                <li className="{{ set_active('paid_deposits') }}">
                                  <a href="{{ route('paid_deposits') }}">
                                    <i className="fa fa-arrow-circle-right"></i>
                                    {com_id === 1
                                      ? "Receipt"
                                      : "Receipt Voucher "}
                                  </a>
                                </li>
                              )}
                              {com_id === 1 && page_link === "/collection" && (
                                <li className="set_active('collection') }}">
                                  <a href="{{ route('collection') }}">
                                    <i className="fa fa-arrow-circle-right"></i>
                                    Collection
                                  </a>
                                </li>
                              )}
                              {(page_link === "/admin_vouchers" ||
                                page_link === "/admin_paid_vouchers") && (
                                  <li className="{{ set_active(['admin_vouchers*','admin_paid_vouchers*']) }}">
                                    <a href="#">Payment Vouchers</a>
                                    <ul className="submenu">
                                      {page_link === "/admin_vouchers" && (
                                        <li className="{{ set_active('admin_vouchers') }}">
                                          <a href="{{ route('admin_vouchers') }}">
                                            <i className="fa fa-arrow-circle-right"></i>
                                            Unpaid Voucher
                                          </a>
                                        </li>
                                      )}
                                      {page_link === "/admin_paid_vouchers" && (
                                        <li className="{{ set_active('admin_paid_vouchers') }}">
                                          <a href="{{ route('admin_paid_vouchers') }}">
                                            <i className="fa fa-arrow-circle-right"></i>
                                            Paid Voucher
                                          </a>
                                        </li>
                                      )}
                                    </ul>
                                  </li>
                                )}
                            </ul>
                          </li>
                        )}
                      {(page_link === "/flashnews" ||
                        page_link === "/flight_groups" ||
                        page_link === "/circular") && (
                          <li className="{{ set_active(['flashnews*','flight_groups*']) }}">
                            <a href="#">
                              <i className="fa fa-certificate"></i>Promotions{" "}
                            </a>
                            <ul className="submenu">
                              {page_link === "/flashnews" && (
                                <li className="{{ set_active('flashnews') }}">
                                  <a href="{{ route('flashnews') }}">
                                    <i className="fa fa-arrow-circle-right"></i>
                                    Add Flash News
                                  </a>
                                </li>
                              )}
                              {page_link === "/flight_groups" && (
                                <li className="{{ set_active('flight_groups') }}">
                                  <a href="{{ route('flight_groups') }}">
                                    <i className="fa fa-arrow-circle-right"></i>
                                    Flight Groups
                                  </a>
                                </li>
                              )}
                              {page_link === "/circular" && (
                                <li className="{{ set_active('circular') }}">
                                  <a href="{{ route('circular') }}">
                                    <i className="fa fa-arrow-circle-right"></i>
                                    Circular
                                  </a>
                                </li>
                              )}
                              <li className="{{ set_active('home_promotion') }}">
                                <a href="{{ route('home_promotion') }}">
                                  <i className="fa fa-arrow-circle-right"></i>
                                  Promotions
                                </a>
                              </li>
                            </ul>
                          </li>
                        )}
                      {(page_link === "/visa_countries" ||
                        page_link === "/visa_type" ||
                        page_link === "/visa_requirements" ||
                        page_link === "/visa_terms") && (
                          <li className="{{ set_active(['visa_countries*','visa_type*','visa_requirements*','visa_terms']) }}">
                            <a href="#">
                              <i className="fa fa-certificate"></i>Visa{" "}
                            </a>
                            <ul className="submenu">
                              {page_link === "/visa_countries" && (
                                <li className="{{ set_active('visa_countries') }}">
                                  <a href="{{ route('visa_countries') }}">
                                    <i className="fa fa-arrow-circle-right"></i>
                                    Countries
                                  </a>
                                </li>
                              )}
                              {page_link === "/visa_type" && (
                                <li className="{{ set_active('visa_type*') }}">
                                  <a href="{{ route('visa_type') }}">
                                    <i className="fa fa-arrow-circle-right"></i>
                                    Visa Type
                                  </a>
                                </li>
                              )}
                              {page_link === "/visa_requirements" && (
                                <li className="{{ set_active('visa_requirements*') }}">
                                  <a href="{{ route('visa_requirements') }}">
                                    <i className="fa fa-arrow-circle-right"></i>
                                    Requirements
                                  </a>
                                </li>
                              )}
                              {page_link === "/visa_terms" && (
                                <li className="{{ set_active('visa_terms*') }}">
                                  <a href="{{ route('visa_terms') }}">
                                    <i className="fa fa-arrow-circle-right"></i>
                                    Terms & Condition
                                  </a>
                                </li>
                              )}
                            </ul>
                          </li>
                        )}
                      {(page_link === "/umrah_hotel" ||
                        page_link === "/umrah_transfer" ||
                        page_link === "/umrah_visa_supplier") && (
                          <li className="{{ set_active(['umrah_hotel','umrah_transfer','umrah_visa_supplier']) }}">
                            <a href="#">
                              <i className="fa fa-certificate"></i>Umrah{" "}
                            </a>
                            <ul className="submenu">
                              {page_link === "/umrah_hotel" && (
                                <li className="{{ set_active('umrah_hotel') }}">
                                  <a href="{{ route('umrah_hotel') }}">
                                    <i className="fa fa-arrow-circle-right"></i>
                                    Hotel
                                  </a>
                                </li>
                              )}
                              {page_link === "/umrah_transfer" && (
                                <li className="{{ set_active('umrah_transfer') }}">
                                  <a href="{{ route('umrah_transfer') }}">
                                    <i className="fa fa-arrow-circle-right"></i>
                                    Transfer
                                  </a>
                                </li>
                              )}
                              {page_link === "/umrah_visa_supplier" && (
                                <li className="{{ set_active('umrah_visa_supplier') }}">
                                  <a href="{{ route('umrah_visa_supplier') }}">
                                    <i className="fa fa-arrow-circle-right"></i>
                                    Visa Supplier
                                  </a>
                                </li>
                              )}
                              <li className="{{ set_active('transfer_supplier') }}">
                                <a href="{{ route('transfer_supplier') }}">
                                  <i className="fa fa-arrow-circle-right"></i>
                                  Transfer Supplier
                                </a>
                              </li>
                              <li className="{{ set_active('room_type') }}">
                                <a href="{{ route('room_type') }}">
                                  <i className="fa fa-arrow-circle-right"></i>
                                  Hotel Room Type
                                </a>
                              </li>
                              <li className="{{ set_active('hotel_rates') }}">
                                <a href="{{ route('hotel_rates') }}">
                                  <i className="fa fa-arrow-circle-right"></i>
                                  Umrah Hotel Rates
                                </a>
                              </li>
                            </ul>
                          </li>
                        )}
                      {(page_link === "/new_agency" ||
                        page_link === "/new_corporate") && (
                          <li className="{{ set_active(['new_agency*','new_corporate*']) }}">
                            <a href="#">
                              <i className="fa fa-sun"></i>New Registration{" "}
                            </a>
                            <ul className="submenu">
                              {page_link === "/new_agency" && (
                                <li className="{{ set_active('new_agency') }}">
                                  <a href="{{ route('new_agency') }}">
                                    <i className="fa fa-arrow-circle-right"></i>
                                    New Agency Registration
                                  </a>
                                </li>
                              )}
                              {page_link === "/new_corporate" && (
                                <li className="{{ set_active('new_corporate') }}">
                                  <a href="{{ route('new_corporate') }}">
                                    <i className="fa fa-arrow-circle-right"></i>
                                    New Corporate Registration
                                  </a>
                                </li>
                              )}
                            </ul>
                          </li>
                        )}
                      {page_link === "/supplier" && (
                        <li className="{{ set_active('supplier*') }}">
                          <a href="{{ route('supplier') }}">
                            <i className="fa fa-arrow-circle-right"></i>Add New
                            Supplier
                          </a>
                        </li>
                      )}
                      {page_link === "/buyer_grp_cat" && (
                        <li className="{{ set_active('bgc') }}">
                          <a href="{{ route('bgc') }}">
                            <i className="fa fa-star"></i>
                            Agent Categories
                          </a>
                        </li>
                      )}
                      {page_link === "/buyergroup" && (
                        <li className="{{ set_active('buyergroup') }}">
                          <a href="{{ route('buyergroup') }}">
                            <i className="fa fa-users"></i>
                            Agent Group
                          </a>
                        </li>
                      )}
                      {page_link === "/office" && (
                        <li className="{{ set_active('office') }}">
                          <a href="{{ route('office') }}">
                            <i className="fa fa-briefcase"></i>
                            Agents
                          </a>
                        </li>
                      )}
                      {page_link === "/bankdetail" && (
                        <li className="{{ set_active('bankdetail') }}">
                          <a href="{{ route('bankdetail') }}">
                            <i className="fa fa-university"></i>
                            Bank Details
                          </a>
                        </li>
                      )}
                      {page_link === "/currency" && (
                        <li className="{{ set_active('currency') }}">
                          <a href="{{ route('currency') }}">
                            <i className="fa fa-arrow-circle-right"></i>
                            Currency
                          </a>
                        </li>
                      )}
                      {com_id === 1 && page_link === "/role" && (
                        <li className="{{ set_active('role') }}">
                          <a href="{{ route('role') }}">
                            <i className="fa fa-tag"></i>Add Role{" "}
                          </a>
                        </li>
                      )}
                      {com_id === 1 && page_link === "/page" && (
                        <li className="{{ set_active('page') }}">
                          <a href="{{ route('page') }}">
                            <i className="fa fa-tag"></i>Add Pages{" "}
                          </a>
                        </li>
                      )}

                      {page_link === "/pricing" && (
                        <li className="{{ set_active('pricing') }}">
                          <a href="{{ route('pricing') }}">
                            <i className="fa fa-tag"></i>Pricing{" "}
                          </a>
                        </li>
                      )}
                      {page_link === "/airline" && (
                        <li className="{{ set_active('airline') }}">
                          <a href="{{ route('airline') }}">
                            <i className="fa fa-plane"></i>Add Airlines{" "}
                          </a>
                        </li>
                      )}
                      {page_link === "/apis_credentials" && (
                        <li className="{{ set_active('apis_credentials') }}">
                          <a href="{{ route('apis_credentials') }}">
                            <i className="fa fa-key"></i>Password (API){" "}
                          </a>
                        </li>
                      )}
                      {page_link === "/supplier_rules" && (
                        <li className="{{ set_active('supplier_rules') }}">
                          <a href="{{ route('supplier_rules') }}">
                            <i className="fa fa-cog"></i>Suppliers Rules{" "}
                          </a>
                        </li>
                      )}
                      {page_link === "/resolved_requests" && (
                        <li className="{{ set_active('resolved_requests') }}">
                          <a href="{{ route('resolved_requests') }}">
                            <i className="fas fa-comment-dots"></i>
                            Resolved Requests
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              )}
              {page_name === "Finance / Reporting" && (
                <div
                  id="menufinance"
                  style={{ width: "180px", paddingLeft: "150px" }}
                >
                  <div
                    id="jquery-accordion-menu-finance"
                    className="jquery-accordion-menu"
                  >
                    <div className="jquery-accordion-menu-header">
                      {com_id === 1 ? "Finance" : "Finance Section"}
                    </div>
                    <ul>
                      {(page_link === "/reports/offices/1" ||
                        page_link === "/reports/offices/2") && (
                          <li className="{{ set_active(['cust*']) }}">
                            <a href="#">
                              <i className="fa fa-suitcase"></i>
                              Agents
                            </a>
                            <ul className="submenu">
                              {page_link === "/reports/offices/1" && (
                                <li className="@if(request()->id == 1) active @endif">
                                  <a href="{{ route('cust',1) }}">
                                    <i className="fa fa-arrow-circle-right"></i>
                                    Agent List
                                  </a>
                                </li>
                              )}
                              {page_link === "/reports/offices/2" && (
                                <li className="@if(request()->id == 2) active @endif">
                                  <a href="{{ route('cust',2) }}">
                                    <i className="fa fa-arrow-circle-right"></i>
                                    Agent Account Statement
                                  </a>
                                </li>
                              )}
                            </ul>
                          </li>
                        )}
                      {page_link === "/reports/suppliers/4" && (
                        <li className="{{ set_active(['sup*']) }}">
                          <a href="#">
                            <i className="fa fa-cubes"></i>
                            Suppliers
                          </a>
                          <ul className="submenu">
                            {page_link === "/reports/suppliers/4" && (
                              <li className="@if(request()->id == 4) active @endif">
                                <a href="{{ route('sup',4) }}">
                                  <i className="fa fa-arrow-circle-right"></i>
                                  Account Statement
                                </a>
                              </li>
                            )}
                          </ul>
                        </li>
                      )}
                      {(page_link === "/daily_sale_report" ||
                        page_link === "/sale_report_view" ||
                        page_link === "/refund_report_view" ||
                        page_link === "/transactional_report_view" ||
                        page_link === "/visa_report_view" ||
                        page_link === "/group_flight_manifest_view" ||
                        page_link === "/airline_report_view" ||
                        page_link === "/agent_error_finding_report" ||
                        page_link === "/supplier_error_finding_report" ||
                        page_link === "/umrah_manifest_view") && (
                          <li className="{{ set_active(['daily_sale_report.index']) }}">
                            <a href="#">
                              <i className="fa fa-cube"></i>
                              Sale
                            </a>
                            <ul className="submenu">
                              {page_link === "/daily_sale_report" && (
                                <li className="{{ set_active(['daily_sale_report.index']) }}">
                                  <a href="{{ route('daily_sale_report.index') }}">
                                    <i className="fa fa-arrow-circle-right"></i>
                                    Daily Sale Report
                                  </a>
                                </li>
                              )}
                              {page_link === "/sale_report_view" && (
                                <li className="{{ set_active(['sale_report_view']) }}">
                                  <a href="{{ route('sale_report_view') }}">
                                    <i className="fa fa-arrow-circle-right"></i>
                                    Sale Report
                                  </a>
                                </li>
                              )}
                              {page_link === "/refund_report_view" && (
                                <li className="{{ set_active(['refund_report_view']) }}">
                                  <a href="{{ route('refund_report_view') }}">
                                    <i className="fa fa-arrow-circle-right"></i>
                                    Refund Report
                                  </a>
                                </li>
                              )}
                              {page_link === "/transactional_report_view" && (
                                <li className="{{ set_active(['transactional_report_view']) }}">
                                  <a href="{{ route('transactional_report_view') }}">
                                    <i className="fa fa-arrow-circle-right"></i>
                                    Transactional Report
                                  </a>
                                </li>
                              )}
                              {page_link === "/visa_report_view" && (
                                <li className="{{ set_active(['visa_report_view']) }}">
                                  <a href="{{ route('visa_report_view') }}">
                                    <i className="fa fa-arrow-circle-right"></i>
                                    Visa Report
                                  </a>
                                </li>
                              )}
                              {page_link === "/group_flight_manifest_view" && (
                                <li className="{{ set_active(['group_flight_manifest_view']) }}">
                                  <a href="{{ route('group_flight_manifest_view') }}">
                                    <i className="fa fa-arrow-circle-right"></i>
                                    Group Flight Manifest
                                  </a>
                                </li>
                              )}
                              {page_link === "/airline_report_view" && (
                                <li className="{{ set_active(['airline_report_view']) }}">
                                  <a href="{{ route('airline_report_view') }}">
                                    <i className="fa fa-arrow-circle-right"></i>
                                    AirLine PLB
                                  </a>
                                </li>
                              )}
                              {page_link === "/agent_error_finding_report" && (
                                <li className="{{ set_active(['agent_error_finding_report']) }}">
                                  <a
                                    href="#"
                                    onClick='window.open("{{ route("agent_error_finding_report") }}");return false;'
                                  >
                                    <i className="fa fa-arrow-circle-right"></i>
                                    Agent Error Finding
                                  </a>
                                </li>
                              )}
                              {page_link === "/supplier_error_finding_report" && (
                                <li className="{{ set_active(['supplier_error_finding_report']) }}">
                                  <a
                                    href="#"
                                    onClick='window.open("{{ route("supplier_error_finding_report") }}");return false;'
                                  >
                                    <i className="fa fa-arrow-circle-right"></i>
                                    Supplier Error Finding
                                  </a>
                                </li>
                              )}
                              {page_link === "/umrah_manifest_view" && (
                                <li className="{{ set_active(['umrah_manifest_view']) }}">
                                  <a href="{{ route('umrah_manifest_view') }}">
                                    <i className="fa fa-arrow-circle-right"></i>
                                    Umrah Manifest View
                                  </a>
                                </li>
                              )}
                            </ul>
                          </li>
                        )}
                      {(page_link === "/accounts/account_voucher_list" ||
                        page_link === "/accounts/account_voucher" ||
                        page_link === "/accounts/manual/invoice" ||
                        page_link === "/accounts/manual/invoice/refund" ||
                        page_link === "/invoice_logs") && (
                          <li className="{{ set_active(['acc_voucher.list*','acc_voucher.index*','manual.invoice.*','invoice_logs']) }}">
                            <a href="#">
                              <i className="fa fa-file-alt"></i>Vouchers{" "}
                            </a>
                            <ul className="submenu">
                              {page_link === "/accounts/account_voucher_list" && (
                                <li className="{{ set_active('acc_voucher.list') }}">
                                  <a href="{{ route('acc_voucher.list') }}">
                                    <i className="fa fa-arrow-circle-right"></i>
                                    All Vouchers
                                  </a>
                                </li>
                              )}
                              {page_link === "/accounts/account_voucher" && (
                                <li className="{{ set_active('acc_voucher.index') }}">
                                  <a href="{{ route('acc_voucher.index') }}">
                                    <i className="fa fa-arrow-circle-right"></i>
                                    Voucher Entry{" "}
                                  </a>
                                </li>
                              )}
                              {page_link === "/accounts/manual/invoice" && (
                                <li className="{{ set_active('manual.invoice.index') }}">
                                  <a href="{{ route('manual.invoice.index') }}">
                                    <i className="fa fa-arrow-circle-right"></i>
                                    Manual Invoice{" "}
                                  </a>
                                </li>
                              )}
                              {page_link ===
                                "/accounts/manual/invoice/refund" && (
                                  <li className="{{ set_active('manual.invoice.refund') }}">
                                    <a href="{{ route('manual.invoice.refund') }}">
                                      <i className="fa fa-arrow-circle-right"></i>
                                      Manual Credit{" "}
                                    </a>
                                  </li>
                                )}
                              {page_link === "/invoice_logs" && (
                                <li className="{{ set_active('invoice_logs') }}">
                                  <a href="{{ route('invoice_logs') }}">
                                    <i className="fa fa-arrow-circle-right"></i>
                                    Invoice Logs
                                  </a>
                                </li>
                              )}
                            </ul>
                          </li>
                        )}
                      {(page_link === "/balance_sheet" ||
                        page_link === "/bankbook_bank" ||
                        page_link === "/add_chequebook" ||
                        page_link === "/accounts/accounting" ||
                        page_link === "/general_ledger" ||
                        page_link === "/profit_loss" ||
                        page_link === "/trial_balance") && (
                          <li className="{{ set_active(['bankbook_bank*','add_chequebook*','accounting*','cashbook_cash*','balance_sheet*','general_ledger*','trial_balance*','profit_loss*']) }}">
                            <a href="#">
                              <i className="fa fa-folder"></i>Accounts{" "}
                            </a>
                            <ul className="submenu">
                              {page_link === "/accounts/accounting" && (
                                <li className="{{ set_active('accounting') }}">
                                  <a href="{{ route('accounting') }}">
                                    <i className="fa fa-arrow-circle-right"></i>
                                    Chart of Account
                                  </a>
                                </li>
                              )}
                              {page_link === "/general_ledger" && (
                                <li className="{{ set_active('general_ledger') }}">
                                  <a href="{{ route('general_ledger') }}">
                                    <i className="fa fa-arrow-circle-right"></i>
                                    Journal Ledger
                                  </a>
                                </li>
                              )}
                              {page_link === "/trial_balance" && (
                                <li className="{{ set_active('trial_balance') }}">
                                  <a href="{{ route('trial_balance') }}">
                                    <i className="fa fa-arrow-circle-right"></i>
                                    Trial Balance
                                  </a>
                                </li>
                              )}
                              {page_link === "/balance_sheet" && (
                                <li className="{{ set_active('balance_sheet') }}">
                                  <a href="{{ route('balance_sheet') }}">
                                    <i className="fa fa-arrow-circle-right"></i>
                                    Balance Sheet
                                  </a>
                                </li>
                              )}
                              {page_link === "/profit_loss" && (
                                <li className="{{ set_active('profit_loss') }}">
                                  <a href="{{ route('profit_loss') }}">
                                    <i className="fa fa-arrow-circle-right"></i>
                                    Profit &#38; Lose
                                  </a>
                                </li>
                              )}
                              {page_link === "/accrued-adjustment" && (
                                <li className="active">
                                  <a href="{{ route('accrued.adjustment') }}">
                                    <i className="fa fa-arrow-circle-right"></i>
                                    Opening Balance
                                  </a>
                                </li>
                              )}
                              {page_link === "/print_asset_statement" && (
                                <li className="active">
                                  <a
                                    href="#"
                                    onClick='window.open("{{ route("print_asset_statement") }}");return false;'
                                  >
                                    <i className="fa fa-arrow-circle-right"></i>
                                    Assets Statement
                                  </a>
                                </li>
                              )}
                              {page_link === "/expanse_statement" && (
                                <li className="active">
                                  <a href='{{ route("expanse_statement") }}'>
                                    <i className="fa fa-arrow-circle-right"></i>
                                    Expanse Statement
                                  </a>
                                </li>
                              )}
                              {(page_link === "/bankbook_bank" ||
                                page_link === "/add_chequebook") && (
                                  <li className="{{ set_active(['bankbook_bank*','add_chequebook*']) }}">
                                    <a href="#">Bank Book </a>
                                    <ul className="submenu">
                                      {page_link === "/bankbook_bank" && (
                                        <li className="{{ set_active('bankbook_bank') }}">
                                          <a href="{{ route('bankbook_bank') }}">
                                            <i className="fa fa-arrow-circle-right"></i>
                                            Bank/Cash
                                          </a>
                                        </li>
                                      )}
                                      {page_link === "/add_chequebook" && (
                                        <li className="{{ set_active('add_chequebook') }}">
                                          <a href="{{ route('add_chequebook') }}">
                                            <i className="fa fa-arrow-circle-right"></i>
                                            Add Cheque Book{" "}
                                          </a>
                                        </li>
                                      )}
                                    </ul>
                                  </li>
                                )}
                            </ul>
                          </li>
                        )}
                    </ul>
                  </div>
                </div>
              )}
              {page_name === "Office Profile" && (
                <div
                  id="menufinance"
                  style={{ width: "180px", paddingLeft: "250px" }}
                >
                  <div
                    id="jquery-accordion-menu-office"
                    className="jquery-accordion-menu"
                  >
                    <div className="jquery-accordion-menu-header">
                      {com_id === 1 ? "Office Profile" : "Agency Profile"}
                    </div>
                    <ul>
                      {page_link === "/officedata" && (
                        <li className="{{ set_active('officedata*') }}">
                          <a href="{{ route('officedata') }}">
                            <i className="fa fa-file-archive"></i>
                            {com_id === 1 ? "Agency Data" : "Agency Details"}
                          </a>
                        </li>
                      )}
                      {page_link === "/userdocs" && (
                        <li className="{{ set_active('userdocs*') }}">
                          <a href="{{ route('userdocs') }}">
                            <i className="fa fa-folder"></i>
                            {com_id === 1 ? "Documents" : "Agency Document"}
                          </a>
                        </li>
                      )}
                      {page_link === "/userlevel" && (
                        <li className="{{ set_active('userlevel') }}">
                          <a href="{{ route('userlevel') }}">
                            <i className="fa fa-user"></i>User Level{" "}
                          </a>
                        </li>
                      )}
                      {page_link === "/user" && (
                        <li className="{{ set_active('user') }}">
                          <a href="{{ route('user') }}">
                            <i className="fa fa-users"></i>
                            {com_id === 1 ? "Users" : "Users List"}
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-sm-12 zaira_menu_section">
            <div className="iconbar mx-auto">
              <p
                className={`menu_text brackets ${active === "reservation" ? "active" : ""
                  }`}
                onClick={(e) => navigate("/reservation")}
              >
                <strong className="bracket-strong">RESERVATION</strong>
              </p>
              <p
                className={`menu_text ${active === "bookings" ? "active" : ""}`}
                onClick={(e) => navigate("/booking")}
              >
                <strong className="bracket-strong">BOOKINGS</strong>
              </p>
              {com_id === 1 ? (
                <>
                  <p
                    className={`menu_text ${active === "collection" ? "active" : ""
                      }`}
                    onClick={(e) => navigate("/collection")}
                  >
                    <strong className="bracket-strong">COLLECTION</strong>
                    <sup className="font-size-sm collection_request"></sup>
                  </p>
                </>
              ) : (
                <a
                  href="{{ route('requests_count') }}"
                  id="requests"
                  style={{ display: "none" }}
                ></a>
              )}
              <p
                className={`menu_text ${active === "requests" ? "active" : ""}`}
                onClick={(e) => navigate("/requests")}
              >
                <strong className="bracket-strong">REQUESTS</strong>
                {com_id === 1 && (
                  <sup className="font-size-sm total_requests"></sup>
                )}
              </p>
              <p
                className={`menu_text ${active === "flight-requests" ? "active" : ""
                  }`}
                onClick={(e) => navigate("/flight-requests")}
              >
                <strong className="bracket-strong">FLIGHT-REQ</strong>
                <sup className="font-size-sm oth_requests"></sup>
              </p>
              <p
                className={`menu_text ${active === "umrah-requests" ? "active" : ""
                  }`}
                onClick={(e) => navigate("/umrah-requests")}
              >
                <strong className="bracket-strong">UMRAH-REQ</strong>
                <sup className="font-size-sm umrah_requests"></sup>
              </p>
              <p className={`menu_text ${active === "groups" ? "active" : ""}`}  onClick={(e) => navigate("/flight-group-list")}>
                <strong className="bracket-strong">GROUPS</strong>
              </p>

              {auth_user?.id === 1 && (
                <>
                  <p
                    className={`menu_text ${active === "backup" ? "active" : ""
                      }`}
                  >
                    <strong className="bracket-strong">BACKUP</strong>
                  </p>
                </>
              )}
              {auth_user?.id === 1 && (
                <>
                  <p
                    className={`menu_text ${active === "setup" ? "active" : ""
                      }`}
                    onMouseEnter={e => {
                      const element = document.getElementById("dropdown-menu-setup");
                      element.classList.add("dropdown-menu-setup-show");
                    }}
                    onMouseLeave={e => {
                      const element = document.getElementById("dropdown-menu-setup");
                      element.classList.remove("dropdown-menu-setup-show");
                    }}
                  >
                    <strong className="bracket-strong">Setup</strong>
                    <SetupDropDown />
                  </p>
                </>
              )}
              {auth_user?.id === 1 && (
                <>
                  <p
                    className={`menu_text ${active === "finance" ? "active" : ""
                      }`}
                    onMouseEnter={e => {
                      const element = document.getElementById("dropdown-menu-finance");
                      element.classList.add("dropdown-menu-finance-show");
                    }}
                    onMouseLeave={e => {
                      const element = document.getElementById("dropdown-menu-finance");
                      element.classList.remove("dropdown-menu-finance-show");
                    }}
                  >
                    <strong className="bracket-strong">Finance</strong>
                    <FinanceDropDown />
                  </p>
                </>
              )}
              {auth_user?.id === 1 && (
                <>
                  <p
                    className={`menu_text ${active === "agency" ? "active" : ""
                      }`}
                    onMouseEnter={e => {
                      const element = document.getElementById("dropdown-menu-agency");
                      element.classList.add("dropdown-menu-agency-show");
                    }}
                    onMouseLeave={e => {
                      const element = document.getElementById("dropdown-menu-agency");
                      element.classList.remove("dropdown-menu-agency-show");
                    }}
                  >

                    <strong className="bracket-strong">Agency</strong>
                    <AgencyDropDown />
                  </p>
                </>
              )}


              {auth_user?.id === 1 && (
                <div
                  className="modal fade"
                  id="confirmation_dialog_open_for_db"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog " role="document">
                    <form method="post" action="{{ route('databasebackup') }}">
                      <div className="modal-content ">
                        <div className="modal-header bg-success">
                          <h5 className="modal-title" id="exampleModalLabel">
                            DATABASE BACKUP & TRUNCATE
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="row text-center">
                            <div className="form-group col-md-4">
                              <div className="form-check form-check-inline">
                                <label className="form-check-label">
                                  <input
                                    type="radio"
                                    value="BACKUP"
                                    checked
                                    className="form-check-input-styled back_up"
                                    name="input_value"
                                  />{" "}
                                  BACKUP
                                </label>
                              </div>
                            </div>
                            <div className="form-group col-md-4">
                              <div className="form-check form-check-inline">
                                <label className="form-check-label">
                                  <input
                                    type="radio"
                                    value="TRUNCATE"
                                    className="form-check-input-styled back_up"
                                    name="input_value"
                                  />{" "}
                                  TRUNCATE
                                </label>
                              </div>
                            </div>
                            <div className="form-group col-md-4">
                              <div className="form-check form-check-inline">
                                <label className="form-check-label">
                                  <input
                                    type="radio"
                                    value="BACKUP_&_TRUNCATE"
                                    className="form-check-input-styled back_up"
                                    name="input_value"
                                  />{" "}
                                  BACKUP & TRUNCATE
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="row text-center password_for_db d-nnone">
                            <div className="form-group col-md-8">
                              <div className="form-check form-check-inline">
                                <label className="font-weight-semibold">
                                  Login Password{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="password"
                                  className="form-control form-control-sm"
                                  name="password"
                                  required
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button type="submit" className="btn btn-success">
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              )}

              {(auth_user?.company === "Travel_Consultant" ||
                auth_user?.company === "Travel_Agent") && (
                  <>
                    <p
                      className={`menu_text ${active === "PAX" ? "active" : ""}`}
                    >
                      <strong className="bracket-strong">PAX</strong>
                    </p>
                  </>
                )}

              {/* <i
                id="menuShow"
                className="fa-briefcase iconheader"
                data-toggle="tooltip"
                data-placement="top"
                title="Administration"
              >
              </i>
              <p className="menu_text">
                <strong className="bracket-strong">ADMINISTRATION</strong>
                </p>*/}

              <p className="menu_text">
                <strong className="bracket-strong">CHAT</strong>
                <sup className="font-size-sm total_messages">
                  {messages?.length || 0}
                </sup>
              </p>
              <a href="{{ route('search') }}" id="search"></a>
              <a href="{{ route('booking') }}" id="bookings"></a>
              <a
                href="{{ route('travelerprofile') }}"
                id="traveler_consultant"
              ></a>
              <a href="{{ route('active_user') }}" id="active_users"></a>
              <a href="{{ route('flight_group_list') }}" id="flight_groups"></a>
              <a href="{{ route('user_profile_edit') }}" id="user_profile"></a>
              <a
                href="{{ route('flight_request_booking') }}"
                id="flight_request_booking"
              ></a>
              <a href="{{ route('umrah_requests') }}" id="umrah_requests"></a>
            </div>
          </div>
          {/*<div className="col-sm-6">
                    <div className="user-infobar float-right">
                        {company && <>
                        <span className="user-info">{ company.office_name || "Zairaa B2B" }</span>
                        <span className="user-info">|</span></>
                        }
                        <span className="user-info">{ auth_user.name }</span>
                        <span className="user-info">|</span>
                        <span className="user-info" onClick="event.preventDefault(); document.getElementById('logout-form-secound').submit();" style={{cursor: "pointer"}}>Logout
                            <i className="fas fa-sign-out-alt"></i>
                        </span>
                        <form id="logout-form-secound" action="{{ route('logout') }}" method="POST" style={{display: "none"}}>    
                        </form>
                    </div>
                      </div>*/}
        </div>
      </div>
      {/* <div id="flashnews" className="row textslider">
      //           <div id="carousel">
      //               <div className="btn-bar" style={{display:"none"}}>
      //                   <div id="buttons">
      //                   <a id="prev" href="#"></a>
      //                   <a id="next" href="#"></a>
      //                   </div>
      //               </div>
      //               <div id="slides">
      //                   <ul id="slides_ul">
      //                       {flash_news.map(flash => 
      //                         <li className="slide">
      //                           <div className="quoteContainer">
      //                               <p className="quote-phrase">{flash}</p>
      //                           </div>
      //                       </li>
      //                     )}
      //                   </ul>
      //               </div>
      //           </div>
                      </div> */}
    </div>
  );
};

















const FinanceDropDown = () => {
  const [sub_menuagents, setSub_MenuAgents] = useState(false)
  const [sub_menuSUpllier, setSub_MenuSupplier] = useState(false)
  const [sub_menuSale, setSub_MenuSale] = useState(false)

  const [sub_menuVocher, setSub_MenuVocher] = useState(false)
  const [sub_menuAccount, setSub_MenuAccount] = useState(false)
  const [bankbooking, setBankBooking] = useState(false)
  return (
    <div className="dropdown-menu-finance" id="dropdown-menu-finance">
      <div>
        <div className="finance-dropdown-items" onClick={() => {
          setSub_MenuAgents(!sub_menuagents)
          setSub_MenuSupplier(false)
          setSub_MenuSale(false)
          setSub_MenuVocher(false)
          setSub_MenuAccount(false)

        }}>
          <div className="d-flex align-items-center" >
            <div className="drp-links" >Agents</div>
          </div>
          <div>
            {sub_menuagents ? <IoIosArrowUp size={25} /> : <IoIosArrowDown size={25} />}
          </div>
        </div>
        {sub_menuagents && <div className='py-2' style={{ paddingLeft: '2rem' }}>
          <Link to="/agent-list" className="finance-dropdown-items">
            <div >Agent List</div>
          </Link>
          <Link to='/agent-account-statement' className="finance-dropdown-items">
            <div>Agent Statement</div>
          </Link>
        </div>}
      </div>



      <div>
        <div className="finance-dropdown-items" onClick={() => {
          setSub_MenuSupplier(!sub_menuSUpllier)
          setSub_MenuAgents(false)

          setSub_MenuSale(false)
          setSub_MenuVocher(false)
          setSub_MenuAccount(false)
        }}>
          <div className="d-flex align-items-center">
            <div className="drp-links">Suppliers</div>
          </div>
          <div>
            {sub_menuSUpllier ? <IoIosArrowUp size={25} /> : <IoIosArrowDown size={25} />}
          </div>
        </div>
        {sub_menuSUpllier && <div className='py-2' style={{ paddingLeft: '2rem' }}>
          <Link to='/supplier-account-statement' className="finance-dropdown-items">
            <div >Account Statement</div>
          </Link>

        </div>}
      </div>





      <div>
        <div className="finance-dropdown-items" onClick={() => {

          setSub_MenuSale(!sub_menuSale)

          setSub_MenuAgents(false)
          setSub_MenuSupplier(false)

          setSub_MenuVocher(false)
          setSub_MenuAccount(false)
        }}>
          <div className="d-flex align-items-center">
            <div className="drp-links">Sale</div>
          </div>
          <div>
            {sub_menuSale ? <IoIosArrowUp size={25} /> : <IoIosArrowDown size={25} />}
          </div>
        </div>
        {sub_menuSale && <div className='py-2' style={{ paddingLeft: '2rem' }}>
          <Link className="finance-dropdown-items" to='/daily-sales-report'>
            <div>Daily Sale Report</div>
          </Link>
          <Link className="finance-dropdown-items" to='/sales-report'>
            <div>Sale Report</div>
          </Link>

          <Link to={'/refund-report'} className="finance-dropdown-items">
            <div>Refund Report</div>
          </Link>
          <Link to={'/transaction-report'} className="finance-dropdown-items">
            <div>Transactional Report</div>
          </Link>

          <Link to={'/visa-report'} className="finance-dropdown-items">
            <div>Visa Report</div>
          </Link>
          <Link to={'/group-flight-manifest'} className="finance-dropdown-items">
            <div>Group Flight Menifest</div>
          </Link>
          <Link to={'/airline-report'} className="finance-dropdown-items">
            <div>Airline PLB</div>
          </Link>

          <div className="finance-dropdown-items">Agent Error Finding</div>

          <div className="finance-dropdown-items">Supplier Error Finding</div>

          <Link to={'/umrah-manifest-view'} className="finance-dropdown-items">
            <div>Umrah Manifest View</div>
          </Link>
        </div>}
      </div>


      <div>
        <div className="finance-dropdown-items" onClick={() => {
          setSub_MenuVocher(!sub_menuVocher)
          setSub_MenuAgents(false)
          setSub_MenuSupplier(false)
          setSub_MenuSale(false)

          setSub_MenuAccount(false)

        }}>

          <div className="d-flex align-items-center">
            <div className="drp-links">Vochers</div>
          </div>
          <div>
            {sub_menuVocher ? <IoIosArrowUp size={25} /> : <IoIosArrowDown size={25} />}
          </div>
        </div>
        {sub_menuVocher && <div className='py-2' style={{ paddingLeft: '2rem' }}>

          <Link to='/all-vouchers' className="finance-dropdown-items">
            <div>All Vouchers</div>
          </Link>
          <Link to='/vouchers-entry' className="finance-dropdown-items">
            <div>Vochers Entry</div>
          </Link>
          <Link to='/manual-invoice' className="finance-dropdown-items">
            <div>Manual Invoice</div>
          </Link>
          <Link to='/manual-credit' className="finance-dropdown-items">
            <div>Manual Credit</div>
          </Link>
          <Link to='/invoice-logs' className="finance-dropdown-items">
            <div>Invoice Logs</div>
          </Link>

        </div>}
      </div>




      <div>
        <div className="finance-dropdown-items" onClick={() => {

          setSub_MenuAccount(!sub_menuAccount)
          setSub_MenuAgents(false)
          setSub_MenuSupplier(false)
          setSub_MenuSale(false)
          setSub_MenuVocher(false)
          setBankBooking(false)

        }}>
          <div className="d-flex align-items-center">
            <div className="drp-links">Accounts</div>
          </div>
          <div>
            {sub_menuAccount ? <IoIosArrowUp size={25} /> : <IoIosArrowDown size={25} />}
          </div>
        </div>
      </div>
      {sub_menuAccount && <div className='py-2' style={{ paddingLeft: '2rem' }}>
        <Link to={'/chart-of-account'} className="finance-dropdown-items">
          <div>Chart of Account</div>
        </Link>
        <Link to={'/gournal-ledger'} className="finance-dropdown-items">
          <div>Gournal ladger</div>
        </Link>
        <Link to={'/trial-balance'} className="finance-dropdown-items">
          <div>Trial Balence</div>
        </Link>
        <Link to={'/balance-sheet'} className="finance-dropdown-items">
          <div>Balence Sheet</div>
        </Link>
        <Link to={'/profit-and-lose'} className="finance-dropdown-items">
          <div>Profit and Lose</div>
        </Link>
        <Link to={'/asset-statement'} className="finance-dropdown-items">
          <div>Assets Statement</div>
        </Link>
        <Link to={'/expance-statement'} className="finance-dropdown-items">
          <div>Expance Statement</div>
        </Link>

        <div className=' finance-dropdown-items' onClick={() => {
          setBankBooking(!bankbooking)
        }}>
          <div>Bank Book</div>
          <div>
            {bankbooking ? <IoIosArrowUp size={25} /> : <IoIosArrowDown size={25} />}
          </div>
        </div>
        {

          bankbooking && <div className='py-2' style={{ paddingLeft: '2rem' }}>
            <Link to={'/bank-cash'} className="finance-dropdown-items">
              <div>Bank/Cash</div>
            </Link>
            <Link to={'/add-cheque-book'} className="finance-dropdown-items">
              <div>Add Cheque Book</div>
            </Link>

          </div>

        }
      </div>}
    </div>
  );
};








const SetupDropDown = () => {
  const [payment, setpayment] = useState(false)
  const [promotion, setPromotion] = useState(false)
  const [visa, setVisa] = useState(false)
  const [umrah, setumrah] = useState(false)
  const [newRegistration, setnewRegistration] = useState(false)

  const [agency_vocher, setAgency_Vouchers] = useState(false);
  const navigate = useNavigate();
  return (
    <div className="dropdown-menu-setup" id="dropdown-menu-setup">


      <div>
        <div className="finance-dropdown-items" onClick={() => {
          setpayment(!payment)
          setPromotion(false)
          setVisa(false)
          setumrah(false)
          setnewRegistration(false)

        }}>
          <div className="d-flex align-items-center" >
            <div className="drp-links" >Payements</div>
          </div>
          <div>
            {payment ? <IoIosArrowUp size={25} /> : <IoIosArrowDown size={25} />}
          </div>
        </div>
        {payment && <div className='py-2' style={{ paddingLeft: '2rem' }}>
          <div className="finance-dropdown-items" onClick={e => navigate("/make-payment")}>Make Payment</div>
          <div className="finance-dropdown-items" onClick={e => navigate("/corporate-payment")}>Corporate Payment</div>
          <div className="finance-dropdown-items" onClick={e => navigate("/paid-deposits")}>Reciept</div>
          <div className="finance-dropdown-items" onClick={e => navigate("/collection")}>Collection</div>
          <div className=' finance-dropdown-items' onClick={() => {
            setAgency_Vouchers(!agency_vocher)
          }}>
            <div>Payment Vochers</div>
            <div>
              {agency_vocher ? <IoIosArrowUp size={25} /> : <IoIosArrowDown size={25} />}
            </div>
          </div>
          {
            agency_vocher && <div className='py-2' style={{ paddingLeft: '2rem' }}>
              <div className="finance-dropdown-items" onClick={e => navigate("/admin-vouchers")}>Unpaid Vocher</div>
              <div className="finance-dropdown-items" onClick={e => navigate("/admin-paid-vouchers")}>Paid Vocher</div>
            </div>
          }
        </div>}
      </div>


      <div>
        <div className="finance-dropdown-items" onClick={() => {
          setpayment(false)
          setPromotion(!promotion)
          setVisa(false)
          setumrah(false)
          setnewRegistration(false)

        }}>
          <div className="d-flex align-items-center" >
            <div className="drp-links" >Promotions</div>
          </div>
          <div>
            {promotion ? <IoIosArrowUp size={25} /> : <IoIosArrowDown size={25} />}
          </div>
        </div>
        {promotion && <div className='py-2' style={{ paddingLeft: '2rem' }}>
          <div className="finance-dropdown-items" onClick={e => navigate("/flash-news")}>Add Flash News</div>
          <div className="finance-dropdown-items" onClick={e => navigate("/flight-groups")}>Flight Groups</div>
          <div className="finance-dropdown-items" onClick={e => navigate("/circular")}>Circular</div>
          <div className="finance-dropdown-items" onClick={e => navigate("/promotions")}>Promotions</div>
        </div>}
      </div>


      <div>
        <div className="finance-dropdown-items" onClick={() => {
          setpayment(false)
          setPromotion(false)
          setVisa(!visa)
          setumrah(false)
          setnewRegistration(false)

        }}>
          <div className="d-flex align-items-center" >
            <div className="drp-links" >Visa</div>
          </div>
          <div>
            {visa ? <IoIosArrowUp size={25} /> : <IoIosArrowDown size={25} />}
          </div>
        </div>
        {visa && <div className='py-2' style={{ paddingLeft: '2rem' }}>
          <div className="finance-dropdown-items" onClick={e => navigate("/visa-countries")}>Countries</div>
          <div className="finance-dropdown-items" onClick={e => navigate("/visa-type")}>Visa Type</div>
          <div className="finance-dropdown-items" onClick={e => navigate("/visa-requirements")}>Requirements</div>
          <div className="finance-dropdown-items" onClick={e => navigate("/visa-terms")}>Terms & Conditions</div>
        </div>}
      </div>


      <div>
        <div className="finance-dropdown-items" onClick={() => {
          setpayment(false)
          setPromotion(false)
          setVisa(false)
          setumrah(!umrah)
          setnewRegistration(false)

        }}>
          <div className="d-flex align-items-center" >
            <div className="drp-links" >Umrah</div>
          </div>
          <div>
            {umrah ? <IoIosArrowUp size={25} /> : <IoIosArrowDown size={25} />}
          </div>
        </div>
        {umrah && <div className='py-2' style={{ paddingLeft: '2rem' }}>
          <div className="finance-dropdown-items" onClick={e => navigate("/umrah-hotels")}>Hotel</div>
          <div className="finance-dropdown-items" onClick={e => navigate("/umrah-tranfer")}>Transfer</div>
          <div className="finance-dropdown-items" onClick={e => navigate("/umrah-visa-supplier")}>Visa Supplier</div>
          <div className="finance-dropdown-items" onClick={e => navigate("/umrah-transfer-supplier")}>Transfer Supplier</div>
          <div className="finance-dropdown-items" onClick={e => navigate("/room-type")}>Hotel Room Type</div>
          <div className="finance-dropdown-items" onClick={e => navigate("/hotel-rates")}>Umrah Hotel Rates</div>
        </div>}
      </div>





      <div>
        <div className="finance-dropdown-items" onClick={() => {
          setpayment(false)
          setPromotion(false)
          setVisa(false)
          setumrah(false)
          setnewRegistration(!newRegistration)

        }}>
          <div className="d-flex align-items-center" >
            <div className="drp-links" >New Registration</div>
          </div>
          <div>
            {newRegistration ? <IoIosArrowUp size={25} /> : <IoIosArrowDown size={25} />}
          </div>
        </div>
        {newRegistration && <div className='py-2' style={{ paddingLeft: '2rem' }}>
          <div className="finance-dropdown-items" onClick={e => navigate("/new-agency-registration")}>New Agency Registration</div>
          <div className="finance-dropdown-items" onClick={e => navigate("/new-corporate-registration")}>New Corporate Registration</div>

        </div>}
      </div>

      <div className="finance-dropdown-items" onClick={e => navigate("/supplier")}>Add New Supplier</div>
      <div className="finance-dropdown-items" onClick={e => navigate("/agent-categories")}>Agent Categories</div>
      <div className="finance-dropdown-items" onClick={e => navigate("/agent-groups")}>Agents Group</div>
      <div className="finance-dropdown-items" onClick={e => navigate("/agents")}>Agent</div>
      <div className="finance-dropdown-items" onClick={e => navigate("/bank-details")}>Bank Details</div>
      <div className="finance-dropdown-items" onClick={e => navigate("/currency")}>Currency</div>
      <div className="finance-dropdown-items" onClick={e => navigate("/role")}>Add Role</div>
      <div className="finance-dropdown-items" onClick={e => navigate("/pricing")}>Pricing</div>
      <div className="finance-dropdown-items" onClick={e => navigate("/airlines")}>Add Airlines</div>
      <div className="finance-dropdown-items" onClick={e => navigate("/apis-credentials")}>Password (Api)</div>

      <div className="finance-dropdown-items" onClick={e => navigate("/supplier-rules")}>Supplier Rules</div>
      <div className="finance-dropdown-items" onClick={e => navigate("/resolved-requests")}>Resolved Requests</div>

    </div>
  );
};





const AgencyDropDown = () => {

  return (
    <div className="dropdown-menu-agency" id="dropdown-menu-agency">

      <div className="finance-dropdown-items">Agency Data </div>
      <div className="finance-dropdown-items">Documents</div>
      <div className="finance-dropdown-items">Users</div>
      <div className="finance-dropdown-items">User Level</div>


    </div>
  );
};




export default Header;
