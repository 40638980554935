import React, { useState } from "react";
import Fare from "./Fare";
import PassangerInfo from "./PassangerInfo";
import FlightInfo from "./FlightInfo";
import { Button } from "@mui/material";

const NewPnr = () => {
  const [InputGroup, setInputGroup] = useState("passanger");
  return (
    <div className="mt-4">
      <div className="row">
        <div className="d-flex justify-content-center py-3 pt-5 align-items-center progress_bar-register">
          <div className="d-flex justify-content-between align-items-center w-100 progress_bar-register-cont">
            <div
              className="d-flex flex-column align-items-center pb-3"
              style={{ cursor: 'pointer' }}

            >
              <div className="progress_bar-register rounded-circle bg-primary text-white px-3 py-2 h4"
                onClick={() => setInputGroup('passanger')}>
                1
              </div>

              <h4 className="text-primary">Passanger Information</h4>
            </div>
            <div
              className="d-flex flex-column align-items-center pb-3"
              style={{ cursor: 'pointer' }}

            >
              <div
                className={`progress_bar-register rounded-circle ${InputGroup === "flight"
                    ? "bg-primary"
                    : "bg-secondary" && InputGroup === "fare"
                      ? "bg-primary"
                      : "bg-secondary"
                  } text-white px-3 py-2 h4`}
                onClick={() => setInputGroup('flight')}
              >
                2
              </div>

              <h4
                className={`${InputGroup === "flight"
                    ? "text-primary"
                    : "text-secondary" && InputGroup === "fare"
                      ? "text-primary"
                      : "text-secondary"
                  }`}
              >
                Flight Information
              </h4>
            </div>
            <div
              className="d-flex flex-column align-items-center pb-3"
              style={{ cursor: 'pointer' }}

            >
              <div
                className={`progress_bar-register rounded-circle ${InputGroup === "fare" ? "bg-primary" : "bg-secondary"
                  } text-white px-3 py-2 h4`}
                onClick={() => setInputGroup('fare')}
              >
                3
              </div>

              <h4
                className={`${InputGroup === "fare" ? "text-primary" : "text-secondary"
                  } `}
              >
                Fare Information
              </h4>
            </div>
          </div>
        </div>
        <div className="inputs_group">
          {InputGroup === "passanger" && <PassangerInfo />}
          {InputGroup === "flight" && <FlightInfo />}
          {InputGroup === "fare" && <Fare />}
        </div>
        <div className="d-flex justify-content-center align-items-center py-5 px-3 pe-4 pb-0">
          <div>
            {/*<>
            {InputGroup === "passanger" && (
              <span
                onClick={() => setInputGroup("flight")}
                className={`${
                  InputGroup === "passanger" ? "btn-primary" : "btn-secondary"
                } px-2 py-2`}
                style={{ cursor: "pointer" }}
              >
                Continue
              </span>
            )}
            {InputGroup === "flight" && (
              <>
                <span
                  onClick={() => setInputGroup("passanger")}
                  className={`btn-primary px-2 py-2 me-4 `}
                  style={{ cursor: "pointer" }}
                >
                  Previous
                </span>
                {InputGroup === "pessenger" ? (
                  <span
                    onClick={() => setInputGroup("flight")}
                    className={`btn-primary px-2 py-2`}
                    style={{ cursor: "pointer" }}
                  >
                    Continue
                  </span>
                ) : (
                  <span
                    onClick={() => setInputGroup("fare")}
                    className={`btn-primary px-2 py-2`}
                    style={{ cursor: "pointer" }}
                  >
                    Continue
                  </span>
                )}
              </>
            )}
          </>*/}
            {InputGroup === "fare" && (
              <>
                <Button variant='contained'
                >
                  Generate Booking
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPnr;
