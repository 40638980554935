import React, { useState } from "react";
import { TextField } from "@mui/material";
import MUIDataTable from "mui-datatables";

function AgencyDocument() {
  const columns = [
    {
      name: "id",
      label: "S.No",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "document_title",
      label: "Document Title",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
        name: "document_details",
        label: "Document Details",
        options: {
          filter: false,
          sort: true,
        },
      },
  ];

  const data = [
    {
      id: 1,
      document_title: "",
      document_details: ""
    },
  ];
  const options = {
    filter: false,
    Selection: false,
    print: false,
    download: false,
    viewColumns: false,
    displayRowCheckbox: false,
    selectableRows: "none",
    // onRowClick: (rowData) => {
    //   navigate(`/booking-details/${rowData[3]}/active`)
    // },
  };
  return (
    <div className="col-12 col-sm-6 mx-auto rounded-2 shadow mt-5 pb-5 flash-news">
      <p className="h4 otawix-heading">Documents</p>
      <div className="col-12 px-4 d-flex flex-column justify-content-center">
        <div className="d-flex">
          <div className="col-6 px-3 mt-3">
            <TextField
              id="standard-basic"
              label="Document Title *"
              variant="standard"
              className="col-12"
            />
          </div>
          <div className="col-6 px-3 mt-3">
          <TextField
              variant="standard"
              label="Upload Document *"
              className="w-100"
              type={"file"}
            />
          </div>
        </div>
        <button className="btn setup-btn mt-5">save</button>
      </div>
      <div className="col-12 mt-5 px-4">
        <MUIDataTable
          className="muidatatable"
          title={"Document Details"}
          data={data}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  );
}

export default AgencyDocument;
