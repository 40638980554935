
import { TextField } from "@mui/material";
import React from "react";
import ReactDatePicker from "react-datepicker";

function FlightInfo() {
  return (
    <div className="mt-4">
      <div className="d-flex justify-content-between align-items-end my-3 mb-5">
        <h4>Flight Information:</h4>
        <TextField variant="standard" label='Enter ENR' />
      </div>
      <div className="mb-5">
        <h6>Arrival ( Saudi Arabia )</h6>
        <Arival />
      </div>
      <div className="mb-4">
        <h6>Departure ( Saudi Arabia )</h6>
        <Departure />
      </div>
    </div>
  );
}

const Arival = () => (
  <div className="row align-items-end custom_Flight_info pb-4">
    <div className="col-2">
      <TextField variant='standard' label='Flight' className="w-100" />
    </div>
    <div className="col-2">
      <ReactDatePicker placeholderText="Arrival Date" className="date_picker" monthsShown={2} minDate={new Date()} />
    </div>
    <div className="col-2">
      <TextField variant='standard' label='Origin' className="w-100" />
    </div>
    <div className="col-2">
      <TextField variant='standard' label='Destination' className="w-100" />

    </div>
    <div className="col-2">
      <TextField variant='standard' label='Departure Time' className="w-100" />
    </div>
    <div className="col-2">
      <TextField variant='standard' label='Arrival Time' className="w-100" />
    </div>
  </div>
);

const Departure = () => (
  <div className="row align-items-end custom_Flight_info pb-4">
    <div className="col-2">
      <TextField variant='standard' label='Flight' className="w-100" />
    </div>
    <div className="col-2">
      <ReactDatePicker placeholderText="Departure Date" className="date_picker" monthsShown={2} minDate={new Date()} />
    </div>
    <div className="col-2">
      <TextField variant='standard' label='Origin' className="w-100" />
    </div>
    <div className="col-2">
      <TextField variant='standard' label='Destination' className="w-100" />

    </div>
    <div className="col-2">
      <TextField variant='standard' label='Departure Time' className="w-100" />
    </div>
    <div className="col-2">
      <TextField variant='standard' label='Arrival Time' className="w-100" />
    </div>
  </div>
);

export default FlightInfo;
