import React from "react";
import { TextField } from "@mui/material";
import MUIDataTable from "mui-datatables";

const Transfer = () => {
  const transferColumns = [
    {
      name: "transfer_route",
      label: "route",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "Action",
      name: "edit",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <button
              className="btn-success rounded edit-delete-icon-button me-1"
              onClick={() => console.log(value, tableMeta)}
            >
              <i className="fa fa-thin fa-pencil"></i>
            </button>
          );
        },
      },
    },
  ];

  const transferData = [
    {
      id: 1,
      transfer_route: "Jeddah APT to Makkah Hotel VV",
    },
  ];
  const vehicleColumns = [
    {
      name: "vehicle_type",
      label: "vehicle",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "Action",
      name: "edit",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <button
              className="btn-success rounded edit-delete-icon-button me-1"
              onClick={() => console.log(value, tableMeta)}
            >
              <i className="fa fa-thin fa-pencil"></i>
            </button>
          );
        },
      },
    },
  ];

  const vehicleData = [
    {
      id: 1,
      vehicle_type: "Jeddah APT to Makkah Hotel VV",
    },
  ];
  const options = {
    filter: false,
    Selection: false,
    print: false,
    download: false,
    viewColumns: false,
    displayRowCheckbox: false,
    selectableRows: "none",
    // onRowClick: (rowData) => {
    //   navigate(`/booking-details/${rowData[3]}/active`)
    // },
  };
  return (
    <div className="col-12 col-sm-9 mx-auto rounded-2 shadow my-5 pb-5 flash-news">
      <p className="h4 otawix-heading">Add Umrah Transfer</p>
      <div className="col-12 px-4">
        <div className="col-12 mt-4 py-3 d-flex">
        <div className="col-sm-6 d-flex flex-column px-5">
          <TextField
            id="standard-basic"
            label="Route"
            variant="standard"
          />
          <button className="btn setup-btn mt-3">save</button>
          </div>
          <div className="col-sm-6 d-flex flex-column px-5">
          <TextField
            id="standard-basic"
            label="Vehicle Type"
            variant="standard"
          />
          <button className="btn setup-btn mt-3">save</button>
          </div>
        </div>
      </div>
      <div className="col-12 mt-4 px-4 d-flex justify-content-between">
        <div className="col-sm-6 px-2">
          <MUIDataTable
            className="muidatatable"
            title={"Umrah Route"}
            data={transferData}
            columns={transferColumns}
            options={options}
          />
        </div>
        <div className="col-sm-6 px-2">
          <MUIDataTable
            className="muidatatable"
            title={"Vehicle Type"}
            data={vehicleData}
            columns={vehicleColumns}
            options={options}
          />
        </div>
      </div>
    </div>
  );
};

export default Transfer;
