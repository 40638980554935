import { Button } from '@mui/material'
import React from 'react'
import Header from '../../../Common/Header'
import CustomDropdown from '../../../CustomDrp'
import ReactDatePicker from "react-datepicker";


function JournalLedger() {
    return (
        <div className='Full_height'>
            <Header />
            <div className="manual_invoices mx-auto">
                <div className="manual_invoices_header">
                    <div>Journal Ledger</div>
                </div>
                <div className='row py-3 px-2 pb-5 px-md-4 align-items-end'>

                    <div className='col-12 col-md-6'>
                        <CustomDropdown arry={['Assets', 'Liabilities', 'Capital', 'Income', 'Expances']} placehlder='Account Level ' />
                    </div>
                    <div className='col-12 col-md-6 mt-3 mt-md-0'>
                        <CustomDropdown arry={['Assets', 'Liabilities', 'Capital', 'Income', 'Expances']} placehlder='Account Level To Print ' />
                    </div>
                    <div className='row align-items-end my-5 my-md-4'>
                        <div className='col-6 col-md-4'>
                            <ReactDatePicker
                                placeholderText="From"
                                // selected={startDate}
                                // onChange={(date) => {
                                //     setStartDate(date);
                                // }}
                                monthsShown={1}
                                // selected={new Date()}
                                // onCalendarClose={handleCalendarClose}
                                minDate={new Date()}
                                // onCalendarOpen={handleCalendarOpen}
                                className="date_picker"
                            />
                        </div>
                        <div className='col-6 col-md-4'>
                            <ReactDatePicker
                                placeholderText="To"
                                // selected={startDate}
                                // onChange={(date) => {
                                //     setStartDate(date);
                                // }}
                                monthsShown={1}
                                // selected={new Date()}
                                // onCalendarClose={handleCalendarClose}
                                minDate={new Date()}
                                // onCalendarOpen={handleCalendarOpen}
                                className="date_picker"
                            />
                        </div>
                        <div className='col-12 col-md-4 mt-4 mt-md-0'>
                            <Button variant='contained'>Generate PDF</Button>
                        </div>
                    </div>
                    <div class="checkbox-rect me-5">
                        <input type="checkbox" id="date_wise" name="date_wise" />
                        <label for="date_wise" className="h6">Date Wise</label>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default JournalLedger