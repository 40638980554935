import React, { useState } from "react";
import Carousel from 'react-bootstrap/Carousel';

function TextSlider() {
  const [flash_news, set_flash_news] = useState([
    "ZAIRAA OFFER ON PIA -3% ALL AGENTS ZAIRAA V3.1 with GDS and LCC airline APIs, Also with Hotel APIs.",
    "Welcome to ZAIRAA B2B V3.2 New Version.",
    "ZAIRAA OFFER ON PIA -3% ALL AGENTS",
  ]);
  return (
    <Carousel interval={2000} indicators={false} nextIcon={false} prevIcon={false} className="custom--class-text-slider px-auto">

      {flash_news.map((flash, index) => <Carousel.Item className="pt-2" key={index}>
        <p className="text-justify">{flash}</p>
      </Carousel.Item>)}
    </Carousel>
  );
}

export default TextSlider;
