import BorderColorIcon from '@mui/icons-material/BorderColor';
import { Button } from "@mui/material";

const columns = [
    {
        name: "Voucher Date",
        label: "Voucher Date",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "Voucher Type",
        label: "Voucher Type",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "Voucher Title",
        label: "Voucher Title",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "Voucher Number",
        label: "Voucher Number",
        options: {
            filter: true,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
                return (
                    <div className="d-flex">
                        <Button variant="contained" color='success' className='me-2'>View</Button>
                        <Button variant="contained" color='info' className='me-2'>RV-53</Button>
                        <Button variant="contained" color='success' endIcon={<BorderColorIcon />} />
                    </div>
                );
            },
        }
    },

];
export default columns