import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ReactDatePicker from "react-datepicker";

import "../../../Styles/setup.css";

const CorporateModal = ({ show, setShow }) => {
  const [radio, setRadio] = useState("cheque");
  const [data, setData] = useState({
    file: "No File Choosen",
  });
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName={"modal-xl"}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Corporate Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column">
            <div className="row justify-content-between">
              <div className="col-sm-4">
                <span>Office ID *</span>
                <input
                  type="text"
                  className="inputs_visa_tab mt-3"
                  placeholder=""
                  required
                />
              </div>
              <div className="col-sm-4">
                <span>Office Name *</span>
                <input
                  type="text"
                  className=" inputs_visa_tab mt-3"
                  placeholder=""
                />
              </div>
              <div className="col-sm-4">
                <span>Amount *</span>
                <input
                  type="text"
                  className=" inputs_visa_tab mt-3"
                  placeholder=""
                />
              </div>
            </div>
            <hr className="mt-5" />
            <h5 className="section-text otawix-sub-heading">Select Bank for Confirm Deposit:</h5>
            <hr />
            <div className="row justify-content-between mt-1">
              <div className="col-sm-4">
                <span>Bank *</span>
                <input
                  type="text"
                  className="inputs_visa_tab mt-3"
                  placeholder=""
                  required
                />
              </div>
              <div className="col-sm-4">
                <span>Bank Receipt Number *</span>
                <input
                  type="text"
                  className=" inputs_visa_tab mt-3"
                  placeholder=""
                />
              </div>
              <div className="col-sm-4">
                <span>Enter Bank Receipt Number Bank Receipt Image</span>
                <input
                  type="text"
                  className=" inputs_visa_tab mt-3"
                  placeholder=""
                />
              </div>
            </div>
            <div className="col-sm-5 d-flex justify-content-between mt-5">

              <label class="form-control">
                <input type="radio"
                  id="cheque"
                  value="cheque"
                  checked={radio === "cheque"}
                  onChange={(e) => setRadio(e.target.value)} />
                <span className="h6">Cheque</span>
              </label>

              <label class="form-control">
                <input type="radio"
                  id="IBFT"
                  value="IBFT"
                  checked={radio === "IBFT"}
                  onChange={(e) => setRadio(e.target.value)} />
                <span className="h6">IBFT</span>
              </label>
              <label class="form-control">
                <input type="radio"
                  id="cash"
                  value="cash"
                  checked={radio === "cash"}
                  onChange={(e) => setRadio(e.target.value)} />
                <span className="h6">Cash</span>
              </label>

            </div>
            {radio === "cheque" && (
              <div className="row align-items-end bank-info mt-5">
                <div className="col-sm-4 ">
                  <span>Bank Name *</span>
                  <input
                    type="text"
                    className="inputs_visa_tab"
                    placeholder=""
                    required
                  />
                </div>
                <div className="col-sm-4">
                  <span>Branch *</span>
                  <input
                    type="text"
                    className=" inputs_visa_tab "
                    placeholder=""
                    required
                  />
                </div>
                <div className="d-flex flex-column col-sm-4 ">
                  <span>Cheque Date *</span>
                  <ReactDatePicker
                    className="inputs_visa_tab "
                    monthsShown={2}
                  />
                </div>
                <div className="col-sm-4 mt-5">
                  <span>Check No *</span>
                  <input
                    type="text"
                    className="inputs_visa_tab mt-3"
                    value={data.file}
                    disabled
                  />
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            cancel
          </Button>
          <Button variant="secondary">save</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CorporateModal;
