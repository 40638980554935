import React from 'react';
import Header from '../components/Common/Header'
import CurrencyComp from '../components/Setup/Currency'
import Footer from '../components/Footer'

const Currency = () => {
  return (
    <div className='Full_height'>
      <Header active="setup" />
      <CurrencyComp />
      <Footer />
    </div>
  )
}

export default Currency