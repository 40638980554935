import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

function ImageSlider() {
  return (
    <Carousel indicators={false} interval={5000}>
      <Carousel.Item>
        <img
          className="d-block w-100 slider_image"
          src="https://images.unsplash.com/photo-1663326224028-27ba787bb7ce?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 slider_image"
          src="https://media.istockphoto.com/photos/aerial-view-of-the-cliff-and-rocky-beach-call-manta-bay-or-kelingking-picture-id1278919683?k=20&m=1278919683&s=170667a&w=0&h=4tamxqRrJECLvo8eLpuffGRiG6Rdu_ksJYLhN1aR7jk="
          alt="Second slide"
        />


      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 slider_image"
          src="https://images.unsplash.com/photo-1657648099622-49a30c08400e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDV8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
          alt="Third slide"
        />

      </Carousel.Item>
    </Carousel>
  )
}

export default ImageSlider