import React, {useState} from "react";
import MUIDataTable from "mui-datatables";
import { Modal, Button } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import DeleteModal from "../../DeleteModal";

const UnpaidVoucher = () => {
  const [showBankStatus, setShowBankStatus] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const columns = [
    {
      name: "date",
      label: "Date/Time",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "supplier",
      label: "Supplier | Customer",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "Status By Bank",
      name: "bank_status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <button
              className="table-btn btn-primary"
              onClick={() => {
                setShowBankStatus(true);
                setModalData(tableMeta.rowData);
              }}
            >
              {value}
            </button>
          );
        },
      },
    },
    {
      label: "Deposit Image",
      name: "deposit_slip",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <a
              role="button"
              href={value}
              target="_blank"
              style={{textDecoration: "none"}}
            >
              <button className="table-btn btn-success">View</button>
            </a>
          );
        },
      },
    },
    {
      label: "Delete",
      name: "bank_status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <button
              className="btn-danger edit-delete-icon-button rounded"
              onClick={() => {
                setShowDeleteModal(true);
                setModalData(tableMeta.rowData);
              }}
            >
              <i class="fa fa-thin fa-trash-can"></i>
            </button>
          );
        },
      },
    },
  ];

  const data = [
    {
      date: "17-Sep-2022 11:47 AM", // payment_id and this one should be same
      supplier: "ZAIRAA Main Office",
      bank_status: "Confirmed",
      amount: "Issue",
      deposit_slip: "/zairaa.jpeg"
    },
  ];
  const options = {
    filter: false,
    Selection: false,
    print: false,
    download: false,
    viewColumns: false,
    displayRowCheckbox: false,
    selectableRows: "none",
    // onRowClick: (rowData) => {
    //   navigate(`/booking-details/${rowData[3]}/active`)
    // },
  };

  return (
    <div className="pb-5 px-4 mt-5">
    <StatusModal
        show={showBankStatus}
        setShow={setShowBankStatus}
        modalData={modalData}
      />
    <DeleteModal
        show={showDeleteModal}
        setShow={setShowDeleteModal}
        rowId={modalData && modalData[3]}
      />
      <MUIDataTable
        className="muidatatable"
        title={"Unpaid Voucher"}
        data={data}
        columns={columns}
        options={options}
      />
    </div>
  );
};

export default UnpaidVoucher;

const StatusModal = ({ show, setShow, modalData }) => {
  return (
    <Modal
      show={show}
      onHide={(e) => setShow(false)}
      centered
    >
      <Modal.Header>
        <Modal.Title>Voucher Number: PV-00000006</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-12">
          <div className="col-12 text-center mt-3">
          Do you really want to confirm the payment?
          </div>
          <div className="col-12 d-flex my-3">
            <div className="col-10 mx-auto d-flex justify-content-center">
              <ReactDatePicker
                placeholderText="Date"
                // selected={startDate}
                // onChange={(date) => {
                //     setStartDate(date);
                // }}
                monthsShown={2}
                selected={new Date()}
                // onCalendarClose={handleCalendarClose}
                minDate={new Date()}
                // onCalendarOpen={handleCalendarOpen}
                className="date_picker mt-4"
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="modal-buttons-parent mx-auto">
          <Button variant="danger modal-button" onClick={(e) => setShow(false)}>
            Close
          </Button>
          <Button
            variant="primary modal-button"
            onClick={(e) => setShow(false)}
          >
            Confirm
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
