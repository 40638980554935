import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import MUIDataTable from "mui-datatables";
import ReactDatePicker from "react-datepicker";

import columns from "./Columns";
import data from "./data";
import { Button, TextField } from "@mui/material";
import CustomDropdown from "../../CustomDrp";

function PassengerInformation() {
  const [multiRows, setMultiRows] = useState([]);
  const [radio, setRadio] = useState("pax_info_manual");
  const [show, setShow] = useState(false);
  const [passFromDB, setPassangersFromDB] = useState([]);
  const addRow = () => {
    const newvalue = multiRows[multiRows.length - 1];
    setMultiRows([...multiRows, newvalue ? newvalue + 1 : 1]);
  };

  const removeRow = () => {
    let rowsArray = multiRows;
    rowsArray.pop();
    setMultiRows([...rowsArray]);
  };
  return (
    <>
      <div className="d-flex justify-content-start align-items-start mt-5">
        <label class="form-control me-3 me-md-5">
          <input type="radio" name="radio" id="pax-info-manual"
            value="pax_info_manual"
            onChange={(e) => setRadio(e.target.value)}
            checked={radio === "pax_info_manual"} />
          <span className="h6">Passenger Information Manual</span>
        </label>
        <label class="form-control me-3 me-md-5">
          <input type="radio" name="radio" id="pax-info-db"
            value="pax_info_db"
            onChange={(e) => {
              setRadio(e.target.value);
              setShow(true);
            }}
            checked={radio === "pax_info_db"} />
          <span className="h6">Passenger Information From Database</span>
        </label>
      </div>

      <div className="pt-5 mt-5">
        <PassengerInfoModal
          show={show}
          setShow={setShow}
          setPassangersFromDB={setPassangersFromDB}
        />
      </div>
      <div className="row align-items-end">
        <div className="d-flex justify-content-end align-items-end">
          <Button variant="contained"
            className="me-2"
            onClick={addRow}
          >
            Add Passanger
          </Button>
          <Button variant="contained" color="error"
            onClick={removeRow}
          >
            Remove Passanger
          </Button>
        </div>

        {passFromDB?.length > 0 ? (
          <div className="custom_passenger_info">
            {passFromDB.map((val) => (
              <PassengerInfoList key={val} />
            ))}
          </div>
        ) : (
          <div className="custom_passenger_info">
            <PassengerInfoList />
            {multiRows.map((val) => (
              <PassengerInfoList key={val} />
            ))}
          </div>
        )}
      </div>
    </>
  );
}



function PassengerInfoModal({ show, setShow, setPassangersFromDB }) {
  let dataObj;
  const onRowsSelect = (row, allrows) => {
    dataObj = [];
    allrows.map((d) => {
      dataObj.push(data[d.dataIndex]);
    });
  };

  const options = {
    filterType: false,
    download: false,
    onRowSelectionChange: onRowsSelect,
    filter: false,
    print: false,
    pagination: false,
  };

  return (
    <>
      <div className="model_container">
        <Modal
          show={show}
          onHide={() => setShow(false)}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
          className="w-100"
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Customer Information From Database
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <MUIDataTable
              title={""}
              data={data}
              columns={columns}
              options={options}
              className="mui_datatable"
            />
            <div className="d-flex justify-content-end align-items-end pt-2">
              <Button variant="contained" onClick={onRowsSelect}>
                Select
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

const PassengerInfoList = () => (
  <div className="row align-items-end py-3">
    <div className="col-1">
      <CustomDropdown arry={['Adult', 'Child', 'Infant']} placehlder='Type' />
    </div>
    <div className="col-1">
      <CustomDropdown arry={['MS', 'MRS', 'MISS', 'MSTR']} placehlder='Title' />
    </div>
    <div className="col-2">
      <TextField variant='standard' label='First Name' className="w-100" />
    </div>
    <div className="col-2">
      <TextField variant='standard' label='First Name' className="w-100" />
    </div>
    <div className="col-1">
      <ReactDatePicker
        placeholderText="D.O.B"
        className="date_picker"
        monthsShown={2}
      />
    </div>
    <div className="col-2">
      <TextField variant='standard' label='PSPT No' className="w-100" />
    </div>
    <div className="col-1">
      <TextField variant='standard' label='Visa No' className="w-100" />
    </div>
    <div className="col-2">
      <TextField variant='standard' label='Visa Supplier' className="w-100" />
    </div>
  </div>
);

export default PassengerInformation;
