import React from "react";
import { TextField } from "@mui/material";
import MUIDataTable from "mui-datatables";
import CustomDropdown from "../../CustomDrp";

const Hotel = () => {
  const columns = [
    {
      label: "Status",
      name: "checkbox",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div class="checkbox-rect">
              <input type="checkbox" id="with_sar" name="check" />
              <label for="with_sar" className="h6"></label>
            </div>
          );
        },
      },
    },
    {
      name: "city",
      label: "city",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "name",
      label: "name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "category",
      label: "category",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "contact_no",
      label: "contact no",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "email",
      label: "email",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "distance",
      label: "distance",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "Action",
      name: "edit",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <button
              className="btn-success rounded edit-delete-icon-button me-1"
              onClick={() => console.log(value, tableMeta)}
            >
              <i className="fa fa-thin fa-pencil"></i>
            </button>
          );
        },
      },
    },
  ];

  const data = [
    {
      id: 1,
      city: "Mecca",
      name: "	Swissotel Al Maqam Makkah",
      category: "5 Star",
      contact_no: 832169821649832,
      email: "example@gmail.com",
      distance: "300km",
    },
  ];
  const options = {
    filter: false,
    Selection: false,
    print: false,
    download: false,
    viewColumns: false,
    displayRowCheckbox: false,
    selectableRows: "none",
    // onRowClick: (rowData) => {
    //   navigate(`/booking-details/${rowData[3]}/active`)
    // },
  };
  return (
    <div className="col-12 col-sm-9 mx-auto rounded-2 shadow my-5 pb-5 flash-news">
      <p className="h4 otawix-heading">Add Umrah Hotel</p>
      <div className="col-12 px-5 d-flex flex-column justify-content-center">
        <div className="col-12 mt-4 py-3 d-flex">
          <div className="col-6 px-5">
            <CustomDropdown
              arry={["Adult", "Child"]}
              placehlder="Select City"
            />
          </div>
          <div className="col-6 px-5">
            <CustomDropdown
              arry={["MS", "MR", "MRS"]}
              placehlder="Select Category"
            />
          </div>
        </div>
        <div className="col-12 mt-4 py-3 d-flex">
          <div className="col-6 px-5">
            <TextField
              id="standard-basic"
              label="Name"
              variant="standard"
              className="col-12"
            />
          </div>
          <div className="col-6 px-5">
            <TextField
              id="standard-basic"
              label="Phone"
              variant="standard"
              className="col-12"
              type="number"
            />
          </div>
        </div>
        <div className="col-12 mt-4 py-3 d-flex">
          <div className="col-6 px-5">
            <TextField
              id="standard-basic"
              label="Email"
              variant="standard"
              className="col-12"
            />
          </div>
          <div className="col-6 px-5">
            <TextField
              id="standard-basic"
              label="Distance"
              variant="standard"
              className="col-12"
            />
          </div>
        </div>
        <div className="col-12 mt-4 py-3">
        <div className="col-6 px-5">
          <label>Address *</label>
          <textarea className="col-12 mt-3" rows={5}></textarea>
        </div>
        </div>
        <button className="btn setup-btn">save</button>
      </div>
      <div className="col-12 mt-5 px-4">
        <MUIDataTable
          className="muidatatable"
          title={"Umrah Hotel Details"}
          data={data}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  );
};

export default Hotel;
