import { TextField } from '@mui/material'
import React from 'react'
import CustomDropdown from '../../../CustomDrp'

function Fare() {
    return (
        <div className='row align-items-center py-2'>
            <div className='h5'>Fare</div>
            <div className='col-3 col-md-2'>
                <TextField label="Amount" variant="standard" className="w-100" disabled type={'number'} />
            </div>
            <div className='col-3 col-md-2'>
                <TextField label="S.PSF" variant="standard" className="w-100" disabled type={'number'} />
            </div>
            <div className='col-3 col-md-2'>
                <TextField label="S.GST" variant="standard" className="w-100" disabled type={'number'} />
            </div>
            <div className='col-3 col-md-2'>
                <TextField label="S.ST" variant="standard" className="w-100" disabled type={'number'} />
            </div>

            <div className='col-3 col-md-2'>
                <TextField label="S.WHT" variant="standard" className="w-100" disabled type={'number'} />
            </div>
            <div className='col-3 col-md-2'>
                <TextField label="5000" variant="standard" className="w-100" disabled type={'number'} />
            </div>
            <div className='col-3 col-md-2'>
                <TextField label="C.GST" variant="standard" className="w-100" disabled type={'number'} />
            </div>
            <div className='col-3 col-md-2'>
                <TextField label="C.SP" variant="standard" className="w-100" disabled type={'number'} />
            </div>
            <div className='col-3 col-md-2'>
                <TextField label="C.WHT" variant="standard" className="w-100" disabled type={'number'} />
            </div>

            <div className='col-3 col-md-2'>
                <TextField label="Sector" variant="standard" className="w-100" disabled type={'number'} />
            </div>
            <div className='col-3 col-md-2'>
                <TextField label="ACC" variant="standard" className="w-100" disabled type={'number'} />
            </div>
            <div className='col-3 col-md-2'>
                <TextField label="SCC" variant="standard" className="w-100" disabled type={'number'} />
            </div>
        </div>
    )
}

export default Fare