import React from 'react'
import AboutUs from '../components/LandingPage/AboutUs';
import Header from '../components/LandingPage/Header'
import Slider from '../components/LandingPage/Swipper';
import PopularPackages from "../components/LandingPage/Packages";
import "../Styles/landingPage.css";
import LogosConteiner from '../components/LandingPage/LogosConteiner';
import WhyChooseUs from '../components/LandingPage/WhyChooseUs';
import Footer from '../components/LandingPage/Footer';

const LandingPage = () => {
  return (
    <div>
      <Header />
      <Slider />
      <AboutUs />
      <PopularPackages />
      <LogosConteiner />
      <WhyChooseUs />
      <Footer />
    </div>
  )
}

export default LandingPage