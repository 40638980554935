import { TextField } from "@mui/material";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ReactDatePicker from "react-datepicker";

import "../../../Styles/setup.css";

const MakePaymentModal = ({ show, setShow }) => {
  const [radio, setRadio] = useState("cheque");
  const [data, setData] = useState({
    file: "No File Choosen",
  });
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName={"modal-lg"}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Generate Payment Voucher</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column">
            <div className="row justify-content-between">
              <div className="col-sm-4 px-3">
                <TextField
                  label="Staff ID *"
                  variant="standard"
                  className="col-12"
                />
              </div>
              <div className="col-sm-4 px-3">
                <TextField
                  label="Staff Name *"
                  variant="standard"
                  className="col-12"
                />
              </div>
              <div className="col-sm-4 px-3">
                <TextField
                  label="Amount *"
                  variant="standard"
                  className="col-12"
                />
              </div>
            </div>
            <div className="col-sm-12 d-flex mt-5">
              <span class="form-control me-5">
                <input
                  type="radio"
                  id="cheque"
                  value="cheque"
                  checked={radio === "cheque"}
                  onChange={(e) => setRadio(e.target.value)}
                />
                <label for="cheque" style={{ marginLeft: "5px" }}>
                  Cheque
                </label>
              </span>
              <span class="form-control me-5">
                <input
                  type="radio"
                  id="IBFT"
                  value="IBFT"
                  checked={radio === "IBFT"}
                  onChange={(e) => setRadio(e.target.value)}
                />
                <label htmlFor="IBFT" style={{ marginLeft: "5px" }}>
                  IBFT
                </label>
              </span>
              <span class="form-control me-5">
                <input
                  type="radio"
                  id="cash"
                  value="cash"
                  checked={radio === "cash"}
                  onChange={(e) => setRadio(e.target.value)}
                />
                <label htmlFor="cash" style={{ marginLeft: "5px" }}>
                  Cash
                </label>
              </span>
            </div>
            {radio !== "cash" && (
              <div className="col-12 d-flex bank-info">
                <div className="col-sm-5 mt-5 mx-3">
                  <TextField
                    label="Bank Name"
                    variant="standard"
                    className="col-12"
                  />
                </div>
                <div className="col-sm-5 mt-5 mx-3 align-items-end">
                  <TextField
                        // label="Attach Deposit Slip"
                        variant="standard"
                        // value={data.file}
                        className="col-12 mt-3"
                        type={"file"}
                      />
                </div>
                {radio === "cheque" && (
                  <>
                    <div className="col-sm-5 mt-5 mx-3">
                      <TextField
                        label="Cheque No. *"
                        variant="standard"
                        className="col-12"
                      />
                    </div>
                    <div className="col-sm-5 mt-5 align-items-end mx-3">
                      <ReactDatePicker
                        className="date_picker mt-4"
                        placeholderText="Cheque Date"
                        monthsShown={2}
                      />
                    </div>
                  </>
                )}
              </div>
            )}
            <div className="mt-5">
              <span>Cheque No. *</span>
              <textarea className="col-sm-12 mt-3" rows={6} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            cancel
          </Button>
          <Button variant="secondary">save</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MakePaymentModal;
