import React from "react";
import Header from '../components/Common/Header'
import SupplierComp from '../components/Setup/Umrah/VisaSupplier'
import Footer from '../components/Footer'

const UmrahVisaSupplier = () => {
  return (
    <div className='Full_height'>
      <Header active="setup" />
      <SupplierComp />
      <Footer />
    </div>
  );
};

export default UmrahVisaSupplier;
