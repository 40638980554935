import { Button } from '@mui/material'
import React from 'react'
import Header from '../../../Common/Header'
import CustomDropdown from '../../../CustomDrp'
import ReactDatePicker from "react-datepicker";

function BankCash() {
    return (
        <div className='Full_height'>
            <Header />
            <div className="manual_invoices  mx-auto">
                <div className="manual_invoices_header">
                    <div>Bank / Cash Transactions</div>
                </div>
                <div className='row py-3 pb-5 px-2 px-md-4 align-items-end'>

                    <div className='col-12 col-md-4'>
                        <CustomDropdown arry={['Assets', 'Liabilities', 'Capital', 'Income', 'Expances']} placehlder='Bank / Cash ' />
                    </div>


                    <div className='col-6 col-md-4 mt-4 mt-md-0'>
                        <ReactDatePicker
                            placeholderText="From"
                            // selected={startDate}
                            // onChange={(date) => {
                            //     setStartDate(date);
                            // }}
                            monthsShown={1}
                            // selected={new Date()}
                            // onCalendarClose={handleCalendarClose}
                            minDate={new Date()}
                            // onCalendarOpen={handleCalendarOpen}
                            className="date_picker"
                        />
                    </div>
                    <div className='col-6 col-md-4 mt-5 mt-md-0 '>
                        <ReactDatePicker
                            placeholderText="To"
                            // selected={startDate}
                            // onChange={(date) => {
                            //     setStartDate(date);
                            // }}
                            monthsShown={1}
                            // selected={new Date()}
                            // onCalendarClose={handleCalendarClose}
                            minDate={new Date()}
                            // onCalendarOpen={handleCalendarOpen}
                            className="date_picker"
                        />
                    </div>

                    <div class="checkbox-rect col-6 col-md-3 ">
                        <input type="checkbox" id="date_wise" name="date_wise" />
                        <label for="date_wise" className="h6">Date Wise</label>
                    </div>
                    <div className='col-6 col-md-3 pt-4'>
                        <Button variant='contained'>Generate PDF</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BankCash