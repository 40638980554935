import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import { Modal } from "react-bootstrap";
import { ImBook } from "react-icons/im";
import { BsFillPersonFill } from "react-icons/bs";
import { RiInformationFill } from "react-icons/ri";
import { IoIosAirplane } from "react-icons/io";
import Accordion from "react-bootstrap/Accordion";
import Flydubai from "../../assets/images/Flydubai.jpeg";
import Economy from "../../assets/images/Economy.png";
import Plan from "../../assets/images/plan.png";
import WaitingTime from "../../assets/images/waiting_time.png";
import ReactDatePicker from "react-datepicker";
import CustomDropDown from '../../components/CustomDrp'
import AddEditModal from "./Add/EditModal";




const AirlineInfo = [
  {
    logo: "https://www.logolynx.com/images/logolynx/d0/d0df26bdd9cb0103f88d681d367f5f9a.jpeg",
    dateDep: "19-Oct-2022 19:35",
    airportDep: 'KHI Karachi Pakistan',
    dateAriv: '19-Oct-2022 19:35',
    airportAriv: 'ISB Islamabad Pakistan'
  },
  {
    logo: "https://www.logolynx.com/images/logolynx/d0/d0df26bdd9cb0103f88d681d367f5f9a.jpeg",
    dateDep: "19-Oct-2022 19:35",
    airportDep: 'KHI Karachi Pakistan',
    dateAriv: '19-Oct-2022 19:35',
    airportAriv: 'ISB Islamabad Pakistan'
  }
]



function General() {
  return (
    <div className="d-flex flex-column justify-content-center mt-4">
      <ButtonsGroup />
      <BookedItem />
      <PassengerData />
      <Flight />
      <RemarksSSR />
    </div>
  );
}

const ButtonsGroup = () => {
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showTicketModal, setShowTicketModal] = useState(false);
  // const [rowData, setRowData] = useState({});
  const [showAddEditModal, setshowAddEditModal] = useState(false)
  return (
    <>
      <AddEditModal show={showAddEditModal} setShow={setshowAddEditModal} />
      <RequestChangeModal
        show={showUpdateModal}
        setShow={setShowUpdateModal}
      // rowData={rowData}
      />
      <ReIssueTicketModal
        show={showTicketModal}
        setShow={setShowTicketModal}
      // rowData={rowData}
      />
      <div
        class="d-flex justify-content-center align-items-center  mx-5"
        role="group"
        aria-label="Basic example"
      >
        <button type="button" class="btn booking-buttons px-4 mx-1">
          View Document
        </button>
        <button
          type="button"
          class="btn booking-buttons px-4 mx-1"
          onClick={() => {
            setShowUpdateModal(true);
            // setRowData(data[tableMeta.rowIndex]);
          }}
        >
          Request
        </button>
        <button type="button" class="btn booking-buttons px-4 mx-1">
          Update Segment
        </button>
        <button type="button" class="btn booking-buttons px-4 mx-1"
          onClick={e => setshowAddEditModal(!showAddEditModal)}>
          Add / Edit SSR
        </button>
        <button
          type="button"
          class="btn booking-buttons px-4 mx-1"
          onClick={() => {
            setShowTicketModal(true);
            // setRowData(data[tableMeta.rowIndex]);
          }}
        >
          Re-Issue Tickets
        </button>
        <button type="button" class="btn booking-buttons px-4 mx-1">
          Cancel Booking
        </button>
      </div>
    </>
  );
};

const BookedItem = () => {
  return (
    <div className="booked_items">
      <div className="d-flex justify-content-between align-items-center py-3 px-4 border-bottom">
        <div className="h5">
          <ImBook /> Booked items
        </div>
        <div className="h5">ZairaaMain Office</div>
      </div>
      <div className="booked_items_table">
        <div className="d-flex justify-content-between align-items-center border-bottom">
          <div className="booked_tabel_items h5">Booking ID</div>
          <div className="booked_tabel_items h5">PNR</div>
          <div className="booked_tabel_items h5">Booking status</div>
          <div className="booked_tabel_items h5">Document status</div>
          <div className="booked_tabel_items h5">Paid by Agency</div>
        </div>
        <div className="d-flex justify-content-between align-items-center ">
          <div className="booked_tabel_items h6">277</div>
          <div className="booked_tabel_items h6">BWAX0G (BWAX0G)</div>
          <div className="booked_tabel_items h6">CONFIRMED</div>
          <div className="booked_tabel_items h6">ISSUED</div>
          <div className="booked_tabel_items h6">17-Sep-2022</div>
        </div>
      </div>
    </div>
  );
};

const PassengerData = () => {
  return (
    <div className="booked_items">
      <Accordion>
        <Accordion.Item eventKey="0" className="w-100">
          <Accordion.Header>
            <div className="h5">
              <BsFillPersonFill /> Passengers data
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div className="booked_items_table">
              <div className="d-flex justify-content-between align-items-center border-bottom">
                <div className="booked_tabel_items h5">#</div>
                <div className="booked_tabel_items h5">Type</div>
                <div className="booked_tabel_items h5">Name</div>
                <div className="booked_tabel_items h5">DOB</div>
                <div className="booked_tabel_items h5">Passport #</div>
                <div className="booked_tabel_items h5">TKT #</div>
              </div>
              <div className="d-flex justify-content-between align-items-center border-bottom">
                <div className="booked_tabel_items h6">1</div>
                <div className="booked_tabel_items h6">ADT</div>
                <div className="booked_tabel_items h6">Mr ATTA AFRIDI</div>
                <div className="booked_tabel_items h6">30-Nov--0001</div>
                <div className="booked_tabel_items h6"></div>
                <div className="booked_tabel_items h6">BWAX0G-P1</div>
              </div>
              <div className="d-flex justify-content-between align-items-center ">
                <div className="booked_tabel_items h6">2</div>
                <div className="booked_tabel_items h6">ADT</div>
                <div className="booked_tabel_items h6">Mr ATTA AFRIDI</div>
                <div className="booked_tabel_items h6">30-Nov--0001</div>
                <div className="booked_tabel_items h6"></div>
                <div className="booked_tabel_items h6">BWAX0G-P1</div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

const Flight = () => {
  return (
    <div className="booked_items">
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0" className="w-100">
          <Accordion.Header>
            <div className="h5">
              <IoIosAirplane /> Flight
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div className="booked_items_table">
              <div className="d-flex justify-content-between align-items-center border-bottom">
                <div className="text-center">
                  <img src={Flydubai} className="mb-2" />
                  <div>FZ 329</div>
                </div>
                <div className="d-flex col-sm-8 justify-content-between align-items-center">
                  <div className="flight-from-div">
                    <div>25 Nov 2022 07:50 PM</div>
                    <div>DXB-Dubai, United Arab Emirates - International</div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <img
                          src={Economy}
                          style={{ height: "25px", width: "25px" }}
                        />
                        <span> 0</span>
                      </div>
                      <div>
                        <img
                          src={Plan}
                          style={{ height: "25px", width: "25px" }}
                        />
                        <span>Equipment 7200</span>
                      </div>
                      <div>
                        <img
                          src={WaitingTime}
                          style={{ height: "25px", width: "25px" }}
                        />
                        <span> -6h 0m</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <i
                      class="fa fa-thin fa-arrow-right"
                      style={{ color: "#4d496b", fontFamily: "FontAwesome" }}
                    ></i>
                  </div>
                  <div className="flight-from-div">
                    <div>25 Nov 2022 07:50 PM</div>
                    <div>DXB-Dubai, United Arab Emirates - International</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="baggage-allowance">
              <h5>Baggage allowance</h5>
              <br />
              <div>
                <h6>First Flight Baggage Information</h6>
                <p>Hand Carry Bags: 7 Kg</p>
                <p>Checked Bags: 2 Bag 20 Kg Each</p>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

const RemarksSSR = () => {
  return (
    <div className="booked_items">
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0" className="w-100">
          <Accordion.Header>
            <div className="h5">
              <RiInformationFill /> Remarks and SSR
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div className="booked_items_table">
              <div className="d-flex justify-content-between align-items-center border-bottom">
                <div className="booked_tabel_items h6">Remarks</div>
              </div>
              <div className="remarks-ssr">
                <h6 className="d-flex justify-content-between align-items-center border-bottom py-1">
                  Refund Charges
                </h6>
                <div className="d-flex justify-content-between align-items-center py-1">
                  TicketTimeLimit 2022-09-29T13:43:13
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center ">
                <div className="booked_tabel_items h6">SSR Info</div>
              </div>
              <div className="remarks-ssr">
                <h6 className="d-flex justify-content-between align-items-center border-bottom py-1">
                  SSR
                </h6>
                <div className="d-flex justify-content-between align-items-center py-1">
                  TicketTimeLimit 2022-09-29T13:43:13
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

const RequestChangeModal = ({ show, setShow, rowData }) => {

  return (
    <Modal
      show={show}
      onHide={(e) => setShow(false)}
      centered
      dialogClassName="modal-xl modal-full"
    >
      <Modal.Header>
        <Modal.Title>Update Request</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <div className="px-md-5">
          <div className="row my-4">
            <div className="col-3">
              <TextField variant="standard" label='Request Number' className="w-100" />
            </div>
            <div className="col-3">
              <TextField variant="standard" label='Request Time' className="w-100" />
            </div>
            <div className="col-3">
              <CustomDropDown arry={['Issue', 'Re-issue', 'Change']} placehlder='Type' />
              {/* <TextField variant="standard" label='Type' className="w-100" /> */}
            </div>
          </div>
          <hr className="my-5" />
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="custom_border p-2">
                <div className="d-flex justify-content-between align-items-center">
                  <h5>Booked Items</h5>
                  <h5>ZairaaMain Office</h5>
                </div>
                <hr />
                <div className="row mt-2">
                  <div className="col-3">
                    <p className='h6 mb-3'>Booking ID/Type</p>
                    <p>#2333</p>
                  </div>
                  <div className="col-3">
                    <p className='h6 mb-3'>Booking Status</p>
                    <p>Confirmed</p>
                  </div>
                  <div className="col-3">
                    <p className='h6 mb-3'>Document Status</p>
                    <p>Pending</p>
                  </div>
                  <div className="col-3">
                    <p className='h6 mb-3'>Paid by Agency</p>
                    <p></p>
                  </div>
                </div>
              </div>
              <div className="col-12 my-4">
                <div className="custom_border p-2">
                  <div className="row align-items-center">

                    <div className="col-4">
                      <img src="https://www.logolynx.com/images/logolynx/d0/d0df26bdd9cb0103f88d681d367f5f9a.jpeg"
                        alt="logo" width={140} height={100} />
                    </div>
                    <div className="col-4">
                      <p className='text-info h6'>19-Oct-2022 19:35</p>
                      <p>KHI Karachi Pakistan</p>
                    </div>
                    <div className="col-4">
                      <p className='text-info h6'>19-Oct-2022 19:35</p>
                      <p>KHI Karachi Pakistan</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 my-4">
                <TextField variant="standard" label='Agent Description' className="w-100" />
              </div>
              <div className="col-12 my-4 mt-5">
                <TextField variant="outlined" label='12.21.2021-30' className="w-100" />
                <TextField variant="outlined" label='' className="w-100" multiline
                  rows={2} />
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="custom_border p-2">
                <div className="d-flex justify-content-between align-items-center">
                  <h5>Passenger</h5>
                  <h5></h5>
                </div>
                <hr />
                <div className="row mt-2">
                  <div className="col-2">
                    <p className='h6 mb-3'>Check</p>
                    <div class="checkbox-rect">
                      <input type="checkbox" id="passeng" name="passeng" />
                      <label for="passeng" className="h6"></label>
                    </div>
                  </div>
                  <div className="col-2">
                    <p className='h6 mb-3'>Type</p>
                    <p>ADULT</p>
                  </div>
                  <div className="col-2">
                    <p className='h6 mb-3'>Name</p>
                    <p>MR Asif Ali</p>
                  </div>
                  <div className="col-2">
                    <p className='h6 mb-3'>BirthDate</p>
                    <p></p>
                  </div>
                  <div className="col-2">
                    <p className='h6 mb-3'>Nationality</p>
                    <p></p>
                  </div>
                  <div className="col-2">
                    <p className='h6 mb-3'>#Passport</p>
                    <p></p>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="d-flex justify-content-end align-items-end ">
            <div className="me-2">
              <Button
                variant="contained"
                onClick={(e) => setShow(false)}
                color='error'
              >
                Close
              </Button>
            </div>
            <div className="">
              <Button
                variant="contained"
                onClick={(e) => setShow(false)}
              >
                Save Request
              </Button>
            </div>
          </div>

        </div>

      </Modal.Body>
    </Modal>
  );
};

const ReIssueTicketModal = ({ show, setShow, rowData }) => {
  const [multiRows, setMultiRows] = useState([1]);
  const addRow = () => {
    console.log("add");
    const newvalue = multiRows[multiRows.length - 1];
    setMultiRows([...multiRows, newvalue ? newvalue + 1 : 1]);
  };
  const removeRow = () => {
    if (multiRows.length === 1) return;
    let rowsArray = multiRows;
    rowsArray.pop();
    setMultiRows([...rowsArray]);
  };

  const data = [
    {
      req_no: "FLT-103",
      date: "17-Sep-2022 11:47 AM",
      office_name: "ZAIRAA Main Office",
      booking_id: "276",
      Call_center_staff: "Demo Two",
      status: "New",
      req_type: "Issue",
      description: "Issue Ticket Description",
      action: "Take this Booking",
    },
  ];
  const columns = [
    {
      name: "req_no",
      label: "Booking ID",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "req_no",
      label: "Booking status",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "req_no",
      label: "Document status",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "req_no",
      label: "Paid by Agency",
      options: {
        filter: false,
        sort: true,
      },
    },
  ];
  const options = {
    filter: false,
    Selection: false,
    print: false,
    download: false,
    viewColumns: false,
    displayRowCheckbox: false,
    selectableRows: "none",
  };
  return (
    <Modal
      show={show}
      onHide={(e) => setShow(false)}
      centered
      dialogClassName="modal-xl modal-full"
    >
      <Modal.Header>
        <Modal.Title>Re-Issue Request</Modal.Title>
      </Modal.Header>
      <Modal.Body>



        <div className="px-md-5">

          <div className="row mt-4">
            <div className="col-12 col-md-6">
              <div className="custom_border p-2">
                <div className="d-flex justify-content-between align-items-center">
                  <h5>Booked Items</h5>
                  <h5>ZairaaMain Office</h5>
                </div>
                <hr />
                <div className="row mt-2">
                  <div className="col-3">
                    <p className='h6 mb-3'>Booking ID/Type</p>
                    <p>#2333</p>
                  </div>
                  <div className="col-3">
                    <p className='h6 mb-3'>Booking Status</p>
                    <p>Confirmed</p>
                  </div>
                  <div className="col-3">
                    <p className='h6 mb-3'>Document Status</p>
                    <p>Pending</p>
                  </div>
                  <div className="col-3">
                    <p className='h6 mb-3'>Paid by Agency</p>
                    <p></p>
                  </div>
                </div>
              </div>
              <div className="col-12 my-4">
                <div className="custom_border p-2">
                  {AirlineInfo.map(airline => (
                    <div className="row align-items-end">

                      <div className="col-4">
                        <img src={airline.logo}
                          alt="logo" width={140} height={100} />
                      </div>
                      <div className="col-4">
                        <p className='text-info h6'>{airline.dateDep}</p>
                        <p>{airline.airportDep}</p>
                      </div>
                      <div className="col-4">
                        <p className='text-info h6'>{airline.dateAriv}</p>
                        <p>{airline.airportAriv}</p>
                      </div>
                    </div>
                  ))}

                </div>
              </div>
              <div className="col-12 my-5">
                <TextField variant="standard" label='Agent Description' className="w-100" />
              </div>
              <div className="col-12 my-4 mt-5">
                <TextField variant="outlined" label='12.21.2021-30' className="w-100" />
                <TextField variant="outlined" label='' className="w-100" multiline
                  rows={2} />
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="custom_border p-2">
                <div className="d-flex justify-content-between align-items-center">
                  <h5>Passenger</h5>
                </div>
                <hr />
                <div className="row mt-2">
                  <div className="col-2">
                    <p className='h6 mb-3'>Check</p>
                    <div class="checkbox-rect">
                      <input type="checkbox" id="passeng" name="passeng" />
                      <label for="passeng" className="h6"></label>
                    </div>
                  </div>
                  <div className="col-2">
                    <p className='h6 mb-3'>Type</p>
                    <p>ADULT</p>
                  </div>
                  <div className="col-2">
                    <p className='h6 mb-3'>Name</p>
                    <p>MR Asif Ali</p>
                  </div>
                  <div className="col-2">
                    <p className='h6 mb-3'>BirthDate</p>
                    <p></p>
                  </div>
                  <div className="col-2">
                    <p className='h6 mb-3'>Nationality</p>
                    <p></p>
                  </div>
                  <div className="col-2">
                    <p className='h6 mb-3'>#Passport</p>
                    <p></p>
                  </div>
                </div>


              </div>
              <div className="custom_border p-2 my-4">
                <div className="d-flex justify-content-between align-items-center">
                  <h5>Re-issue Flight Details</h5>
                  <div className="d-flex mb-3">
                    <Button variant="contained" color="error" className="me-2" onClick={(e) => removeRow()}>Remove Flight</Button>
                    <Button variant="contained" onClick={addRow}>Add Flight</Button>
                  </div>
                </div>
                {multiRows.map((row) => (
                  <ReIssueFlight index={row} />
                ))}
              </div>
            </div>

          </div>
          <div className="d-flex justify-content-end align-items-end ">
            <div className="me-2">
              <Button
                variant="contained"
                onClick={(e) => setShow(false)}
                color='error'
              >
                Close
              </Button>
            </div>
            <div className="">
              <Button
                variant="contained"
                onClick={(e) => setShow(false)}
              >
                Save Request
              </Button>
            </div>
          </div>

        </div>




        {/* <div className="col-12">
          <div className="col-12 d-flex">
            <div className="col-sm-6 px-3 mt-5">
              <TextField
                label="Agent Description"
                variant="standard"
                className="w-100"
              />
            </div>
            <div className="col-sm-6 px-3 mt-5">
              <TextField
                label="Agent Notes"
                variant="standard"
                className="w-100"
              />
            </div>
          </div>
          <div className="row px-3">
            <div className="col-sm-6 px-3 mt-5">
              <MUIDataTable
                className="muidatatable"
                title={"Booked Items"}
                data={data}
                columns={columns}
                options={options}
              />
            </div>
            <div className="col-sm-6 px-3 mt-5">
              <MUIDataTable
                className="muidatatable"
                title={"Passengers"}
                data={data}
                columns={columns}
                options={options}
              />
            </div>
          </div>
          <div className="col-12 mt-5 px-3">
            <div className="d-flex justify-content-between align-items-center border px-5 py-2">
              <div className="text-center">
                <img src={Flydubai} className="mb-2" />
                <div>FZ 329</div>
              </div>
              <div className="d-flex col-sm-8 justify-content-between align-items-center">
                <div className="flight-from-div">
                  <div>25 Nov 2022 07:50 PM</div>
                  <div>DXB-Dubai, United Arab Emirates - International</div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <img
                        src={Economy}
                        style={{ height: "25px", width: "25px" }}
                      />
                      <span> 0</span>
                    </div>
                    <div>
                      <img
                        src={Plan}
                        style={{ height: "25px", width: "25px" }}
                      />
                      <span>Equipment 7200</span>
                    </div>
                    <div>
                      <img
                        src={WaitingTime}
                        style={{ height: "25px", width: "25px" }}
                      />
                      <span> -6h 0m</span>
                    </div>
                  </div>
                </div>
                <div>
                  <i
                    class="fa fa-thin fa-arrow-right"
                    style={{
                      color: "#4d496b",
                      fontFamily: "FontAwesome",
                    }}
                  ></i>
                </div>
                <div className="flight-from-div">
                  <div>25 Nov 2022 07:50 PM</div>
                  <div>DXB-Dubai, United Arab Emirates - International</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mt-5 px-3">
            <div className="align-items-center border px-3 py-2">
              <div className="row col-12 otawix-sub-heading">
                <div className="col-sm-9 pt-3">
                  <h6>Re-Issue Flight Detail's</h6>
                </div>
                <div className="col-sm-3">
                  <button
                    className="btn btn-small btn-primary col-sm-6 border rounded-pill px-1"
                    onClick={addRow}
                  > */}
        {/*<i className="fa  fa-thin fa-plus"></i>Add Flight*/}
        {/* </button>
                  <button
                    className="btn btn-small btn-danger col-sm-6 border rounded-pill px-1"
                    onClick={(e) => removeRow()}
                  >
                    Remove Flight
                    
                  </button>
                </div>
              </div>
              {multiRows.map((row) => (
                <ReIssueFlight index={row} />
              ))}
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-end mt-5 mb-3">
            <div className="me-2">
              <Button
                variant="danger modal-button"
                onClick={(e) => setShow(false)}
                style={{ background: "#dc3545", color: "#fff" }}
              >
                Close
              </Button>
            </div>
            <div className="">
              <Button
                variant="primary modal-button"
                onClick={(e) => setShow(false)}
                style={{ background: "#0d6efd", color: "#fff" }}
              >
                Save
              </Button>
            </div>
          </div>
        </div> */}
      </Modal.Body>
    </Modal>
  );
};

const ReIssueFlight = ({ index }) => {
  return (
    <div className="">
      <h6>{index}. Flight</h6>
      <div className="row align-items-end">
        <div className="col-md-3">
          <TextField
            variant="standard"
            label="From Where"
            className="w-100"
          />
        </div>
        <div className="col-md-3">
          <ReactDatePicker
            minDate={new Date()}
            className="date_picker"
            placeholderText="Departure Date"
            monthsShown={2}
          />
        </div>
        <div className="col-md-3">
          <input
            type="time"
            placeholder="Departure Time"
            className="inputs w-100 "
          />
        </div>
        <div className="col-md-3">
          <TextField
            variant="standard"
            label="AirLine Code PK,EK"
            className="w-100"
          />
        </div>
      </div>
      <div className="row align-items-end">
        <div className="col-md-3 px-3">
          <TextField
            variant="standard"
            label="To Where"
            className="w-100"
          />
        </div>
        <div className="col-md-3">
          <ReactDatePicker
            minDate={new Date()}
            className="date_picker"
            placeholderText="Arrival Date"
            monthsShown={2}
          />
        </div>
        <div className="col-md-3">
          <input
            type="time"
            placeholder="Departure Time"
            className="inputs w-100 "
          />
        </div>
        <div className="col-md-3">
          <TextField
            variant="standard"
            label="AirLine No e.g 220"
            className="w-100"
          />
        </div>
      </div>
      <hr />
    </div>
  );
};

export default General;
