import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import DeleteModal from "../DeleteModal";


const Agents = () => {
  const [tabs, setTabs] = useState("active");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const active_columns = [
    {
      label: "Status",
      name: "checkbox",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div class="checkbox-rect">
              <input type="checkbox" id="with_sar" name="check" />
              <label for="with_sar" className="h6"></label>
            </div>
          );
        },
      },
    },
    {
      name: "registered",
      label: "registered",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "agent_group",
      label: "Agent Group",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "office_id",
      label: "Office id",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "office_type",
      label: "Office type",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "office_name",
      label: "Office name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "manager",
      label: "Office manager",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "location",
      label: "location",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "email",
      label: "email",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "office_phone",
      label: "office phone",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "emergency_phone",
      label: "emergency phone",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "credit_type",
      label: "credit type",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "credit_limit",
      label: "credit limit",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "Action",
      name: "delete",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <button
              className="btn-success rounded edit-delete-icon-button me-1"
              onClick={() => console.log(value, tableMeta)}
            >
              <i className="fa fa-thin fa-pencil"></i>
            </button>
          );
        },
      },
    },
  ];
  const in_active_columns = [
    {
      label: "Status",
      name: "checkbox",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div class="checkbox-rect">
              <input type="checkbox" id="with_sar" name="check" />
              <label for="with_sar" className="h6"></label>
            </div>
          );
        },
      },
    },
    {
      name: "registered",
      label: "registered",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "agent_group",
      label: "Agent Group",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "office_id",
      label: "Office id",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "office_name",
      label: "Office name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "manager",
      label: "Office manager",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "location",
      label: "location",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "email",
      label: "email",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "office_phone",
      label: "office phone",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "emergency_phone",
      label: "emergency phone",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "credit_limit",
      label: "credit limit",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "Action",
      name: "delete",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <button
              className="btn-success rounded edit-delete-icon-button me-1"
              onClick={() => console.log(value, tableMeta)}
            >
              <i className="fa fa-thin fa-pencil"></i>
            </button>
          );
        },
      },
    },
  ];
  const pending_columns = [
    // {
    //   label: "Status",
    //   name: "checkbox",
    //   options: {
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return (
    //         <div class="checkbox-rect">
    //           <input type="checkbox" id="with_sar" name="check" />
    //           <label for="with_sar" className="h6"></label>
    //         </div>
    //       );
    //     },
    //   },
    // },
    {
      name: "registered",
      label: "registered",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "agent_group",
      label: "Agent Group",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "office_id",
      label: "Office id",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "office_name",
      label: "Office name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "manager",
      label: "Office manager",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "location",
      label: "location",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "email",
      label: "email",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "office_phone",
      label: "office phone",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "emergency_phone",
      label: "emergency phone",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "credit_limit",
      label: "credit limit",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "Action",
      name: "delete",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="">
              <button
                className="btn-success rounded edit-delete-icon-button me-1"
                onClick={() => console.log(value, tableMeta)}
              >
                <i className="fa fa-thin fa-pencil"></i>
              </button>
              <button
                className="btn-danger rounded edit-delete-icon-button"
                onClick={() => {
                  setShowDeleteModal(true);
                  setModalData(tableMeta.rowData);
                }}
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
              </button>
            </div>
          );
        },
      },
    },
  ];

  const data = [
    {
      id: 1,
      registered: "",
      agent_group: "",
      office_id: "",
      office_type: "",
      office_name: "",
      manager: "",
      location: "",
      email: "",
      office_phone: "",
      emergency_phone: "",
      credit_type: "",
      credit_limit: "",
    },
  ];

  const options = {
    filter: false,
    Selection: false,
    print: false,
    download: false,
    viewColumns: false,
    displayRowCheckbox: false,
    selectableRows: "none",
    // onRowClick: (rowData) => {
    //   navigate(`/booking-details/${rowData[3]}/active`)
    // },
  };
  return (
    <div className="col-12 rounded-2 shadow my-5 pb-5 flash-news">
      <div className="agents-container">
        <div className="agents-navigation-tabs">
          <div
            className={`h5  ${
              tabs === "active" ? "active-link" : "agents_details_links"
            }`}
            onClick={() => setTabs("active")}
          >
            Active
          </div>
          <div
            className={`h5  ${
              tabs === "inactive" ? "active-link" : "agents_details_links"
            }`}
            onClick={() => setTabs("inactive")}
          >
            Inactive
          </div>
          <div
            className={`h5  ${
              tabs === "pending" ? "active-link" : "agents_details_links"
            }`}
            onClick={() => setTabs("pending")}
          >
            Pending
          </div>
        </div>
        <DeleteModal
          show={showDeleteModal}
          setShow={setShowDeleteModal}
          rowId={modalData && modalData[0]}
        />
        {tabs === "active" && (
          <div className="col-12 mt-5">
            <MUIDataTable
              className="muidatatable"
              title={"Agents Details"}
              data={data}
              columns={active_columns}
              options={options}
            />
          </div>
        )}
        {tabs === "inactive" && (
          <div className="col-12 mt-5">
            <MUIDataTable
              className="muidatatable"
              title={"Agents Details"}
              data={data}
              columns={in_active_columns}
              options={options}
            />
          </div>
        )}
        {tabs === "pending" && (
          <div className="col-12 mt-5">
            <MUIDataTable
              className="muidatatable"
              title={"Agents Details"}
              data={data}
              columns={pending_columns}
              options={options}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Agents;
