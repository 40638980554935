import { Button, TextField } from "@mui/material";
import { Modal } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import CustomDropdown from "../../CustomDrp";

const AddEditModal = ({ show, setShow }) => {

    return (
        <Modal
            show={show}
            onHide={(e) => setShow(false)}
            centered
            size="xl"
        >
            <Modal.Header>
                <Modal.Title>Add or Edit SSR Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className="px-md-5">
                    <div className="row my-4">
                        <div className="col-3">
                            <CustomDropdown arry={['Issue', 'Re-issue', 'Change']} placehlder='Passport' />
                        </div>
                        <div className="col-3">
                            <TextField variant="standard" label='SSR Version' className="w-100" />
                        </div>
                    </div>
                    <hr className="my-5" />
                    <div className="row my-4 align-items-end">
                        <div className="col-3">
                            <TextField variant="standard" label='Passenger F-Name' className="w-100" />
                        </div>
                        <div className="col-3">
                            <TextField variant="standard" label='Passenger L-Name' className="w-100" />
                        </div>
                        <div className="col-3">
                            <CustomDropdown arry={['Issue', 'Re-issue', 'Change']} placehlder='Passport' />
                        </div>
                        <div className="col-3">
                            <TextField variant="standard" label='Passenger Number' className="w-100" />
                        </div>
                        <div className="col-3 my-3">
                            <ReactDatePicker
                                placeholderText="DOB"
                                // selected={startDate}
                                // onChange={(date) => {
                                //     setStartDate(date);
                                // }}
                                monthsShown={1}
                                // selected={new Date()}
                                // onCalendarClose={handleCalendarClose}
                                minDate={new Date()}
                                // onCalendarOpen={handleCalendarOpen}
                                className="date_picker"
                            />
                        </div>
                        <div className="col-3 my-3">
                            <ReactDatePicker
                                placeholderText="Expiration Date"
                                // selected={startDate}
                                // onChange={(date) => {
                                //     setStartDate(date);
                                // }}
                                monthsShown={1}
                                // selected={new Date()}
                                // onCalendarClose={handleCalendarClose}
                                minDate={new Date()}
                                // onCalendarOpen={handleCalendarOpen}
                                className="date_picker"
                            />
                        </div>
                        <div className="col-3 my-3">
                            <CustomDropdown arry={['Issue', 'Re-issue', 'Change']} placehlder='Nationality' />
                        </div>
                        <div className="col-3 my-3">
                            <CustomDropdown arry={['Issue', 'Re-issue', 'Change']} placehlder='Issue Country' />
                        </div>
                    </div>

                    <div className="d-flex justify-content-end align-items-end ">
                        <div className="me-2">
                            <Button
                                variant="contained"
                                onClick={(e) => setShow(false)}
                                color='error'
                            >
                                Cancel
                            </Button>
                        </div>
                        <div className="">
                            <Button
                                variant="contained"
                                onClick={(e) => setShow(false)}
                            >
                                Add | Edit
                            </Button>
                        </div>
                    </div>

                </div>

            </Modal.Body>
        </Modal>
    );
}

export default AddEditModal;