import React from 'react'
import Header from '../components/Common/Header'
import PaidVoucher from '../components/Setup/Payments/PaidVoucher'
import Footer from '../components/Footer'

const AdminPaidVoucher = () => {
  return (
    <div className='Full_height'>
      <Header active="collection" />
      <PaidVoucher />
      <Footer />
    </div>
  )
}

export default AdminPaidVoucher