import React from 'react'
import Header from '../components/Common/Header'
import AgencyDocument from "../components/Agency/AgencyDocument";
import Footer from '../components/Footer'

const Document = () => {
  return (
    <div className='Full_height'>
      <Header active="setup" />
      <AgencyDocument />
      <Footer />
    </div>
  )
}

export default Document