import React, { useState } from 'react'
import Header from "../components/LandingPage/Header"

const Register = () => {
    const [error, setError] = useState({
        session: "",
    });
    const [counteries, setCountries] = useState(["Pakistan", "Afghanistan", "Iran", "UAE"])
  return (
    <div className='Full_height'>
    <Header />
    <div className="row">
    {/* <!-- Content area --> */}
        <div className="col-md-8 mx-auto">
    {/*<!-- Registration form -->*/}
            <form>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="mb-0">
                            <div className="card-header">
                                <h4>B2B Registration</h4>
                                <span className="hr-hieght">You will get the confirmation of your account activation soon as well as news about our products.<hr className="hrdash" /></span>
                            </div>
                            <div className="card-body">
                                {error.session && <div className="alert alert-warning" role="alert">
                                    {error.session}
                                </div>}
                                <div className='row'>
                                <div className='col-md-6'>
                                <h4 className="hr-hieght">Company Info <hr className="hrdash" /></h4>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="font-weight-semibold">Office Name</label>
                                        <div className="form-group form-group-feedback form-group-feedback-right">
                                            <input type="text" className='form-control' required placeholder='Character limit 25'/>
                                            <div className="form-control-feedback">
                                                <i className="icon-office text-muted"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label className="font-weight-semibold">Official Name (Trading)<span className="text-danger">*</span></label>
                                        <div className="form-group form-group-feedback form-group-feedback-right">
                                            <input type="text" name="company[compnay_name]" className="form-control" placeholder='Character limit 25' required />
                                            <div className="form-control-feedback">
                                                <i className="icon-office text-muted"></i>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="row">

                                    <div className="col-md-6">
                                        <label className="font-weight-semibold">Registration number</label>
                                        <div className="form-group form-group-feedback form-group-feedback-right">
                                            <input type="text" name="company[reg_number]" className="form-control"/>
                                            <div className="form-control-feedback">
                                                <i className="icon-pencil4 text-muted"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label className="font-weight-semibold">Tax / TRN number </label>
                                        <div className="form-group form-group-feedback form-group-feedback-right">
                                            <input type="text" name="company[tax_number]" className="form-control" />
                                            <div className="form-control-feedback">
                                                <i className="icon-pencil4 text-muted"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                <div className="col-md-6">
                                <label className="font-weight-semibold">Country <span className="text-danger">*</span></label>
                                <div className="form-group form-group-feedback form-group-feedback-right">
                                <select className="form-control" required>
                                    <option value="">- select country -</option>
                                    {counteries.length && counteries.map ((country, key) => <option value={country} key={key}>{country}</option>)}
                                </select>
                            </div>
                            </div>
                            <div className="col-md-6">
                                        <label className="font-weight-semibold">City <span className="text-danger">*</span></label>
                                        <div className="form-group form-group-feedback form-group-feedback-right">
                                            <input type="text" name="company_address[city]" className="form-control" required />
                                            <div className="form-control-feedback">
                                                <i className="icon-city text-muted"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="font-weight-semibold">Zip code</label>
                                        <div className="form-group form-group-feedback form-group-feedback-right">
                                            <input type="text" name="company_address[zip]" className="form-control"/>
                                            <div className="form-control-feedback">
                                                <i className="icon-price-tag3 text-muted"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label className="font-weight-semibold">Address <span className="text-danger">*</span></label>
                                        <div className="form-group form-group-feedback form-group-feedback-right">
                                            <input type="text" name="company_address[address]" className="form-control" required />
                                            <div className="form-control-feedback">
                                                <i className="icon-location4 text-muted"></i>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="row">

                                    
                                    <div className="col-md-6">
                                        <label className="font-weight-semibold">Company url </label>
                                        <div className="form-group form-group-feedback form-group-feedback-right">
                                            <input type="text" name="company_address[company_url]" className="form-control" />
                                            <div className="form-control-feedback">
                                                <i className="icon-cloud text-muted"></i>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <label className="font-weight-semibold">Official email <span className="text-danger">*</span></label>
                                        <div className="form-group form-group-feedback form-group-feedback-right">
                                            <input type="email" name="office_email" className="form-control" required />
                                           
                                            
                                                {error.invalidFeedback && <span className="invalid-feedback" role="alert">
                                                    <strong>{error.invalidFeedback}</strong>
                                                </span>}
                                        

                                            <div className="form-control-feedback">
                                                <i className="icon-mention text-muted"></i>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                                <div className="row">

                                    <div className="col-md-6">
                                        <label className="font-weight-semibold">Office Number <span className="text-danger">*</span></label>
                                        <div className="form-group form-group-feedback form-group-feedback-right">
                                            <input type="text" name="company_address[phone]" className="form-control" placeholder='10 digits no special character' required />
                                            <div className="form-control-feedback">
                                                <i className="icon-phone2 text-muted"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label className="font-weight-semibold">Emergency phone number <span className="text-danger">*</span></label>
                                        <div className="form-group form-group-feedback form-group-feedback-right">
                                            <input type="text" name="company_address[emg_phone]" placeholder='10 digits no special character' className="form-control" required />
                                            <div className="form-control-feedback">
                                                <i className="icon-phone2 text-muted"></i>
                                            </div>
                                        </div>
                                    </div>

                                </div></div>
                                <div className='col-md-6'>
                                <h4 className="hr-hieght">Contact Person <hr className="hrdash" /></h4>

                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="font-weight-semibold">Title <span className="text-danger">*</span></label>
                                        <div className="form-group form-group-feedback form-group-feedback-right">
                                            <select id="title" className="form-control select" value="MR" required>
                                            <option value="MR">MR</option>
                                            <option value="MS">MS</option>
                                            <option value="MRS">MRS</option>       
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label className="font-weight-semibold">First name <span className="text-danger">*</span></label>
                                        <div className="form-group form-group-feedback form-group-feedback-right">
                                            <input type="text" name="company_contact[fname]" className="form-control" required />
                                            <div className="form-control-feedback">
                                                <i className="icon-user-check text-muted"></i>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    </div>
                                    <div className="row">
                                    <div className="col-md-6">
                                        <label className="font-weight-semibold">Last name <span className="text-danger">*</span></label>
                                        <div className="form-group form-group-feedback form-group-feedback-right">
                                            <input type="text" name="company_contact[lname]" className="form-control" required />
                                            <div className="form-control-feedback">
                                                <i className="icon-user-check text-muted"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label className="font-weight-semibold">E-mail <small className="font-weight-semibold">(Your Login ID)</small> <span className="text-danger">*</span></label>
                                        <div className="form-group form-group-feedback form-group-feedback-right">
                                            <input type="email" name="email" className="form-control" required />
                                                {error.email && <span className="invalid-feedback" role="alert">
                                                    <strong>{error.email}</strong>
                                                </span>}
                                            
    
                                            <div className="form-control-feedback">
                                                <i className="icon-mention text-muted"></i>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                
                                <div className="row">

                                    <div className="col-md-6">
                                        <label className="font-weight-semibold">Phone</label>
                                        <div className="form-group form-group-feedback form-group-feedback-right">
                                            <input type="number" name="company_contact[phone]" className="form-control" />
                                            <div className="form-control-feedback">
                                                <i className="icon-phone2 text-muted"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label className="font-weight-semibold">Mobile <span className="text-danger">*</span></label>
                                        <div className="form-group form-group-feedback form-group-feedback-right">
                                            <input type="text" name="company_contact[mobile]" className="form-control" required />
                                            <div className="form-control-feedback">
                                                <i className="icon-phone2 text-muted"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">

                                    <div className="col-md-6">
                                        <label className="font-weight-semibold">Position</label>
                                        <div className="form-group form-group-feedback form-group-feedback-right">
                                            <input type="text" name="company_contact[position]" className="form-control" />
                                            <div className="form-control-feedback">
                                                <i className="icon-pin text-muted"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label className="font-weight-semibold">Skype id </label>
                                        <div className="form-group form-group-feedback form-group-feedback-right">
                                            <input type="text" name="company_contact[skype_id]" className="form-control" />
                                            <div className="form-control-feedback">
                                                <i className="icon-skype text-muted"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="font-weight-semibold">Password <span className="text-danger">*</span></label>
                                        <div className="form-group form-group-feedback form-group-feedback-right">
                                            <input id="password" type="password" className="form-control" name="password" required />
                                                {error.password && <span className="invalid-feedback" role="alert">
                                                    <strong>{error.password}</strong>
                                                </span>}

                                            <div className="form-control-feedback">
                                                <i className="icon-user-lock text-muted"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label className="font-weight-semibold">Repeat Password <span className="text-danger">*</span></label>
                                        <div className="form-group form-group-feedback form-group-feedback-right">
                                            <input id="password-confirm" type="password" className="form-control" name="password_confirmation" />

                                            <div className="form-control-feedback">
                                                <i className="icon-user-lock text-muted"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-4">
                                <div className="col-md-6"><button type="submit" className="btn btn-info float-right btn-labeled-right col-md-12"><b><i className="icon-plus3"></i></b> Create account</button>&nbsp;</div>
                                <div className="col-md-6"><button type="submit" className="btn btn-info float-right btn-labeled-right col-md-12"><b><i className="icon-plus3"></i></b> back</button>&nbsp;</div>
                                </div>
                                
                            </div>
                                </div>
                            </div>
                            </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    </div>
  )
}

export default Register