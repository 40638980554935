import React from 'react';
import Header from '../components/Common/Header'
import RoleComp from '../components/Setup/Role'
import Footer from '../components/Footer'

const Role = () => {
  return (
    <div className='Full_height'>
      <Header active="setup" />
      <RoleComp />
      <Footer />
    </div>
  )
}

export default Role