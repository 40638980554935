import React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

const Groups = () => {
  return (
    <div className="col-12 rounded-2 shadow mt-5 pb-5 flash-news">
      <p className="h4 otawix-heading">Flight Group Promotions</p>
      <div className="col-12 px-3 mt-5 d-flex flex-wrap justify-content-between">
        <Card>
          <Card.Body>
            <Card.Text>
              <div className="mb-5 text-center">
                <p style={{lineHeight: "1px"}} className="mt-3">ER 123 LHE JED 20 Oct 2022 03:15 PM 20KG</p>
                <p style={{lineHeight: "1px"}}>ER 123 LHE JED 20 Oct 2022 03:15 PM 20KG</p>
                <p style={{lineHeight: "1px"}}>ER 123 LHE JED 20 Oct 2022 03:15 PM 20KG</p>
                <p style={{lineHeight: "1px"}}>ER 123 LHE JED 20 Oct 2022 03:15 PM 20KG</p>
                <p className="my-5"><b>Group Name: LHEJED-20OCT-ERABCD</b></p>
                <p>15 SEATS PKR 50000/Adult</p>
              </div>
              <div className="pt-2 col-12">
              <Button variant="primary col-12">Select</Button>
              </div>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Card.Text>
              <div className="mb-5 text-center">
                <p style={{lineHeight: "1px"}} className="mt-3">ER 123 LHE JED 20 Oct 2022 03:15 PM 20KG</p>
                <p style={{lineHeight: "1px"}}>ER 123 LHE JED 20 Oct 2022 03:15 PM 20KG</p>
                <p style={{lineHeight: "1px"}}>ER 123 LHE JED 20 Oct 2022 03:15 PM 20KG</p>
                <p style={{lineHeight: "1px"}}>ER 123 LHE JED 20 Oct 2022 03:15 PM 20KG</p>
                <p className="my-5"><b>Group Name: LHEJED-20OCT-ERABCD</b></p>
                <p>15 SEATS PKR 50000/Adult</p>
              </div>
              <div className="pt-2 col-12">
              <Button variant="primary col-12">Select</Button>
              </div>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Card.Text>
              <div className="mb-5 text-center">
                <p style={{lineHeight: "1px"}} className="mt-3">ER 123 LHE JED 20 Oct 2022 03:15 PM 20KG</p>
                <p style={{lineHeight: "1px"}}>ER 123 LHE JED 20 Oct 2022 03:15 PM 20KG</p>
                <p style={{lineHeight: "1px"}}>ER 123 LHE JED 20 Oct 2022 03:15 PM 20KG</p>
                <p style={{lineHeight: "1px"}}>ER 123 LHE JED 20 Oct 2022 03:15 PM 20KG</p>
                <p className="my-5"><b>Group Name: LHEJED-20OCT-ERABCD</b></p>
                <p>15 SEATS PKR 50000/Adult</p>
              </div>
              <div className="pt-2 col-12">
              <Button variant="primary col-12">Select</Button>
              </div>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Card.Text>
              <div className="mb-5 text-center">
                <p style={{lineHeight: "1px"}} className="mt-3">ER 123 LHE JED 20 Oct 2022 03:15 PM 20KG</p>
                <p style={{lineHeight: "1px"}}>ER 123 LHE JED 20 Oct 2022 03:15 PM 20KG</p>
                <p style={{lineHeight: "1px"}}>ER 123 LHE JED 20 Oct 2022 03:15 PM 20KG</p>
                <p style={{lineHeight: "1px"}}>ER 123 LHE JED 20 Oct 2022 03:15 PM 20KG</p>
                <p className="my-5"><b>Group Name: LHEJED-20OCT-ERABCD</b></p>
                <p>15 SEATS PKR 50000/Adult</p>
              </div>
              <div className="pt-2 col-12">
              <Button variant="primary col-12">Select</Button>
              </div>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Card.Text>
              <div className="mb-5 text-center">
                <p style={{lineHeight: "1px"}} className="mt-3">ER 123 LHE JED 20 Oct 2022 03:15 PM 20KG</p>
                <p style={{lineHeight: "1px"}}>ER 123 LHE JED 20 Oct 2022 03:15 PM 20KG</p>
                <p style={{lineHeight: "1px"}}>ER 123 LHE JED 20 Oct 2022 03:15 PM 20KG</p>
                <p style={{lineHeight: "1px"}}>ER 123 LHE JED 20 Oct 2022 03:15 PM 20KG</p>
                <p className="my-5"><b>Group Name: LHEJED-20OCT-ERABCD</b></p>
                <p>15 SEATS PKR 50000/Adult</p>
              </div>
              <div className="pt-2 col-12">
              <Button variant="primary col-12">Select</Button>
              </div>
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default Groups;
