import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import { Modal, Button } from "react-bootstrap";
import { TextField } from "@mui/material";
import CustomDropdown from "../CustomDrp";

const User = () => {
  const [ShowModel, setShowModel] = useState(false);
  const [rowData, setRowData] = useState({});
  const columns = [
    {
      label: "Status",
      name: "id",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div class="checkbox-rect">
              <input type="checkbox" id="with_sar" name="check" />
              <label for="with_sar" className="h6"></label>
            </div>
          );
        },
      },
    },
    {
      name: "name",
      label: "name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "access_level",
      label: "Access Level",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "skype",
      label: "Skype",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "created_date",
      label: "Created Date",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "last_signin",
      label: "Last Signin",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "signin_count",
      label: "Signin",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "created_by",
      label: "Created By",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "Action",
      name: "checkbox",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <button
              className="btn-success rounded edit-delete-icon-button"
              onClick={() => {
                setShowModel(true);
                setRowData(data[tableMeta.rowIndex]);
              }}
            >
              <i className="fa fa-thin fa-pencil"></i>
            </button>
          );
        },
      },
    },
  ];

  const data = [
    {
      id: 1,
      name: "UAE",
      access_level: "",
      email: "",
      skype: "",
      created_date: "",
      last_signin: "",
      signin_count: "",
      created_by: "",
    },
  ];
  const options = {
    filter: false,
    Selection: false,
    print: false,
    download: false,
    viewColumns: false,
    displayRowCheckbox: false,
    selectableRows: "none",
  };
  return (
    <div className="col-12 mx-auto rounded-2 shadow mt-5 pb-5 flash-news">
      {ShowModel && (
        <SupplierModel setShowModel={setShowModel} rowData={rowData} />
      )}
      <p className="h4 otawix-heading">New Company User</p>
      <div className="col-12 px-4 d-flex justify-content-end my-4">
        <button
          className="setup-btn"
          onClick={(e) => {
            setShowModel(true);
          }}
        >
          Add New User <i class="fa fa-thin fa-plus"></i>
        </button>
      </div>
      <div className="col-12 mt-2 px-4">
        <MUIDataTable
          className="muidatatable"
          title={"Users Details"}
          data={data}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  );
};

function SupplierModel({ setShowModel, rowData }) {
  console.log(rowData)
  return (
    <div className="backdrop_blur_cotianer">
      <div
        className="d-flex justify-content-center align-items-center rounded-3"
        style={{ height: "100vh" }}
      >
        <div className="model_body">
          {/* *************** */}
          <p className="otawix-heading h4">
            {rowData?.id ? "Update Company User" : "New Company User"}
          </p>

          {/* *************************** */}
          <div className="p-4 d-flex">
            <div className="col-2 px-3">
              <CustomDropdown
                arry={["Visa", "Flight", "Insurance", "Hotel"]}
                placehlder="Title"
              />
            </div>
            <div className="col-5 px-3">
              <TextField
                label="First Name"
                variant="standard"
                className="w-100"
              />
            </div>
            <div className="col-5 px-3">
              <TextField
                label="Last Name"
                variant="standard"
                className="w-100"
              />
            </div>
          </div>
          <div className="p-4 d-flex">
            <div className="col-6 px-3">
              <TextField label="Email" variant="standard" className="w-100" />
            </div>
            <div className="col-6 px-3">
              <CustomDropdown
                arry={["Visa", "Flight", "Insurance", "Hotel"]}
                placehlder="User Access Level"
              />
            </div>
          </div>
          <div className="p-4 d-flex">
            <div className="col-6 px-3">
              <TextField
                label="Password"
                variant="standard"
                className="w-100"
              />
            </div>
            <div className="col-6 px-3">
              <TextField
                label="Repeat Password"
                variant="standard"
                className="w-100"
              />
            </div>
          </div>
          <div className="p-4 d-flex">
            <div className="col-4 px-3">
              <TextField
                label="Mobile"
                variant="standard"
                className="w-100"
              />
            </div>
            <div className="col-4 px-3">
              <TextField
                label="Phone"
                variant="standard"
                className="w-100"
              />
            </div>
            <div className="col-4 px-3">
              <TextField
                label="Skype ID"
                variant="standard"
                className="w-100"
              />
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-end my-3 pb-2">
          <div className="me-2">
            <Button
              variant="danger modal-button"
              onClick={(e) => setShowModel(false)}
              style={{ background: "#dc3545", color: "#fff" }}
            >
              Close
            </Button>
          </div>
          <div className="">
            {rowData?.id ? 
              <Button
              variant="primary modal-button"
              onClick={(e) => setShowModel(false)}
              style={{ background: "#0d6efd", color: "#fff" }}
            >
              Update
            </Button>
             :<Button
              variant="primary modal-button"
              onClick={(e) => setShowModel(false)}
              style={{ background: "#0d6efd", color: "#fff" }}
            >
              Save
            </Button>}
          </div>
        </div>
        </div>
      </div>
    </div>
  );
}
export default User;
