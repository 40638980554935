import React from 'react';
import Header from '../components/Common/Header'
import Corporate from '../components/Setup/Payments/CorporatePayment'
import Footer from '../components/Footer'

const CorporatePayment = () => {
  return (
    <div className='Full_height'>
      <Header active="setup" />
      <Corporate />
      <Footer />
    </div>
  )
}

export default CorporatePayment