import React from 'react'
import Header from '../components/Common/Header'
import AddSliderText from '../components/Setup/Promotions/AddSliderText'
import Footer from '../components/Footer'

const FlashNews = () => {
  return (
    <div className='Full_height'>
      <Header active="setup" />
      <AddSliderText />
      <Footer />
    </div>
  )
}

export default FlashNews