import React from 'react'
import Header from '../components/Common/Header'
import CorporateRegistrationComp from '../components/Setup/Registration/CorporateRegistration'
import Footer from '../components/Footer'

const CorporateRegistration = () => {
  return (
    <div className='Full_height'>
    <Header active="setup"/>
    <CorporateRegistrationComp />
    <Footer />
    </div>
  )
}

export default CorporateRegistration