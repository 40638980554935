import React, { useState } from "react";
import CustomDropdown from "../CustomDrp";

const VisaTab = () => {
  const [countries, setCountries] = useState([
    "Dubai",
    "Saudia",
    "Turkey",
    "Thai Visa",
  ]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [multiRows, setMultiRows] = useState([]);

  const addRow = () => {
    const newvalue = multiRows[multiRows.length - 1];
    setMultiRows([...multiRows, newvalue ? newvalue + 1 : 1]);
  };
  const removeRow = () => {
    let rowsArray = multiRows;
    rowsArray.pop();
    setMultiRows([...rowsArray]);
  };

  return (
    <div className="my-3">
      <div className="row align-items-end">
        <div className="col-sm-3">
          <CustomDropdown arry={countries} placehlder='Select Country' initvalue={selectedCountry} setfunction={setSelectedCountry} />
          {/* <select
            className="col-sm-11 inputs_flight_tab bg-white w-100"
            value={selectedCountry}
            onChange={(e) => setSelectedCountry(e.target.value)}
          >
            <option value="">--Select Country--</option>
            {countries.map((country) => (
              <option value={country}>{country}</option>
            ))}
          </select> */}
        </div>
        {selectedCountry && (
          <div className="col-sm-3">
            <input
              type="text"
              className="col-sm-11 inputs_visa_tab h6"
              placeholder="Reference No"
            />
          </div>
        )}
        {selectedCountry && (
          <div className="col-sm-3">
            <input
              type="text"
              className="col-sm-11 inputs_visa_tab h6"
              placeholder="Please Select a Company"
            />
          </div>
        )}
      </div>
      {selectedCountry && (
        <>
          <h5 className="mt-5">Visa Type</h5>
          <hr />
          <div className="d-flex justify-content-start align-items-start mt-5 ">
            <label class="form-control me-3 me-md-5">
              <input type="radio" name="radio" />
              <span className="">30 days single entry</span>
            </label>
            <label class="form-control me-3 me-md-5">
              <input type="radio" name="radio" />
              <span className="">Short Stay</span>
            </label>
            <label class="form-control">
              <input type="radio" name="radio" />
              <span className="">Short Stay</span>
            </label>
          </div>
          <h5 className="mt-5">PAX Details</h5>
          <hr />
          <div className="col-sm-12 fl-right">
            <div className="text-right row">
              <div className="col-sm-8"></div>
              <div className="col-sm-4">
                <button
                  className="btn btn-small btn-primary col-sm-6 border rounded-pill px-1"
                  onClick={addRow}
                >
                  {/*<i className="fa  fa-thin fa-plus"></i>*/} Add Passanger
                </button>
                <button
                  className="btn btn-small btn-danger col-sm-6 border rounded-pill px-1"
                  onClick={(e) => removeRow()}
                >
                  Remove Passanger
                  {/*<i class="fa fa-close"></i>*/}
                </button>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-sm-2">
              <span>Passanger Type*</span>
              <input
                type="text"
                className="inputs_visa_tab mt-3"
                placeholder=""
              />
            </div>
            <div className="col-sm-2">
              <span>Complete Name*</span>{" "}
              <input type="text" className="inputs_visa_tab mt-3" />
            </div>
            <div className="col-sm-2">
              <span>Date Of Birth*</span>{" "}
              <input type="text" className="inputs_visa_tab mt-3" />
            </div>
            <div className="col-sm-2">
              <span>Nationality*</span>{" "}
              <input type="text" className="inputs_visa_tab mt-3" />
            </div>
            <div className="col-sm-2">
              <span>Passport*</span>{" "}
              <input type="text" className="inputs_visa_tab mt-3" />
            </div>
            <div className="col-sm-2">
              <span>Price*</span>{" "}
              <input type="text" className="inputs_visa_tab mt-3" />
            </div>
          </div>
          <div className="row justify-content-between mt-5">
            <div className="col-sm-5 border rounded-2 py-2">
              <p>
                Attachment 1 File Size Not Greater Then 2 MB And Format Must Be
                PDF JPG and PNG *
              </p>
              <input type="file" />
            </div>
            <div className="col-sm-3 border rounded-2 py-2">
              <p>Attachment 2</p>
              <input type="file" />
            </div>
            <div className="col-sm-3 border rounded-2 py-2 px-2">
              <p>Attachment 3</p>
              <input type="file" />
            </div>
          </div>

          {multiRows.map((val) => (
            <>
              <div className="col-sm-12 fl-right mt-5">
                <hr />
              </div>
              <div className="row mt-1">
                <div className="col-sm-2">
                  <span>Passanger Type*</span>
                  <input
                    type="text"
                    className="inputs_visa_tab mt-3"
                    placeholder=""
                  />
                </div>
                <div className="col-sm-2">
                  <span>Complete Name*</span>{" "}
                  <input type="text" className=" inputs_visa_tab mt-3" />
                </div>
                <div className="col-sm-2">
                  <span>Date Of Birth*</span>{" "}
                  <input type="text" className=" inputs_visa_tab mt-3" />
                </div>
                <div className="col-sm-2">
                  <span>Nationality*</span>{" "}
                  <input type="text" className=" inputs_visa_tab mt-3" />
                </div>
                <div className="col-sm-2">
                  <span>Passport*</span>{" "}
                  <input type="text" className=" inputs_visa_tab mt-3" />
                </div>
                <div className="col-sm-2">
                  <span>Price*</span>{" "}
                  <input type="text" className=" inputs_visa_tab mt-3" />
                </div>
              </div>
              <div className="row justify-content-between mt-5">
                <div className="col-sm-5 border rounded-2 py-2">
                  <p>
                    Attachment 1 File Size Not Greater Then 2 MB And Format Must
                    Be PDF JPG and PNG *
                  </p>
                  <input type="file" />
                </div>
                <div className="col-sm-3 border rounded-2 py-2 ">
                  <p>Attachment 2</p>
                  <input type="file" />
                </div>
                <div className="col-sm-3 border rounded-2 py-2 px-2">
                  <p>Attachment 3</p>
                  <input type="file" />
                </div>
              </div>
            </>
          ))}
          <h5 className="mt-5">Requirement</h5>
          <hr />
          <ol>
            <li>PSPT size icture white background</li>
          </ol>

          <h5 className="mt-5">Requirement</h5>
          <hr />
          <ul>
            <li>
              Visa fee once deposited shall NOT BE refunded in case of visa
              application is rejected/denied by the authorities.
            </li>
          </ul>

          <button className="btn btn-small btn-primary my-3 rounded-pill">
            submit
          </button>
        </>
      )}
    </div>
  );
};

export default VisaTab;
