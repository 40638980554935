import { Button, TextField } from "@mui/material";
import React from "react";
import { IoIosClose } from "react-icons/io";
import CustomDropdown from "../../CustomDrp";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";

function AddRoleModel({ setShowModel }) {
  return (
    <div className="backdrop_blur_cotianer">
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="model_body">
          {/* *************** */}
          <p className="otawix-heading h4">Add New Role</p>

          {/* *************************** */}
          <div className="p-4">
            <div className="">
              <TextField
                label="Role Name"
                variant="standard"
                className="w-100"
              />
            </div>

            <div className="d-flex justify-content-center align-items-end pt-5">
              <div className="me-2">
                <Button
                  variant="danger modal-button"
                  onClick={(e) => setShowModel(false)}
                  style={{ background: "#dc3545", color: "#fff" }}
                >
                  Close
                </Button>
              </div>
              <div className="">
                <Button
                  variant="primary modal-button"
                  onClick={(e) => setShowModel(false)}
                  style={{ background: "#0d6efd", color: "#fff" }}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddRoleModel;
