import React, { useEffect, useState } from "react";
const ChildrensAge = ({ childrens }) => {
  const [array, setArray] = useState([]);
  useEffect(() => {
    setArray(
      Array.apply(null, new Array(childrens)).map(function (el, i) {
        return ++i + "";
      })
    );
  }, [childrens]);

  const dropdown = <select className="col-sm-12 child_age_dropdown child_age_dropdown_border">
      <option>2</option>
      <option>3</option>
      <option>4</option>
      <option>5</option>
      <option>6</option>
      <option>7</option>
      <option>8</option>
      <option>9</option>
      <option>10</option>
      <option>11</option>
      <option>12</option>
    </select>;
  // const formdropDown = (values) => {
  //   if (values.length === 1) {
  //     return 
  //   } else {
  //     return <>{values.map(value => <div className="col-sm-6">{Dropdown}</div>)}</>;
  //   }
  // };
  // console.log(formdropDown(array))
  return <div className="row flex-wrap mt-3">
  {array.length === 1 && <div className="col-sm-12">{dropdown}</div>}
  {array.length > 1 && array.map(value => <div className="col-sm-6 mt-1" key={value}>{dropdown}</div>)}
  </div>;
};

export default ChildrensAge;
