import { useEffect } from "react";
import LandingPage from "./pages/LandingPage";
import { Routes, Route } from "react-router-dom";
import Register from "./pages/Register";
import Home from "../src/pages/Home";
import Reservation from "./pages/Reservation";
import Booking from "./pages/Booking";
import BookingDetails from "./pages/BookingDetails";
import Collection from "./pages/Collection";
import Request from "./pages/Request";
import FlightRequest from "./pages/FlightRequest";
import FlightRequestView from "./pages/FlightRequestView";
import Umrah from "./pages/Umrah";
import Groups from "./pages/Groups";
import UmrahDetails from "./pages/UmrahDetails";
import ManualInvoice from "./pages/ManualInvoice";
import AgentAccountStatement from "./pages/AgentAccountStatement";
import DailySaleReport from "./pages/DailySaleReport";
import AgentList from "./pages/AgentList";
import SupplierAccountStatement from "./pages/SupplierAccountStatement";
import SaleReport from "./pages/SaleReport";
import RefundReport from "./pages/RefundReport";
import TransactionalReport from "./pages/TransactionalReport";
import VisaReport from "./pages/VisaReport";
import GroupFlightManifest from "./pages/GroupFlightManifest";
import AirlineReport from "./pages/AirlineReport";
import UmrahMnifestView from "./pages/UmrahManifestView";
import MakePayment from "./pages/MakePayment";
import CorporatePayment from "./pages/CorporatePayment";
import Reciept from "./pages/Reciept";
import AdminUnpaidVoucher from "./pages/AdminUnpaidVoucher";
import AdminPaidVoucher from "./pages/AdminPaidVoucher";
import FlashNews from "./pages/FlashNews";
import Circular from "./pages/Circular";
import Promotions from "./pages/Promotions";
import FlightGroups from "./pages/FlightGroups";
import Countries from "./pages/Countries";
import Supplier from "./pages/Supplier";
import AgentCategories from "./pages/AgentCategories";
import AgentGroups from "./pages/AgentGroups";
import BankDetails from "./pages/BankDetails";
import Currency from "./pages/Currency";
import Role from "./pages/Role";
import Airlines from "./pages/Airlines";
import ResolvedRequests from "./pages/ResolvedRequests";
import SupplierRules from "./pages/SupplierRules";
import Agents from "./pages/Agents";
import Pricing from "./pages/Pricing";
import ApiPassword from "./pages/ApiPassword";
import VisaType from "./pages/VisaType";
import VisaRequirement from "./pages/VisaRequirement";
import VisaTerms from "./pages/VisaTerms";

import Hotel from "./pages/Hotel";
import UmrahTransfer from "./pages/UmrahTransfer";
import UmrahVisaSupplier from "./pages/UmrahVisaSupplier";
import UmrahTransferSupplier from "./pages/UmrahTransferSupplier";
import UmrahHotelRates from "./pages/UmrahHotelRates";

import AgencyRegistration from "./pages/AgencyRegistration";
import CorporateRegistration from "./pages/CorporateRegistration";

import AllVouchers from './components/Finance/Vouchers/AllVoucher'
import VoucherEntry from './components/Finance/Vouchers/VoucherEntry'
import ManualCredit from './components/Finance/Vouchers/ManualCredit'
import InvoiceLogs from './components/Finance/Vouchers/InvoiceLogs'

import ChartOfAccount from './components/Finance/Accounts/ChartOfAccount'
import GournalLedger from './components/Finance/Accounts/JournelLedger'
import TrialBalance from './components/Finance/Accounts/TrialBalance'
import BalanceSheet from './components/Finance/Accounts/BalanceSheet'
import ProfitLose from './components/Finance/Accounts/ProfitLose'
import AsseteStatement from './components/Finance/Accounts/AssestStatement'
import ExpanceStatement from './components/Finance/Accounts/ExpenseStatement'
import BankCash from './components/Finance/Accounts/BankBook/BankCash'
import AddChequeBook from './components/Finance/Accounts/BankBook/AddChequeBook'
import UmrahRoomType from "./pages/UmrahRoomType";
import AgentGroupSubPage from "./components/Setup/AgentGroupSubPage";

import AgencyData from "./pages/FinanceAgency";
import AgencyDocument from "./pages/Document";
import AgencyUsers from "./pages/Users";
import AgencyUserLevel from "./pages/UserLevel";

const App = () => {
  // useEffect(() => {
  //   document.addEventListener('contextmenu', (e) => {
  //     e.preventDefault();
  //   });
  // }, [])
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/register" element={<Register />} />
        <Route path="/home" element={<Home />} />
        <Route path="/reservation" element={<Reservation />} />
        <Route path="/booking" element={<Booking />} />
        <Route path="/booking-details/:booking_id" element={<BookingDetails />} />
        <Route path="/booking-details/:booking_id/:active_status" element={<BookingDetails />} />
        <Route path="/collection" element={<Collection />} />
        <Route path="/requests" element={<Request />} />
        <Route path="/flight-requests" element={<FlightRequest />} />
        <Route path="/flight-requests/:booking_id" element={<FlightRequestView />} />
        <Route path="/umrah-requests" element={<Umrah />} />
        <Route path="/flight-group-list" element={<Groups />} />
        <Route path="/umrah-requests/:booking_id" element={<UmrahDetails />} />
        <Route path="/manual-invoice" element={<ManualInvoice />} />
        <Route path="/agent-account-statement" element={<AgentAccountStatement />} />
        <Route path="/daily-sales-Report" element={<DailySaleReport />} />
        <Route path="/agent-list" element={<AgentList />} />
        <Route path="/supplier-account-statement" element={<SupplierAccountStatement />} />
        <Route path="/sales-report" element={<SaleReport />} />
        <Route path="/refund-report" element={<RefundReport />} />
        <Route path="/transaction-report" element={<TransactionalReport />} />
        <Route path="/visa-report" element={<VisaReport />} />
        <Route path="/group-flight-manifest" element={<GroupFlightManifest />} />
        <Route path="/airline-report" element={<AirlineReport />} />
        <Route path="/umrah-manifest-view" element={<UmrahMnifestView />} />
        <Route path="/make-payment" element={<MakePayment />} />
        <Route path="/corporate-payment" element={<CorporatePayment />} />
        <Route path="/paid-deposits" element={<Reciept />} />
        <Route path="/admin-vouchers" element={<AdminUnpaidVoucher />} />
        <Route path="/admin-paid-vouchers" element={<AdminPaidVoucher />} />
        <Route path="/flash-news" element={<FlashNews />} />
        <Route path="/circular" element={<Circular />} />
        <Route path="/promotions" element={<Promotions />} />
        <Route path="/flight-groups" element={<FlightGroups />} />
        <Route path="/visa-countries" element={<Countries />} />
        <Route path="/visa-type" element={<VisaType />} />
        <Route path="/visa-requirements" element={<VisaRequirement />} />
        <Route path="/visa-terms" element={<VisaTerms />} />

        <Route path="/umrah-hotels" element={<Hotel />} />
        <Route path="/umrah-tranfer" element={<UmrahTransfer />} />
        <Route path="/umrah-visa-supplier" element={<UmrahVisaSupplier />} />
        <Route path="/umrah-transfer-supplier" element={<UmrahTransferSupplier />} />
        <Route path="/room-type" element={<UmrahRoomType />} />
        <Route path="/hotel-rates" element={<UmrahHotelRates />} />

        <Route path="/new-agency-registration" element={<AgencyRegistration />} />
        <Route path="/new-corporate-registration" element={<CorporateRegistration />} />

        <Route path="/supplier" element={<Supplier />} />
        <Route path="/agent-categories" element={<AgentCategories />} />
        <Route path="/agent-groups" element={<AgentGroups />} />
        <Route path="/bank-details" element={<BankDetails />} />
        <Route path="/currency" element={<Currency />} />


        <Route path="/all-vouchers" element={<AllVouchers />} />
        <Route path="/vouchers-entry" element={<VoucherEntry />} />
        <Route path="/manual-credit" element={<ManualCredit />} />
        <Route path="/invoice-logs" element={<InvoiceLogs />} />


        <Route path="/chart-of-account" element={<ChartOfAccount />} />
        <Route path="/gournal-ledger" element={<GournalLedger />} />
        <Route path="/trial-balance" element={<TrialBalance />} />
        <Route path="/balance-sheet" element={<BalanceSheet />} />
        <Route path="/profit-and-lose" element={<ProfitLose />} />
        <Route path="/asset-statement" element={<AsseteStatement />} />
        <Route path="/expance-statement" element={<ExpanceStatement />} />
        <Route path="/bank-cash" element={<BankCash />} />
        <Route path="/add-cheque-book" element={<AddChequeBook />} />
        <Route path="/role" element={<Role />} />
        <Route path="/airlines" element={<Airlines />} />
        <Route path="/supplier-rules" element={<SupplierRules />} />
        <Route path="/resolved-requests" element={<ResolvedRequests />} />
        <Route path="/agents" element={<Agents />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/apis-credentials" element={<ApiPassword />} />
        <Route path="/agent-group-form" element={<AgentGroupSubPage />} />



        <Route path="/agency-data" element={<AgencyData />} />
        <Route path="/agency-documents" element={<AgencyDocument />} />
        <Route path="/agency-users" element={<AgencyUsers />} />
        <Route path="/agency-user-level" element={<AgencyUserLevel />} />
      </Routes>
    </div>
  );
};

export default App;
