import React from "react";
import Header from '../components/Common/Header'
import FlightsGroupComp from '../components/Setup/Promotions/FlightsGroup'
import Footer from '../components/Footer'

const FlightGroups = () => {
  return (
    <div className='Full_height'>
      <Header active="setup" />
      <FlightsGroupComp />
      <Footer />
    </div>
  );
};

export default FlightGroups;
