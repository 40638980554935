import React, { useState } from "react";
import Header from "../components/Common/Header";
import FlightsBefore from "../assets/images/flightBefore.jpeg";
import FlightsAfter from "../assets/images/flightsAfter.jpeg";

import ImportBefore from "../assets/images/importBefore.jpeg";
import ImportAfter from "../assets/images/importAfter.jpeg";

import Footer from '../components/Footer'
import HotelBefore from "../assets/images/hotelBefore.jpeg";
import HotelAfter from "../assets/images/hotelAfter.jpeg";

import UmrahBefore from "../assets/images/umrahBefore.jpeg";
import UmrahAfter from "../assets/images/umrahAfter.jpeg";

import VisaBefore from "../assets/images/visaBefore.jpeg";
import VisaAfter from "../assets/images/visaAfter.jpeg";

import InsuranceBefore from "../assets/images/insuranceBefore.jpeg";
import InsuranceAfter from "../assets/images/insuranceAfter.jpeg";
import FlightsTab from "../components/Reservation/FlightsTab";
import ImportTab from "../components/Reservation/ImportTab";
import HotelsTab from "../components/Reservation/HotelsTab";
import UmrahTab from "../components/Reservation/UmrahTab";
import VisaTab from "../components/Reservation/VisaTab";
import InsuranceTab from "../components/Reservation/InsuranceTab";

import "../Styles/Reservation.css";
import "../Styles/radio.css";
import "../Styles/Hotels.css";

const Reservation = () => {
  const [active_tab, set_active_tab] = useState("flights");
  return (
    <div className="Full_height">
        <Header active="reservation" />
        <main className="tabs_main_container" style={{ marginTop: "6rem"}}>
          <div className="tabs_header mx-auto">
            <input
              id="tab1"
              type="radio"
              name="tabs"
              value="flights"
              checked={active_tab === "flights"}
              onChange={(e) => set_active_tab(e.target.value)}
              style={{ visibility: "hidden" }}
            />
            <label for="tab1" id="tabs-label">
              <span>
                <img src={FlightsBefore} className="tabs-image" />
              </span>
              <span>
                <img src={FlightsAfter} className="active-tab-image" />
              </span>
              <p
                className={`${active_tab === "flights" ? "text-primary" : "text-secondary"
                  }`}
              >
                Flights
              </p>
            </label>

            <input
              id="tab2"
              type="radio"
              name="tabs"
              value="import"
              checked={active_tab === "import"}
              onChange={(e) => set_active_tab(e.target.value)}
              style={{ visibility: "hidden" }}
            />
            <label for="tab2" id="tabs-label">
              <span>
                <img src={ImportBefore} className="tabs-image" />
              </span>
              <span>
                <img src={ImportAfter} className="active-tab-image" />
              </span>
              <p
                className={`${active_tab === "import" ? "text-primary" : "text-secondary"
                  }`}
              >
                Import PNR
              </p>
            </label>

            <input
              id="tab3"
              type="radio"
              name="tabs"
              value="hotels"
              checked={active_tab === "hotels"}
              onChange={(e) => set_active_tab(e.target.value)}
              style={{ visibility: "hidden" }}
            />
            <label for="tab3" id="tabs-label">
              <span>
                <img src={HotelBefore} className="tabs-image" />
              </span>
              <span>
                <img src={HotelAfter} className="active-tab-image" />
              </span>
              <p
                className={`${active_tab === "hotels" ? "text-primary" : "text-secondary"
                  }`}
              >
                Hotels
              </p>
            </label>

            <input
              id="tab4"
              type="radio"
              name="tabs"
              value="umrah"
              checked={active_tab === "umrah"}
              onChange={(e) => set_active_tab(e.target.value)}
              style={{ visibility: "hidden" }}
            />
            <label for="tab4" id="tabs-label">
              <span>
                <img src={UmrahBefore} className="tabs-image" />
              </span>
              <span>
                <img src={UmrahAfter} className="active-tab-image" />
              </span>
              <p
                className={`${active_tab === "umrah" ? "text-primary" : "text-secondary"
                  }`}
              >
                Umrah
              </p>
            </label>

            <input
              id="tab5"
              type="radio"
              name="tabs"
              value="visa"
              checked={active_tab === "visa"}
              onChange={(e) => set_active_tab(e.target.value)}
              style={{ visibility: "hidden" }}
            />
            <label for="tab5" id="tabs-label">
              <span>
                <img src={VisaBefore} className="tabs-image" />
              </span>
              <span>
                <img src={VisaAfter} className="active-tab-image" />
              </span>
              <p
                className={`${active_tab === "visa" ? "text-primary" : "text-secondary"
                  }`}
              >
                Visa
              </p>
            </label>

            <input
              id="tab6"
              type="radio"
              name="tabs"
              value="insurance"
              checked={active_tab === "insurance"}
              onChange={(e) => set_active_tab(e.target.value)}
              style={{ visibility: "hidden" }}
            />
            <label for="tab6" id="tabs-label">
              <span>
                <img src={InsuranceBefore} className="tabs-image" />
              </span>
              <span>
                <img src={InsuranceAfter} className="active-tab-image" />
              </span>
              <p
                className={`${active_tab === "insurance" ? "text-primary" : "text-secondary"
                  }`}
              >
                Insurance
              </p>
            </label>
          </div>

          <div>
            {active_tab === "flights" && <FlightsTab />}
            {active_tab === "import" && <ImportTab />}
            {active_tab === "hotels" && <HotelsTab />}
            {active_tab === "umrah" && <UmrahTab />}
            {active_tab === "visa" && <VisaTab />}
            {active_tab === "insurance" && <InsuranceTab />}
          </div>
        </main>
        <div className="my-5" style={{marginTop: "5rem", height: "50px"}}>.</div>
        <Footer />
      </div>
  );
};

export default Reservation;
