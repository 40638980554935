import React from 'react'
import Header from '../components/Common/Header'
import PasswordComp from '../components/Setup/Password'
import Footer from '../components/Footer';

const ApiPassword = () => {
  return (
    <div className='Full_height'>
      <Header active="setup" />
      <PasswordComp />
      <Footer />
    </div>
  )
}

export default ApiPassword