import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ChildrensAge from "./FlightsTab/ChildrensAge";
import TravelersDropDown from "./FlightsTab/TravelersDropDown";
import Countries from "./flyingzo_b2b.json";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import Airblue from "../../assets/images/Airblue.jpeg";
import Airsial from "../../assets/images/Airsial.jpeg";
import Serene from "../../assets/images/Serene.jpeg";
import PIA from "../../assets/images/PIA.jpeg";
import Travelport from "../../assets/images/Travelport.jpeg";
import Sabre from "../../assets/images/Sabre.jpeg";
import Flydubai from "../../assets/images/Flydubai.jpeg";
import CountriesDropdown from "./FlightsTab/CountriesDropdown";
import { Button, TextField } from "@mui/material";

const FlightsTab = () => {
  const [startDate, setStartDate] = useState("");
  const [filtered_countries, set_filtered_countries] = useState({
    by_code: [],
    by_start_char: [],
    by_city_name: [],
    by_airport_name: [],
  });
  const [endDate, setEndDate] = useState("");
  const [data, setData] = useState({
    allselect: false,
    adults: 1,
    child: 0,
    infant: 0,
    trip: "round_trip",
  });
  const [multiRows, setMultiRows] = useState([]);

  const addRow = () => {
    const newvalue = multiRows[multiRows.length - 1];
    setMultiRows([...multiRows, newvalue ? newvalue + 1 : 1]);
  };

  const removeRow = (value) => {
    setMultiRows(multiRows.filter((val) => val !== value));
  };
  return (
    <div className="col-12">
      <div className="row">
        <div className="col-12">
          <div className="col-md-7 my-2 mb-5 radio-buttons ">
            <div className="d-flex justify-content-center align-items-center mt-2">
              <label class="form-control me-3 me-md-5">
                <input type="radio"
                  id="round_trip"
                  value="round_trip"
                  name="destinations"
                  checked={data.trip === "round_trip"}
                  onChange={(e) => setData({ ...data, trip: e.target.value })} />
                <span className="h6">Round Trip</span>
              </label>

              <label class="form-control me-3 me-md-5">
                <input type="radio"
                  id="one_way"
                  value="one_way"
                  name="destinations"
                  checked={data.trip === "one_way"}
                  onChange={(e) => setData({ ...data, trip: e.target.value })} />
                <span className="h6">One Way</span>
              </label>

              <label class="form-control me-3 me-md-5">
                <input type="radio" id="multi_destination"
                  value="multi_destination"
                  name="destinations"
                  checked={data.trip === "multi_destination"}
                  onChange={(e) => setData({ ...data, trip: e.target.value })} />
                <span className="h6">Multiple destination</span>
              </label>

            </div>
          </div>
        </div>
        {/* <DirTab /> */}
        <div className=" mt-3">

          <div className="row align-items-end">
            <div className="col-sm-2">
              <TextField
                label="From Where"
                variant="standard"
                className="w-100"
                onAbort={(e) => {
                  const element = document.getElementById("flight-from");
                  element.style.display = "none";
                }}
                onClick={(e) => {
                  const element = document.getElementById("flight-from");
                  console.log(element);
                  if (element.style.display === "none") {
                    element.style.display = "block";
                  } else {
                    element.style.display = "none";
                  }
                }}
                onChange={(e) => {
                  const value = e.target.value.toLocaleLowerCase();
                  let obj = { ...filtered_countries };
                  const countriesList = Countries;
                  let codeCities = [];
                  let byCityFilter = [];
                  let byAirportName = [];
                  countriesList.forEach((c) => {
                    if (c.code.toLocaleLowerCase().includes(e.target.value)) {
                      codeCities.push({
                        ...c,
                        airports: countriesList.filter(
                          (con) => con.city === c.city
                        ),
                      });
                    }
                    if (c.city.toLocaleLowerCase().includes(value)) byCityFilter.push(c)
                    if (c.airport_name.toLocaleLowerCase().includes(value)) byAirportName.push(c)
                    else {
                      obj = {
                        ...obj,
                        by_start_char: Countries.filter((c) => {
                          const airport_char = c.airport_name.slice(
                            0,
                            value.length
                          );
                          return airport_char.toLocaleLowerCase() === value;
                        }),
                      };
                    }
                  });

                  obj = { ...obj, by_code: codeCities, by_city_name: byCityFilter, by_airport_name: byAirportName };
                  set_filtered_countries(obj);
                }}
              />
              <CountriesDropdown
                id="flight-from"
                countriesList={filtered_countries}
              />
            </div>
            <div className="col-md-2">
              <TextField
                label="To Where"
                variant="standard"
                className="w-100"
                onAbort={(e) => {
                  const element = document.getElementById("flight-to");
                  element.style.display = "none";
                }}
                onChange={(e) => {
                  const value = e.target.value.toLocaleLowerCase();
                  let obj = { ...filtered_countries };
                  const countriesList = Countries;
                  let codeCities = [];
                  let byCityFilter = [];
                  let byAirportName = [];
                  countriesList.forEach((c) => {
                    if (c.code.toLocaleLowerCase().includes(e.target.value)) {
                      codeCities.push({
                        ...c,
                        airports: countriesList.filter(
                          (con) => con.city === c.city
                        ),
                      });
                    }
                    if (c.city.toLocaleLowerCase().includes(value)) byCityFilter.push(c)
                    if (c.airport_name.toLocaleLowerCase().includes(value)) byAirportName.push(c)
                    else {
                      obj = {
                        ...obj,
                        by_start_char: Countries.filter((c) => {
                          const airport_char = c.airport_name.slice(
                            0,
                            value.length
                          );
                          return airport_char.toLocaleLowerCase() === value;
                        }),
                      };
                    }
                  });

                  obj = { ...obj, by_code: codeCities, by_city_name: byCityFilter, by_airport_name: byAirportName };
                  set_filtered_countries(obj);
                }}
              />
              <CountriesDropdown
                id="flight-to"
                countriesList={filtered_countries}
              />
            </div>
            {data.trip === "round_trip" && (
              <>
                <div className="col-md-2">
                  <ReactDatePicker
                    placeholderText="Departure Date"
                    className="date_picker"
                    selected={startDate}
                    monthsShown={2}
                    onChange={(date) => {
                      setStartDate(date);
                      setEndDate("");
                    }}
                    // onCalendarClose={handleCalendarClose}
                    minDate={new Date()}
                  // onCalendarOpen={handleCalendarOpen}
                  />
                </div>
                <div className="col-md-2">
                  <ReactDatePicker
                    placeholderText="Arrival Date"
                    className="date_picker"
                    selected={endDate}
                    monthsShown={2}
                    onChange={(date) => setEndDate(date)}
                    // onCalendarClose={handleCalendarClose}
                    minDate={startDate}
                  // onCalendarOpen={handleCalendarOpen}
                  />
                </div>
              </>
            )}
            {(data.trip === "one_way" ||
              data.trip === "multi_destination") && (
                <div className="col-sm-2">
                  <ReactDatePicker
                    placeholderText="Departure Date"
                    monthsShown={2}
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                    }}
                    // onCalendarClose={handleCalendarClose}
                    minDate={new Date()}
                    // onCalendarOpen={handleCalendarOpen}
                    className="date_picker"
                  />
                </div>
              )}
            <div className="btn-group col-sm-2">
              <FormControl variant="standard" className="w-100 " sx={{}}>
                <InputLabel id="demo-simple-select-standard-label">
                  {`${data.child + data.adults} Traveler`}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  label={`${data.child + data.adults} Traveler`}
                  value={""}
                  SelectDisplayProps
                >
                  <MenuItem
                    sx={{ m: 1, bgcolor: "#fff" }}
                    disableRipple
                  >
                    <TravelersDropDown
                      title="adults"
                      values={data}
                      setData={setData}
                    />
                  </MenuItem>
                  <MenuItem sx={{ m: 1, bgcolor: "#fff" }} disableRipple>
                    <div className="d-block">
                      <TravelersDropDown
                        title="child"
                        values={data}
                        setData={setData}
                      />
                      {data.child > 0 && (
                        <ChildrensAge childrens={data.child} />
                      )}
                    </div>
                  </MenuItem>
                  <MenuItem sx={{ m: 1, bgcolor: "#fff" }} disableRipple>
                    <TravelersDropDown
                      title="infant"
                      values={data}
                      setData={setData}
                    />
                  </MenuItem>
                  <MenuItem value="done" sx={{ m: 1, bgcolor: "#fff" }} disableRipple>
                    <button className="btn btn-primary col-12">Done</button>
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="btn-group col-sm-2">
              <button
                className="btn btn-primary rounded-pill"
                type="button"
                name="dropdown-btn"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fa fa-search" aria-hidden="true"></i> Search
              </button>
            </div>
          </div>
        </div>

        {/********************************** */}
        {data.trip === "multi_destination" && (
          <div className="row mt-4 align-items-end">
            <div className="col-md-2">
              <TextField variant="standard" label='From Where' className="w-100" />
            </div>
            <div className="col-md-2">
              <TextField variant="standard" label='To Where' className="w-100" />
            </div>
            {(data.trip === "round_trip" ||
              data.trip === "multi_destination") && (
                <div className="col-md-2">
                  <ReactDatePicker
                    placeholderText="Departure Date"
                    monthsShown={2}
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                    }}
                    // onCalendarClose={handleCalendarClose}
                    minDate={new Date()}
                    // onCalendarOpen={handleCalendarOpen}
                    className="date_picker"
                  />
                </div>
              )}
            {data.trip === "multi_destination" && (
              <div className="col-md-2">
                <Button onClick={(e) => addRow()} variant="contained">Add Flight</Button>

              </div>
            )}
          </div>
        )}

        {data.trip === "multi_destination" &&
          multiRows.map((value) => (
            <div className="row mt-4 align-items-end" key={value}>

              <div className="col-md-2">
                <TextField variant="standard" label='From Where' className="w-100" />
              </div>
              <div className="col-md-2">
                <TextField variant="standard" label='To Where' className="w-100" />
              </div>
              {(data.trip === "round_trip" ||
                data.trip === "multi_destination") && (
                  <div className="col-md-2">
                    <ReactDatePicker
                      placeholderText="Departure Date"
                      monthsShown={2}
                      selected={startDate}
                      onChange={(date) => {
                        setStartDate(date);
                      }}
                      // onCalendarClose={handleCalendarClose}
                      minDate={new Date()}
                      // onCalendarOpen={handleCalendarOpen}
                      className="date_picker"
                    />
                  </div>
                )}
              <div className="col-md-3">
                <Button variant="contained" color="error" onClick={(e) => removeRow(value)}>Remove Flight</Button>

              </div>
            </div>
          ))}

        <div className="col-md-12 my-3">
          <div className="row pt-5 align-items-center">
            <div className="d-flex  align-items-center col-sm-8 mx-auto">
              <div className="me-5 mb-1 checkbox-rect">
                <input
                  type="checkbox"
                  id="umrah_fare"
                  style={{ width: "1rem", height: "1rem", marginRight: "8px" }}
                />
                <label for="umrah_fare">PK Umrah Fare</label>
              </div>

              <div className="checkbox-rect">
                <input
                  type="checkbox"
                  id="all_select"
                  value={data.allselect}
                  onChange={(e) =>
                    setData({
                      ...data,
                      allselect: e.target.checked,
                    })
                  }
                  style={{ width: "1rem", height: "1rem", marginRight: "8px" }}
                />
                <label for="all_select">Select All GDS</label>
              </div>
            </div>
            <div className="row">
              <div className="d-flex justify-content-between col-sm-8 align-items-center mt-5 mx-auto">
                {!data.allselect && (
                  <>
                    <span className="d-flex justify-content-between align-items-center checkbox-rect">
                      <input
                        type="checkbox"
                        id="Airblue"
                        style={{
                          width: "1rem",
                          height: "1rem",
                          marginRight: "8px",
                        }}
                        className="mt-3"
                      />
                      <label for="Airblue">
                        <img src={Airblue} className="flight-icons" />
                      </label>
                    </span>
                    <span className="d-flex justify-content-between align-items-center checkbox-rect">
                      <input
                        type="checkbox"
                        id="Airsial"
                        style={{
                          width: "1rem",
                          height: "1rem",
                          marginRight: "8px",
                        }}
                      />
                      <label for="Airsial">
                        <img src={Airsial} className="flight-icons" />
                      </label>
                    </span>
                    <span className="d-flex justify-content-between align-items-center checkbox-rect">
                      <input
                        type="checkbox"
                        id="Serene"
                        style={{
                          width: "1rem",
                          height: "1rem",
                          marginRight: "8px",
                        }}
                      />
                      <label for="Serene">
                        <img src={Serene} className="flight-icons" />
                      </label>
                    </span>
                    <span className="d-flex justify-content-between align-items-center checkbox-rect">
                      <input
                        type="checkbox"
                        id="Hitit"
                        style={{
                          width: "1rem",
                          height: "1rem",
                          marginRight: "8px",
                        }}
                      />
                      <label for="Hitit">
                        <img src={PIA} className="flight-icons" />
                      </label>
                    </span>
                    <span className="d-flex justify-content-between align-items-center checkbox-rect">
                      <input
                        type="checkbox"
                        id="TravelPort"
                        style={{
                          width: "1rem",
                          height: "1rem",
                          marginRight: "8px",
                        }}
                      />
                      <label for="TravelPort">
                        <img src={Travelport} className="flight-icons" />
                      </label>
                    </span>
                    <span className="d-flex justify-content-between align-items-center checkbox-rect">
                      <input
                        type="checkbox"
                        id="Flydubai"
                        style={{
                          width: "1rem",
                          height: "1rem",
                          marginRight: "8px",
                        }}
                      />
                      <label for="Flydubai">
                        <img src={Flydubai} className="flight-icons" />
                      </label>
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};





const DirTab = () => {
  const [active_tab, setactive_tab] = useState('round_trip')
  return (
    <div className="d-flex justify-content-between align-items-center d-md-none px-3 ">
      <div className={`mobile_tab ${active_tab === 'round_trip' ? 'mobile_tab_active' : ''} `} onClick={e => {
        setactive_tab('round_trip')
      }}>
        ROUND TRIP
      </div>
      <div className={`mobile_tab ${active_tab === 'oneway_trip' ? 'mobile_tab_active' : ''} `}
        onClick={e => {
          setactive_tab('oneway_trip')
        }}>
        ONE WAY
      </div>
      <div className={`mobile_tab ${active_tab === 'multicity_trip' ? 'mobile_tab_active' : ''} `}
        onClick={e => {
          setactive_tab('multicity_trip')
        }}>
        MULTI CITY
      </div>
    </div>
  )
}
export default FlightsTab;
