import React from "react";

function Cards({ bg_color, title, img, counter, preview }) {
  return (
    <div className="px-md-2">
      <div className={`custom-card ${bg_color}`} style={{ background: `${bg_color}` }}>
        <h4 className="card-title  text-white card_title">{title}</h4>
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="text-white h4 mt-3">{counter}</h5>
          <img src={img} className='card-image' />
        </div>
        {
          preview && <div className="text-white text-center mt-2" style={{ cursor: 'pointer' }}>
            Preview All
          </div>
        }

      </div>
    </div>
  );
}

export default Cards;
