import React, { useState } from "react";
import MUIDataTable from "mui-datatables";

function Tabs({ InputGroup, setInputGroup }) {
  return (
    <div className="d-flex justify-content-between align-items-center w-100 progress_bar-register-cont mt-5">
      <div
        className="d-flex flex-column align-items-center pb-3"
        style={{ height: "8rem", width: "", cursor: "pointer" }}
      >
        <div
          className={`progress_bar-register rounded-circle bg-primary text-white px-3 py-2 h4`}
          onClick={() => setInputGroup("passanger")}
        >
          1
        </div>

        <h6 className={`text-primary`}>Passenger Information</h6>
      </div>

      <div
        className="d-flex flex-column align-items-center pb-3"
        style={{ height: "8rem", width: "", cursor: "pointer" }}
      >
        <div
          className={`progress_bar-register rounded-circle ${
            (InputGroup === "flight" || InputGroup === "hotel" || InputGroup === "Transfer")
              ? "bg-primary": "bg-secondary"
              //  && InputGroup === "hotel"
              // ? "bg-primary"
              // : "bg-secondary" && InputGroup === "Transfer"
              // ? "bg-primary"
              // : "bg-secondary"
          } text-white px-3 py-2 h4`}
          onClick={() => setInputGroup("flight")}
        >
          2
        </div>

        <h6
          className={`${
            (InputGroup === "flight" || InputGroup === "hotel" || InputGroup === "Transfer")
              ? "text-primary"
              : "text-secondary" 
              // && InputGroup === "hotel"
              // ? "text-primary"
              // : "text-secondary" && InputGroup === "Transfer"
              // ? "text-primary"
              // : "text-secondary"
          }`}
        >
          Flight Information
        </h6>
      </div>
      <div
        className="d-flex flex-column align-items-center pb-3"
        style={{ height: "8rem", width: "", cursor: "pointer" }}
      >
        <div
          className={`progress_bar-register rounded-circle ${
            (InputGroup === "hotel" || InputGroup === "Transfer")
              ? "bg-primary"
              : "bg-secondary" 
              // && InputGroup === "Transfer"
              // ? "bg-primary"
              // : "bg-secondary"
          } text-white px-3 py-2 h4`}
          onClick={() => setInputGroup("hotel")}
        >
          3
        </div>

        <h6
          className={`${
            (InputGroup === "hotel" || InputGroup === "Transfer")
              ? "text-primary"
              : "text-secondary"
              //  && InputGroup === "Transfer"
              // ? "text-primary"
              // : "text-secondary"
          } `}
        >
          Hotel Information
        </h6>
      </div>
      <div
        className="d-flex flex-column align-items-center pb-3"
        style={{ height: "8rem", width: "", cursor: "pointer" }}
      >
        <div
          className={`progress_bar-register rounded-circle ${
            InputGroup === "Transfer" ? "bg-primary" : "bg-secondary"
          } text-white px-3 py-2 h4`}
          onClick={() => setInputGroup("Transfer")}
        >
          4
        </div>

        <h6
          className={`${
            InputGroup === "Transfer" ? "text-primary" : "text-secondary"
          } `}
        >
          Transfer Information
        </h6>
      </div>
    </div>
  );
}

export default Tabs;
