import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function LogosConteiner() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <div className='mb-5 py-3  custom-logos-container'>
      <div className='row align-items-center py-4'>
        <p className='col-12 col-md-4 text-white text-center text-md-end pe-md-5' style={{ fontSize: '1.5rem' }}>Our Partners</p>
        <div className='col-12 col-md-8 '>
          <Carousel
            autoPlaySpeed={4000}
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            arrows={false}
          >
            {/* card */}
            {
              logos.map(logo => (
                <div key={logo.id} className='logo-div'>
                  <img src={logo.logo} alt=''></img>
                </div>
              ))
            }
          </Carousel>
        </div>
      </div>
    </div>
  )
}



const logos = [
  {
    id: '001',
    logo: 'https://tevily-nextjs.vercel.app/_next/static/media/brand-one-1.2f819844.png'
  },
  {
    id: '002',
    logo: 'https://tevily-nextjs.vercel.app/_next/static/media/brand-one-5.e9ffdc28.png'
  },
  {
    id: '003',
    logo: 'https://tevily-nextjs.vercel.app/_next/static/media/brand-one-1.2f819844.png'
  },
  {
    id: '004',
    logo: 'https://tevily-nextjs.vercel.app/_next/static/media/brand-one-5.e9ffdc28.png'
  },
  {
    id: '005',
    logo: 'https://tevily-nextjs.vercel.app/_next/static/media/brand-one-1.2f819844.png'
  },
  {
    id: '006',
    logo: 'https://tevily-nextjs.vercel.app/_next/static/media/brand-one-1.2f819844.png'
  }
  ,
  {
    id: '005',
    logo: 'https://tevily-nextjs.vercel.app/_next/static/media/brand-one-1.2f819844.png'
  },
  {
    id: '006',
    logo: 'https://tevily-nextjs.vercel.app/_next/static/media/brand-one-1.2f819844.png'
  }
]

export default LogosConteiner