import React from "react";

const Invoice = () => {
  return (
    <div className="justify-content-center mt-4 px-5">
      <div className="d-flex flex-column rounded-pill border px-4 py-3 invoice-banner">
        <span>
          Please! note that ticket can be issued in case we recieve payment on
          our account before deadline only.
        </span>
        <span>
          Please! inform us if you are paying the invoice and please put booking
          reference in payment description.
        </span>
        <span>
          Please! note that airline may cancel unpaid seats any moment. The
          price can change if seats or fares become unavailable.
        </span>
      </div>
      <div className="my-5 border rounded-2 px-2">
        <div class="d-flex justify-content-between align-items-center border-bottom">
          <div>
            <img
              src="/zairaa.jpeg"
              alt="travel-agency"
              style={{ width: "200px", height: "200px" }}
            />
          </div>
          <div className="d-flex flex-column">
            <h6>ZAIRAA B2B</h6>
            <span>Address: Jinnah Road, Bani Gala, Islamabad.</span>
            <span className="my-2">Email: support@huzatech.com</span>
            <span>Contact: +923345469946</span>
          </div>
        </div>
        <div>
          <div className="d-flex flex-column px-4 py-5">
            <span>To</span>
            <h6>ZAIRAA B2B</h6>
            <span>Address: Jinnah Road, Bani Gala, Islamabad.</span>
            <span className="my-2">Email: support@huzatech.com</span>
            <span>Contact: +923345469946</span>
          </div>
        </div>
        <div className="row px-4">
          <table className="table table-bordered text-nowrap table-responsive">
            <thead>
              <tr>
                <th>Passenger</th>
                <th>Fare</th>
                <th>Taxes</th>
                <th>Total</th>
                <th>Discount</th>
                <th>PSF</th>
                <th>Receivable</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>ADT- MS SHAIZA MUSHTAQ</td>
                <td>12000</td>
                <td>0</td>
                <td>12000</td>
                <td>0</td>
                <td>3000</td>
                <td>15000</td>
              </tr>
              <tr>
                <td colSpan={6}>
                  <b>Total Receivable</b>
                </td>
                <td>15000</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-end px-2 my-2">
          <div className="d-flex justify-content-between col-sm-3">
            <label>Due Date:</label>
            <div className="">
              <b className="ml-4">Fifteen Thousand Only</b>
              <label>15000 PKR</label>
            </div>
          </div>
        </div>
        <div className="px-3 border-bottom">
        <p>Payment is performed within one banking day</p>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center my-3">
        <span>This is a computer generated document & does not need a signature and Stamp</span>
        <button className="btn print-btn col-sm-1 mt-2">Print invoice</button>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
