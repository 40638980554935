import { Button, TextField } from "@mui/material";
import React, { useState } from "react";

const Fare = () => {
  const [multiRows, setMultiRows] = useState([]);

  const addRow = () => {
    const newvalue = multiRows[multiRows.length - 1];
    setMultiRows([...multiRows, newvalue ? newvalue + 1 : 1]);
  };
  const removeRow = (value) => {
    setMultiRows(multiRows.filter((val) => val !== value));
  };
  return (
    <div>
      <div className="col-sm-1 d-flex justify-content-between">
        <label class="form-control me-3 me-md-5">
          <input type="radio" name="radio" id="PSF" />
          <span className="h6">PSF</span>
        </label>
        <label class="form-control me-3 me-md-5">
          <input type="radio" name="radio" id="SP" />
          <span className="h6">SP</span>
        </label>
      </div>
      <div className="row mt-4 ">
        <div className="col-sm-3">
          <TextField variant="standard" label='Adult Base Fare' className="w-100" />
        </div>
        <div className="col-sm-3">
          <TextField variant="standard" label='PSF/SP percent' className="w-100" />
        </div>
      </div>
      <div className="row mt-4 align-items-end">
        <div className="col-sm-3">
          <TextField variant="standard" label='Tax Code' className="w-100" />
        </div>
        <div className="col-sm-3">
          <TextField variant="standard" label='Tax Amount' className="w-100" />
        </div>
        <div className="col-3">

          <Button variant='contained' onClick={addRow}>Add Tax</Button>
        </div>

      </div>

      {multiRows.map((val) => (

        <div className="row mt-4 align-items-end" >
          <div className="col-sm-3">
            <TextField variant="standard" label='Tax Code' className="w-100" />
          </div>
          <div className="col-sm-3">
            <TextField variant="standard" label='Tax Amount' className="w-100" />
          </div>
          <div className="col-3">

            <Button variant='contained' color="error" onClick={(e) => removeRow(val)}>Remove Tax</Button>
          </div>
        </div>

      ))}
    </div>
  );
};

export default Fare;
