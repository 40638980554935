import { FormControl, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { useNavigate } from "react-router-dom";
axios.defaults.withCredentials = true;

const LoginModal = ({ handleClose }) => {
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({
    email: "support@huzatech.com",
    password: "123123123",
  });
  const [forgetPasswordForm, setForgetPasswordForm] = useState(false);
  const http = axios.create({
    baseURL: "https://api.huzatech.com",
    headers: {
      "X-Requested-With": "XMLHttpRequest",
    },
    withCredentials: true,
  });
  useEffect(() => {}, []);
  const [error, setError] = useState("");
  const submitForm = async () => {
    const { email, password } = credentials;
    if (!email || !password) {
      setError("please enter all the fields");
      return;
    }
    // axios.defaults.baseURL = "https://api.huzatech.com";
    // axios.defaults.withCredentials = true;
    // const csrf = await http.get("/sanctum/csrf-cookie");
    // console.log(csrf);
    // const login = await http.post("/api/authenticate", credentials);
    // console.log(login)
    http
      .get("/sanctum/csrf-cookie")
      .then((res) => {
        http
          .post("/authenticate", credentials)
          .then((res) => console.log(res))
          .catch((res) => console.log(res));
        })
        .catch((res) => console.log(res));
        navigate("/home");
        setError("");
  };
  const resetPassword = () => {
    const { email } = credentials;
    if (!email) return setError("please enter an email");
  };
  return (
    <div className="back_drop_shadow_container">
      <Modal show={true} onHide={(e) => handleClose(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {forgetPasswordForm ? "Forget Password" : "Login"}
          </Modal.Title>
        </Modal.Header>
        {forgetPasswordForm ? (
          <Modal.Body>
            <div className="col-12 form-group mt-3">
              <TextField
                label="User Name"
                variant="standard"
                className="col-12"
                onChange={(e) =>
                  setCredentials({
                    ...credentials,
                    email: e.target.value,
                  })
                }
                value={credentials.email}
                required
                autoFocus
              />
            </div>
            {error && <span style={{ color: "red" }}>{error}</span>}
          </Modal.Body>
        ) : (
          <Modal.Body>
            <FormControl onSubmit={(e) => submitForm()} className="col-12">
              <div className="col-12 mt-3">
                <TextField
                  label="User Name"
                  variant="standard"
                  className="col-12"
                  onChange={(e) =>
                    setCredentials({
                      ...credentials,
                      email: e.target.value,
                    })
                  }
                  value={credentials.email}
                  required
                  autoFocus
                />
              </div>
              <div className="col-12 mt-5">
                <TextField
                  label="Password"
                  variant="standard"
                  className="col-12"
                  value={credentials.password}
                  onChange={(e) =>
                    setCredentials({
                      ...credentials,
                      password: e.target.value,
                    })
                  }
                  required
                  autoFocus
                />
              </div>
              {error && <span style={{ color: "red" }}>{error}</span>}
            </FormControl>
          </Modal.Body>
        )}

        {forgetPasswordForm ? (
          <div className="modal-footer justify-content-between">
            <span
              onClick={(e) => setForgetPasswordForm(false)}
              className="text-primary"
              role="button"
              style={{ float: "left" }}
            >
              Login instead?
            </span>
            <Button variant="secondary" onClick={(e) => resetPassword(false)}>
              Send Password Reset Link
            </Button>
          </div>
        ) : (
          <div className="modal-footer justify-content-between">
            <span
              onClick={(e) => setForgetPasswordForm(true)}
              className="text-primary"
              role="button"
              style={{ float: "left" }}
            >
              Forget Password?
            </span>
            <Button variant="primary" onClick={(e) => submitForm()}>
              Login
            </Button>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default LoginModal;
