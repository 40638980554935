import React from 'react'
import Header from '../components/Common/Header'
import TermsComp from '../components/Setup/Visa/Terms'
import Footer from '../components/Footer'

const Terms = () => {
  return (
    <div className='Full_height'>
      <Header active="setup" />
      <TermsComp />
      <Footer />
    </div>
  )
}

export default Terms