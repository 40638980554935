import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import Accordion from "react-bootstrap/Accordion";
import TravelersDropDown from "./Hotelstabs/TravelersDropdown";
import ChildrensAge from "./FlightsTab/ChildrensAge";
import FormControl from "@mui/material/FormControl";
import { Button, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { SearchOffOutlined, SearchOutlined } from "@mui/icons-material";

const HotelsTab = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [rooms, setRooms] = useState([]);
  // const [data, setData] = useState({
  //   adults: 1,
  //   child: 0,
  //   lap_infant: 0,
  // });
  const [formData, setFormData] = useState({
    rooms: [
      {
        allselect: false,
        adults: 1,
        child: 0,
        lap_infant: 0,
      },
    ]
  });
  const addRoom = () => {
    // const newvalue = rooms[rooms.length - 1];
    // setRooms([...rooms, newvalue ? newvalue + 1 : 1]);
    const rooms = [
      ...formData.rooms,
      {
        adults: 1,
        child: 0,
        lap_infant: 0,
      }
    ]
    setFormData({
      ...formData,
      rooms,
    })
  };

  const removeRow = (value) => {
    // setRooms(rooms.filter((val) => val !== value));
    if (formData.rooms.length === 1) return;
    const array = [...formData.rooms];
    setFormData({
      ...formData,
      rooms: array.pop(),
    })

  };
  return (
    <div className=" mt-4">
      <div className="row align-items-end">
        <div className="col-sm-3">
          <TextField
            label="Destination / City"
            variant="standard"
            className="w-100"
          />
        </div>
        <div className="col-sm-2">
          <ReactDatePicker
            placeholderText="Checkin Date"
            className="date_picker"
            selected={startDate}
            monthsShown={2}
            onChange={(date) => {
              setStartDate(date);
              setEndDate("");
            }}
            // onCalendarClose={handleCalendarClose}
            minDate={new Date()}
          // onCalendarOpen={handleCalendarOpen}
          />
        </div>
        <div className="col-md-2">
          <ReactDatePicker
            placeholderText="Checkout Date"
            className="date_picker"
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            // onCalendarClose={handleCalendarClose}
            minDate={startDate}
            monthsShown={2}
          // onCalendarOpen={handleCalendarOpen}
          />
        </div>
        <div className="btn-group col-sm-3 ">
          <FormControl variant="standard" className="w-100 " sx={{}}>
            <InputLabel id="demo-simple-select-standard-label">
              Travelers
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              label="Travelers"
              value={""}
            >
              <Accordion
                defaultActiveKey={rooms.length ? rooms.length - 1 : 0}
                sx={{ bgcolor: "#fff" }}
              >
                {formData.rooms?.map((room, index) => {
                  return (
                    <MenuItem sx={{ m: 1, bgcolor: "#fff" }} disableRipple>
                      <TravelersDropDown
                        title="adults"
                        values={room}
                        setData={setFormData}
                        formdata={formData}
                        room_no={index + 1}
                        key={index}
                      />
                    </MenuItem>
                  );
                })}
                <MenuItem sx={{ m: 1, bgcolor: "#fff" }} disableRipple>
                  <div
                    className="w-100 pt-3 pt-lg-0 d-flex justify-content-between"
                    style={{ justifyContent: "space-between" }}
                  >
                    <button
                      className="btn btn-primary mt-3 col-sm-5"
                      id="closeDialog"
                      onClick={addRoom}
                    >
                      Add Room
                    </button>
                    <button
                      className="btn btn-primary mt-3 col-sm-5"
                      id="closeDialog"
                      onClick={(e) => {
                        const element =
                          document.getElementById("dropdown-menu");
                        element.style.display = "none";
                      }}
                    >
                      Done
                    </button>
                  </div>
                </MenuItem>
              </Accordion>
            </Select>
          </FormControl>
        </div>
        <div className="col-sm-2">
          <Button variant='contained'

            name="dropdown-btn"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            startIcon={<SearchOutlined />}
          >Search
          </Button>
        </div>
      </div>
    </div>

  );
};

export default HotelsTab;
