import { Button, TextField } from "@mui/material";
import React from "react";
import { IoIosClose } from "react-icons/io";
import CustomDropdown from "../../CustomDrp";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";

function BankModel({ setShowModel, rowData }) {
  return (
    <div className="backdrop_blur_cotianer">
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="model_body">
          {/* *************** */}
          <p className="otawix-heading h4">Bank Details</p>

          {/* *************************** */}
          <div className="p-4 py-5">
            <div className="row">
              <div className="col-6">
                <TextField
                  label="Bank Name"
                  variant="standard"
                  className="w-100"
                />
              </div>
              <div className="col-6">
                <TextField
                  label="Branch"
                  variant="standard"
                  className="w-100"
                />
              </div>
            </div>
            <div className="row my-5">
              <div className="col-6">
                <TextField label="SWIFT" variant="standard" className="w-100" />
              </div>
              <div className="col-6">
                <TextField
                  label="Bank Account Number"
                  variant="standard"
                  className="w-100"
                />
              </div>
            </div>
            <div className="row my-5">
              <div className="col-6">
                <TextField label="IBAN" variant="standard" className="w-100" />
              </div>
              <div className="col-6">
                <TextField
                  label="Beneficiary Name"
                  variant="standard"
                  className="w-100"
                />
              </div>
            </div>
            <div className="row my-5 align-items-end">
              <div className="col-6">
                <TextField
                  label="Address"
                  variant="outlined"
                  className="w-100"
                  multiline
                  rows={2}
                />
              </div>
              <div className="col-6 ">
                <div className="d-flex justify-content-between">
                  <div className="w-50">
                    <div class="checkbox-rect">
                      <input
                        type="checkbox"
                        id="collection"
                        name="collection"
                      />
                      <label for="collection" className="h6">
                        Collection
                      </label>
                    </div>
                  </div>
                  <div className="w-50">
                    <div class="checkbox-rect">
                      <input type="checkbox" id="pia" name="pia" />
                      <label for="pia" className="h6">
                        PIA
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-end">
              <div className="me-2">
                <Button
                  variant="danger modal-button"
                  onClick={(e) => setShowModel(false)}
                  style={{ background: "#dc3545", color: "#fff" }}
                >
                  Close
                </Button>
              </div>
              <div className="">
                <Button
                  variant="primary modal-button"
                  onClick={(e) => setShowModel(false)}
                  style={{ background: "#0d6efd", color: "#fff" }}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BankModel;
