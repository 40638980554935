import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import CustomDropdown from "../../CustomDrp";

function HotelInfo() {
  const [multiRows, setMultiRows] = useState([]);

  const addRow = () => {
    const newvalue = multiRows[multiRows.length - 1];
    setMultiRows([...multiRows, newvalue ? newvalue + 1 : 1]);
  };

  const removeRow = () => {
    let rowsArray = multiRows;
    rowsArray.pop();
    setMultiRows([...rowsArray]);
  };
  return (
    <div className="mt-4">
      {/** ************************ */}
      <div className="d-flex justify-content-between align-items-end row">
        <h4 className="col-sm-9">Hotel Information:</h4>
        <div className="col-sm-3 text-end">
          <Button variant="contained" className="me-2"
            onClick={addRow}
          > Add Hotel
          </Button>
          <Button variant="contained" color='error'
            onClick={removeRow}
          >Remove Hotel
          </Button>
        </div>
      </div>
      <div>
        <HotelInformation />
      </div>
      {multiRows.map((val) => {
        return (
          <div className="mt-3">
            <HotelInformation />
          </div>
        );
      })}
    </div>
  );
}

{
  /****************************************** */
}

const HotelInformation = () => {
  const [multiRows, setMultiRows] = React.useState([]);

  const addRow = () => {
    const newvalue = multiRows[multiRows.length - 1];
    setMultiRows([...multiRows, newvalue ? newvalue + 1 : 1]);
  };

  const removeRow = (value) => {
    setMultiRows(multiRows.filter((val) => val !== value));
  };
  return (
    <div
      className="custom_Flight_info mt-3 py-2 my-5 px-2"
      style={{ height: "auto" }}
    >
      <div className="row align-items-end my-4">
        <div className="col-2">
          <ReactDatePicker
            placeholderText="From Date"
            minDate={new Date()}
            monthsShown={2}
            className="date_picker"
          />
        </div>
        <div className="col-2">
          <ReactDatePicker
            placeholderText="To Date"
            minDate={new Date()}
            monthsShown={2}
            className="date_picker"
          />
        </div>
        <div className="col-2">
          <TextField variant="standard" label='Nights' className="w-100" />
        </div>
        <div className="col-2">
          <CustomDropdown arry={['Room', 'Sharing']} placehlder='Room Nature' />
        </div>
        <div className="col-2">
          <CustomDropdown arry={['Mecca', 'Madina']} placehlder='Select City' />
        </div>
        <div className="col-2">
          <CustomDropdown arry={['Swidotel Almaqam Maka', 'Madina']} placehlder='Select Hotel' />
        </div>
      </div>
      <hr /> {/***************************** */}
      <SecondColumn addRow={addRow} removeRow={removeRow} isPrimaryRow={true} />
      {multiRows.map((val) => {
        return (
          <SecondColumn
            addRow={addRow}
            removeRow={removeRow}
            isPrimaryRow={false}
            val={val}
          />
        );
      })}
      <hr />
      {/***************************** */}
      <div className="row align-items-end my-4 ">
        <div className="col-3">
          <TextField variant="standard" label='Total Rec SAR' className="w-100" type="number" />
        </div>
        <div className="col-3">
          <TextField variant="standard" label='Total Pay SAR' className="w-100" type="number" />
        </div>
        <div className="col-3">
          <TextField variant="standard" label='Total Rec PKR' className="w-100" type="number" />
        </div>
        <div className="col-3">
          <TextField variant="standard" label='Total Pay PKR' className="w-100" type="number" />
        </div>
      </div>
    </div>
  );
};

const SecondColumn = ({ addRow, removeRow, isPrimaryRow, val }) => (
  <div className="row my-4">
    <div className="col-2">
      <CustomDropdown arry={['Delux', 'Superior']} placehlder='Room' />
    </div>
    <div className="col-2">
      <CustomDropdown arry={['Double', 'Triple', 'Quad']} placehlder='Room Price' />
    </div>
    <div className="col-2">
      <TextField variant="standard" label='SAR ROE' className="w-100" type="number" />
    </div>
    <div className="col-1">
      <TextField variant="standard" label='Rec SAR' className="w-100" type="number" />
    </div>
    <div className="col-1">
      <TextField variant="standard" label='Pay SAR' className="w-100" type="number" />
    </div>
    <div className="col-1">
      <TextField variant="standard" label='Rec PKR' className="w-100" type="number" />
    </div>
    <div className="col-1">
      <TextField variant="standard" label='Pay PKR' className="w-100" type="number" />
    </div>
    <div className="col-2">
      {isPrimaryRow ? (
        <button
          className="btn btn-small btn-primary border rounded-2 "
          style={{ width: "fit-content", marginLeft: "auto" }}
          onClick={addRow}
        >
          <i className="fa  fa-thin fa-plus"></i>
        </button>
      ) : (
        <button
          className="btn btn-small btn-danger border rounded-2 "
          style={{ width: "fit-content", marginLeft: "auto" }}
          onClick={(e) => removeRow(val)}
        >
          <i className="fa  fa-thin fa-close"></i>
        </button>
      )}
    </div>
  </div>
);

export default HotelInfo;
