import React, { useState } from "react";
import CustomDropdown from "../components/CustomDrp";
import Header from "../components/Common/Header";
import "../Styles/manualInvoices.css";


function AgentList() {
  const [first, setfirst] = useState('')
  return (
    <div className=" finance-container Full_height">
      <Header />
      <div className="manual_invoices mx-auto mt-5">
        <div className="manual_invoices_header px-md-5">
          <div className="w-100 text-center">Office Reports (Date / Status Wise)</div>
        </div>
        <div className="px-5 py-4">
          <div className="pt-5 ">
            <CustomDropdown arry={['Active', 'Inactive', 'Pending']} placehlder='--select status--' setfunction={setfirst} />
          </div>

          <div className="d-flex justify-content-center align-items-center my-4 mt-5">
            <button className="btn btn-primary">Generate Report</button>
          </div>
        </div>
      </div>
    </div>
  );
}






export default AgentList;
