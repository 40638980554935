import { TextField } from '@mui/material'
import React from 'react'
import CustomDropdown from '../../../CustomDrp'

function PAX() {
    return (
        <div className='row align-items-center py-2 '>
            <div className='h5'>PAX</div>
            <div className='col-4 col-md-2'>
                <CustomDropdown arry={['Adult', 'Child', 'Infant']} placehlder='Type' />
            </div>
            <div className='col-4 col-md-2'>
                <TextField label="Title" variant="standard" className="w-100" disabled />
            </div>
            <div className='col-4 col-md-2'>
                <TextField label="First Name" variant="standard" className="w-100" disabled />
            </div>
            <div className='col-4 col-md-2'>
                <TextField label="Last Name" variant="standard" className="w-100" disabled />
            </div>

            <div className='col-4 col-md-2'>
                <TextField label="Ticket Number" variant="standard" className="w-100" disabled />
            </div>
            <div className='col-4 col-md-2'>
                <div class="checkbox-rect">
                    <input type="checkbox" id="PAX" name="PAX" />
                    <label for="PAX" className="h6"></label>
                </div>
            </div>
        </div>
    )
}

export default PAX