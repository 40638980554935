import React from 'react'
import {FaPhoneSquareAlt} from 'react-icons/fa'
import {IoMdMail} from 'react-icons/io'
import {MdLocationOn} from 'react-icons/md'
import {MdLocalTaxi} from 'react-icons/md'

function Footer() {
  return (
    <div className='container-fluid py-4' style={{backgroundColor:'#313041'}} id="contact-us">
    <div className='container py-5'> 
    <div className='row'>
     <div className='col-12 col-md-4 pe-5'>
        <img src='/zairaa.jpeg' style={{width:'7rem' }}/>
        <p className='my-3 ' style={{color:'gray',lineHeight:'2rem'}}>Welcome to our Trip and Tour Agency. Lorem simply text amet cing elit.</p>
        <hr className='bg-info'/>
        <div className='d-flex align-items-center'>
          <div>
          <FaPhoneSquareAlt color='#e8604c' size={20}/>
          </div>
          <div style={{color:'gray',marginLeft:'10PX'}}>
          + 92 666 999 0000
          </div>
        </div>
        <div className='d-flex align-items-center my-2'>
          <div>
          <IoMdMail color='#e8604c' size={20}/>
          </div>
          <div style={{color:'gray',marginLeft:'10PX'}}>
          needhelp@company.com
          </div>
        </div>
        <div className='d-flex align-items-center'>
          <div>
          <MdLocationOn color='#e8604c' size={20}/>
          </div>
          <div style={{color:'gray',marginLeft:'10PX'}}>
          666 road, broklyn street new york
          </div>
        </div>
     </div>
     <div className='col-12 col-md-8 pt-5 pt-md-0'>
        <div className='d-flex align-items-center h-100 py-auto '>
        <MdLocalTaxi color='#e8604c' size={50} className='custom-icons' />
        <MdLocalTaxi color='#e8604c' size={50} className='mx-2 mx-md-5 custom-icons'/>
        <MdLocalTaxi color='#e8604c' size={50} className='custom-icons'/>
        </div>
     </div>
     
   
    </div>
    </div>
    
    </div>
  )
}

export default Footer