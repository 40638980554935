import React, { useState } from "react";
import { TextField } from "@mui/material";
import MUIDataTable from "mui-datatables";
import DeleteModal from "../../DeleteModal";

const AddSliderText = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const columns = [
    {
      name: "id",
      label: "S.No",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "flash_news",
      label: "Flash News",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "Action",
      name: "delete",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <button
              className="edit-delete-icon-button btn-danger"
              onClick={() => {
                setShowDeleteModal(true);
                setModalData(tableMeta.rowData);
              }}
            >
              <i className="fa fa-trash" aria-hidden="true"></i>
            </button>
          );
        },
      },
    },
  ];

  const data = [
    {
      id: 1,
      flash_news:
        "ZAIRAA V3.1 with GDS and LCC airline APIs, Also with Hotel APIs.",
    },
    {
      id: 2,
      flash_news: "Welcome to ZAIRAA B2B V3.1",
    },
    {
      id: 3,
      flash_news: "Welcome to ZAIRAA B2B V3.2 New Version.",
    },
    {
      id: 4,
      flash_news: "ZAIRAA OFFER ON PIA -3% ALL AGENTS",
    },
  ];
  const options = {
    filter: false,
    Selection: false,
    print: false,
    download: false,
    viewColumns: false,
    displayRowCheckbox: false,
    selectableRows: "none",
    // onRowClick: (rowData) => {
    //   navigate(`/booking-details/${rowData[3]}/active`)
    // },
  };
  return (
    <div className="col-12 col-sm-6 mx-auto rounded-2 shadow mt-5 pb-5 flash-news">
      <p className="h4 otawix-heading">Flash News</p>
      <div className="col-12 px-4 d-flex flex-column justify-content-center">
        <div className="col-12 my-4 py-3 me-5">
          <TextField
            id="standard-basic"
            label="Add Flash News"
            variant="standard"
            className="col-12"
            inputProps={{ maxLength: 50 }}
          />
        </div>
        <button className="btn setup-btn">save</button>
      </div>
      <div className="col-12 mt-5 px-4">
        <DeleteModal
          show={showDeleteModal}
          setShow={setShowDeleteModal}
          rowId={modalData && modalData[0]}
        />
        <MUIDataTable
          className="muidatatable"
          title={"Flash News Details"}
          data={data}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  );
};

export default AddSliderText;
