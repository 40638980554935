import React from 'react'
import Header from '../components/Common/Header'
import UmrahTable from '../components/UmrahRequests/UmrahTable'
import Footer from '../components/Footer'

const Umrah = () => {
  return (
    <div className='Full_height'>
      <Header active="umrah-requests" />
      <UmrahTable />
      <Footer />
    </div>
  )
}

export default Umrah