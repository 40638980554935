import React from "react";

function Tabs({ tabs, setTabs }) {
  return (
    <div className="booking_detail_navigation_tabs">
      <div
        className={`h5  ${
          tabs === "general" ? "active-link" : "bookings_details_links"
        }`}
        onClick={() => setTabs("general")}
      >
        General
      </div>
      <div
        className={`h5  ${
          tabs === "contact" ? "active-link" : "bookings_details_links"
        }`}
        onClick={() => setTabs("contact")}
      >
        Contact
      </div>
      <div
        className={`h5  ${
          tabs === "pricing" ? "active-link" : "bookings_details_links"
        }`}
        onClick={() => setTabs("pricing")}
      >
        Pricing
      </div>
      <div
        className={`h5  ${
          tabs === "invoice" ? "active-link" : "bookings_details_links"
        }`}
        onClick={() => setTabs("invoice")}
      >
        Invoices
      </div>
      {/*<div className={`h5  ${tabs === 'supllier' ? 'active-link':'bookings_details_links'}`} onClick={()=>setTabs('supllier')}> 
    Suppliers Ruls
  </div>*/}
      <div
        className={`h5  ${
          tabs === "chats" ? "active-link" : "bookings_details_links"
        }`}
        onClick={() => setTabs("chats")}
      >
        Chats
      </div>
      <div
        className={`h5  ${
          tabs === "requests" ? "active-link" : "bookings_details_links"
        }`}
        onClick={() => setTabs("requests")}
      >
        Requests
      </div>
    </div>
  );
}

export default Tabs;
