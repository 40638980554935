import { Button, TextField } from '@mui/material'
import React from 'react'
import { IoIosClose } from 'react-icons/io'
import CustomDropdown from '../../CustomDrp'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

function AgentGroupAccountModel({ setShowAccountModel }) {
    return (
        <div className='backdrop_blur_cotianer'>
            <div className='d-flex justify-content-center align-items-center' style={{ height: '100vh' }}>
                <div className='model_body'>

                    {/* *************** */}
                    <div className='model_header text-white'>
                        <div>
                            Add bank account to Agent group
                        </div>
                        <div>
                            <IoIosClose size={35} style={{ cursor: 'pointer' }} onClick={e => setShowAccountModel(false)} />
                        </div>
                    </div>

                    {/* *************************** */}
                    <div className='p-4 py-4' >

                        <div className='my-3'>
                            <CustomDropdown arry={['Alfah - 00351006655919', 'PayPro - 1242342134213234']} placehlder='Bank Account' />
                        </div>

                        <div className='d-flex justify-content-center align-items-end pt-2 mt-5'>
                            <div className='me-2'>
                                <Button variant='contained' startIcon={<CloseRoundedIcon />} onClick={e => setShowAccountModel(false)}>Close</Button>
                            </div>
                            <div className=''>
                                <Button variant='contained' startIcon={<CheckRoundedIcon />}>Okay</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AgentGroupAccountModel