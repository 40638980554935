import React, {useState} from "react";
import { TextField } from "@mui/material";
import MUIDataTable from "mui-datatables";
import CustomDropdown from "../../CustomDrp";
import DeleteModal from "../../DeleteModal";

const Type = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const columns = [
    {
      label: "Status",
      name: "checkbox",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div class="checkbox-rect">
              <input type="checkbox" id="with_sar" name="check" />
              <label for="with_sar" className="h6"></label>
            </div>
          );
        },
      },
    },
    {
      name: "country",
      label: "country",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "supplier",
      label: "supplier",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "visa_passanger_type",
      label: "visa passanger type",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <ol>
              {value.map((line) => (
                <li>{line}</li>
              ))}
            </ol>
          );
        },
      },
    },
    {
      name: "visa_price",
      label: "visa price",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <ol>
              {value.map((line) => (
                <li>{line}</li>
              ))}
            </ol>
          );
        },
      },
    },
    {
      name: "Action",
      label: "action",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="">
              <button
                className="btn-success rounded edit-delete-icon-button me-1"
                onClick={() => console.log(value, tableMeta)}
              >
                <i className="fa fa-thin fa-pencil"></i>
              </button>
              <button
                className="btn-danger rounded edit-delete-icon-button"
                onClick={() => {
                  setShowDeleteModal(true);
                setModalData(tableMeta.rowData);
                }}
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
              </button>
            </div>
          );
        },
      },
    },
  ];

  const data = [
    {
      id: 1,
      country: "Dubai",
      supplier: "Dubai",
      visa_passanger_type: ["hdvhsjcvschjewd", "jgdzijdsgijfc", "kfkjdcogoi"],
      visa_price: ["hdvhsjcvschjewd", "jgdzijdsgijfc", "kfkjdcogoi"],
    },
  ];
  const options = {
    filter: false,
    Selection: false,
    print: false,
    download: false,
    viewColumns: false,
    displayRowCheckbox: false,
    selectableRows: "none",
  };
  return (
    <div className="col-12 col-sm-8 mx-auto rounded-2 shadow mt-5 pb-5 flash-news">
      <p className="h4 otawix-heading">Visa Type</p>
      <div className="col-12 px-4 mt-5 d-flex flex-column justify-content-center">
        <div className="col-12 d-flex px-5">
          <div className="col-6 px-3">
            <CustomDropdown
              arry={["AED", "EURO", "Room 3", "Room 4"]}
              placehlder="Select Country"
            />
          </div>
          <div className="col-6 px-3">
            <CustomDropdown
              arry={["AED", "EURO", "Room 3", "Room 4"]}
              placehlder="Select Supplier"
            />
          </div>
        </div>
        <div className="col-sm-12 d-flex buttons mt-3 py-2 px-5">
          <button className="btn btn-danger me-2">Rem Visa Type</button>
          <button className="btn btn-primary">Add Visa Type</button>
        </div>
        <div className="col-12 mt-3 mb-5 d-flex px-5">
          <div className="col-sm-6 px-3">
            <TextField
              id="standard-basic"
              label="Visa Type ( - Sign Is Not Allowed To Type)"
              variant="standard"
              className="col-sm-12"
            />
          </div>
          <div className="col-sm-6 d-flex justify-content-between px-3">
            <div className="col-sm-6 me-5">
              <CustomDropdown
                arry={["AED", "EURO", "Room 3", "Room 4"]}
                placehlder="Passanger Type"
              />
            </div>
            <div className="col-sm-5">
              <CustomDropdown
                arry={["AED", "EURO", "Room 3", "Room 4"]}
                placehlder="Currency"
              />
            </div>
          </div>
        </div>
        <div className="col-12 mt-5 mb-5 d-flex px-5">
          <div className="col-sm-6 d-flex justify-content-between px-3">
            <TextField
              id="standard-basic"
              label="Pay SAR *"
              variant="standard"
              className="col-sm-6"
            />
            <TextField
              id="standard-basic"
              label="Rec SAR *"
              variant="standard"
              className="col-sm-5"
            />
          </div>
          <div className="col-sm-6 d-flex justify-content-between px-3">
            <TextField
              id="standard-basic"
              label="Pay PKR *"
              variant="standard"
              className="col-sm-6 me-5"
            />
            <TextField
              id="standard-basic"
              label="Rec PKR *"
              variant="standard"
              className="col-sm-5"
            />
          </div>
        </div>
        <button className="btn setup-btn mx-auto">save</button>
      </div>
      <div className="col-12 mt-5 px-4">
      <DeleteModal
          show={showDeleteModal}
          setShow={setShowDeleteModal}
          rowId={modalData && modalData[0]}
        />
        <MUIDataTable
          className="muidatatable"
          title={"Visa Type Details"}
          data={data}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  );
};

export default Type;
