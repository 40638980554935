import React from 'react';
import Header from '../components/Common/Header'
import TransferComp from '../components/Setup/Umrah/Transfer'
import Footer from '../components/Footer'

const UmrahTransfer = () => {
  return (
    <div className='Full_height'>
      <Header active="setup" />
      <TransferComp />
      <Footer />
    </div>
  )
}

export default UmrahTransfer