import React, {useState} from "react";
import DeleteModal from "../DeleteModal";
import MUIDataTable from "mui-datatables";
import { Link, useNavigate } from "react-router-dom";



const AgentGroups = () => {
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const [modalData, setModalData] = useState([]);
  const columns = [
    {
      label: "Status",
      name: "checkbox",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div class="checkbox-rect">
              <input type="checkbox" id="with_sar" name="check" />
              <label for="with_sar" className="h6"></label>
            </div>
          );
        },
      },
    },
    {
      name: "agent_group",
      label: "Agent Group",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "agent_group_category",
      label: "Agent Group Category",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "currency",
      label: "Currency",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "Action",
      name: "actions",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="">
              <button
                className="btn-success rounded edit-delete-icon-button me-1"
                onClick={() => {
                  setRowData(data[tableMeta.rowIndex]);
                  navigate("/agent-group-form")
                }}
              >
                <i className="fa fa-thin fa-pencil"></i>
              </button>
              <button
                className="btn-danger rounded edit-delete-icon-button"
                onClick={() => {
                setShowDeleteModal(true);
                setModalData(tableMeta.rowData);
              }}
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
              </button>
            </div>
          );
        },
      },
    },
  ];

  const data = [
    {
      id: 1,
      agent_group: "",
      agent_group_category: "",
      currency: "",
    },
  ];
  const options = {
    filter: false,
    Selection: false,
    print: false,
    download: false,
    viewColumns: false,
    displayRowCheckbox: false,
    selectableRows: "none",
  };
  return (
    <div className="col-12 col-sm-8 mx-auto rounded-2 shadow mt-5 pb-5 flash-news">
      <p className="h4 otawix-heading">Agent Group</p>
      <div className="col-12 px-4 d-flex justify-content-end my-4">
        <Link to='/agent-group-form' className="setup-btn">
          Add Agent Group <i class="fa fa-thin fa-plus"></i>
        </Link>
        <DeleteModal
        show={showDeleteModal}
        setShow={setShowDeleteModal}
        rowId={modalData && modalData[0]}
      />
      </div>
      <div className="col-12 mt-2 px-4">
        <MUIDataTable
          className="muidatatable"
          title={"Agent Group Details"}
          data={data}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  );
};

export default AgentGroups;
