import React from "react";
import { GrClose } from "react-icons/gr";
function PreviewInsurance({ setPreview, formData }) {
  console.log(formData);
  return (
    <div className="Preview_Container container">
    <p className="otawix-heading h4">Insurance Information</p>
      {/********************************  plan information ************************** */}
      <div className="px-3">
        <div className="my-4 border-bottom pb-3">
          <h4>Plan:</h4>

          <div className="row">
        <div className="col-md-6 d-flex my-4">
          <span class="form-control me-5">
            <input type="radio" id="covered" name="covid-status" checked={formData.covid === "covered"} />
            <label for="covered" style={{ marginLeft: "5px" }}>
              Covid Covered{" "}
            </label>
          </span>
          <span class="form-control">
            <input type="radio" id="not-covered" name="covid-status" checked={formData.covid === "Not covered"}/>
            <label for="not-covered" style={{ marginLeft: "5px" }}>
              Covid Not Covered{" "}
            </label>
          </span>
        </div>
        <div className="col-md-6 d-flex my-4">
          <span class="form-control me-5">
            <input type="radio" id="single" name="family_status" checked={formData.family_status === "single"} />
            <label for="single" style={{ marginLeft: "5px" }}>
              single
            </label>
          </span>
          <span class="form-control">
            <input type="radio" id="family" name="family_status" checked={formData.family_status === "family"}/>
            <label for="family" style={{ marginLeft: "5px" }}>
              Family
            </label>
          </span>
        </div>
      </div>
      <div className="col-12 mt-5 d-flex">
        <span class="form-control me-5">
          <input
            type="radio"
            id="worldwide"
            value="WORLDWIDE"
            name="trip-type"
            checked={formData.trip === "WORLDWIDE"}
          />
          <label for="worldwide" style={{ marginLeft: "5px" }}>
            Worldwide
          </label>
        </span>
        <span class="form-control me-5">
          <input
            type="radio"
            id="rest-of-world"
            value="RESTOFTHEWORLD"
            name="trip-type"
            checked={formData.trip === "RESTOFTHEWORLD"}
          />
          <label for="rest-of-world" style={{ marginLeft: "5px" }}>
            Rest Of The world
          </label>
        </span>
        <span class="form-control me-5">
          <input
            type="radio"
            id="schengen"
            value="SCENGEN"
            name="trip-type"
            checked={formData.trip === "SCENGEN"}
          />
          <label for="schengen" style={{ marginLeft: "5px" }}>
            Schengen Countries
          </label>
        </span>
      </div>

          <div className="d-flex align-items-center my-4">
            <div className="d-flex flex-column">
              <label className="h6">From Date</label>
              <input
                disabled
                className="inputs_flight_tab w-100 h6 bg-white"
                value={formData.from_date}
              />
            </div>
            <div className="d-flex flex-column mx-5">
              <label className="h6">Days</label>
              <input
                disabled
                className="inputs_flight_tab w-100 h6 bg-white"
                value={formData.no_of_days + " Days"}
              />
            </div>
            <div className="d-flex flex-column">
              <label className="h6">Country</label>
              <input
                disabled
                className="inputs_flight_tab w-100 h6 bg-white"
                placeholder="Country Name"
                value={formData.country}
              />
            </div>
          </div>
        </div>

        {/********************************  Traveler ************************** */}
        <div className="my-4 border-bottom py-4 pb-5">
          <h4>Traveler:</h4>

          <div className="d-flex  align-items-end mt-4 flex-wrap">
            <div className="d-flex flex-column w-25 pe-4 mt-2 ">
              <label className="h6 mt-2 ">Name Of Insurance</label>
              <input
                disabled
                className="inputs_flight_tab w-100 h6 bg-white"
                placeholder="Flight here ..."
                value={formData.insured_name}
              />
            </div>
            <div className="d-flex flex-column w-25 pe-4 mt-2">
              <label className="h6 mt-2 ">Date Of Birth</label>
              <input
                disabled
                className="inputs_flight_tab w-100 h6 bg-white"
                placeholder="12/aug/1990"
                value={formData.date_of_birth}
              />
            </div>
            <div className="d-flex flex-column w-25 pe-4 mt-2">
              <label className="h6 mt-2 ">Email</label>
              <input
                disabled
                className="inputs_flight_tab w-100 h6 bg-white"
                placeholder="example@gmail.com"
                value={formData.email}
              />
            </div>
            <div className="d-flex flex-column w-25 pe-4 mt-2">
              <label className="h6 mt-2 ">Passport No</label>
              <input
                disabled
                className="inputs_flight_tab w-100 h6 bg-white"
                placeholder="dubai ...."
                value={formData.passport_no}
              />
            </div>
            <div className="d-flex flex-column w-25 pe-4 mt-2">
              <label className="h6 mt-2 ">CNIC</label>
              <input
                disabled
                className="inputs_flight_tab w-100 h6 bg-white"
                placeholder="Mecca"
                value={formData.cnic}
              />
            </div>
            <div className="d-flex flex-column w-25 pe-4 mt-2">
              <label className="h6 mt-2 ">Address</label>
              <input
                disabled
                className="inputs_flight_tab w-100 h6 bg-white"
                placeholder="dubai ...."
                value={formData.address}
              />
            </div>
            <div className="d-flex flex-column w-25 pe-4 mt-2">
              <label className="h6 mt-2 ">Contact</label>
              <input
                disabled
                className="inputs_flight_tab w-100 h6 bg-white"
                placeholder="Flight here ..."
                value={formData.contact}
              />
            </div>
            <div className="d-flex align-items-baseline w-25 pe-4 mt-2">
              <input
                type="checkbox"
                disabled
                className="me-2"
                style={{ width: "1.2rem", height: "1.2rem" }}
                checked={formData.isActiveTaxPayer}
              />
              <label className="h6 mt-2 ">Active Tax Payer</label>
            </div>
          </div>
        </div>

        {/********************************  Traveler ************************** */}
        <div className="my-4  py-2 pb-3">
          <h4>Beneficiary:</h4>

          <div className="d-flex  align-items-end mt-4 flex-wrap">
            <div className="d-flex flex-column w-25 pe-4 mt-2 ">
              <label className="h6 mt-2 ">Beneficiary</label>
              <input
                disabled
                className="inputs_flight_tab w-100 h6 bg-white"
                placeholder=" ....."
                value={formData.beneficiary}
              />
            </div>
            <div className="d-flex flex-column w-25 pe-4 mt-2">
              <label className="h6 mt-2 ">Relationship</label>
              <input
                disabled
                className="inputs_flight_tab w-100 h6 bg-white"
                placeholder="......"
                value={formData.relationship}
              />
            </div>
          </div>
        </div>
      </div>
      {/***********************  foooter ************************************ */}

      <div className="d-flex justify-content-end align-items-center border-top py-2 footer_preview_model">
        <button
          onClick={() => setPreview(true)}
          className="btn btn-primary rounded-2 me-2"
        >
          Edit
        </button>
        <button
          onClick={() => setPreview(true)}
          className="btn btn-primary rounded-2 "
        >
          Submit
        </button>
      </div>
    </div>
  );
}

export default PreviewInsurance;
