import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import SupplierModel from "./Common/SupplierModel";
import { Modal, Button } from "react-bootstrap";


const Supplier = () => {
  const [ShowModel, setShowModel] = useState(false)
  const [rowData, setRowData] = useState({})
  const columns = [
    {
      label: "Status",
      name: "checkbox",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div class="checkbox-rect">
              <input type="checkbox" id="with_sar" name="check" />
              <label for="with_sar" className="h6"></label>
            </div>
          );
        },
      },
    },
    {
      name: "category",
      label: "Supplier Category",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "name",
      label: "Supplier Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "contact",
      label: "Contact",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "currency",
      label: "Currency",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "bill_cycle",
      label: "Bill Cycle",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "commission_amount",
      label: "Commission Amount",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "bsp",
      label: "BSP",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "Action",
      name: "checkbox",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <button
              className="btn-success rounded edit-delete-icon-button"
              onClick={() => {setShowModel(true); setRowData(data[tableMeta.rowIndex])}}
            >
              <i className="fa fa-thin fa-pencil"></i>
            </button>
          );
        },
      },
    },
  ];

  const data = [
    {
      id: 1,
      category: "Dubai",
      name: "UAE",
      contact: "",
      email: "",
      currency: "",
      bill_cycle: "",
      commission_amount: "",
      bsp: "",
    },
  ];
  const options = {
    filter: false,
    Selection: false,
    print: false,
    download: false,
    viewColumns: false,
    displayRowCheckbox: false,
    selectableRows: "none",
  };
  return (
    <div className="col-12 mx-auto rounded-2 shadow mt-5 pb-5 flash-news">
      {
        ShowModel && <SupplierModel setShowModel={setShowModel} rowData={rowData}/>
      }
      <p className="h4 otawix-heading">Supplier</p>
      <div className="col-12 px-4 d-flex justify-content-end my-4">
        <button className="setup-btn" onClick={e => {
          setShowModel(true)
        }}>
          Add supplier <i class="fa fa-thin fa-plus"></i>
        </button>
      </div>
      <div className="col-12 mt-2 px-4">
        <MUIDataTable
          className="muidatatable"
          title={"Supplier Details"}
          data={data}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  );
};

export default Supplier;