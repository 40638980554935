import React from 'react'
import MUIDataTable from 'mui-datatables';
import { useNavigate } from 'react-router-dom';

const Table = () => {
    const navigate = useNavigate();
    const columns = [
        {
            name: "req_no",
            label: "Number",
            options: {
              filter: false,
              sort: true,
            }
        },
        {
            name: "date",
            label: "Date/Time",
            options: {
              filter: false,
              sort: true,
            }
        },
        {
            name: "office_name",
            label: "Office Name",
            options: {
              filter: false,
              sort: true,
            }
        },
        {
            name: "booking_id",
            label: "Booking Id",
            options: {
              filter: false,
              sort: true,
            }
        },
        {
            name: "Call_center_staff",
            label: "Call Center Staff",
            options: {
              filter: false,
              sort: true,
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
              filter: false,
              sort: true,
            }
        },
        {
            name: "req_type",
            label: "Request type",
            options: {
              filter: false,
              sort: true,
            }
        },
        {
            name: "description",
            label: "Description",
            options: {
              filter: false,
              sort: true,
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return <button className="table-btn btn-primary">{value}</button>;
              },
            }
        },
    ];

    const data = [
      {
        req_no: "FLT-103",
        date: "17-Sep-2022 11:47 AM",
        office_name: "ZAIRAA Main Office",
        booking_id: "276",
        Call_center_staff: "Demo Two",
        status: "New",
        req_type: "Issue",
        description: "Issue Ticket Description",
        action: "Take this Booking"
      },
      {
        req_no: "FLT-103",
        date: "17-Sep-2022 11:47 AM",
        office_name: "ZAIRAA Main Office",
        booking_id: "276",
        Call_center_staff: "Demo Two",
        status: "New",
        req_type: "Issue",
        description: "Issue Ticket Description",
        action: "Take this Booking"
      },
      {
        req_no: "FLT-103",
        date: "17-Sep-2022 11:47 AM",
        office_name: "ZAIRAA Main Office",
        booking_id: "276",
        Call_center_staff: "Demo Two",
        status: "New",
        req_type: "Issue",
        description: "Issue Ticket Description",
        action: "Take this Booking"
      },
    ];

    const options = {
      filter:false,
      Selection:false,
      print:false,
      download:false,
      viewColumns:false,
      displayRowCheckbox:false,
      selectableRows: 'none',
      onRowClick: (rowData) => {
        navigate(`/booking-details/${rowData[3]}/active`)
      },
    };
  return (
    <div className='py-4 px-2 mt-5'>
    <MUIDataTable
    className='muidatatable'
          title={"Booking Filter Record"}
          data={data}
          columns={columns}
          options={options}
        />
    </div>
  )
}

export default Table