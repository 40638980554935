import React, {useState} from "react";
import DeleteModal from "../../DeleteModal";
import { TextField } from "@mui/material";
import MUIDataTable from "mui-datatables";
import CustomDropdown from "../../CustomDrp";
import ReactDatePicker from "react-datepicker";

const HotelRates = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const columns = [
    {
      name: "id",
      label: "S.No",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "hotel",
      label: "hotel",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "room_nature",
      label: "room nature",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "room_type",
      label: "room type",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "supplier_name",
      label: "supplier name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "from_date",
      label: "from date",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "to_date",
      label: "to date",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "roe",
      label: "roe",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "pay sar",
      label: "pay sar",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "rec sar",
      label: "rec sar",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "Action",
      label: "action",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <button
              className="btn-danger rounded edit-delete-icon-button"
              onClick={() => {
                setShowDeleteModal(true);
                setModalData(tableMeta.rowData);
              }}
            >
              <i className="fa fa-trash" aria-hidden="true"></i>
            </button>
          );
        },
      },
    },
  ];

  const data = [
    {
      id: 1,
      hotel: "Swissotel Al Maqam Makkah",
      room_nature: "room",
      room_type: "Deluxe",
      supplier_name: "",
      from_date: "",
      to_date: "",
      roe: "",
      pay_sar: "",
      rec_sar: "",
    },
  ];
  const options = {
    filter: false,
    Selection: false,
    print: false,
    download: false,
    viewColumns: false,
    displayRowCheckbox: false,
    selectableRows: "none",
  };
  return (
    <div className="col-12 col-sm-11 mx-auto rounded-3 shadow mt-5 pb-5 flash-news">
      <p className="h4 otawix-heading">Hotel Rates</p>
      <div className="col-12 px-5 mt-5">
        <div className="col-10 mx-auto umrah-hotel-rates">
          <div className="col-12 d-flex">
            <div className="col-4">
              <CustomDropdown
                arry={["AED", "EURO", "Room 3", "Room 4"]}
                placehlder="Hotel *"
              />
            </div>
          </div>
          <div className="col-12 py-2 mt-3 d-flex">
            <div className="col-sm-6 me-5"></div>
            <div className="col-sm-5 buttons d-flex">
              <button className="btn btn-danger me-2">Rem Room Type</button>
              <button className="btn btn-primary">Add Room Type</button>
            </div>
            <div className="col-sm-1"></div>
          </div>
          <Hotels />
          <button className="btn setup-btn mt-5">save</button>
        </div>
      </div>
      <div className="col-12 mt-5 px-4">
      <DeleteModal
          show={showDeleteModal}
          setShow={setShowDeleteModal}
          rowId={modalData && modalData[0]}
        />
        <MUIDataTable
          className="muidatatable"
          title={"Hotel Rates Details"}
          data={data}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  );
};

const Hotels = () => {
  return (
    <>
      <div className="col-12 mt-5 d-flex flex-wrap">
        <div className="col-4 me-5">
          <CustomDropdown
            arry={["AED", "EURO", "Room 3", "Room 4"]}
            placehlder="Room Nature"
          />
        </div>
        <div className="col-3 me-5">
          <CustomDropdown
            arry={["AED", "EURO", "Room 3", "Room 4"]}
            placehlder="Room Name"
          />
        </div>
        <div className="col-3">
          <CustomDropdown
            arry={["AED", "EURO", "Room 3", "Room 4"]}
            placehlder="Supplier"
          />
        </div>
      </div>
      <div className="col-12 mt-5 d-flex flex-wrap align-items-end">
        <TextField
          id="standard-basic"
          label="ROE"
          variant="standard"
          className="col-sm-4 me-5"
        />
        <div className="col-sm-3 me-5 align-items-end">
          <ReactDatePicker
            monthsShown={2}
            className="inputs"
            placeholderText="Rate Valid From (Date)"
          />
        </div>
        <div className="col-sm-3 me-5 align-items-end">
          <ReactDatePicker
            monthsShown={2}
            className="inputs"
            placeholderText="Rate Valid To (Date)"
          />
        </div>
      </div>
      <div className="col-12 py-2 mt-5 d-flex">
        <div className="col-sm-6 me-5"></div>
        <div className="col-sm-5 buttons d-flex">
          <button className="btn btn-danger me-2">Rem Room Rate</button>
          <button className="btn btn-primary">Add Room Rate</button>
        </div>
        <div className="col-sm-1"></div>
      </div>
      <Rooms isFirst={true} />
      <Rooms isFirst={false} />
    </>
  );
};

const Rooms = ({ isFirst }) => {
  return (
    <div className="col-12 mt-5 d-flex flex-wrap">
      <div className="col-4 me-5">
        <CustomDropdown
          arry={["AED", "EURO", "Room 3", "Room 4"]}
          placehlder="Select Room"
        />
      </div>
      <TextField
        id="standard-basic"
        label="Pay SAR"
        variant="standard"
        className="col-sm-3 me-5"
      />
      <TextField
        id="standard-basic"
        label="Rec SAR"
        variant="standard"
        className="col-sm-3 me-5"
      />
    </div>
  );
};
export default HotelRates;
