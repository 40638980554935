import React from 'react'
import ManualInvoic from '../components/Finance/Vouchers/ManualInvoice'

function ManualInvoice() {
  return (
    <div className='Full_height'>
      <ManualInvoic />
    </div>
  )
}

export default ManualInvoice