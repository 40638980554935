import React from 'react';
import Header from '../components/Common/Header'
import AgentsComp from '../components/Setup/Agents'
import Footer from '../components/Footer'

const Agents = () => {
  return (
    <div className='Full_height'>
      <Header active="setup" />
      <AgentsComp />
      <Footer />
    </div>
  )
}

export default Agents