import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import countries from "../Countries.json";
import CustomDropdown from "../../CustomDrp";

const Plan = ({ family_status, setFamilyStatus, formData, setFormData }) => {
  const [isCovidcovered, setCovidCovered] = useState(true);
  const [countriesDropdown, setCountriesDropdown] = useState([]);

  useEffect(() => {
    let countriesArray = countries.filter(
      (country) =>
        country.Category === "WORLDWIDE" || country.Category === "SCENGEN"
    );
    setCountriesDropdown(countriesArray.map(coun => {return coun.Countries}));
  }, []);

  const TripType = (value) => {
    setFormData({ ...formData, trip: value });
    let countriesArray = [];
    if (value === "WORLDWIDE") {
      countriesArray = countries.filter(
        (country) =>
          country.Category === "WORLDWIDE" || country.Category === "SCENGEN"
      );
    } else {
      countriesArray = countries.filter(
        (country) => country.Category === value
      );
    }
    setCountriesDropdown(countriesArray.map(coun => {return coun.Countries}));
  };
  return (
    <div className="row">
      <div className="row">
        <div className="col-md-6 d-flex my-4">
          <span class="form-control me-5">
            <input type="radio" id="covered" name="covid-status" checked />
            <label for="covered" style={{ marginLeft: "5px" }}>
              Covid Covered{" "}
            </label>
          </span>
          <span class="form-control">
            <input type="radio" id="not-covered" name="covid-status" />
            <label for="not-covered" style={{ marginLeft: "5px" }}>
              Covid Not Covered{" "}
            </label>
          </span>
        </div>
        <div className="col-md-6 d-flex my-4">
          <span class="form-control me-5">
            <input type="radio" id="single" name="family_status" checked />
            <label for="single" style={{ marginLeft: "5px" }}>
              single
            </label>
          </span>
          <span class="form-control">
            <input type="radio" id="family" name="family_status" />
            <label for="family" style={{ marginLeft: "5px" }}>
              Family
            </label>
          </span>
        </div>
      </div>
      <div className="col-12 mt-5 d-flex">
        <span class="form-control me-5">
          <input
            type="radio"
            id="worldwide"
            value="WORLDWIDE"
            name="family_status"
            onChange={(e) => TripType(e.target.value)}
            checked={formData.trip === "WORLDWIDE"}
          />
          <label for="worldwide" style={{ marginLeft: "5px" }}>
            Worldwide
          </label>
        </span>
        <span class="form-control me-5">
          <input
            type="radio"
            id="rest-of-world"
            value="RESTOFTHEWORLD"
            onChange={(e) => TripType(e.target.value)}
            name="family_status"
            checked={formData.trip === "RESTOFTHEWORLD"}
          />
          <label for="rest-of-world" style={{ marginLeft: "5px" }}>
            Rest Of The world
          </label>
        </span>
        <span class="form-control me-5">
          <input
            type="radio"
            id="schengen"
            value="SCENGEN"
            name="family_status"
            onChange={(e) => TripType(e.target.value)}
            checked={formData.trip === "SCENGEN"}
          />
          <label for="schengen" style={{ marginLeft: "5px" }}>
            Schengen Countries
          </label>
        </span>
      </div>
      <div className="col-12 mt-5">
        <div className="row">
          <div className="col-sm-4 align-items-end">
            <ReactDatePicker
              minDate={new Date()}
              monthsShown={2}
              className="date_picker mt-4"
              value={formData.from_date}
              placeholderText="From Date"
              onChange={(date) =>
                setFormData({
                  ...formData,
                  from_date: new Date(date).toLocaleDateString(),
                })
              }
            />
          </div>
          <div className="col-sm-4">
            <CustomDropdown
              arry={["10 days", "15 days", "21 days"]}
              placehlder="Select Days"
            />
          </div>
          <div className="col-sm-4">
            <CustomDropdown
              arry={countriesDropdown}
              placehlder="Select Country"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plan;
