import { Button, TextField } from '@mui/material'
import React from 'react'
import ReactDatePicker from 'react-datepicker'
import CustomDropdown from '../../../CustomDrp'
import Header from '../../../Common/Header'
import SearchIcon from '@mui/icons-material/Search';
import Segment from './Segment'
import PAX from './PAX'
import Fare from './Fare'

function ManualCredit() {
    const [startDate, setStartDate] = React.useState("");
    const [ServiceType, setServiceType] = React.useState("Journal Voucher");
    return (
        <div className='finance-container '>
            <Header />
            <div className="manual_invoices width_95percent ">
                <div className="manual_invoices_header">
                    <div>Manual Credit</div>
                </div>
                <div className='px-2 px-md-4'>
                    <div className="row py-4 align-items-end">
                        <div className='col-6 col-md-3'>
                            <CustomDropdown arry={['Flight', 'Insurance', 'Visa']} placehlder='Service Type' setfunction={setServiceType} />
                        </div>
                        <div className='col-6 col-md-3'>
                            <TextField label="Search PNR / Policy No / Invoice No" variant="standard" className="w-100" />
                        </div>
                        <div className='col-12 col-md-3 mt-3 mt-md-0'>
                            <Button variant="contained" startIcon={<SearchIcon />}>
                                Search
                            </Button>
                        </div>
                    </div>

                    <hr />
                    <div>
                        <PAX />
                        <hr />
                        <Segment />
                        <hr />
                        <Fare />
                    </div>



                    <hr />

                    {/* total  */}
                    <div className=" py-2">
                        <div className="h5 text-center my-2">Total</div>
                        <div className="d-flex justify-content-center align-items-center flex-column my-4">
                            <div className="h6 mb-2">
                                Customers = 330 PKR
                            </div>
                            <div className="h6 mb-2">
                                Supplier = 330 PKR
                            </div>

                            <Button size="medium" variant='contained'>Submit</Button>
                        </div>
                    </div>
                </div>

                {/* ********** */}
            </div>
        </div>
    )
}

export default ManualCredit