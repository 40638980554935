import React, { useEffect } from "react";

const CountriesDropdown = ({ id, countriesList }) => {
  return (
    <div
      className="dropdown-menu px-3 shadow"
      id={id}
      style={{
        width: "25rem",
        maxHeight: "30rem",
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      {countriesList.by_code.length > 0 ||
      countriesList.by_city_name.length > 0 ||
      countriesList.by_airport_name.length > 0 ||
      countriesList.by_start_char.length > 0 ? (
        <>
          {countriesList.by_code.map((country) => {
            return (
              <>
                <div className="d-flex mt-2">
                  <div>{country.code}</div>
                  <div>-</div>
                  <div>{country.airport_name}</div>
                </div>
                {country.airports
                  ?.filter((cin) => cin.code !== country.code)
                  .map((array) => {
                    return (
                      <div className="d-flex mt-2 px-5 py-1">
                        <div>{array.code}</div>
                        <div>-</div>
                        <div>{array.airport_name}</div>
                      </div>
                    );
                  })}
                {/* countriesList.by_airport_name.map(array => {
        if(array.city.includes(country.city)) {
            return <div className='d-flex mt-2 px-5'>
            <div>{country.code}</div>
            <div>-</div>
            <div>{country.airport_name}</div>
            </div>
        }
    })*/}
              </>
            );
          })}
          {countriesList.by_start_char.map((country) => {
            return (
              <div className="d-flex mt-4 dropdown-item">
                {`${country.code} - ${country.airport_name}`}
              </div>
            );
          })}
          {countriesList.by_airport_name.map((country) => {
            return (
              <div className="d-flex mt-4 dropdown-item">
                {`${country.code} - ${country.airport_name}`}
              </div>
            );
          })}
        </>
      ) : (
        <span>No country found</span>
      )}
    </div>
  );
};

export default CountriesDropdown;
