import MUIDataTable from 'mui-datatables';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import Header from '../../../Common/Header'
import columns from './Columns'
import data from './data'

function AllVoucher() {
    const navigate = useNavigate();



    const options = {

        Selection: false,
        print: false,
        download: false,
        viewColumns: false,
        displayRowCheckbox: false,
        selectableRows: 'none',
        onRowClick: (rowData) => {
            navigate(`/booking-details/${Number(rowData[2].split(" ")[0])}`)
        },
    };
    return (
        <div className='Full_height'>
            <Header />
            <div className="manual_invoices width_80percent mx-auto">
                <div className="manual_invoices_header">
                    <div>Vouchers List</div>
                </div>
                <div className='p-4'>
                    <MUIDataTable
                        className=' '
                        title={""}
                        data={data}
                        columns={columns}
                        options={options}
                    />
                </div>

            </div>
        </div>)
}

export default AllVoucher