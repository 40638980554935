import React from 'react'
import Header from '../components/Common/Header'
import AgentCategoriesComp from '../components/Setup/AgentCategories'
import Footer from '../components/Footer'

const AgentCategories = () => {
  return (
    <div className='Full_height'>
      <Header active="setup" />
      <AgentCategoriesComp />
      <Footer />
    </div>
  )
}

export default AgentCategories