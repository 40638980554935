import React from 'react'
import Header from '../components/Common/Header';
import FlightsTable from "../components/FlightRequest/RequestsTable";
import Footer from '../components/Footer'

const FlightRequest = () => {
  return (
    <div className='Full_height'>
      <Header active="flight-requests" />
      <FlightsTable />
      <Footer />
    </div>
  )
}

export default FlightRequest