import React, {useState} from "react";
import { TextField } from "@mui/material";
import MUIDataTable from "mui-datatables";
import CustomDropdown from "../../CustomDrp";
import ReactDatePicker from "react-datepicker";
import DeleteModal from "../../DeleteModal";

const FlightsGroup = () => {
  const columns = [
    {
      label: "Airline Logo",
      name: "airline_logo",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <img src={`${value}`} className="table-row-image" />;
        },
      },
    },
    {
      label: "PDF File",
      name: "pdf_file",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <a href={`${value.link}`} className="table-anchor">
              {value.name}
            </a>
          );
        },
      },
    },
    {
      name: "remarks",
      label: "Remarks",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "Action",
      name: "delete",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="">
              <button
                className="btn-success rounded edit-delete-icon-button me-1"
                onClick={() => console.log(value, tableMeta)}
              >
                <i className="fa fa-thin fa-pencil"></i>
              </button>
              <button
                className="btn-danger rounded edit-delete-icon-button"
                onClick={() => console.log(value, tableMeta)}
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
              </button>
            </div>
          );
        },
      },
    },
  ];

  const data = [
    {
      id: 1,
      airline_logo: "/zairaa.jpeg",
      pdf_file: {
        name: "abc.pdf",
        link: "abc.com",
      },
      remarks: "Test Circular 01",
      action: 1,
    },
  ];
  const options = {
    filter: false,
    Selection: false,
    print: false,
    download: false,
    viewColumns: false,
    displayRowCheckbox: false,
    selectableRows: "none",
    // onRowClick: (rowData) => {
    //   navigate(`/booking-details/${rowData[3]}/active`)
    // },
  };
  return (
    <div className="col-12 mx-auto rounded-2 shadow mt-5 pb-5 flash-news">
      <p className="h4 otawix-heading">Add Flight Group</p>
      <div className="col-12 px-4 d-flex flex-column justify-content-center">
        <div className="col-12 d-flex">
          <div className="col-sm-3 px-3 mt-5">
            <CustomDropdown
              arry={["Flight", "Insurance", "Visa"]}
              placehlder="Select Supplier"
            />
          </div>
          <div className="col-sm-3 px-3 mt-5">
            <CustomDropdown
              arry={["Flight", "Insurance", "Visa"]}
              placehlder="Group Type"
            />
          </div>
          <div className="col-sm-3 px-3 mt-5">
            <TextField
              id="standard-basic"
              label="Total Seats"
              variant="standard"
              className="col-sm-12"
              type="number"
            />
          </div>
          <div className="col-sm-3 px-3 mt-5">
            <TextField
              id="standard-basic"
              label="PNR"
              variant="standard"
              className="col-sm-12"
            />
          </div>
        </div>
        <div className="col-12 mt-5 px-3">
          <div className="col-12 buttons d-flex">
            <button className="btn btn-danger me-2">Remove PAX</button>
            <button className="btn btn-primary">Add PAX</button>
          </div>
        </div>
        <AddPassanger />
        <div className="col-12 mt-5 px-3">
          <div className="col-12 d-flex buttons">
            <button className="btn btn-danger me-2">Remove Segment</button>
            <button className="btn btn-primary">Add Segment</button>
          </div>
        </div>
        <AddSegment />
        <button className="btn setup-btn mt-5">save</button>
      </div>
      <div className="col-12 mt-5 px-4">
        <table className="table datatable-basic table-bordered dataTable no-footer">
          <thead>
            <tr className="table-row">
              <th style={{ width: "1%" }}>Group Name | Type</th>
              <th style={{ width: "1%" }}>Total | Reaming Seats</th>
              <th style={{ width: "1%" }}>Supplier</th>
              <th style={{ width: "1%" }}>PNR</th>
              <th style={{ width: "1%" }}>PAX Type</th>
              <th style={{ width: "1%" }}>Payable | Receivable</th>
              <th style={{ width: "1%" }}>Flight Code | Number</th>
              <th style={{ width: "1%" }}>Origin | Destination</th>
              <th style={{ width: "1%" }}>Departure Date</th>
              <th style={{ width: "1%" }}>Departure Time</th>
              <th style={{ width: "1%" }}>Arrival Date</th>
              <th style={{ width: "1%" }}>Arrival Time</th>
              <th style={{ width: "1%" }}>Baggage</th>
              <th style={{ width: "1%" }}>Action</th>
            </tr>
          </thead>
          <tbody>
            <TableRow />
            <TableRow />
            <TableRow />
            <TableRow />
            <TableRow />
            <TableRow />
            <TableRow />
          </tbody>
        </table>
      </div>
    </div>
  );
};

const AddPassanger = () => {
  return (
    <div className="col-12 d-flex">
      <div className="col-sm-4 px-3 mt-5">
        <CustomDropdown
          arry={["Flight", "Insurance", "Visa"]}
          placehlder="Passanger Type"
        />
      </div>
      <div className="col-sm-4 px-3 mt-5">
        <TextField
          id="standard-basic"
          label="Total Seats"
          variant="standard"
          className="col-sm-12"
          type="number"
        />
      </div>
      <div className="col-sm-4 px-3 mt-5">
        <TextField
          id="standard-basic"
          label="Supplier Payable"
          variant="standard"
          className="col-sm-12"
        />
      </div>
    </div>
  );
};

const AddSegment = () => {
  return (
    <div className="col-12 d-flex align-items-end">
      <div className="col-sm-1 px-3 mt-5">
        <TextField
          id="standard-basic"
          label="Flight"
          variant="standard"
          className="col-sm-12"
          type="number"
        />
      </div>
      <div className="col-sm-1 px-3 mt-5">
        <TextField
          id="standard-basic"
          label="Flight No"
          variant="standard"
          className="col-sm-12"
        />
      </div>
      <div className="col-sm-2 px-3 mt-5">
        <TextField
          id="standard-basic"
          label="Origin"
          variant="standard"
          className="col-sm-12"
        />
      </div>
      <div className="col-sm-2 px-3 mt-5">
        <TextField
          id="standard-basic"
          label="Destination"
          variant="standard"
          className="col-sm-12"
        />
      </div>
      <div className="col-sm-1 px-3 mt-5 align-items-end">
        <ReactDatePicker
          minDate={new Date()}
          className="date_picker h6"
          placeholderText="Dep Date"
          monthsShown={2}
        />
      </div>
      <div className="col-sm-1 px-3 mt-5">
        <TextField
          id="standard-basic"
          label="Dep Time"
          variant="standard"
          className="col-sm-12"
        />
      </div>
      <div className="col-sm-1 px-3 mt-5 align-items-end">
        <ReactDatePicker
          minDate={new Date()}
          className="date_picker h6"
          placeholderText="Arrival Date"
          monthsShown={2}
        />
      </div>
      <div className="col-sm-1 px-3 mt-5">
        <TextField
          id="standard-basic"
          label="Arrival time"
          variant="standard"
          className="col-sm-12"
        />
      </div>
      <div className="col-sm-2 px-3 mt-5">
        <TextField
          id="standard-basic"
          label="Baggage Info"
          variant="standard"
          className="col-sm-12"
        />
      </div>
    </div>
  );
};

const TableRow = (rowdata) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  return (
    <>
    <DeleteModal
          show={showDeleteModal}
          setShow={setShowDeleteModal}
          rowId={modalData && modalData[0]}
        />
      <tr>
        <td>LHEJED-20OCT-ERABCD | Umrah</td>
        <td>25 | 15</td>
        <td>Serene AIR</td>
        <td>65</td>
        <td colSpan={9}><span className="d-flex justify-content-between align-items-center checkbox-rect">
        <input
          type="checkbox"
          id="Airsial"
          style={{
            width: "1rem",
            height: "1rem",
            marginRight: "8px",
          }}
        />
        <label for="Airsial">
          Group Status
        </label>
      </span></td>
        <td>
          <div className="">
            <button
              className="btn-success rounded edit-delete-icon-button me-1"
              // onClick={() => console.log(value, tableMeta)}
            >
              <i className="fa fa-thin fa-pencil"></i>
            </button>
            <button
              className="btn-danger rounded edit-delete-icon-button"
              onClick={() => {
                setShowDeleteModal(true);
                setModalData(rowdata);
              }}
            >
              <i className="fa fa-trash" aria-hidden="true"></i>
            </button>
          </div>
        </td>
      </tr>
      <tr>
      <td colSpan={4}></td>
      <td>ADT</td>
      <td>50000 | 45000</td>
      <td colSpan={8}></td>
      </tr>
      <tr>
      <td colSpan={4}></td>
      <td>INF</td>
      <td>50000 | 45000</td>
      <td colSpan={8}></td>
      </tr>
      <tr>
      <td colSpan={6}></td>
      <td>	ER | 123</td>
      <td>LHE | JED</td>
      <td>2022-10-20</td>
      <td>15:15:00</td>
      <td>2022-10-20</td>
      <td>17:25:00</td>
      <td colSpan={2}>20KG</td>
      </tr>
      <tr className="bg-secondary">
      <td colSpan={14} style={{height: "2px"}}></td>
      </tr>
    </>
  );
};

export default FlightsGroup;
