import { Button, TextField } from '@mui/material';
import React from 'react'
import ReactDatePicker from 'react-datepicker';
import CustomDropdown from '../../../CustomDrp';

function Activity() {
    const [startDate, setStartDate] = React.useState("");
    return (
        <div className='py-4 '>
            <div className='w-75 pb-4'>
                <div className='d-flex justify-content-between align-items-end py-3 '>
                    <div className='h5'>Activity</div>
                    <div>
                        <Button size="small" variant='contained' color='error' className='me-2'>Remove Activity</Button>
                        <Button size="small" variant='contained'>Add Activity</Button>
                    </div>
                </div>
                <div className='row justify-content-start align-items-end my-3'>
                    <div className='d-flex justify-content-between'>
                        <div className='h6'>PAX</div>
                        <div>
                            <Button size="small" variant='contained' color='error' className='me-2'>Remove PAX</Button>
                            <Button size="small" variant='contained'>Add PAX</Button>
                        </div>
                    </div>
                    {/* pax info */}

                    <div className='col-3'>
                        <CustomDropdown arry={['Adult', 'Child']} placehlder='Select Type' />
                    </div>
                    <div className='col-3'>
                        <CustomDropdown arry={['MS', 'MR', 'MRS']} placehlder='Select Title' />
                    </div>
                    <div className='col-3'>
                        <TextField label="F-Name" variant="standard" className="w-100" />
                    </div>
                    <div className='col-3'>
                        <TextField label="L-Name" variant="standard" className="w-100" />
                    </div>


                </div>

                {/* currency detail  */}
                <div className='row justify-content-start align-items-end my-4'>
                    <div className='col-2'>
                        <CustomDropdown arry={['AED', 'EURO', 'Room 3', 'Room 4']} placehlder='Currency' />
                    </div>
                    <div className='col-2'>
                        <TextField label="ROE" variant="standard" className="w-100" />
                    </div>
                    <div className='col-2'>
                        <TextField label="AED Pay" variant="standard" className="w-100" />
                    </div>
                    <div className='col-2'>
                        <TextField label="AED REC" variant="standard" className="w-100" />
                    </div>
                    <div className='col-2'>
                        <TextField label="PKR Pay" variant="standard" className="w-100" />
                    </div>
                    <div className='col-2'>
                        <TextField label="PKR REC" variant="standard" className="w-100" />
                    </div>
                </div>

            </div>



            {/* activity detail  */}
            <div className='row justify-content-start align-items-end my-2'>
                <div className='h6'>Activity Detail</div>
                {/* pax info */}

                <div className='col-3'>
                    <TextField label="Destination City" variant="standard" className="w-100" />
                </div>
                <div className='col-3'>
                    <TextField label="Activity/Toure" variant="standard" className="w-100" />
                </div>
                <div className='col-3'>
                    <ReactDatePicker
                        placeholderText="Date"
                        selected={startDate}
                        onChange={(date) => {
                            setStartDate(date);
                        }}
                        value={new Date()}
                        monthsShown={2}
                        // onCalendarClose={handleCalendarClose}
                        minDate={new Date()}
                        // onCalendarOpen={handleCalendarOpen}
                        className="date_picker"
                    />
                </div>



            </div>


            <hr />
            {/* total  */}
            <div className="pt-2">
                <div className="h5 text-center my-2">Total</div>
                <div className="d-flex justify-content-center align-items-center flex-column my-4">
                    <div className="h6 mb-4">
                        Customer = 330 PKR
                    </div>
                    <div className="h6">
                        Supplier = 330 PKR
                    </div>
                    <Button size="small" variant='contained' className='mt-2'>Generate</Button>
                </div>
            </div>
        </div>
    )
}

export default Activity