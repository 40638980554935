import React from "react";
import { TextField } from "@mui/material";
import CountryDropdown from "country-dropdown-with-flags-for-react";

const CorporateRegistration = () => {
  return (
    <div className="col-12 mx-auto rounded-2 shadow mt-5 pb-5 flash-news d-flex flex-column justify-content-center">
      <p className="h4 otawix-heading">New corporate Registration</p>
      <div className="col-12 mt-3">
        <p className="h5 otawix-sub-heading px-5">Price Setting</p>
        <div className="row px-5">
          <div className="col-sm-3 px-3 mt-5">
            <TextField
              label="Buyer Group"
              variant="standard"
              className="col-12"
            />
          </div>
          <div className="col-sm-3 px-3 mt-5">
            <TextField
              label="Authorized By"
              variant="standard"
              className="col-12"
            />
          </div>
          <div className="col-sm-2 px-3 mt-5">
            <TextField
              label="Office Type"
              variant="standard"
              className="col-12"
            />
          </div>
          <div className="col-sm-2 px-3 mt-5">
            <TextField
              label="Key account manager *"
              variant="standard"
              className="col-12"
              defaultValue={"jgschsfcu"}
            />
          </div>
          <div className="col-sm-2 px-3 mt-5">
            <TextField
              label="Payment Type"
              variant="standard"
              className="col-12"
            />
          </div>
        </div>
      </div>
      <div className="col-12 mt-5">
        <p className="h5 otawix-sub-heading px-5">
          Comapny / Personal Information
        </p>
        <div className="row px-5">
          <div className="col-sm-4 px-3 mt-5">
            <TextField
              label="Office Name"
              variant="standard"
              className="col-12"
            />
          </div>
          <div className="col-sm-4 px-3 mt-5">
            <TextField
              label="Official Company Name"
              variant="standard"
              className="col-12"
            />
          </div>
          <div className="col-sm-4 px-3 mt-5">
            <TextField
              label="Office ID"
              variant="standard"
              className="col-12"
            />
          </div>
        </div>
        <div className="row px-5">
          <div className="col-sm-2 px-3 mt-5">
            <TextField label="Title" variant="standard" className="col-12" />
          </div>
          <div className="col-sm-2 px-3 mt-5">
            <TextField
              label="First Name"
              variant="standard"
              className="col-12"
            />
          </div>
          <div className="col-sm-2 px-3 mt-5">
            <TextField
              label="Last Name"
              variant="standard"
              className="col-12"
            />
          </div>
          <div className="col-sm-4 px-3 mt-5">
            <TextField label="Email" variant="standard" className="col-12" />
          </div>
          <div className="col-sm-2 px-3 mt-5">
            <TextField label="Phone" variant="standard" className="col-12" />
          </div>
        </div>
        <div className="row px-5 align-items-end">
          <div className="col-12 col-sm-4 px-3 mt-5">
            <TextField
              label="Registration"
              variant="standard"
              className="col-12"
            />
          </div>
          <div className="col-12 col-sm-4 px-3 mt-5">
            <TextField
              label="Tax Number"
              variant="standard"
              className="col-12"
            />
          </div>
          <div className="col-sm-4 px-3 mt-5">
            <CountryDropdown
              id="UNIQUE_ID"
              className="inputs absolute col-12"
              preferredCountries={["pk"]}
              value="pakistan"
              handleChange={(e) => console.log(e.target.value)}
            />
          </div>
        </div>
        <div className="row px-5">
          <div className="col-sm-4 px-3 mt-5">
            <TextField label="City" variant="standard" className="col-12" />
          </div>
          <div className="col-sm-2 px-3 mt-5">
            <TextField
              label="Zip Code"
              variant="standard"
              className="col-12"
            />
          </div>
          <div className="col-sm-6 px-3 mt-5">
            <TextField
              label="Address"
              variant="standard"
              className="col-12"
            />
          </div>
        </div>
        <div className="row px-5">
          <div className="col-sm-6 px-3 mt-5">
            <TextField
              label="Company URL"
              variant="standard"
              className="col-12"
            />
          </div>
          <div className="col-sm-6 px-3 mt-5">
            <TextField
              label="Official Email"
              variant="standard"
              className="col-12"
            />
          </div>
        </div>
        <div className="row px-5">
          <div className="col-sm-4 px-3 mt-5">
            <TextField
              label="Office Number"
              variant="standard"
              className="col-12"
            />
          </div>
          <div className="col-sm-4 px-3 mt-5">
            <TextField
              label="Emergency Phone Number"
              variant="standard"
              className="col-12"
            />
          </div>
          <div className="col-sm-4 px-3 mt-5">
            <TextField
              label="Skype ID"
              variant="standard"
              className="col-12"
            />
          </div>
        </div>
        <div className="row px-5">
          <div className="col-sm-4 px-3 mt-5">
            <TextField
              label="Pasword"
              variant="standard"
              className="col-12"
            />
          </div>
          <div className="col-sm-4 px-3 mt-5">
            <TextField
              label="Repeat Password"
              variant="standard"
              className="col-12"
            />
          </div>
        </div>
      </div>
      <button className="btn setup-btn mt-5 mx-auto">save</button>
    </div>
  );
};

export default CorporateRegistration;
