import { TextField } from '@mui/material'
import React from 'react'

function Segment() {
    return (
        <div className='row align-items-end py-2'>
            <div className='h5'>Segment</div>
            <div className='col-4 col-md-2'>
                <TextField label="FZ" variant="standard" className="w-100" disabled />
            </div>
            <div className='col-8 col-md-2'>
                <TextField label="LYP - Faisalabad, Pakistan " variant="standard" className="w-100" disabled />
            </div>
            <div className='col-6 col-md-3'>
                <TextField label="DXB - Dubai, United Arab Emirates - International " variant="standard" className="w-100" disabled />
            </div>
            <div className='col-6 col-md-3'>
                <TextField label="2022-01-04T17:25:00.00+05" variant="standard" className="w-100" disabled />
            </div>

            <div className='col-4 col-md-2'>
                <TextField label="Seat/Baggage" variant="standard" className="w-100" disabled />
            </div>
        </div>
    )
}

export default Segment