import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";

const Traveler = ({formData, setFormData }) => {
  const [multiRows, setMultiRows] = useState([]);

  const addRow = () => {
    const newvalue = multiRows[multiRows.length - 1];
    setMultiRows([...multiRows, newvalue ? newvalue + 1 : 1]);
  };
  const removeRow = (value) => {
    setMultiRows(multiRows.filter((val) => val !== value));
  };
  return (
    <div>
      <div className="row">
        <div className="col-sm-6">
          <input
            type="text"
            className="inputs_flight_tab"
            placeholder="Name Of Insured"
            value={formData.insured_name}
            onChange={e => setFormData({...formData, insured_name: e.target.value})}
          />
        </div>
        <div className="col-sm-6">
          <ReactDatePicker
            className="inputs_flight_tab"
            placeholderText="Date Of Birth"
            monthsShown={2}
            value={formData.date_of_birth}
            onChange={date => setFormData({ ...formData, date_of_birth: new Date(date).toLocaleDateString()})}
          />
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-sm-6">
          <input
            type="email"
            className="inputs_flight_tab"
            placeholder="Email"
            value={formData.email}
            onChange={e => setFormData({...formData, email: e.target.value})}
          />
        </div>
        <div className="col-sm-6">
          <input
            type="text"
            className="inputs_flight_tab"
            placeholder="Passport No"
            value={formData.passport_no}
            onChange={e => setFormData({...formData, passport_no: e.target.value})}
          />
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-sm-6">
          <input type="text" className="inputs_flight_tab" placeholder="CNIC"
          value={formData.cnic}
          onChange={e => setFormData({...formData, cnic: e.target.value})}
          />
        </div>
        <div className="col-sm-6">
          <input
            type="text"
            className="inputs_flight_tab"
            placeholder="Address"
            value={formData.address}
            onChange={e => setFormData({...formData, address: e.target.value})}
          />
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-sm-6">
          <input
            type="text"
            className="inputs_flight_tab"
            placeholder="Contact"
            value={formData.contact}
            onChange={e => setFormData({...formData, contact: e.target.value})}
          />
        </div>
        <div className="col-sm-6 checkbox-rect"><input
        type="checkbox"
        id="all_select"
        checked={formData.isActiveTaxPayer}
        onChange={e => setFormData({...formData, isActiveTaxPayer: e.target.checked})}
        style={{marginRight:'8px'}}
      />
      {!formData.isActiveTaxPayer && <label for="all_select">Active Tax Payer</label>}
      {formData.isActiveTaxPayer && 
        <label><input
        type="text"
        className="inputs_flight_tab ml-3"
        placeholder="Enter NTN No"
        value={formData.address}
        onChange={e => setFormData({...formData, address: e.target.value})}
      /></label>}</div>
      </div>

      {formData.family_status === "family" && (
        <div className="mt-5">
          <h4>Family</h4>
          <hr />
          <div className="row">
            <div className="col-sm-4">
              <input className="inputs_flight_tab" placeholder="Spouse" />
            </div>
            <div className="col-sm-2">
              <input className="inputs_flight_tab" placeholder="Age" />
            </div>
            <div className="col-sm-4">
              <input className="inputs_flight_tab" placeholder="passport No" />
            </div>
            <div className="col-sm-2"></div>
          </div>

          <div className="row mt-5">
            <div className="col-sm-4">
              <input className="inputs_flight_tab" placeholder="Children 1" />
            </div>
            <div className="col-sm-2">
              <input className="inputs_flight_tab" placeholder="Age" />
            </div>
            <div className="col-sm-4">
              <input className="inputs_flight_tab" placeholder="passport No" />
            </div>
            <div className="col-sm-2">
              <button
                className="btn btn-small border rounded-2 px-1"
                onClick={addRow}
              >
                <i className="fa  fa-thin fa-plus"></i>
              </button>
            </div>
          </div>
          {multiRows.map(val => <div className="row mt-5">
          <div className="col-sm-4">
            <input className="inputs_flight_tab" placeholder="Children 1" />
          </div>
          <div className="col-sm-2">
            <input className="inputs_flight_tab" placeholder="Age" />
          </div>
          <div className="col-sm-4">
            <input className="inputs_flight_tab" placeholder="passport No" />
          </div>
          <div className="col-sm-2">
          <button
          className="btn btn-small btn-danger border rounded-2 px-1"
          onClick={(e) => removeRow(val)}
        >
          <i class="fa fa-close"></i>
        </button>
          </div>
        </div>)}
        </div>
      )}
    </div>
  );
};

export default Traveler;
