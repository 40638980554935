import React from 'react'

const Pricing = () => {
  return (
    <div className="row mt-4 px-5">
    <table className='table table-bordered text-nowrap table-responsive'>
    <thead>
    <tr>
    <th>Passenger</th>
    <th>Fare</th>
    <th>Taxes</th>
    <th>Total</th>
    <th>Discount</th>
    <th>PSF</th>
    <th>Receivable</th>
    </tr>
    </thead>
    <tbody>
    <tr>
    <td>ADT- MS SHAIZA MUSHTAQ</td>
    <td>12000</td>
    <td>0</td>
    <td>12000</td>
    <td>0</td>
    <td>3000</td>
    <td>15000</td>
    </tr>
    <tr>
    <td colSpan={6}><b>Total Receivable</b></td>
    <td>15000</td>
    </tr>
    </tbody>
    </table>
    </div>
  )
}

export default Pricing