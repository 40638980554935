import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import CustomDropdown from "../CustomDrp";
import DeleteModal from "../DeleteModal";
import { Modal, Button } from "react-bootstrap";
import { TextField } from "@mui/material";

const Pricing = () => {
  const [tabs, setTabs] = useState("Zairaa Main Office");
  const [tabsarray, setTabsArray] = useState([
    "Zairaa Main Office",
    "Travel Consultant",
    "B2B Portal Cash",
  ]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const columns = [
    {
      name: "sevice",
      label: "sevices",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "general",
      label: "general",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "Action",
      name: "general",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value) {
            return (
              <div className="">
                <button
                  className="btn-success rounded edit-delete-icon-button me-1"
                  onClick={() => {
                    setShowEditModal(true);
                    setModalData(data[tableMeta.rowIndex]);
                  }}
                >
                  <i className="fa fa-thin fa-pencil"></i>
                </button>
                <button
                  className="btn-danger rounded edit-delete-icon-button"
                  onClick={() => {
                    setShowDeleteModal(true);
                    setModalData(data[tableMeta.rowIndex]);
                  }}
                >
                  <i className="fa fa-trash" aria-hidden="true"></i>
                </button>
              </div>
            );
          } else {
            return (
              <button
                className="btn-primary rounded edit-delete-icon-button me-1"
                onClick={() => {
                  setShowAddModal(true);
                }}
              >
                <i className="fa fa-thin fa-plus"></i>
              </button>
            );
          }
        },
      },
    },
  ];

  const data = [
    {
      id: 1,
      sevice: "Flights",
      general: "net price 0 | 0 | 3.25 | 500 | 500 | passenger | Thai Airways",
    },
    {
      id: 1,
      sevice: "Flights",
      general: "",
    },
  ];

  const options = {
    filter: false,
    Selection: false,
    print: false,
    download: false,
    viewColumns: false,
    displayRowCheckbox: false,
    selectableRows: "none",
    // onRowClick: (rowData) => {
    //   navigate(`/booking-details/${rowData[3]}/active`)
    // },
  };
  return (
    <div className="col-10 mx-auto rounded-3 shadow my-5 pb-5 flash-news">
      <div className="pricing-container">
        <div className="pricing-navigation-tabs mx-auto">
          {tabsarray.map((tab) => (
            <div
              className={`h5  ${
                tabs === tab ? "active-link" : "pricing_details_links"
              }`}
              onClick={() => setTabs(tab)}
            >
              {tab}
            </div>
          ))}
        </div>
        <div className="col-12 col-sm-6 mt-5 d-flex justify-content-between">
          <div className="col-9">
            <CustomDropdown
              arry={[
                "Flight",
                "Visa",
                "Hotel",
                "Insurance",
                "Transfer",
                "Activity",
                "Umrah",
              ]}
              placehlder="Select Type"
            />
          </div>
          <button className="btn setup-btn col-2">save</button>
        </div>
        <div className="col-12 mt-5">
          <DeleteModal
            show={showDeleteModal}
            setShow={setShowDeleteModal}
            rowId={modalData?.id}
          />
          <EditModal
            show={showEditModal}
            setShow={setShowEditModal}
            modalData={modalData}
          />
          <AddModal
            show={showAddModal}
            setShow={setShowAddModal}
          />
          <MUIDataTable
            className="muidatatable"
            title={tabs + " Details"}
            data={data}
            columns={columns}
            options={options}
          />
        </div>
      </div>
    </div>
  );
};

export default Pricing;

const EditModal = ({ show, setShow, modalData }) => {
  const [radio, setRadio] = useState("company");
  return (
    <Modal
      show={show}
      onHide={(e) => setShow(false)}
      centered
      dialogClassName="modal-lg"
    >
      <Modal.Header>
        <Modal.Title>Update Price</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-12">
          <div className="col-12">
            <div className="col-4 px-3">
              <CustomDropdown
                arry={["Process By Bank", "Cash In Hand"]}
                placehlder="Type"
              />
            </div>
          </div>
          <div className="col-12 d-flex">
            <div className="col-4 px-3 mt-5">
              <TextField
                label="SITI Discount Internaltional %"
                variant="standard"
                className="w-100"
              />
            </div>
            <div className="col-4 px-3 mt-5">
              <TextField
                label="SITI Discount Domestic %"
                variant="standard"
                className="w-100"
              />
            </div>
            <div className="col-4 px-3 mt-5">
              <TextField
                label="SITI-SOTS PSF %"
                variant="standard"
                className="w-100"
              />
            </div>
          </div>
          <div className="col-12 d-flex">
            <div className="col-4 px-3 mt-5">
              <TextField label="Airline" variant="standard" className="w-100" />
            </div>
            <div className="col-4 px-3 mt-5">
              <TextField
                label="Void Charges"
                variant="standard"
                className="w-100"
              />
            </div>
            <div className="col-4 px-3 mt-5">
              <TextField
                label="Refund Charges"
                variant="standard"
                className="w-100"
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="modal-buttons-parent mx-auto">
          <Button
            variant="danger modal-button"
            onClick={(e) => setShow(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary modal-button"
            onClick={(e) => setShow(false)}
          >
            Update
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

const AddModal = ({ show, setShow }) => {
  const [radio, setRadio] = useState("company");
  return (
    <Modal
      show={show}
      onHide={(e) => setShow(false)}
      centered
      dialogClassName="modal-lg"
    >
      <Modal.Header>
        <Modal.Title>Add Price</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-12">
          <div className="col-12 d-flex">
            <div className="col-4 px-3 mt-3">
              <TextField
                label="Percent (%)"
                variant="standard"
                className="w-100"
              />
            </div>
            <div className="col-4 px-3 mt-3">
              <TextField
                label="Amount (PKR)"
                variant="standard"
                className="w-100"
              />
            </div>
            <div className="col-4 px-3 mt-3">
              <CustomDropdown
                arry={["Process By Bank", "Cash In Hand"]}
                placehlder="Type"
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="modal-buttons-parent mx-auto">
          <Button
            variant="danger modal-button"
            onClick={(e) => setShow(false)}
          >
            Close
          </Button>
          <Button
            variant="primary modal-button"
            onClick={(e) => setShow(false)}
          >
            Save
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
