import React from 'react';
import Header from '../components/Common/Header'
import BankDetailsComp from '../components/Setup/BankDetails'
import Footer from '../components/Footer'

const BankDetails = () => {
  return (
    <div className='Full_height'>
      <Header active="setup" />
      <BankDetailsComp />
      <Footer />
    </div>
  )
}

export default BankDetails