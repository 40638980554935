import { Button, TextField } from '@mui/material'
import React from 'react'
import CustomDropdown from '../../../CustomDrp'
import CountryDropdown from 'country-dropdown-with-flags-for-react';
import ReactDatePicker from 'react-datepicker';

function Visa() {
    return (
        <div className='py-3 pb-4'>

            <div className='d-flex align-items-center mt-2'>
                <label class="form-control me-5">
                    <input type="radio" name="radio" />
                    <span className="">30 DAYS SINGLE ENTRY / UAE-Visa</span>
                </label>
                <label class="form-control me-5">
                    <input type="radio" name="radio" />
                    <span className="">Short Stay / Uatv</span>
                </label>
                <label class="form-control">
                    <input type="radio" name="radio" />
                    <span className="">Short Stay / Silkways</span>
                </label>
            </div>

            <div className='pb-3'>

                <VisaSection />
            </div>


            <hr />

            <div className="py-2">

                <div className="h5 text-center my-2">Total</div>
                <div className="d-flex justify-content-center align-items-center flex-column my-4">

                    <div className="h6 mb-4">
                        Customer = 330 PKR
                    </div>
                    <div className="h6">
                        Supplier = 330 PKR
                    </div>
                    <Button size="small" variant='contained' className='mt-3'>Generate</Button>

                </div>

            </div>

        </div>
    )
}




const VisaSection = () => {
    const [startDate, setStartDate] = React.useState("");
    return (
        <div className='pt-4'>
            <div className='d-flex justify-content-between align-items-center pe-4'>
                <div className='h4'>Visa</div>
                <div>
                    <Button size="small" variant='contained' color='error' className='me-2'>Remove PAX</Button>
                    <Button size="small" variant='contained'>Add PAX</Button>
                </div>
            </div>
            <div className='row px-5  align-items-end'>
                <div className='col-3'>
                    <CustomDropdown arry={['Adult', 'Child', 'Infant']} placehlder='Type' />
                </div>

                <div className='col-3 '>
                    <TextField id="standard-basic" label="Payeble" variant="standard" className="w-100" type={'number'} />
                </div>
                <div className='col-3 '>
                    <TextField id="standard-basic" label="Recievable" variant="standard" className="w-100" type={'number'} />
                </div>
                <div className='col-3'></div>
            </div>

            <div className='row px-5 align-items-end my-4'>
                <div className='col-3'>
                    <TextField label="Complete Name" variant="standard" className="w-100" />
                </div>

                <div className='col-3 '>
                    <ReactDatePicker
                        placeholderText="DOB"
                        selected={startDate}
                        onChange={(date) => {
                            setStartDate(date);
                        }}
                        // onCalendarClose={handleCalendarClose}
                        minDate={new Date()}
                        // onCalendarOpen={handleCalendarOpen}
                        className="date_picker"
                    />
                </div>
                <div className='col-3 '>
                    <CountryDropdown className='inputs' value="" preferredCountries={['pk']} handleChange={e => console.log(e.target.value)}></CountryDropdown>
                </div>
                <div className='col-3'>
                    <TextField label="Pasport No" variant="standard" className="w-100" />
                </div>
            </div>
        </div>
    )
}

export default Visa