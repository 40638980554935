import MUIDataTable from "mui-datatables";
import React from "react";

const PaidVoucher = () => {
  const columns = [
    {
      name: "date",
      label: "Date/Time",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "supplier",
      label: "Supplier | Customer",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "Status By Bank",
      name: "bank_status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <button
              className="table-btn btn-success"
              onClick={() => console.log(value, tableMeta)}
            >
              {value}
            </button>
          );
        },
      },
    },
    {
      label: "Deposit Image",
      name: "deposit_slip",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <a
              role="button"
              href={value}
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <button className="table-btn btn-success">View</button>
            </a>
          );
        },
      },
    },
  ];

  const data = [
    {
      date: "17-Sep-2022 11:47 AM", // payment_id and this one should be same
      supplier: "ZAIRAA Main Office",
      bank_status: "Confirmed",
      amount: "Issue",
      deposit_slip: "/zairaa.jpeg"
    },
  ];
  const options = {
    filter: false,
    Selection: false,
    print: false,
    download: false,
    viewColumns: false,
    displayRowCheckbox: false,
    selectableRows: "none",
    // onRowClick: (rowData) => {
    //   navigate(`/booking-details/${rowData[3]}/active`)
    // },
  };

  return (
    <div className="pb-5 px-5 mt-5">
      <MUIDataTable
        className="muidatatable"
        title={"Paid Vouchers"}
        data={data}
        columns={columns}
        options={options}
      />
    </div>
  );
};

export default PaidVoucher;
