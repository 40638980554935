import React from "react";
import { Accordion } from "react-bootstrap";

const Contact = () => {
  return (
    <div className="px-5">
      <div className="col-sm-12 mt-5">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <div className="h5">Agency Data</div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="col-sm-12 accordian-custom-body">
                <div className="d-flex justify-content-between col-sm-12">
                  <span>Name:</span>
                  <span>Zairaa Main Office</span>
                </div>
                <div className="d-flex justify-content-between col-sm-12">
                  <span>Email:</span>
                  <span>example@gmail.com</span>
                </div>
                <div className="d-flex justify-content-between col-sm-12">
                  <span>Phone:</span>
                  <span>+92123456789</span>
                </div>
                <div className="d-flex justify-content-between col-sm-12">
                  <span>Address:</span>
                  <span>Blue Area, Islamabad</span>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <div className="col-sm-12 my-5">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <div className="h5">User Data</div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="col-sm-12 accordian-custom-body">
                <div className="d-flex justify-content-between col-sm-12">
                  <span>Name:</span>
                  <span>Demo Two</span>
                </div>
                <div className="d-flex justify-content-between col-sm-12">
                  <span>Email:</span>
                  <span>example@gmail.com</span>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

export default Contact;
