import React from 'react'
import CustomDropdown from "../../../CustomDrp";
import { Button, TextField } from "@mui/material";

function Flight() {
    return (
        <div>
            <Segment />
            <PaxFare />
            <Total />
        </div>
    )

}







{/*  segment section  */ }

const Segment = () => {
    return (
        <>
            <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
                <div className="h5 ">Segment</div>
                <div className='mt-3 mt-md-0'>
                    <Button size="small" variant='contained' color='error' className='me-1 me-md-2'>Remove Segment</Button>
                    <Button size="small" variant='contained'>Add Segment</Button>

                </div>
            </div>
            <div className="row align-items-end my-4">
                <div className='col-4 col-md-2 mt-3 mt-md-0'>
                    <TextField id="standard-basic" label="Ticket Class" variant="standard" className="w-100" />
                </div>
                <div className='col-4 col-md-2 mt-3 mt-md-0'>
                    <TextField id="standard-basic" label="Airline" variant="standard" className="w-100" />
                </div>
                <div className='col-4 col-md-2 mt-3 mt-md-0'>
                    <TextField id="standard-basic" label="Airline Number" variant="standard" className="w-100" />
                </div>
                <div className='col-4 col-md-2 mt-3 mt-md-0'>
                    <TextField id="standard-basic" label="From" variant="standard" className="w-100" />
                </div>
                <div className='col-4 col-md-1 mt-3 mt-md-0'>
                    <TextField id="standard-basic" label="To" variant="standard" className="w-100" />
                </div>
                <div className='col-4 col-md-1 mt-3 mt-md-0'>
                    {/*            to add date picker here                */}
                    <TextField id="standard-basic" label="From" variant="standard" className="w-100" />
                </div>
                <div className='col-4 col-md-2 mt-3 mt-md-0'>
                    <TextField id="standard-basic" label="Seat/Bagage" variant="standard" className='w-100' />
                </div>

            </div>

            <hr className=" horizontal-line" />
        </>
    );
};



{/*  Total section  */ }

const Total = () => {
    return (
        <div className="manual_invoice_footer py-2">

            <div className="h5 text-center my-2">Total</div>
            <div className="d-flex justify-content-center align-items-center flex-column my-4">

                <div className="h6 mb-4">
                    Customer = 330 PKR
                </div>
                <div className="h6">
                    Supplier = 330 PKR
                </div>
                <Button size="small" variant='contained'>Generated</Button>
            </div>

        </div>
    );
};



const PaxFare = () => {
    return (
        <div className="row">
            {/* pax section */}
            <div className="col-12 col-lg-6 pax mt-2 mt-md-0">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="h5 ">PAX</div>
                    <div>
                        <Button size="small" variant='contained' color='error' className='me-2'>Remove PAX</Button>
                        <Button size="small" variant='contained'>Add PAX</Button>

                    </div>
                </div>
                <div className="row pb-3 pt-3">
                    <div className="col-4 col-md-2" >
                        <CustomDropdown arry={['Adult', 'Child', 'Infant']} placehlder='Type' />
                    </div>
                    <div className="col-4 col-md-2" >
                        <CustomDropdown arry={['MR', 'MS', 'MRS']} placehlder='Title' />
                    </div>
                    <div className="col-4 col-md-3">
                        <TextField id="standard-basic" label="F-Name" variant="standard" />
                    </div>
                    <div className="col-4 col-md-3">
                        <TextField id="standard-basic" label="L-Name" variant="standard" />
                    </div>
                    <div className="col-4 col-md-2">
                        <TextField id="standard-basic" label="Ticket No" variant="standard" />
                    </div>

                </div>
            </div>

            {/* fare section */}
            <div className="col-12 col-lg-6 mt-3 mt-md-0">
                <div className="h5 col-12">Fare</div>
                <div className="row">
                    <div className="col-12 my-3">
                        <TextField id="standard-basic" label="EQ Fare" variant="standard" type={'number'} />
                    </div>
                    <div className="row">
                        <div className="col-4 col-md-2 pe-1 my-3">
                            <TextField id="standard-basic" label="S.PSF %" variant="standard" type={'number'} />
                        </div>
                        <div className="col-4 col-md-2 pe-1 my-3">
                            <TextField id="standard-basic" label="S.PSF" variant="standard" type={'number'} />
                        </div>
                        <div className="col-4 col-md-2 pe-1 my-3">
                            <TextField id="standard-basic" label="S.GST" variant="standard" type={'number'} />
                        </div>
                        <div className="col-4 col-md-2 pe-1 my-3">
                            <TextField id="standard-basic" label="S.SP %" variant="standard" type={'number'} />
                        </div>
                        <div className="col-4 col-md-2 pe-1 my-3">
                            <TextField id="standard-basic" label="S.SP" variant="standard" type={'number'} />
                        </div>
                        <div className="col-4 col-md-2 pe-1 my-3">
                            <TextField id="standard-basic" label="S.WHT" variant="standard" type={'number'} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4 col-md-2 pe-1 my-3">

                            <TextField id="standard-basic" label="C.PSF %" variant="standard" type={'number'} />
                        </div>
                        <div className="col-4 col-md-2 pe-1 my-3">
                            <TextField id="standard-basic" label="C.PSF" variant="standard" type={'number'} />
                        </div>
                        <div className="col-4 col-md-2 pe-1 my-3">

                            <TextField id="standard-basic" label="C.GST" variant="standard" type={'number'} />
                        </div>
                        <div className="col-4 col-md-2 pe-1 my-3">

                            <TextField id="standard-basic" label="C.SP %" variant="standard" type={'number'} />
                        </div>
                        <div className="col-4 col-md-2 pe-1 my-3">

                            <TextField id="standard-basic" label="C.SP" variant="standard" type={'number'} />
                        </div>
                        <div className="col-4 col-md-2 pe-1 my-3">

                            <TextField id="standard-basic" label="C.WHT" variant="standard" type={'number'} />
                        </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-center">
                        <div className="h5 my-3">
                            Tax
                        </div>
                        <div className="my-3">
                            <Button size="small" variant='contained' color='error' className='me-2'>Remove Tax</Button>
                            <Button size="small" variant='contained'>Add Tax</Button>

                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-6 col-md-4 my-3 my-md-1">
                            <TextField id="standard-basic" label="e.g YQ" variant="standard" className='w-100' />

                        </div>
                        <div className="col-6 col-md-4 my-3 my-md-1">
                            <TextField id="standard-basic" label="e.g 2000" variant="standard" className='w-100' />

                        </div>



                    </div>
                </div>
            </div>
        </div>
    )
}


export default Flight