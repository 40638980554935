import { Button, TextField } from '@mui/material'
import React from 'react'
import { IoIosClose } from 'react-icons/io'
import CustomDropdown from '../../CustomDrp'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

function AgentGroupPaymentModel({ setShowPaymentModel }) {
    return (
        <div className='backdrop_blur_cotianer'>
            <div className='d-flex justify-content-center align-items-center' style={{ height: '100vh' }}>
                <div className='model_body'>

                    {/* *************** */}
                    <div className='model_header text-white'>
                        <div>
                            New Agent group paymode
                        </div>
                        <div>
                            <IoIosClose size={35} style={{ cursor: 'pointer' }} onClick={e => setShowPaymentModel(false)} />
                        </div>
                    </div>

                    {/* *************************** */}
                    <div className='p-4 py-4' >
                        <div className='my-3'>
                            <CustomDropdown arry={['Book and Pay Later']} placehlder='Paymode' />
                        </div>
                        <div className='my-3'>
                            <TextField label='Payment Cast (amount)' variant='standard' className='w-100' />
                        </div>
                        <div className='my-3'>
                            <TextField label='Payment Cost (%)' variant='standard' className='w-100' />
                        </div>
                        <div className='d-flex justify-content-end align-items-center pt-2 mt-5'>
                            <div className='me-2'>
                                <Button variant='contained' startIcon={<CloseRoundedIcon />} onClick={e => setShowPaymentModel(false)}>Close</Button>
                            </div>
                            <div className=''>
                                <Button variant='contained' startIcon={<CheckRoundedIcon />}>Okay</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AgentGroupPaymentModel