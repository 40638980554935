import React from 'react'
import "../Styles/Footer.css"

const Footer = () => {
  return (
    <footer>
          <div className="row">
              <div className="col-md-4 col-sm-12 text-sm-center text-center text-md-left copy-right"> &#169; {
                "Default Footer Text"}
              </div>
              <div className="col-sm-4 text-center">
              </div>
              <div className="col-md-4 col-sm-12 text-sm-center text-center text-md-right pawered-by">ZAIRAA V 3.5</div>
          </div>
        </footer>
  )
}

export default Footer