import React from 'react';
import Header from '../components/Common/Header'
import SupplierRulesComp from '../components/Setup/SupplierRules'
import Footer from '../components/Footer'

const SupplierRules = () => {
  return (
    <div className='Full_height'>
      <Header active="setup" />
      <SupplierRulesComp />
      <Footer />
    </div>
  )
}

export default SupplierRules