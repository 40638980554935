import React from "react";
import BookingTable from "../components/Booking/BookingTable";
import Header from "../components/Common/Header";

function Booking() {
  return (
    <div className="Full_height">
      <div className="sticky_header">
        <Header active="bookings" />
      </div>
      <BookingTable />
    </div>
  );
}

export default Booking;
