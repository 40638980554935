import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import BankModel from "./Common/BankModel";
import DeleteModal from "../DeleteModal";

const BankDetails = () => {
  const [ShowModel, setShowModel] = useState(false)
  const [rowData, setRowData] = useState({})
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const columns = [
    {
      name: "bank_name",
      label: "Bank Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "branch",
      label: "Branch",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "swift",
      label: "Swift",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "account_no",
      label: "Account No",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "i_ban",
      label: "IBAN",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "beneficiary",
      label: "Beneficiary",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "address",
      label: "Address",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "pk_bank",
      label: "PK Bank",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "collection_bank",
      label: "Collection Bank",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "Action",
      name: "checkbox",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="">
              <button
                className="btn-success rounded edit-delete-icon-button me-1"
                onClick={() => {
                  setShowModel(!ShowModel);
                  setRowData(data[tableMeta.rowIndex])
                }}
              >
                <i className="fa fa-thin fa-pencil"></i>
              </button>
              <button
                className="btn-danger rounded edit-delete-icon-button"
                onClick={() => {
                  setShowDeleteModal(true);
                  setRowData(data[tableMeta.rowIndex]);
                }}
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
              </button>
            </div>
          );
        },
      },
    },
  ];

  const data = [
    {
      id: 1,
      bank_name: "hbl",
      branch: "university road",
      swift: "hdac",
      account_no: "715387215",
      i_ban: "hadije",
      beneficiary: "jasdv",
      address: "hafdhwfciuw",
      pk_bank: "hxsvhsax",
      collection_bank: "dsacx",
    },
  ];
  const options = {
    filter: false,
    Selection: false,
    print: false,
    download: false,
    viewColumns: false,
    displayRowCheckbox: false,
    selectableRows: "none",
  };
  return (
    <div className="col-12 mx-auto rounded-2 shadow mt-5 pb-5 flash-news">
      {
        ShowModel && <BankModel setShowModel={setShowModel} rowData={rowData}/>
      }
      <p className="h4 otawix-heading">Bank Details</p>
      <div className="col-12 px-4 d-flex justify-content-end my-4">
        <button className="setup-btn"
          onClick={e => setShowModel(!ShowModel)}
        >
          Add Bank Details <i class="fa fa-thin fa-plus"></i>
        </button>
      </div>
      <div className="col-12 mt-2 px-4">
      <DeleteModal
          show={showDeleteModal}
          setShow={setShowDeleteModal}
          rowId={rowData}
        />
        <MUIDataTable
          className="muidatatable"
          title={"Bank Details"}
          data={data}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  );
};

export default BankDetails;
