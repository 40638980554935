import React from "react";
import { TextField } from "@mui/material";
import MUIDataTable from "mui-datatables";

const Countries = () => {
  const columns = [
    {
      label: "Status",
      name: "checkbox",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div class="checkbox-rect">
              <input type="checkbox" id="with_sar" name="check" />
              <label for="with_sar" className="h6"></label>
            </div>
          );
        },
      },
    },
    {
      name: "country_name",
      label: "Country Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "country_code",
      label: "Country Code",
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  const data = [
    {
      id: 1,
      country_name: "Dubai",
      country_code: "UAE",
    },
    {
      id: 1,
      country_name: "Dubai",
      country_code: "UAE",
    },
    {
      id: 1,
      country_name: "Dubai",
      country_code: "UAE",
    },
  ];
  const options = {
    filter: false,
    Selection: false,
    print: false,
    download: false,
    viewColumns: false,
    displayRowCheckbox: false,
    selectableRows: "none",
  };
  return (
    <div className="col-12 col-sm-6 mx-auto rounded-2 shadow mt-5 pb-5 flash-news">
      <p className="h4 otawix-heading">Countries</p>
      <div className="col-12 px-4 d-flex flex-column justify-content-center">
        <div className="col-12 my-4 py-3 d-flex justify-content-around">
          <TextField
            id="standard-basic"
            label="Country Name"
            variant="standard"
            className="col-sm-5"
            inputProps={{ maxLength: 50 }}
          />
          <TextField
            id="standard-basic"
            label="Country Code"
            variant="standard"
            className="col-sm-5"
            inputProps={{ maxLength: 50 }}
          />
        </div>
        <button className="btn setup-btn">save</button>
      </div>
      <div className="col-12 mt-5 px-4">
        <MUIDataTable
          className="muidatatable"
          title={"Country Details"}
          data={data}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  );
};

export default Countries;
