import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import AddRoleModel from "./Common/AddRoleModel";

const Role = () => {
  const [ShowModel, setShowModel] = useState(false)
  const [rowData, setRowData] = useState({})
  const columns = [
    {
      label: "Status",
      name: "checkbox",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div class="checkbox-rect">
              <input type="checkbox" id="with_sar" name="check" />
              <label for="with_sar" className="h6"></label>
            </div>
          );
        },
      },
    },
    {
      name: "role_name",
      label: "Role Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "Action",
      name: "actions",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <button
              className="btn-success rounded edit-delete-icon-button"
              onClick={() => {
                setShowModel(!ShowModel);
                setRowData(data[tableMeta.rowIndex])
              }}
            >
              <i className="fa fa-thin fa-pencil"></i>
            </button>
          );
        },
      },
    },
  ];

  const data = [
    {
      id: 1,
      role_name: "Admin",
    },
  ];
  const options = {
    filter: false,
    Selection: false,
    print: false,
    download: false,
    viewColumns: false,
    displayRowCheckbox: false,
    selectableRows: "none",
  };
  return (
    <div className="col-12 col-sm-6 mx-auto rounded-2 shadow my-5 pb-5 flash-news">
      {
        ShowModel && <AddRoleModel setShowModel={setShowModel} rowData={rowData}/>
      }
      <p className="h4 otawix-heading">Role</p>
      <div className="col-12 px-4 d-flex justify-content-end my-4">
        <button className="setup-btn"
          onClick={e => setShowModel(!ShowModel)}
        >
          Add Role <i class="fa fa-thin fa-plus"></i>
        </button>
      </div>
      <div className="col-12 mt-2 px-4">
        <MUIDataTable
          className="muidatatable"
          title={"Role Details"}
          data={data}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  );
};

export default Role;
