import React, {useState} from "react";
import { TextField } from "@mui/material";
import MUIDataTable from "mui-datatables";
import DeleteModal from "../../DeleteModal";

const Promotion = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const columns = [
    {
      name: "id",
      label: "S.No",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "Image",
      name: "image",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <img src={`${value}`} className="table-row-image" />;
        },
      },
    },
    {
      label: "Action",
      name: "delete",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <button
              className="edit-delete-icon-button btn-danger"
              onClick={() => {
                setShowDeleteModal(true);
                setModalData(tableMeta.rowData);
              }}
            >
              <i className="fa fa-trash" aria-hidden="true"></i>
            </button>
          );
        },
      },
    },
  ];

  const data = [
    {
      id: 1,
      image: "/zairaa.jpeg",
    },
  ];
  const options = {
    filter: false,
    Selection: false,
    print: false,
    download: false,
    viewColumns: false,
    displayRowCheckbox: false,
    selectableRows: "none",
    // onRowClick: (rowData) => {
    //   navigate(`/booking-details/${rowData[3]}/active`)
    // },
  };
  return (
    <div className="col-12 col-sm-6 mx-auto rounded-2 shadow mt-5 pb-5 flash-news">
      <p className="h4 otawix-heading">Home Promotion</p>
      <div className="col-12 px-4 d-flex flex-column justify-content-center">
        <div className="col-12 my-4 py-3 me-5">
          <div className="col-sm-12 d-flex flex-column">
            <label htmlFor="" className="mb-2">
              Promotion Image *  <span className="text-danger">(Size:800*300)</span>
            </label>
            <TextField type={"file"} variant="standard" className="col-sm-12" />
          </div>
        </div>
        <button className="btn setup-btn">save</button>
      </div>
      <div className="col-12 mt-5 px-4">
      <DeleteModal
          show={showDeleteModal}
          setShow={setShowDeleteModal}
          rowId={modalData && modalData[0]}
        />
        <MUIDataTable
          className="muidatatable"
          title={"Promotion list"}
          data={data}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  );
};

export default Promotion;
